import React from 'react';
import classNames from 'classnames';
import ButtonBase from './button-base';

import './button-primary.css';

const ButtonPrimary = (props) => {
  const { className, ...extraProps } = props;

  return (
    <ButtonBase
      className={classNames('button-primary', className)}
      {...extraProps}
    />
  );
};

export default ButtonPrimary;
