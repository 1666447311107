import { api } from '../../api';

const fetchAccounts = async (budgetId) => {
  const response = await api.get('/accounts', {
    params: {
      budget_id: budgetId,
    },
  });
  return response.data;
};

export { fetchAccounts };
