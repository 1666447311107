import React from 'react';
import { Route, Redirect, Switch } from 'react-router';

const RedirectAuthenticated = ({
  path,
  exact,
  isAuthenticated,
  willAuthenticate,
  component: Component,
}) => (
  <Switch>
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        if (isAuthenticated) {
          return <Redirect exact={true} to={{ pathname: '/' }} />;
        }
        if (willAuthenticate) {
          return null;
        }
        if (!willAuthenticate && !isAuthenticated) {
          return <Component {...props} />;
        }
        return null;
      }}
    />;
  </Switch>
);

export default RedirectAuthenticated;
