import cn from 'classnames';

const buttonBaseStyles = cn(
  'relative',
  'inline-flex',
  'items-center',
  'rounded',
  'border',
  'border-transparent',
  'px-2.5',
  'py-2',
  'text-base',
  'font-medium',
  'text-white',
  'shadow-sm',
  'hover:cursor-pointer',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2'
);

export { buttonBaseStyles };
