import React from 'react';
import { translate, Trans } from 'react-i18next';

const Summary = () => {
  return (
    <div className="welcome-text-wrapper">
      <h2 className="welcome-text-coming-soon">
        <Trans i18nKey="welcome.comingSoon">Coming Soon</Trans>
      </h2>
    </div>
  );
};

export default translate('common')(Summary);
