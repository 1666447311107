import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import './left-to-budget-mobile.css';

const printSign = (amount) => {
  const printedSign = Number(amount) >= 0 ? '+' : '-';

  return printedSign;
};

const renderLtbAmount = (ltbBreakdown, globalLtbEnabled) => {
  if (globalLtbEnabled) {
    return (ltbBreakdown && ltbBreakdown.globalLeftToBudget) || 0;
  } else {
    return (ltbBreakdown && ltbBreakdown.leftToBudget) || 0;
  }
};

class LeftToBudgetMobileView extends Component {
  state = {
    ltbLoading: true,
  };

  componentDidMount() {
    setTimeout(() => {
      //Start the timer
      this.setState({ ltbLoading: false }); //After 1 second, set render to true
    }, 1300);
  }

  renderLtb = () => {
    const {
      lastMonthString,
      ltbBreakdown,
      settings,
      thisMonthString,
    } = this.props;

    const amountLtb = renderLtbAmount(ltbBreakdown, settings.global_ltb);

    return (
      <div className="ltb-wrapper">
        <p className="ltb-line-item left-to-budget-line-item">
          <span>
            <i className="fas fa-question-circle fa-fw fa-sm" /> Left to Budget
          </span>
          <NumberFormat
            displayType="text"
            className={classNames('ltb-amount', {
              'neg-val': amountLtb && amountLtb < 0,
            })}
            name="fwdFromLastMonth"
            value={amountLtb || 0}
            isNumericString={true}
            allowNegative={true}
            placeholder="0.00"
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={settings.thousands}
            decimalSeparator={settings.decimal}
            prefix={settings.show_symbol ? `${settings.symbol}` : ''}
          />
        </p>
      </div>
    );
  };

  render() {
    return (
      <div className="ltb-mobile-month">
        {this.state.ltbLoading ? (
          <i className="far fa-spinner-third fa-spin ltb-loading" />
        ) : (
          this.renderLtb()
        )}
      </div>
    );
  }
}

export default LeftToBudgetMobileView;
