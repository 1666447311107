import React, { PureComponent } from 'react';
import NewBudgetView from './new-budget-view';

import './new-budget.css';

class NewBudgetContainer extends PureComponent {
  render() {
    const { createNewBudget, currentUserId, onClose } = this.props;

    return (
      <NewBudgetView
        createNewBudget={createNewBudget}
        currentUserId={currentUserId}
        onClose={onClose}
      />
    );
  }
}

export default NewBudgetContainer;
