import React, { useEffect } from 'react';
import axios from 'axios';
import { ELIXIR_API } from '../../constants';

function VerifyEmail({ location }) {
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const confirmationToken = urlParams.get('confirmation_token');
    axios
      .get(`${ELIXIR_API}/verify_email?confirmation_token=${confirmationToken}`)
      .then((res) => {
        window.location = '/';
      });
  }, []);

  return <></>;
}

export default VerifyEmail;
