import React from 'react';
import classNames from 'classnames';
import ButtonPrimary from '../../common/button/button-primary';
import { isMobile } from '../../../utilities/helpers';

import './budget-header.css';

function BudgetHeaderContainer({
  activeMonths,
  addCategoryGroup,
  budgetDetails,
  monthNext,
  monthPrevious,
  settings,
  updateSettings,
}) {
  const { month_view } = settings;
  const { currentMonth } = activeMonths;

  const monthYearFormatter = (date) => {
    const monthYearString = isMobile()
      ? `${date.format('MMM')} '${date.format('YY')}`
      : `${date.format('MMMM')} ${date.format('YYYY')}`;

    return monthYearString;
  };

  const handleMultiMonthToggle = (monthView) => () => {
    const prevSettings = settings;
    const { id: budgetId } = budgetDetails;
    const { id: settingsId } = prevSettings;

    const newSettings = {
      month_view: monthView,
    };

    updateSettings(budgetId, settingsId, prevSettings, newSettings);
  };

  const handleAddCategoryGroup = () => {
    addCategoryGroup(budgetDetails.id);
  };

  return (
    <div className="budget-header-row">
      <div className="budget-header-column">
        <h1 className="page-title mobile-hidden">Budget</h1>
        <div className="month-view-toggle mobile-hidden">
          <span
            className={classNames('toggle-item', {
              'is-toggled': month_view === 'single',
            })}
            onClick={handleMultiMonthToggle('single')}
          >
            Single Month
          </span>
          <span
            className={classNames('toggle-item', {
              'is-toggled': month_view === 'multi',
            })}
            onClick={handleMultiMonthToggle('multi')}
          >
            Multi Month
          </span>
        </div>
      </div>
      <div className="budget-header-column date-selector-wrapper">
        <div className="date-selector">
          <button className="date-previous" onClick={monthPrevious}>
            <i className="far fa-angle-left fa-fw fa-2x" />
          </button>
          <h2 className="date-title">{monthYearFormatter(currentMonth)}</h2>
          <button className="date-next" onClick={monthNext}>
            <i className="far fa-angle-right fa-fw fa-2x" />
          </button>
        </div>
      </div>
      <div className="budget-header-column controls-wrapper mobile-hidden">
        <ButtonPrimary onClick={handleAddCategoryGroup}>
          <i className="fas fa-plus fa-fw button-icon" />
          New Group
        </ButtonPrimary>
      </div>
      <div className="mobile-only">
        <ButtonPrimary
          onClick={handleAddCategoryGroup}
          className="mobile-category-add"
        >
          <i className="fas fa-plus fa-fw button-icon" />
          New Category Group
        </ButtonPrimary>
      </div>
    </div>
  );
}

export default BudgetHeaderContainer;
