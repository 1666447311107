import React, { Component } from 'react';
import moment from 'moment';
import CurrencyInput from 'react-currency-input';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import ButtonPrimary from '../common/button/button-primary';
import ButtonSecondary from '../common/button/button-secondary';

const selectButtonStyles = (backgroundColor, color) => ({
  backgroundColor,
  padding: '0px 0px 0px 0px',
  margin: '6px 6px 6px 6px',
  border: `2px solid ${backgroundColor}`,
  borderRadius: '2px',
  width: 'auto',
  textAlign: 'center',
  color,
});

const customSelectStyles = {
  option: (base, state) => {
    if (state.data.style === 'button') {
      return {
        ...base,
        ...selectButtonStyles('#1b97b3', '#fff'),
      };
    }
    return {
      ...base,
      backgroundColor: state.isFocused ? '#F4F5F6' : '',
      padding: '4px 8px',
      color: state.isSelected ? '#565a5c' : '#565a5c',
    };
  },
  control: (base, state) => ({}),
  groupHeading: (base, state) => ({
    ...base,
    borderBottom: '1px solid #D8D8D8',
    borderTop: '1px solid #D8D8D8',
    padding: '4px 6px',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '10px',
    color: '#384f66',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  }),
  group: (base, state) => ({
    paddingTop: '0 !important',
    marginTop: '0 !important',
  }),
  menu: (base, state) => ({
    ...base,
    width: 'auto',
    minWidth: '125px',
    wrap: 'none !important',
  }),
  menuList: (base, state) => ({
    ...base,
    width: 'auto',
    minWidth: 'auto',
    wrap: 'none !important',
    paddingTop: '0 !important',
    marginTop: '0 !important',
  }),
  singleValue: (base, state) => ({
    ...base,
    ...(state.data.style === 'button'
      ? { ...selectButtonStyles('#2fbfc4', '#fff'), top: '30%', left: '0' }
      : {}),
  }),
};

class MobileNewTransactionForm extends Component {
  state = {
    date: moment()
      .utc()
      .local()
      .toISOString(true),
    type: 'outflow',
    memo: '',
    payeesLoading: false,
  };

  handleCategoryChange = (value) => {
    this.setState({
      category: value,
    });
  };

  handlePayeeChange = (value) => {
    this.setState({
      payee: value,
      createdOptions: value,
    });
  };

  handleDayChange = (date) => {
    if (date) {
      this.setState({
        date,
      });
    } else {
      this.setState({
        date: '',
      });
    }
  };

  handleChange = (event, maskedvalue, floatvalue) => {
    this.setState({
      amount: maskedvalue,
      amount_unmasked: floatvalue,
    });
  };

  handleCreatePayee = (inputValue) => {
    this.setState({ payeesLoading: true });
    setTimeout(() => {
      const { payees } = this.props;
      const newPayee = this.createOption(inputValue);
      this.props.onSavePayee(newPayee.label);
      this.setState({
        payees: [...payees, newPayee],
        payee: newPayee,
        payeesLoading: false,
      });
    }, 0);
  };

  createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
  });

  handleNotesChange = (evt) => {
    this.setState({
      memo: evt.target.value,
    });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const currentDate = moment()
      .utc()
      .local()
      .toISOString(true);

    const { date, payee, amount_unmasked, type, category, memo } = this.state;

    const transactionDetails = {
      amount: amount_unmasked,
      type,
      date: date || currentDate,
      memo,
      deleted: false,
      payee: (payee && payee.label) || '',
      category: (category && category.label) || '',
      category_id: (category && (category.value && category.value.id)) || '',
      is_transfer: false,
    };

    this.props.onSaveMobileTransaction(transactionDetails);

    this.props.onClose();
  };

  render() {
    const {
      amount,
      category,
      date,
      payee,
      memo,
      payeesLoading,
      type,
    } = this.state;

    const { accountDetails, budgetDetails, payees, settings } = this.props;

    return (
      <div className="form-body">
        <div className="expense-income-switch">
          <span
            className={
              type === 'inflow'
                ? 'selectable-pill inflow-selected'
                : 'selectable-pill'
            }
            onClick={() => this.setState({ type: 'inflow' })}
          >
            Income
          </span>
          <span
            className={
              type === 'outflow'
                ? 'selectable-pill outflow-selected'
                : 'selectable-pill'
            }
            onClick={() => this.setState({ type: 'outflow' })}
          >
            Expense
          </span>
        </div>
        <div className="form-group">
          <label className="input-label">Date</label>
          <DayPickerInput
            parseDate={parseDate}
            formatDate={formatDate}
            inputProps={{
              className: 'input-field mobile-date-picker',
            }}
            dayPickerProps={{
              numberOfMonths: settings.num_of_months,
              firstDayOfWeek: settings.first_day,
            }}
            onDayChange={this.handleDayChange}
            // showOverlay={true}
            format={settings.date_format}
            placeholder="---"
            value={date ? formatDate(date, settings.date_format) : ''}
          />
        </div>
        <div className="form-group">
          <label className="input-label">Amount</label>
          <CurrencyInput
            value={amount || null}
            allowNegative={false}
            inputType="decimal"
            autoFocus={true}
            allowEmpty={true}
            placeholder="0"
            thousandSeparator={settings.thousands}
            decimalSeparator={settings.decimal}
            prefix={(settings.show_symbol && settings.symbol) || ''}
            className="input-field"
            onChangeEvent={this.handleChange}
          />
        </div>
        <div className="form-group">
          <label className="input-label">Payee</label>
          <Creatable
            styles={customSelectStyles}
            className="editable-cell-input"
            components={{ DropdownIndicator: null }}
            placeholder="No Payee"
            onChange={this.handlePayeeChange}
            value={payee}
            isLoading={payeesLoading}
            onCreateOption={this.handleCreatePayee}
            openMenuOnFocus={true}
            blurInputOnSelect={true}
            options={payees}
          />
        </div>
        {!accountDetails.off_budget && (
          <div className="form-group">
            <label className="input-label">Category</label>
            <Select
              placeholder="Uncategorized"
              value={category}
              className="editable-cell-input"
              styles={customSelectStyles}
              onChange={this.handleCategoryChange}
              components={{ DropdownIndicator: null }}
              openMenuOnFocus={true}
              blurInputOnSelect={true}
              options={budgetDetails.categoryAndAccountList}
            />
          </div>
        )}
        <div className="form-group">
          <label className="input-label">Notes</label>
          <input
            className="input-field"
            name="budget-name"
            placeholder="Enter notes"
            ref={(input) => (this.nameField = input)}
            value={memo}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleNotesChange}
          />
        </div>
        <div className="form-controls-group">
          <ButtonSecondary onClick={this.props.onClose}>Cancel</ButtonSecondary>
          <ButtonPrimary
            onClick={this.handleFormSubmit}
            isDisabled={!this.state.amount}
          >
            Save Transaction
          </ButtonPrimary>
        </div>
      </div>
    );
  }
}

export default MobileNewTransactionForm;
