import { ACCOUNT_LIST_BY_IDS_UPDATE } from '../action-list';

export default function(state = {}, action) {
  switch (action.type) {
    case ACCOUNT_LIST_BY_IDS_UPDATE:
      return {
        ...state,
        accountsOrder: action.payload,
      };

    default:
      return state;
  }
}
