import React from 'react';
import { connect } from 'react-redux';
import BudgetTableContainer from './budget-table-container';

const BudgetTableConnector = ({ budget, settings }) => {
  return (
    <BudgetTableContainer
      categoriesLength={budget && budget.categories && budget.categories.length}
      settings={settings}
    />
  );
};

const mapStateToProps = ({ budget, settings }) => ({
  budget,
  settings,
});

export default connect(
  mapStateToProps,
  null
)(BudgetTableConnector);
