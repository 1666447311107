import React, { useState } from 'react';
import { useMutation } from 'react-query';
import axios from 'axios';
import { Link as RRLink } from 'react-router-dom';
import cn from 'classnames';
import {
  FormProvider,
  useForm,
  SubmitHandler,
  Controller,
} from 'react-hook-form';

import { Heading } from '../heading';
import { Input } from '../input';
import { Button } from '../button';
import { Box } from '../box';
import { LoggedOutFormContainer } from '../../layout';
import { FASTAPI_API } from '../../../../constants';
import { Link } from '../link';

interface ResetPasswordFields {
  email: string;
}

function ResetPassword() {
  const [emailSent, setEmailSent] = useState(false);

  const methods = useForm<ResetPasswordFields>({
    defaultValues: {
      email: '',
    },
  });
  const { handleSubmit } = methods;

  const mutation = useMutation({
    mutationFn: (email) =>
      axios
        .post(`${FASTAPI_API}/reset-password`, {
          email,
        })
        .then((response) => response.data),
    onSuccess: (response) => {
      setEmailSent(true);
    },
  });

  const onSubmit: SubmitHandler<ResetPasswordFields> = async (data) => {
    // @ts-ignore
    mutation.mutate(data.email);
  };

  return (
    <LoggedOutFormContainer>
      {emailSent ? (
        <>
          <Heading h2 className={cn('text-center', 'mb-6')}>
            Reset email sent
          </Heading>

          <p>
            We just sent you an email with a link to reset your password.
            Usually these emails could take up to 10 minutes to reach your
            inbox. Please check your inbox and follow the instructions in the
            email.
          </p>

          <Box className={cn('mt-6')}>
            <RRLink to={'/login'} component={Link}>
              Back to login
            </RRLink>
          </Box>
        </>
      ) : (
        <>
          <Heading h2 className={cn('text-center', 'mb-6')}>
            Reset your password
          </Heading>

          <p>
            Please enter the email address associated with your account. We’ll
            send you an email with instructions on how to reset your password.
          </p>

          <div className={cn('block', 'py-2')} />

          <FormProvider {...methods}>
            <form className={cn('space-y-6')} onSubmit={handleSubmit(onSubmit)}>
              <Controller
                rules={{ required: 'Email address is required' }}
                render={({ field, fieldState }) => {
                  // console.log(fieldState.error?.message);
                  return (
                    <Input
                      {...field}
                      label="Email address"
                      type="email"
                      error={fieldState.error && fieldState.error.message}
                    />
                  );
                }}
                name={'email'}
              />
              <Button isLoading={mutation.isLoading} fill>
                Send reset email
              </Button>
            </form>
          </FormProvider>
        </>
      )}
    </LoggedOutFormContainer>
  );
}

export { ResetPassword };
