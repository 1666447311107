import React from 'react';
import classNames from 'classnames';

import './button-base.css';

const ButtonBase = (props) => {
  const { children, className, isDisabled, ...extraProps } = props;

  return (
    <button
      className={classNames('button-base', className, {
        [`is-disabled`]: isDisabled,
      })}
      disabled={isDisabled}
      {...extraProps}
    >
      {children}
    </button>
  );
};

export default ButtonBase;
