export const budgetingStyle = [
  { value: true, label: 'Global LTB' },
  { value: false, label: 'Walled-Off Months' },
];

export const hideDueDateOptions = [
  { value: false, label: 'Show' },
  { value: true, label: 'Hide' },
];

export const showSymbolOptions = [
  { value: true, label: 'Show' },
  { value: false, label: 'Hide' },
];

export const thousandsOptions = [
  { value: ',', label: '1,234' },
  { value: '.', label: '1.234' },
  { value: "'", label: `1'234` },
  { value: ' ', label: '1 234' },
];

export const decimalOptions = [
  { value: '.', label: '456.78' },
  { value: ',', label: '456,78' },
  { value: '-', label: `456-78` },
];

export const dateFormatOptions = [
  { value: 'MM/DD/YY', label: 'MM/DD/YY' },
  { value: 'DD/MM/YY', label: 'DD/MM/YY' },
  { value: 'MM-DD-YY', label: 'MM-DD-YY' },
  { value: 'DD-MM-YY', label: 'DD-MM-YY' },
  { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
  { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' },
  { value: 'MM-DD-YYYY', label: 'MM-DD-YYYY' },
  { value: 'DD-MM-YYYY', label: 'DD-MM-YYYY' },
  { value: 'YYYY/MM/DD', label: 'YYYY/MM/DD' },
  { value: 'YYYY/DD/MM', label: 'YYYY/DD/MM' },
  { value: 'YYYY-MM-DD', label: 'YYYY-MM-DD' },
  { value: 'YYYY-DD-MM', label: 'YYYY-DD-MM' },
];

export const weekStartOptions = [
  { value: 0, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
];

export const accountTypeOptions = [
  {
    label: 'Spending & Savings',
    options: [
      { value: 'checking', label: 'Checking' },
      { value: 'cash', label: 'Cash' },
      { value: 'giftcard', label: 'Prepaid Card' },
      { value: 'spending', label: 'Spending - Other' },
      { value: 'savings', label: 'Savings' },
    ],
  },
  {
    label: 'Debt',
    options: [
      { value: 'creditcard', label: 'Credit Card' },
      { value: 'debtother', label: 'Debt - Other' },
    ],
  },
  {
    label: 'Off Budget',
    options: [
      { value: 'mortgage', label: 'Mortgage' },
      { value: 'investment', label: 'Investment' },
      { value: 'offbudget_asset', label: 'Asset - Other' },
      { value: 'offbudget_liability', label: 'Liability - Other' },
    ],
  },
];
