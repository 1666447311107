import React from 'react';
import { connect } from 'react-redux';
import MoveMoneyContainer from './move-money-container';

const MoveMoneyConnector = ({
  allCategories,
  amtRemaining,
  budgetDetails,
  categoryId,
  settings,
}) => {
  return (
    <MoveMoneyContainer
      allCategories={allCategories}
      amtRemaining={amtRemaining}
      budgetDetails={budgetDetails}
      categoryId={categoryId}
      settings={settings}
    />
  );
};

const mapStateToProps = (state, props) => {
  return {
    budgetDetails: state.budgetDetails,
    // TODO: rename the subcategories in state to categories
    allCategories: state.budget.subcategories,
    settings: state.settings,
  };
};

export default connect(mapStateToProps)(MoveMoneyConnector);
