import React from 'react';
import { connect } from 'react-redux';
import { getAccountBalanceSheetFactory } from '../../../../selectors/account-balance-sheet';
import SidebarAccountItemView from './sidebar-account-item-view';

const SidebarAccountsItemConnector = ({
  accountId,
  accountName,
  accountBalanceSheet,
  isOffBudget,
  settings,
  totalUncategorized,
}) => {
  return (
    <SidebarAccountItemView
      accountId={accountId}
      accountName={accountName}
      accountBalanceSheet={accountBalanceSheet}
      isOffBudget={isOffBudget}
      settings={settings}
      totalUncategorized={totalUncategorized}
    />
  );
};

const makeMapStateToProps = () => {
  const getAccountBalanceSheet = getAccountBalanceSheetFactory();

  const mapStateToProps = (state, props) => {
    return {
      accountBalanceSheet: getAccountBalanceSheet(state, {
        match: { params: { id: props.accountId } },
      }),
    };
  };

  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  {}
)(SidebarAccountsItemConnector);
