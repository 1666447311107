import React from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import ButtonPrimary from '../../common/button/button-primary';
import ButtonSecondary from '../../common/button/button-secondary';
import ButtonWarning from '../../common/button/button-warning';
import ButtonLink from '../../common/button/button-link';
import { add } from '../../../utilities/budgeting-formulas';
import { formattedAccountType } from '../../../utilities/account-types';
import { useTransactionsView } from '../../../store/transactionsView';
import './account-header.css';
import { SearchBar } from '../search-bar';

const AccountHeaderView = ({
  accountBalanceSheet,
  accountDetails,
  addTransaction,
  deleteSelectedTransactions,
  isSelectEnabled,
  numOfAccounts,
  onAddMobileTransaction,
  onAddTransfer,
  onToggleSelect,
  // searchString = '',
  settings,
  selectedTransactionsCount,
  transactionBeingEdited,
  updateSearchString,
}) => {
  const { transactionsView, setTransactionsView } = useTransactionsView(
    (state) => state
  );
  const { name, type } = accountDetails || '';
  const totalBalance = add(
    accountBalanceSheet.totalApproved,
    accountBalanceSheet.totalUnapproved
  );

  const onAddTransaction = () => {
    addTransaction();
  };

  return (
    <section className="account-header-wrapper">
      <div className="account-header-controls-row upper-row">
        <div className="account-header-left-column top-row">
          {name && (
            <>
              <span className="account-type mobile-and-tablet-hidden">
                {formattedAccountType(type)}
              </span>
              <br />
              <span className="account-name">{name}</span>
            </>
          )}
        </div>
        <div className="account-header-right-column">
          <div className="account-balance-block tablet-and-desktop-only">
            <span className="balance-description">Total Approved</span>
            <NumberFormat
              displayType="text"
              className="balance-amount"
              name="totalApproved"
              value={accountBalanceSheet.totalApproved}
              isNumericString={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={settings.show_symbol ? `${settings.symbol}` : ''}
              thousandSeparator={settings.thousands}
              decimalSeparator={settings.decimal}
            />
          </div>
          <div className="account-balance-block tablet-and-desktop-only">
            <span className="balance-description">Total Unapproved</span>
            <NumberFormat
              displayType="text"
              className="balance-amount"
              name="totalUnapproved"
              value={accountBalanceSheet.totalUnapproved}
              isNumericString={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={settings.show_symbol ? `${settings.symbol}` : ''}
              thousandSeparator={settings.thousands}
              decimalSeparator={settings.decimal}
            />
          </div>
          <div className="account-balance-block">
            <span className="balance-description">
              {/* <span className="tablet-and-desktop-hidden">
                <i className="fas fa-question-circle fa-fw fa-sm" />{' '}
              </span> */}
              Balance
            </span>
            <NumberFormat
              displayType="text"
              className={classNames('balance-amount balance-total', {
                'transaction-neg-amount': parseFloat(totalBalance) < 0,
              })}
              name="currentBalance"
              value={totalBalance || '0'}
              isNumericString={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={settings.show_symbol ? `${settings.symbol}` : ''}
              thousandSeparator={settings.thousands}
              decimalSeparator={settings.decimal}
            />
          </div>
        </div>
      </div>
      <div className="account-header-controls-row bottom-row tablet-and-desktop-only">
        <div className="account-header-left-column">
          <div className="account-view-toggle">
            <span
              className={classNames('toggle-item', {
                'is-toggled': transactionsView === 'current',
              })}
              onClick={() => setTransactionsView('current')}
            >
              Current Transactions
            </span>
            <span
              className={classNames('toggle-item', {
                'is-toggled': transactionsView === 'scheduled',
              })}
              onClick={() => setTransactionsView('scheduled')}
            >
              Scheduled Transactions
            </span>
          </div>
        </div>
        <div className="account-header-right-column">
          <SearchBar callback={updateSearchString} />
          {!transactionBeingEdited && (
            <>
              {isSelectEnabled ? (
                <>
                  <ButtonLink
                    onClick={onToggleSelect}
                    className="add-transaction-btn tablet-and-desktop-only"
                  >
                    <i className="fas fa-times-square fa-fw button-icon" />
                    Deselect
                  </ButtonLink>
                  <ButtonWarning
                    onClick={deleteSelectedTransactions}
                    isDisabled={selectedTransactionsCount === 0}
                    className="add-transaction-btn tablet-and-desktop-only"
                  >
                    <i className="fas fa-times-octagon fa-fw button-icon" />
                    Delete {selectedTransactionsCount} Transactions
                  </ButtonWarning>
                </>
              ) : (
                <>
                  <ButtonLink
                    onClick={onToggleSelect}
                    className="add-transaction-btn tablet-and-desktop-only"
                  >
                    <i className="far fa-square fa-fw button-icon" />
                    Select
                  </ButtonLink>
                </>
              )}
            </>
          )}
          {!isSelectEnabled && numOfAccounts > 1 && (
            <ButtonSecondary
              onClick={onAddTransfer}
              className="add-transaction-btn tablet-and-desktop-only"
            >
              <i className="fas fa-exchange-alt fa-fw button-icon" />
              Transfer
            </ButtonSecondary>
          )}
          {!isSelectEnabled && (
            <ButtonPrimary
              onClick={onAddTransaction}
              className="add-transaction-btn tablet-and-desktop-only"
            >
              <i className="fas fa-plus fa-fw button-icon" />
              New Transaction
            </ButtonPrimary>
          )}
        </div>
      </div>
      <div className="mobile-only">
        <ButtonPrimary
          onClick={onAddMobileTransaction}
          className="mobile-add-transaction-button"
        >
          <i className="fas fa-plus fa-fw button-icon" />
          New Transaction
        </ButtonPrimary>
      </div>
    </section>
  );
};

export default AccountHeaderView;
