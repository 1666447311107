import React, { Fragment, Component } from 'react';
import ManageBudgetItemView from './manage-budget-item-view';

import './manage-budget-item.css';

class ManageBudgetItemContainer extends Component {
  handleFetchBudget = (budgetId) => {
    this.props.fetchBudget(budgetId);
    this.props.onClose();
  };

  render() {
    const {
      budget,
      defaultBudget,
      deleteBudgetAlert,
      setDefaultBudget,
    } = this.props;

    return (
      <ManageBudgetItemView
        budget={budget}
        defaultBudget={defaultBudget}
        fetchBudget={this.handleFetchBudget}
        deleteBudgetAlert={deleteBudgetAlert}
        setDefaultBudget={setDefaultBudget}
      />
    );
  }
}

export default ManageBudgetItemContainer;
