import {
  TRANSACTIONS_RECEIVED,
  TRANSACTION_SAVED,
  TRANSACTION_DELETED,
  TRANSACTION_ADDED,
  TRANSACTION_REMOVED,
} from '../action-list';

export default function(state = [], action) {
  switch (action.type) {
    case TRANSACTIONS_RECEIVED:
      return action.payload;

    case TRANSACTION_ADDED:
      if (action.payload.id === state[0].id) {
        return state;
      }
      return [action.payload, ...state];

    case TRANSACTION_SAVED:
      const transactionIdx = state.findIndex(
        (obj) => obj.id === action.payload.id
      );
      return state;

    case TRANSACTION_REMOVED:
      const newState = [...state];
      newState.splice(action.payload.index, 1);
      return newState;

    case TRANSACTION_DELETED:
      return state;

    default:
      return state;
  }
}
