import create from 'zustand';

const columns = ['Date', 'Memo', 'Amount', 'Outflow', 'Inflow'];

const selectedColumns = columns.map(() => '');

const useColumnNamesStore = create((set) => ({
  columns,
  selectedColumnsMap: {},

  selectedColumns,
  selectOption: (option, index, csvHeader) => {
    return set((state) => {
      // If element is already in the array
      if (state.selectedColumns.includes(option)) {
        // Create a copy of the current state to avoid mutation
        let newArr = state.selectedColumns;

        // Find the index of the option
        const currentIndex = newArr.indexOf(option);

        // Delete option
        newArr[currentIndex] = '';

        // Save option in the new location
        newArr[index] = option;

        return {
          selectedColumnsMap: {
            ...state.selectedColumnsMap,
            ...{ [option]: option },
          },
          selectedColumns: newArr,
        };
      }

      // Create a copy of the current state to avoid mutation
      let newArr = state.selectedColumns;

      // Add new option to array
      newArr[index] = option;

      return {
        selectedColumnsMap: {
          ...state.selectedColumnsMap,
          ...{ [csvHeader]: option },
        },
        selectedColumns: newArr,
      };
    });
  },
}));

export { useColumnNamesStore };
