import React, { forwardRef } from 'react';
import cn from 'classnames';
import { buttonBaseStyles } from '../button';

// @ts-ignore
const Link = forwardRef(({ navigate, ...props }, ref) => {
  return (
    <a
      className={cn(buttonBaseStyles, 'bg-gray-300', 'text-gray-700')}
      // @ts-ignore
      ref={ref}
      {...props}
    >
      {props.children}
    </a>
  );
});

export { Link };
