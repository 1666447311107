import React from 'react';
import { PropTypes } from 'prop-types';

const Input = ({ input, label, type, placeholder, style, meta }) => (
  <div style={{ marginBottom: '1rem' }}>
    {label && <label htmlFor={input.name}>{label}</label>}
    <input
      {...input}
      type={type}
      placeholder={placeholder}
      className="form-input"
      style={style && style}
    />
    {meta.touched && meta.error && (
      <div style={{ fontSize: '85%', color: 'rgb(255,59,48)' }}>
        {meta.error}
      </div>
    )}
  </div>
);

Input.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  label: PropTypes.node,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  meta: PropTypes.shape({
    touched: PropTypes.any,
    error: PropTypes.node,
  }),
};

export default Input;
