import React, { PureComponent } from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import Popover from 'react-tiny-popover';
import { Parser } from 'expr-eval';
import MoveMoneyTool from '../../../move-money-tool';
import {
  roundToTwoDecimals,
  trimTrailingOperators,
} from '../../../../../utilities/number-formatting';
import { isMobile } from '../../../../../utilities/helpers';

const parser = new Parser();

class BudgetTableCategoryMonthView extends PureComponent {
  budgetInput = React.createRef();

  state = {
    budgetFieldString:
      (this.props.categoryDetailsByMonth &&
        this.props.categoryDetailsByMonth.budgeted) ||
      roundToTwoDecimals(
        (this.props.categoryDetailsByMonth &&
          this.props.categoryDetailsByMonth.budgeted) ||
          0
      ),
    savedBudgetAmount:
      (this.props.categoryDetailsByMonth &&
        this.props.categoryDetailsByMonth.budgeted) ||
      roundToTwoDecimals(
        (this.props.categoryDetailsByMonth &&
          this.props.categoryDetailsByMonth.budgeted) ||
          0
      ),
    isEditingEnabled: false,
  };

  constructor(props) {
    super(props);

    this.budgetInput = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.categoryDetailsByMonth &&
        this.props.categoryDetailsByMonth.budgeted) !==
      (prevProps.categoryDetailsByMonth &&
        prevProps.categoryDetailsByMonth.budgeted)
    ) {
      this.setState({
        budgetFieldString: roundToTwoDecimals(
          (this.props.categoryDetailsByMonth &&
            this.props.categoryDetailsByMonth.budgeted) ||
            0
        ),
        savedBudgetAmount: roundToTwoDecimals(
          (this.props.categoryDetailsByMonth &&
            this.props.categoryDetailsByMonth.budgeted) ||
            0
        ),
      });
    }
  }

  handleBudgetFieldFocus = () => {
    this.setState(
      {
        isEditingEnabled: true,
      },
      () => {
        return (
          this.budgetInput &&
          this.budgetInput.current &&
          this.budgetInput.current.focus()
        );
      }
    );
  };

  handleBudgetEditFieldFocus = (e) => {
    return e.target.select();
  };

  handleBudgetInput = (e) => {
    this.setState({
      budgetFieldString: e.target.value,
    });
  };

  handleCopyLastMonthsBudget = (amtBudgeted = '0.00') => {
    this.setState(
      {
        budgetFieldString: amtBudgeted,
      },
      () => {
        this.handleBlur();
      }
    );
  };

  handleSaveBudgetedField = (e) => {
    // TODO: Refactor this horrible mess into a clean function
    if (e.key === 'Enter' || e.type === 'blur') {
      e.preventDefault();
      e.stopPropagation();

      let expressionString;

      // Evaluate, and convert all custom decimals to a . for calculation
      if (this.props.settings.decimal === ',') {
        expressionString = this.state.budgetFieldString
          .toString()
          .replace(/,/g, '.');
        // .replace(/./g, '');
      } else if (this.props.settings.decimal === '-') {
        expressionString = this.state.budgetFieldString
          .toString()
          .replace(/-/g, '.')
          .replace(/./g, '')
          .replace(/,/g, '');
      } else {
        expressionString = this.state.budgetFieldString
          .toString()
          .replace(/,/g, '');
      }

      const trimmedExpression = trimTrailingOperators(expressionString);

      const evaluatedExpression = parser
        .parse(trimmedExpression || '0')
        .evaluate(trimmedExpression);

      this.setState(
        {
          budgetFieldString:
            roundToTwoDecimals(
              evaluatedExpression,
              this.props.settings.decimal
            ) || '0.00',
          savedBudgetAmount: roundToTwoDecimals(evaluatedExpression) || '0.00',
          isEditingEnabled: false,
        },
        () => {
          this.handleBlur();
        }
      );
    }

    if (e.keyCode === 187 && e.shiftKey) {
      // + symbol
      e.preventDefault();
      e.stopPropagation();
      // this.handleInlineCalculation();
      this.setState((prevState) => {
        const formattedCalculation =
          this.state.budgetFieldString === prevState.budgetFieldString
            ? `${this.state.budgetFieldString}+`
            : this.state.budgetFieldString;

        return {
          budgetFieldString: formattedCalculation,
        };
      });
    }

    if (e.keyCode === 189) {
      // - symbol
      e.preventDefault();
      e.stopPropagation();
      // this.handleInlineCalculation();
      this.setState((prevState) => {
        const formattedCalculation =
          this.state.budgetFieldString === prevState.budgetFieldString
            ? `${this.state.budgetFieldString}-`
            : this.state.budgetFieldString;

        return {
          budgetFieldString: formattedCalculation,
        };
      });
    }

    if (e.keyCode === 191) {
      // / symbol
      e.preventDefault();
      e.stopPropagation();
      // this.handleInlineCalculation();
      this.setState((prevState) => {
        const formattedCalculation =
          this.state.budgetFieldString === prevState.budgetFieldString
            ? `${this.state.budgetFieldString}/`
            : this.state.budgetFieldString;

        return {
          budgetFieldString: formattedCalculation,
        };
      });
    }

    if (e.keyCode === 56 && e.shiftKey) {
      // * symbol
      e.preventDefault();
      e.stopPropagation();
      // this.handleInlineCalculation();
      this.setState((prevState) => {
        const formattedCalculation =
          this.state.budgetFieldString === prevState.budgetFieldString
            ? `${this.state.budgetFieldString}*`
            : this.state.budgetFieldString;

        return {
          budgetFieldString: formattedCalculation,
        };
      });
    }
  };

  handleBlur = () => {
    const {
      categoryDetails,
      categoryDetailsByMonth,
      monthToDisplay,
    } = this.props;

    this.setState({ isEditingEnabled: false });

    const updatedCategoryDetails = {
      ...categoryDetailsByMonth,
      section_id: categoryDetails.section_id,
      category_id: categoryDetails.id,
      budget_id: categoryDetails.budget_id,
      timeframe: monthToDisplay,
      budgeted: parseFloat(
        this.state.budgetFieldString.toString().replace(/,/g, '.')
      ),
    };

    this.props.saveTimeframeSubcategory(
      updatedCategoryDetails,
      categoryDetails.budget_id
    );
  };

  viewActivityTransactions = () => {
    if (
      this.props.categoryActivityByMonth.activity &&
      this.props.categoryActivityByMonth.activity.length
    ) {
      this.props.viewActivityTransactions();
    }
  };

  renderBudgetedField = (budgeted) => {
    const { settings } = this.props;
    const { isEditingEnabled } = this.state;

    return !isEditingEnabled ? (
      <NumberFormat
        displayType="input"
        className="budget-category-input-editable"
        inputmode="decimal"
        name="budgeted"
        value={this.state.budgetFieldString}
        isNumericString={true}
        placeholder={`0${settings.decimal}00`}
        decimalScale={2}
        allowNegative={true}
        fixedDecimalScale={true}
        thousandSeparator={settings.thousands}
        decimalSeparator={settings.decimal}
        onFocus={this.handleBudgetFieldFocus}
      />
    ) : (
      <input
        className="budget-category-input-editable"
        type="text"
        inputmode="decimal"
        ref={this.budgetInput}
        value={this.state.budgetFieldString}
        onFocus={this.handleBudgetEditFieldFocus}
        // onFocus={onBudgetFieldFocus}
        onChange={this.handleBudgetInput}
        onKeyDown={this.handleSaveBudgetedField}
        placeholder={`0${settings.decimal}00`}
        onBlur={this.handleSaveBudgetedField}
      />
    );
  };

  render() {
    const {
      categoryDetails,
      categoryActivityByMonth,
      categoryDetailsByMonth,
      categoryTotalRemainingByMonth,
      isMiddleColumn,
      settings,
    } = this.props;

    const categoryDetailsByMonthWithDefaults = {
      budgeted: 0,
      spent: 0,
      ...categoryDetailsByMonth,
    };

    const { budgeted } = categoryDetailsByMonthWithDefaults;

    return (
      <div
        className={classNames('month-group', {
          'is-middle-column': isMiddleColumn,
        })}
      >
        <div className="budget-column-budgeted">
          <span className="budget-input-wrapper">
            <i
              className="fas fa-angle-double-right fa-lg fa-fw copy-last-month-budget-icon"
              onClick={() =>
                this.handleCopyLastMonthsBudget(
                  categoryDetailsByMonth.lastMonthBudgeted
                )
              }
              data-balloon-pos="left"
              aria-label={`Copy last month's budget of ${roundToTwoDecimals(
                (this.props.categoryDetailsByMonth &&
                  this.props.categoryDetailsByMonth.lastMonthBudgeted) ||
                  0,
                this.props.settings.decimal
              ) || `0.00`}`}
            />
            {this.renderBudgetedField(budgeted)}
          </span>
        </div>
        {!isMobile() && (
          <div className="budget-column-activity">
            <span
              className={classNames({
                'has-activity':
                  categoryActivityByMonth.activity &&
                  categoryActivityByMonth.activity.length,
              })}
              onClick={this.viewActivityTransactions}
            >
              <NumberFormat
                displayType="text"
                className="budget-category-input-editable"
                name="spent"
                value={categoryActivityByMonth.activity || 0}
                isNumericString={true}
                placeholder="---"
                decimalScale={2}
                allowNegative={true}
                fixedDecimalScale={true}
                thousandSeparator={settings.thousands}
                decimalSeparator={settings.decimal}
              />
            </span>
          </div>
        )}
        <div className="budget-column-remaining">
          {/* <Popover
            isOpen={this.state.isPopoverOpen}
            padding={-2}
            position={'bottom'}
            containerClassName="move-money-popover-container"
            // TODO: dont make this inline function for perf
            onClickOutside={() =>
              this.setState({ isPopoverOpen: !this.state.isPopoverOpen })
            }
            content={
              <MoveMoneyTool
                amtRemaining={categoryTotalRemainingByMonth}
                categoryId={categoryDetails.id}
              />
            }
          >
            <span
              className=""
              onClick={() =>
                parseFloat(categoryTotalRemainingByMonth) !== 0 &&
                this.setState({ isPopoverOpen: !this.state.isPopoverOpen })
              }
            > */}
          <NumberFormat
            displayType="text"
            // className={classNames('budget-category-input-editable', {
            //   'neg-val':
            //     categoryTotalRemainingByMonth &&
            //     categoryTotalRemainingByMonth < 0,
            //   underlined: parseFloat(categoryTotalRemainingByMonth) !== 0,
            // })}
            className={classNames('budget-category-input-editable', {
              'neg-val':
                categoryTotalRemainingByMonth &&
                categoryTotalRemainingByMonth < 0,
            })}
            name="spent"
            value={categoryTotalRemainingByMonth}
            isNumericString={true}
            placeholder="0.00"
            decimalScale={2}
            allowNegative={true}
            fixedDecimalScale={true}
            thousandSeparator={settings.thousands}
            decimalSeparator={settings.decimal}
          />
          {/* </span>
          </Popover> */}
        </div>
      </div>
    );
  }
}

export default BudgetTableCategoryMonthView;
