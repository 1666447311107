import React, { Component } from 'react';
import moment from 'moment';
import BudgetTableCategoryMonthView from './budget-table-category-month-view';

class BudgetTableCategoryMonthContainer extends Component {
  selectFieldOnFocus = (e) => {
    return e.target.select();
  };

  viewActivityTransactions = () => {
    const { monthToDisplay, categoryActivityByMonth } = this.props;
    const monthInMonthYear = moment(monthToDisplay, 'MMYYYY').format(
      'MMMM YYYY'
    );
    this.props.onViewActivityTransactions(
      categoryActivityByMonth.transactionsByMonthCategory,
      monthInMonthYear
    );
  };

  render() {
    const {
      categoryActivityByMonth,
      categoryDetails,
      categoryDetailsByMonth,
      categoryTotalRemainingByMonth,
      isMiddleColumn,
      monthToDisplay,
      saveTimeframeSubcategory,
      settings,
    } = this.props;

    return (
      <BudgetTableCategoryMonthView
        categoryActivityByMonth={categoryActivityByMonth}
        categoryDetails={categoryDetails}
        categoryDetailsByMonth={categoryDetailsByMonth}
        categoryTotalRemainingByMonth={categoryTotalRemainingByMonth}
        isMiddleColumn={isMiddleColumn}
        monthToDisplay={monthToDisplay}
        saveTimeframeSubcategory={saveTimeframeSubcategory}
        selectFieldOnFocus={this.selectFieldOnFocus}
        settings={settings}
        viewActivityTransactions={this.viewActivityTransactions}
      />
    );
  }
}

export default BudgetTableCategoryMonthContainer;
