export const currencySymbols = [
  { value: '$', label: '$' },
  { value: '£', label: '£' },
  { value: '€', label: '€' },
  { value: 'Fr', label: 'Fr' },
  { value: 'SEK', label: 'SEK' },
  { value: 'kr', label: 'kr' },
  { value: '₽', label: '₽' },
  { value: '¥', label: '¥' },
  { value: '₩', label: '₩' },
  { value: 'R', label: 'R' },
  { value: '$', label: '$' },
  { value: '₱', label: '₱' },
  { value: '₹', label: '₹' },
];
