import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate, Trans } from 'react-i18next';
import { Chart, Bar } from 'react-chartjs-2';
import axios from 'axios';
import moment from 'moment';
import { headers } from '../../api';
import { ELIXIR_API } from '../../constants';
import './summary.css';

const lastThreeMonths = {
  firstMonth: moment().subtract(3, 'months').format('YYYY-MM-DD'),
  lastMonth: moment().format('YYYY-MM-DD'),
};

const lastSixMonths = {
  firstMonth: moment().subtract(6, 'months').format('YYYY-MM-DD'),
  lastMonth: moment().format('YYYY-MM-DD'),
};

class SummaryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      income: {},
      spend: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.budgetDetails.id === undefined &&
      this.props.budgetDetails.id
    ) {
      this.fetchReport('income', lastThreeMonths);
      this.fetchReport('spend', lastThreeMonths);
    }
  }

  componentDidMount() {
    this.fetchReport('income', lastThreeMonths);
    this.fetchReport('spend', lastThreeMonths);
  }

  fetchReport(type, { firstMonth, lastMonth }) {
    const { id: budgetId } = this.props.budgetDetails;

    return axios({
      method: 'get',
      url: `${ELIXIR_API}/budgets/${budgetId}/reports/${type}?start_date=${firstMonth}&end_date=${lastMonth}`,
      headers: headers(),
    }).then((res) => {
      if (res.data.data.length) {
        this.renderData(type, res.data);
      } else {
        Chart.pluginService.register({
          afterDraw(chart) {
            if (chart.data.datasets.length === 0) {
              // No data is present
              const ctx = chart.chart.ctx;
              const width = chart.chart.width;
              const height = chart.chart.height;
              chart.clear();

              ctx.save();
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.font = "16px normal 'Helvetica Nueue'";
              ctx.fillText(
                'Not enough data to display yet',
                width / 2,
                height / 2
              );
              ctx.restore();
            }
          },
        });
      }
    });
  }

  fetchAllReports(range) {
    this.fetchReport('income', range);
    this.fetchReport('spend', range);
  }

  lastXMonths = ({ target }) => {
    const { value: numOfMonths } = target;
    const range = {
      firstMonth: moment().subtract(numOfMonths, 'months').format('YYYY-MM-DD'),
      lastMonth: moment().format('YYYY-MM-DD'),
    };
    this.fetchAllReports(range);
  };

  renderData = (type, { labels, data }) => {
    let datasetLabel, primaryColor, borderColor;

    if (type === 'income') {
      datasetLabel = 'Inflow';
      primaryColor = 'rgb(80,162,115, 0.75)';
      borderColor = '#EC89D6';
    } else if (type === 'spend') {
      datasetLabel = 'Outflow';
      primaryColor = 'rgb(211, 84, 0, 0.75)';
    }

    const dataObject = {
      labels,
      datasets: [
        {
          label: `${datasetLabel} Line`,
          fill: false,
          lineTension: 0.05,
          backgroundColor: '#FFFFFF',
          borderColor: '#1c96b3',
          borderCapStyle: 'butt',
          borderDash: [15, 5],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 6,
          pointHitRadius: 10,
          type: 'line',
          data,
        },
        {
          label: datasetLabel,
          fill: false,
          lineTension: 0.1,
          backgroundColor: primaryColor,
          borderColor: primaryColor,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 3,
          pointHitRadius: 10,
          data,
        },
      ],
    };

    this.setState({
      [type]: dataObject,
    });
  };

  render() {
    const { income: inflow, spend: outflow } = this.state;
    const { settings } = this.props;
    const chartOptions = {
      maintainAspectRatio: false,
      legend: {
        display: true,
        labels: {
          boxWidth: 30,
        },
      },
      scales: {
        xAxes: [
          {
            barThickness: 65,
          },
        ],
        yAxes: [
          {
            display: true,
            ticks: {
              suggestedMin: 0,
              beginAtZero: true,
              maxTicksLimit: 11,
              callback(value, index, values) {
                return `${
                  (settings.show_symbol && settings.symbol) || ''
                } ${value}`;
              },
            },
          },
        ],
      },
      tooltips: {
        backgroundColor: '#354b61',
        cornerRadius: 4,
        caretSize: 3,
        displayColors: false,
        callbacks: {
          label(tooltipItems, data) {
            return `${
              (settings.show_symbol && settings.symbol) || ''
            }${tooltipItems.yLabel.toString()}`;
          },
        },
      },
    };

    return (
      <div className="summary-page">
        <div className="level top-level summary-top">
          <div className="level-left top-level-padded">
            <div className="level-item">
              <h3 className="header-large" style={{ marginTop: '0' }}>
                Summary & Reports
              </h3>
            </div>
          </div>
          <div className="summary-dropdown-select">
            <div className="level-item">
              <div className="field" style={{ marginBottom: '0px' }}>
                <div className="control">
                  <div className="select is-small">
                    <select placeholder="Select" onChange={this.lastXMonths}>
                      <option value={3}>3 Months</option>
                      <option value={6}>6 Months</option>
                      <option value={12}>Past Year</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-area container is-fluid budget">
          <div className="container is-fluid">
            <div className="columns is-multiline">
              {/* <div className="column is-3 has-text-centered">
                <div className="">
                  <span className="chart-header">Total Inflow</span>
                  <span className="chart-header-details">$4,283.53</span>{' '}
                  <i
                    className="fas fa-caret-up"
                    style={{ marginLeft: '8px', color: '#00B16A' }}
                  />
                </div>
              </div>
              <div className="column is-3 has-text-centered">
                <div className="">
                  <span className="chart-header">Total Outflow</span>
                  <span className="chart-header-details">$2,746.90</span>
                  <i
                    className="fas fa-caret-down"
                    style={{ marginLeft: '8px', color: '#00B16A' }}
                  />
                </div>
              </div>
              <div className="column is-3 has-text-centered">
                <div className="">
                  <span className="chart-header">Total Assets</span>
                  <span className="chart-header-details">$12,973.51</span>
                  <i
                    className="fas fa-caret-down"
                    style={{ marginLeft: '8px', color: '#d35400' }}
                  />
                </div>
              </div>
              <div className="column is-3 has-text-centered">
                <div className="">
                  <span className="chart-header">% of Inflow Spent</span>
                  <span className="chart-header-details">78.4%</span>
                  <i
                    className="fas fa-caret-down"
                    style={{ marginLeft: '8px', color: '#d35400' }}
                  />
                </div>
              </div> */}
              <div className="column is-6" style={{ border: 'none' }}>
                <div
                  className="box"
                  style={{ padding: '8px 15px', border: 'none' }}
                >
                  <span className="chart-header">
                    Inflow Total (Per Month)
                    <i
                      className="fas fa-long-arrow-alt-down"
                      data-fa-transform="rotate-30"
                      style={{ marginLeft: '8px', color: '#00B16A' }}
                    />
                  </span>
                  <Bar data={inflow} ref="chart" options={chartOptions} />
                </div>
              </div>
              <div className="column is-6" style={{ border: 'none' }}>
                <div
                  className="box"
                  style={{ padding: '8px 15px', border: 'none' }}
                >
                  <span className="chart-header">
                    Outflow Total (Per Month)
                    <i
                      className="fas fa-long-arrow-alt-up"
                      data-fa-transform="rotate-30"
                      style={{ marginLeft: '8px', color: '#d35400' }}
                    />
                  </span>
                  <Bar data={outflow} ref="chart" options={chartOptions} />
                </div>
              </div>
              {/* <div className="column is-2" style={{ border: 'none' }}>
                <div
                  className="box"
                  style={{ padding: '12px 15px', border: 'none', height: '100%' }}
                >
                  <span className="chart-header">Upcoming Transactions</span>
                </div>
              </div> */}
              {/* <div className="column is-4" style={{ border: 'none' }}>
              <div
                className="box"
                style={{ padding: '8px', border: 'none', height: '440px' }}
              />
            </div>
            <div className="column is-4" style={{ border: 'none' }}>
              <div
                className="box"
                style={{ padding: '8px', border: 'none', height: '440px' }}
              />
            </div>
            <div className="column is-4" style={{ border: 'none' }}>
              <div
                className="box"
                style={{ padding: '8px', border: 'none', height: '440px' }}
              />
            </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate('common')(SummaryView);

SummaryView.propTypes = {
  budgetDetails: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
};
