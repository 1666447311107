import React, { Component } from 'react';
import Select from 'react-select';
import ButtonPrimary from '../common/button/button-primary';
import ButtonSecondary from '../common/button/button-secondary';
import { currencySymbols } from '../../utilities/currency-symbols';
import {
  showSymbolOptions,
  thousandsOptions,
  decimalOptions,
  dateFormatOptions,
  weekStartOptions,
  hideDueDateOptions,
  budgetingStyle,
} from '../../utilities/settings-options';

const customSelectStyles = {
  option: (base, state) => {
    return {
      ...base,
      backgroundColor: state.isFocused ? '#F4F5F6' : '',
      padding: '4px 8px',
      color: state.isSelected ? '#565a5c' : '#565a5c',
    };
  },
  control: (base, state) => ({
    ...base,
    justifyContent: 'center',
  }),
  placeholder: (base, state) => ({
    ...base,
  }),
  container: (base, state) => ({
    ...base,
    justifyContent: 'center',
  }),
  groupHeading: (base, state) => ({
    ...base,
    borderBottom: '1px solid #D8D8D8',
    borderTop: '1px solid #D8D8D8',
    padding: '0px 0px',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '10px',
    color: '#384f66',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  }),
  group: (base, state) => ({
    paddingTop: '0 !important',
    marginTop: '0 !important',
  }),
  input: (base, state) => ({
    ...base,
    justifyContent: 'center',
    textAlign: 'center',
  }),
  menu: (base, state) => ({
    ...base,
    width: 'auto',
    wrap: 'none !important',
  }),
  menuList: (base, state) => ({
    ...base,
    width: 'auto',
    minWidth: '90px',
    wrap: 'none !important',
    paddingTop: '0 !important',
    marginTop: '0 !important',
  }),
  singleValue: (base, state) => ({
    ...base,
    justifyContent: 'center',
  }),
};

class SettingsForm extends Component {
  state = {
    ...this.props.settings,
  };

  handleCurrencyChange = (selectedOption) => {
    const symbol = selectedOption.value;

    this.setState({
      ...this.state.settings,
      symbol,
    });
  };

  handleThousandsChange = (selectedOption) => {
    const thousands = selectedOption.value;

    this.setState({
      thousands,
    });
  };

  handleDateFormatChange = (selectedOption) => {
    const date_format = selectedOption.value;

    this.setState({
      date_format,
    });
  };

  handleWeekStartChange = (selectedOption) => {
    const first_day = selectedOption.value;

    this.setState({
      first_day,
    });
  };

  handleDecimalChange = (selectedOption) => {
    const decimal = selectedOption.value;

    this.setState({
      decimal,
    });
  };

  handleShowSymbolToggle = (selectedOption) => {
    const show_symbol = selectedOption.value;
    this.setState({
      show_symbol,
    });
  };

  handleCurrencyChange = (selectedOption) => {
    const symbol = selectedOption.value;

    this.setState({
      symbol,
    });
  };

  handleBudgetingStyleChange = (selectedOption) => {
    const global_ltb = selectedOption.value;

    this.setState({
      global_ltb,
    });
  };

  handleDueDateColumnChange = (selectedOption) => {
    const hide_due_date = selectedOption.value;

    this.setState({
      hide_due_date,
    });
  };

  handleFormSubmit = () => {
    const oldSettings = this.props.settings;
    const newSettings = {
      ...this.state,
    };

    if (newSettings.decimal === newSettings.thousands) {
      // this.setState({
      //   alert: true,
      // });
    } else {
      this.props.onSaveSettings(newSettings, oldSettings);
    }

    this.props.onClose();
  };

  render() {
    const { settings } = this.props;

    return (
      <div className="form-body">
        <span className="form-section">Budget</span>
        <div className="form-group form-column">
          <div className="form-split-column">
            <label className="input-label" for="default-currency">
              Budgeting Style
              <i
                className="fas fa-question-circle fa-fw"
                data-balloon-pos="right"
                data-balloon-length="large"
                data-balloon-break
                aria-label="Global LTB considers all future budgeted amounts when calculating your Left to Budget.&#13;&#10;Walled Off Months lets you split up income by month, and prevents future budget months from affecting the current month"
              />
            </label>
            <Select
              className="long-select input-select"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              backspaceRemovesValue={true}
              styles={customSelectStyles}
              defaultValue={budgetingStyle.find((option) => {
                return option.value === settings.global_ltb;
              })}
              // defaultMenuIsOpen={true}
              placeholder="---"
              onChange={this.handleBudgetingStyleChange}
              components={{ ClearIndicator: null, IndicatorSeparator: null }}
              name="input-select"
              options={budgetingStyle}
            />
          </div>
          <div className="form-split-column">
            <label className="input-label" for="hide-symbol">
              Due Date Column
            </label>
            <Select
              className="input-select"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              backspaceRemovesValue={true}
              styles={customSelectStyles}
              placeholder="---"
              defaultValue={hideDueDateOptions.find((option) => {
                return option.value === settings.hide_due_date;
              })}
              onChange={this.handleDueDateColumnChange}
              components={{ ClearIndicator: null, IndicatorSeparator: null }}
              name="input-select"
              options={hideDueDateOptions}
            />
          </div>
        </div>

        <span className="form-section">Currency</span>
        <div className="form-group form-column">
          <div className="form-split-column">
            <label className="input-label" for="default-currency">
              Default Currency
            </label>
            <Select
              className="input-select"
              classNamePrefix="select"
              defaultValue={currencySymbols.find((option) => {
                return option.value === settings.symbol;
              })}
              isClearable={true}
              isSearchable={true}
              backspaceRemovesValue={true}
              styles={customSelectStyles}
              placeholder="---"
              onChange={this.handleCurrencyChange}
              components={{ ClearIndicator: null, IndicatorSeparator: null }}
              name="input-select"
              options={currencySymbols}
            />
          </div>
          <div className="form-split-column">
            <label className="input-label" for="hide-symbol">
              Show/Hide Symbol
            </label>
            <Select
              className="input-select"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              defaultValue={showSymbolOptions.find((option) => {
                return option.value === settings.show_symbol;
              })}
              backspaceRemovesValue={true}
              styles={customSelectStyles}
              placeholder="---"
              onChange={this.handleShowSymbolToggle}
              components={{ ClearIndicator: null, IndicatorSeparator: null }}
              name="input-select"
              options={showSymbolOptions}
            />
          </div>
          <div className="form-split-column">
            <label className="input-label" for="thousands">
              Thousands
            </label>
            <Select
              className="input-select"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              backspaceRemovesValue={true}
              styles={customSelectStyles}
              defaultValue={thousandsOptions.find((option) => {
                return option.value === settings.thousands;
              })}
              // defaultMenuIsOpen={true}
              placeholder="---"
              onChange={this.handleThousandsChange}
              components={{ ClearIndicator: null, IndicatorSeparator: null }}
              name="input-select"
              options={thousandsOptions}
            />
          </div>
          <div className="form-split-column">
            <label className="input-label" for="decimal">
              Decimal
            </label>
            <Select
              className="input-select"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              backspaceRemovesValue={true}
              styles={customSelectStyles}
              placeholder="---"
              defaultValue={decimalOptions.find((option) => {
                return option.value === settings.decimal;
              })}
              onChange={this.handleDecimalChange}
              components={{ ClearIndicator: null, IndicatorSeparator: null }}
              name="input-select"
              options={decimalOptions}
            />
          </div>
        </div>

        <span className="form-section">Date</span>
        <div className="form-group form-column">
          <div className="form-split-column">
            <label className="input-label" for="default-currency">
              Date Format
            </label>
            <Select
              className="input-select"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              backspaceRemovesValue={true}
              styles={customSelectStyles}
              defaultValue={dateFormatOptions.find((option) => {
                return option.value === settings.date_format;
              })}
              // defaultMenuIsOpen={true}
              placeholder="---"
              onChange={this.handleDateFormatChange}
              components={{ ClearIndicator: null, IndicatorSeparator: null }}
              name="input-select"
              options={dateFormatOptions}
            />
          </div>
          <div className="form-split-column">
            <label className="input-label" for="hide-symbol">
              Week Starts On
            </label>
            <Select
              className="input-select"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              backspaceRemovesValue={true}
              styles={customSelectStyles}
              placeholder="---"
              defaultValue={weekStartOptions.find((option) => {
                return option.value === settings.first_day;
              })}
              onChange={this.handleWeekStartChange}
              components={{ ClearIndicator: null, IndicatorSeparator: null }}
              name="input-select"
              options={weekStartOptions}
            />
          </div>
        </div>

        <div className="form-controls-group">
          <ButtonSecondary onClick={this.props.onClose}>Cancel</ButtonSecondary>
          <ButtonPrimary onClick={this.handleFormSubmit}>
            Apply Changes
          </ButtonPrimary>
        </div>
      </div>
    );
  }
}

export default SettingsForm;
