import React, { Component } from 'react';
import ButtonPrimary from '../common/button/button-primary';
import ButtonSecondary from '../common/button/button-secondary';

class EditNotesForm extends Component {
  state = {
    notes: this.props.accountDetails.budget_notes,
  };

  componentDidMount() {
    this.notesInput.focus();
  }

  handleNotesChange = (event) => {
    this.setState({ notes: event.target.value });
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      this.handleSave();
    }
  };

  handleSave = () => {
    const { accountDetails } = this.props;

    this.props.onSave(
      accountDetails.id,
      this.state.notes,
      accountDetails.budget_id
    );
    this.props.onClose();
  };

  render() {
    const { accountDetails } = this.props;
    const { notes } = this.state;

    return (
      <div className="form-body">
        <div className="form-group">
          <label className="input-label" htmlFor="budget-name">
            Notes for {accountDetails.name}
          </label>
          <textarea
            className="input-field"
            name="budget-name"
            placeholder="Enter notes"
            ref={(input) => {
              this.notesInput = input;
            }}
            value={notes}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleNotesChange}
          />
        </div>
        <div className="form-controls-group">
          <ButtonSecondary onClick={this.props.onClose}>Cancel</ButtonSecondary>
          <ButtonPrimary onClick={this.handleSave}>Apply Changes</ButtonPrimary>
        </div>
      </div>
    );
  }
}

export default EditNotesForm;
