import { useMutation } from 'react-query';
import { addTransactions } from './add-transactions';

function useAddTransactions() {
  return useMutation((payload) => {
    return addTransactions(payload);
  });
}

export { useAddTransactions };
