import React from 'react';
import { Route } from 'react-router-dom';
import AccountView from '../../account';
import Header from './header';
import { PageBilling } from '../../../v1/pages/billing';

import './content-area.css';

function ContentAreaView({ routes, onToggleSidebar }) {
  return (
    <section className="app-viewport">
      <Header toggleSidebar={onToggleSidebar} />
      {routes.map((route) => (
        <Route
          key={route.label}
          path={route.path}
          component={route.render}
          exact={route.exact}
        />
      ))}
      <Route
        path="/:budget/accounts/:id"
        component={() => <AccountView />}
        exact={true}
      />
      <Route path="/subscribe" component={PageBilling} exact={true} />
    </section>
  );
}

export default ContentAreaView;
