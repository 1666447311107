import React from 'react';
import { connect } from 'react-redux';
import BudgetTableCategoryGroupContainer from './budget-table-category-group-container';
import { getCategoriesByGroupIdFactory } from '../../../../selectors/categories';
import {
  addCategory,
  deleteCategoryGroupRow,
  saveCategoryGroup,
  updateSubcategoryOrderInGroup,
  updateSubcategoryOrderInGroupLocally,
} from '../../../../actions/budget';

const BudgetTableCategoryGroupConnector = ({
  activeMonths,
  addCategory,
  categoryGroupDetails,
  categories,
  deleteCategoryGroupRow,
  index,
  saveCategoryGroup,
  settings,
  updateSubcategoryOrderInGroup,
  updateSubcategoryOrderInGroupLocally,
}) => {
  return (
    <BudgetTableCategoryGroupContainer
      activeMonths={activeMonths}
      addCategory={addCategory}
      deleteCategoryGroupRow={deleteCategoryGroupRow}
      index={index}
      saveCategoryGroup={saveCategoryGroup}
      settings={settings}
      categories={categories}
      categoryGroupDetails={categoryGroupDetails}
      updateSubcategoryOrderInGroup={updateSubcategoryOrderInGroup}
      updateSubcategoryOrderInGroupLocally={
        updateSubcategoryOrderInGroupLocally
      }
    />
  );
};

const makeMapStateToProps = () => {
  const getCategoriesByGroupId = getCategoriesByGroupIdFactory();

  const mapStateToProps = (state, props) => {
    return {
      activeMonths: state.activeMonths,
      settings: state.settings,
      categories: getCategoriesByGroupId(state, props),
    };
  };
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  {
    addCategory,
    deleteCategoryGroupRow,
    saveCategoryGroup,
    updateSubcategoryOrderInGroup,
    updateSubcategoryOrderInGroupLocally,
  }
)(BudgetTableCategoryGroupConnector);
