import { createSelector } from 'reselect';
import moment from 'moment';
import { sumTimeframeSubcategories } from '../utilities/budgeting-formulas';

const getCurrentTimeframe = (state, { monthToDisplay }) => monthToDisplay;

const getAllTimeframes = ({ timeframes }) => timeframes;

const getAllTimeframeSubcategoriesByCategory = (
  { budget },
  { accountDetails }
) => {
  if (
    budget.cc_timeframe_subcategories &&
    budget.cc_timeframe_subcategories.length
  ) {
    return budget.cc_timeframe_subcategories.filter((ccTimeframeSubcat) => {
      return ccTimeframeSubcat.cc_account_id === accountDetails.id;
    });
  }

  return [];
};

const getTimeframeSubcategoryByMonth = (
  { budget },
  { accountDetails, monthToDisplay }
) => {
  if (
    budget.cc_timeframe_subcategories &&
    budget.cc_timeframe_subcategories.length
  ) {
    const cherryPickedTimeframeSubcategory = budget.cc_timeframe_subcategories.filter(
      (cc_timeframe_subcategory) => {
        return (
          cc_timeframe_subcategory.cc_account_id === accountDetails.id &&
          cc_timeframe_subcategory.timeframe === monthToDisplay
        );
      }
    );

    return cherryPickedTimeframeSubcategory[0];
  }

  return [];
};

export const getCreditCardDetailsByMonthFactory = () => {
  return createSelector(
    getTimeframeSubcategoryByMonth,
    (timeframeSubcategory) => {
      return timeframeSubcategory;
    }
  );
};

export const getCreditCardTotalRemainingByMonthFactory = () => {
  return createSelector(
    [
      getCurrentTimeframe,
      getAllTimeframeSubcategoriesByCategory,
      getAllTimeframes,
    ],
    (currentTimeframe, timeframeSubcategoriesByCategory, timeframes) => {
      return sumTimeframeSubcategories(
        currentTimeframe,
        timeframes,
        timeframeSubcategoriesByCategory,
        (subCat, month) => {
          return (
            subCat.timeframe === month.format('MMYYYY') &&
            moment(subCat.timeframe, 'MMYYYY').isSameOrBefore(
              moment(currentTimeframe, 'MMYYYY')
            )
          );
        }
      );
    }
  );
};
