import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Flex } from '../box';

interface ContainerProps {
  children: ReactNode;
  className?: string;
}

function Container({ children, className }: ContainerProps) {
  const classes = classNames('flex-col', 'py-4', 'px-8', className);
  return <Flex className={classes}>{children}</Flex>;
}

export { Container };
