import React from 'react';
import { connect } from 'react-redux';
import BudgetTableCCCategoryMonthContainer from './budget-table-cc-category-month-container';
import {
  getCreditCardDetailsByMonthFactory,
  getCreditCardTotalRemainingByMonthFactory,
} from '../../../../../selectors/credit-cards';
import { saveTimeframeSubcategory } from '../../../../../actions/budget';

const BudgetTableCCCategoryMonthConnector = ({
  accountDetails,
  creditCardDetailsByMonth,
  creditCardTotalRemainingByMonth,
  isMiddleColumn,
  monthToDisplay,
  saveTimeframeSubcategory,
  settings,
}) => {
  return (
    <BudgetTableCCCategoryMonthContainer
      accountDetails={accountDetails}
      creditCardDetailsByMonth={creditCardDetailsByMonth}
      creditCardTotalRemainingByMonth={creditCardTotalRemainingByMonth}
      isMiddleColumn={isMiddleColumn}
      monthToDisplay={monthToDisplay}
      saveTimeframeSubcategory={saveTimeframeSubcategory}
      settings={settings}
    />
  );
};

const makeMapStateToProps = () => {
  const getCreditCardDetailsByMonth = getCreditCardDetailsByMonthFactory();
  const getCreditCardTotalRemainingByMonth = getCreditCardTotalRemainingByMonthFactory();

  const mapStateToProps = (state, props) => {
    return {
      creditCardDetailsByMonth: getCreditCardDetailsByMonth(state, props),
      creditCardTotalRemainingByMonth: getCreditCardTotalRemainingByMonth(
        state,
        props
      ),
      settings: state.settings,
    };
  };
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  { saveTimeframeSubcategory }
)(BudgetTableCCCategoryMonthConnector);
