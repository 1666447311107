import React from 'react';
import { connect } from 'react-redux';
import {
  addCategoryGroup,
  monthNext,
  monthPrevious,
  updateSettings,
} from '../../../actions/budget';
import BudgetHeaderContainer from './budget-header-container';

const BudgetHeaderConnector = ({
  addCategoryGroup,
  activeMonths,
  budgetDetails,
  monthNext,
  monthPrevious,
  settings,
  updateSettings,
}) => {
  return (
    <BudgetHeaderContainer
      activeMonths={activeMonths}
      addCategoryGroup={addCategoryGroup}
      budgetDetails={budgetDetails}
      settings={settings}
      monthNext={monthNext}
      monthPrevious={monthPrevious}
      updateSettings={updateSettings}
    />
  );
};

const mapStateToProps = ({ activeMonths, budgetDetails, settings }) => ({
  activeMonths,
  budgetDetails,
  settings,
});

const mapDispatchToProps = {
  addCategoryGroup,
  monthPrevious,
  monthNext,
  updateSettings,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BudgetHeaderConnector);
