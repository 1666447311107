import React from 'react';
import { connect } from 'react-redux';
import { login } from '../../actions/session';
import LoginForm from '../forms/login-form';

function Login({ session }) {
  return <LoginForm errors={session.errors} />;
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

export default connect(mapStateToProps, { login })(Login);
