import React from 'react';
import { connect } from 'react-redux';
import BudgetTableCategoryMonthContainer from './budget-table-category-month-container';
import {
  getCategoryActivityByMonthFactory,
  getCategoryDetailsByMonthFactory,
  getCategoryTotalRemainingByMonthFactory,
} from '../../../../../selectors/categories';
import { saveTimeframeSubcategory } from '../../../../../actions/budget';

const BudgetTableCategoryMonthConnector = ({
  categoryActivityByMonth,
  categoryDetails,
  categoryDetailsByMonth,
  categoryTotalRemainingByMonth,
  isMiddleColumn,
  monthToDisplay,
  onViewActivityTransactions,
  saveTimeframeSubcategory,
  settings,
}) => {
  return (
    <BudgetTableCategoryMonthContainer
      categoryActivityByMonth={categoryActivityByMonth}
      categoryDetails={categoryDetails}
      categoryDetailsByMonth={categoryDetailsByMonth}
      categoryTotalRemainingByMonth={categoryTotalRemainingByMonth}
      isMiddleColumn={isMiddleColumn}
      monthToDisplay={monthToDisplay}
      onViewActivityTransactions={onViewActivityTransactions}
      saveTimeframeSubcategory={saveTimeframeSubcategory}
      settings={settings}
    />
  );
};

const makeMapStateToProps = () => {
  const getCategoryActivityByMonth = getCategoryActivityByMonthFactory();
  const getCategoryDetailsByMonth = getCategoryDetailsByMonthFactory();
  const getCategoryTotalRemainingByMonth = getCategoryTotalRemainingByMonthFactory();

  const mapStateToProps = (state, props) => {
    return {
      categoryActivityByMonth: getCategoryActivityByMonth(state, props),
      categoryDetailsByMonth: getCategoryDetailsByMonth(state, props),
      categoryTotalRemainingByMonth: getCategoryTotalRemainingByMonth(
        state,
        props
      ),
      settings: state.settings,
    };
  };
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  { saveTimeframeSubcategory }
)(BudgetTableCategoryMonthConnector);
