import React, { useRef, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import * as csvParser from 'papaparse';
import toast from 'react-hot-toast';
import { FaTrashAlt } from 'react-icons/fa';
import { v4 as uuid } from 'uuid';
import { Container } from '../../ui/container';
import { useAccounts } from '../../accounts/api/queries';
import { useImports } from '../../imports';
import { useUserStore } from '../../user';
import { useColumnNamesStore } from './column-names-store';
import { useAddTransactions } from '../../transactions';
import { useDeleteImport } from '../../imports/api/mutations';
import ButtonPrimary from '../../../components/common/button/button-primary';
import { fetchAllAccountsAndTransactions } from '../../../actions/account';
import { useQueryClient } from 'react-query';

/**
 * @param {number} index
 * @param {string} defaultOption
 * @param {array} givenCsvHeaders - CSV headers parsed from given file
 * @returns {JSX.Element}
 * @constructor
 */
const SelectColumnName = ({
  index,
  defaultOption = '',
  givenCsvHeaders = [],
}) => {
  const { columns, selectedColumns, selectOption } = useColumnNamesStore(
    (state) => state
  );

  const csvHeader = useMemo(
    () => givenCsvHeaders.at(index),
    [index, givenCsvHeaders]
  );

  useEffect(() => {
    if (columns.includes(defaultOption)) {
      selectOption(defaultOption, index, csvHeader);
    }
  }, []);

  /**
   * @param {ChangeEvent<HTMLSelectElement>} event
   */
  const handleSelect = (event) => {
    const { value } = event.target;

    selectOption(value, index, csvHeader);
  };

  const options = ['Select', ...columns];

  return (
    <select onChange={handleSelect}>
      {options.map((option) => {
        // Check selection with store's state
        const selected = selectedColumns[index] === option;

        // We keep "Select" valueless
        const value = option === 'Select' ? null : option;

        return (
          <option key={value} value={value} selected={selected}>
            {option}
          </option>
        );
      })}
    </select>
  );
};

function PageImport({ fetchAllAccountsAndTransactions }) {
  const queryClient = useQueryClient();
  const fileInputRef = useRef();
  const budget = useUserStore((state) => state.budget);
  const { selectedColumnsMap, selectedColumns } = useColumnNamesStore(
    (state) => state
  );

  const [selectedAccount, setSelectedAccount] = useState('');

  // Updates the list of imports
  const refreshImports = async () => {
    const importsQueryKey = ['IMPORTS', { accountId: selectedAccount }];
    await queryClient.invalidateQueries(importsQueryKey);
  };

  // In reality, it's file name, but we will change later in the database and here
  const [fileURL, setFileURL] = useState('');

  const addTransactionsMutation = useAddTransactions();

  const accounts = useAccounts(budget, {
    onSuccess: (data) => {
      setSelectedAccount(data[0].id);
    },
  });

  const deleteImport = useDeleteImport();

  const imports = useImports(selectedAccount, {
    enabled: !accounts.isLoading,
  });

  const [csvSample, setCsvSample] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);

  // Checks if the columns selected are "Date", "Memo" and either "Amount",
  // "Outflow", or "Inflow"
  const allowSubmission = (() => {
    const dateAndMemo = ['Date', 'Memo'];
    const hasDateAndMemo = dateAndMemo.every((item) =>
      selectedColumns.includes(item)
    );
    const hasAmountOrFlows =
      selectedColumns.includes('Amount') ||
      selectedColumns.includes('Outflow') ||
      selectedColumns.includes('Inflow');

    return hasDateAndMemo && hasAmountOrFlows;
  })();

  if (accounts.isLoading) {
    return (
      <Container>
        <p>Loading...</p>
      </Container>
    );
  }

  // We are doing lots of calculations and probably getting O(n^2) complexity.
  // We could probably mutate the headers on selection.
  const formatCsvData = (csvData) => {
    return csvData.map((dataItem, index) => {
      let newItem = {};

      // Replace given (from csv file) headers with user selected headers
      Object.entries(dataItem).forEach((obj) => {
        const [key, value] = obj;
        const newKey = selectedColumnsMap[key];

        // Avoid unselected columns
        if (newKey) {
          newItem[newKey.toLowerCase()] = value;
        }
      });

      newItem['id'] = uuid();
      return newItem;
    });
  };

  const cleanImportInfo = () => {
    setCsvData([]);
    setCsvHeaders([]);
    setCsvSample([]);
    fileInputRef.current.value = '';
  };

  const handleSubmitCSVData = () => {
    const importingTransactionsToast = toast.loading(
      'Importing transactions...'
    );
    const formattedData = formatCsvData(csvData);

    const payload = {
      transactions: formattedData,
      file_url: fileURL,
      account_id: selectedAccount,
      budget_id: budget,
    };

    addTransactionsMutation.mutate(payload, {
      onSuccess: async () => {
        toast.success('Transactions imported!', {
          id: importingTransactionsToast,
        });
        cleanImportInfo();
        fetchAllAccountsAndTransactions(budget);
        await refreshImports();
      },
      onError: () => {
        toast.error('Error trying to import transactions', {
          id: importingTransactionsToast,
        });
      },
    });
  };

  /**
   * Parses the CSV file
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const handleFile = (event) => {
    let file;
    if (event.target.files) {
      file = event.target.files[0];
      setFileURL(file.name);
      csvParser.parse(file, {
        skipEmptyLines: true,
        header: true,
        preview: 0,
        complete(file) {
          setCsvSample([...file.data.slice(0, 5)]);
          setCsvData(file.data);
          setCsvHeaders(file.meta.fields);
        },
      });
    }
  };

  const confirmImportDelete = (id) => {
    if (
      window.confirm(
        'Are you sure you want to delete your import? All of the transactions imported will be deleted as well. This action cannot be undone.'
      )
    ) {
      const deleteImportToast = toast.loading(
        'Deleting the selected import and all related transactions'
      );
      deleteImport.mutate(id, {
        async onSuccess() {
          fetchAllAccountsAndTransactions(budget);

          toast.success('Import deleted!', { id: deleteImportToast });

          await refreshImports();
        },
        onError() {
          toast.error('There was an error while trying to delete the import.', {
            id: deleteImportToast,
          });
        },
      });
    }
  };

  return (
    <Container>
      <div className="budget-header-row">
        <div className="budget-header-column">
          <h1 className="page-title p-2">Import</h1>
        </div>
      </div>

      <div className="flex my-3 p-4 bg-gray-100 border border-gray-300">
        <div className="flex flex-col grow">
          <form>
            <div>
              <p className="mb-1">Select account:</p>
              <select
                value={selectedAccount}
                onChange={(event) => {
                  setSelectedAccount(event.target.value);
                }}
              >
                {accounts.data.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="py-4">
              <p className="mb-1">Browse to the file on your computer</p>
              <label className="block">
                <input
                  type="file"
                  // className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"

                  onChange={handleFile}
                  ref={fileInputRef}
                />
              </label>
            </div>
          </form>
          <div className="flex">
            <table className="divide-y divide-gray-200 table-fixed">
              <thead className="bg-gray-50">
                <tr>
                  {csvHeaders.map((header, index) => (
                    <th
                      key={header}
                      scope="col"
                      className="whitespace-nowrap p-3 text-left text-sm font-semibold"
                    >
                      <SelectColumnName
                        index={index}
                        defaultOption={header}
                        givenCsvHeaders={csvHeaders}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {csvSample.map((csvItem) => {
                  const row = csvHeaders.map((header) => (
                    <td
                      key={header}
                      className="whitespace-nowrap px-2 py-2 text-sm text-gray-800"
                    >
                      {csvItem[header]}
                    </td>
                  ));

                  return <tr>{row}</tr>;
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex grow flex-col justify-start">
          {imports.data && (
            <ul>
              {imports.data.map(({ id, file_url, inserted_at }) => {
                return (
                  <li className="flex items-center mb-1" key={file_url}>
                    <span className="font-bold">{inserted_at}</span>: {file_url}{' '}
                    <button onClick={() => confirmImportDelete(id)}>
                      <FaTrashAlt className="text-red-700 ml-2" />
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>

      <ButtonPrimary
        isDisabled={!allowSubmission}
        onClick={handleSubmitCSVData}
      >
        Import transactions
      </ButtonPrimary>
    </Container>
  );
}

// We need to connect to Redux to despatch the legacy Redux dispatchers
const PageImportConnected = connect(null, { fetchAllAccountsAndTransactions })(
  PageImport
);

export { PageImportConnected };
