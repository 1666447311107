import React, { Fragment, PureComponent } from 'react';
import { Portal } from 'react-portal';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import DayPickerWithRecurrence from './day-picker-with-recurrence';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import ButtonPrimary from '../../../common/button/button-primary';
import ButtonSecondary from '../../../common/button/button-secondary';
import ButtonLink from '../../../common/button/button-link';
import ButtonBase from '../../../common/button/button-base';
import { thisMonthAndNextMonthInMMMM } from '../../../../utilities/helpers';
import {
  sum,
  sub,
  add,
  greaterThan,
  equals,
} from '../../../../utilities/budgeting-formulas';

const selectButtonStyles = (backgroundColor, color) => ({
  backgroundColor,
  padding: '0px 0px 0px 0px',
  margin: '6px 6px 6px 6px',
  border: `2px solid ${backgroundColor}`,
  borderRadius: '2px',
  width: 'auto',
  textAlign: 'center',
  color,
});

const customSelectStyles = {
  option: (base, state) => {
    if (state.data.style === 'button') {
      return {
        ...base,
        ...selectButtonStyles('#1b97b3', '#fff'),
      };
    }
    return {
      ...base,
      backgroundColor: state.isFocused ? '#F4F5F6' : '',
      padding: '4px 8px',
      color: state.isSelected ? '#565a5c' : '#565a5c',
    };
  },
  control: (base, state) => ({}),
  groupHeading: (base, state) => ({
    ...base,
    borderBottom: '1px solid #D8D8D8',
    borderTop: '1px solid #D8D8D8',
    padding: '4px 6px',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '10px',
    color: '#384f66',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  }),
  group: (base, state) => ({
    paddingTop: '0 !important',
    marginTop: '0 !important',
  }),
  menu: (base, state) => ({
    ...base,
    width: 'auto',
    minWidth: '125px',
    wrap: 'none !important',
  }),
  menuList: (base, state) => ({
    ...base,
    width: 'auto',
    minWidth: 'auto',
    wrap: 'none !important',
    paddingTop: '0 !important',
    marginTop: '0 !important',
  }),
  singleValue: (base, state) => ({
    ...base,
    ...(state.data.style === 'button'
      ? { ...selectButtonStyles('#2fbfc4', '#fff'), top: '30%', left: '0' }
      : {}),
  }),
};

const includeNowIconClass = (includeNow) => {
  if (includeNow) {
    return `fas fa-hourglass-end fa-fw include-now-icon-filled`;
  } else {
    return `fas fa-hourglass-start fa-fw include-now-icon`;
  }
};

const shouldRenderIncludeNow = (global_ltb, transactionDate) => {
  if (!global_ltb && moment(transactionDate).isAfter(moment().endOf('day'))) {
    return true;
  }

  return false;
};

class TransactionListItemEditableView extends PureComponent {
  static defaultProps = {
    inflowFirst: false,
  };

  state = {
    dateSelected: this.props.transaction.date,
  };

  constructor(props) {
    super(props);

    this.dateInput = {};
    this.payeeInput = {};
    this.categoryInput = {};
    this.memoInput = {};
    this.inflowInput = {};
    this.outflowInput = {};

    this.state = {
      edits: {},
      isNew: this.props.transaction && this.props.transaction.type === null,
      payeesLoading: false,
      payees: this.props.payees,
      categoryList: this.addLtbOptionsToCategoryList(
        this.props.categoryAndAccountList
      ),
      isEditable: true, // As this is edit-only component now
    };
  }

  componentDidMount() {
    // if (!this.props.transaction.created) {
    //   this.payeeInput[0].focus();
    // }
  }

  getPropValues(id) {
    if (id === this.props.transaction.id) {
      return this.props.transaction;
    }
    const child = this.props.transaction.child_transactions.find(
      (e) => e.id === id
    );
    if (child) {
      return child;
    }

    return null;
  }

  getPropValue(id, field) {
    const values = this.getPropValues(id);
    if (values && values.hasOwnProperty(field)) return values[field];

    if (values.created) return null;
  }

  getValues(id) {
    let value;
    if (typeof id === 'number') {
      if (!id) {
        id = this.props.transaction.id;
        value = this.props.transaction;
      } else {
        let child = this.props.transaction.child_transactions[id - 1];
        if (child) {
          id = child.id;
          value = child;
        } else {
          const parent = this.state.edits[this.props.transaction.id];
          if (
            parent &&
            parent.children &&
            (child =
              parent.children[
                id - (1 + this.props.transaction.child_transactions.length)
              ])
          ) {
            return child;
          }

          return null;
        }
      }
    }

    const changes = this.state.edits[id];
    if (changes === null) {
      return null;
    }
    return { ...(value || this.getPropValues(id)), ...changes };
  }

  getValue(id, field) {
    let value;
    if (typeof id === 'number') {
      if (!id) {
        id = this.props.transaction.id;
        value = this.props.transaction[field];
      } else {
        let child = this.props.transaction.child_transactions[id - 1];
        if (child) {
          id = child.id;
          value = child[field];
        } else {
          const parent = this.state.edits[this.props.transaction.id];
          if (
            parent &&
            parent.children &&
            (child =
              parent.children[
                id - (1 + this.props.transaction.child_transactions.length)
              ])
          ) {
            return child[field];
          }

          return null;
        }
      }
    }

    const changes = this.state.edits[id];
    if (changes === null) {
      return null;
    }
    return (changes && changes[field]) || value || this.getPropValue(id, field);
  }

  edit(edits, id, newEdits) {
    if (typeof id === 'number') {
      if (!id) {
        return this.edit(edits, this.props.transaction.id, newEdits);
      }
      let child = this.props.transaction.child_transactions[id - 1];
      if (child) {
        return this.edit(edits, child.id, newEdits);
      }
      const parent = edits[this.props.transaction.id];
      const childId =
        id - (1 + this.props.transaction.child_transactions.length);
      if (parent && parent.children && (child = parent.children[childId])) {
        let result;
        edits = {
          ...edits,
          [this.props.transaction.id]: {
            ...parent,
            children: parent.children.map((c, i) =>
              i === childId
                ? {
                    ...c,
                    ...(result =
                      typeof newEdits === 'function' ? newEdits(c) : newEdits),
                  }
                : c
            ),
          },
        };
        return { edits, result };
      }

      return { edits };
    }

    const result =
      typeof newEdits === 'function'
        ? newEdits({ ...this.getPropValues(id), ...edits[id] })
        : newEdits;
    edits = { ...edits, [id]: { ...edits[id], ...result } };
    return { edits, result };
  }

  setValues(id, newEdit, newState, callback = () => {}, editing = true) {
    const shared = { component: this };
    this.setState(
      function (prev) {
        const { edits } = prev;
        const newEdits = this.component.edit(edits, id, newEdit);
        this.result = newEdits.result;
        return {
          ...(typeof newState === 'function' ? newState(prev) : newState),
          edits: newEdits.edits,
          // isEditable: editing,
        };
      }.bind(shared),
      function () {
        callback(this.result);
      }.bind(shared)
    );
  }

  setValue(id, field, value, callback, editing) {
    this.setValues(
      id,
      typeof value === 'function'
        ? (prev) => ({ [field]: value(prev[field]) })
        : { [field]: value },
      undefined,
      callback && ((current) => callback(current[field])),
      editing
    );
  }

  getChildren() {
    const values = this.getValues(this.props.transaction.id);
    return [
      ...values.child_transactions
        .filter(({ id }) => this.state.edits[id] !== null)
        .map(({ id }) => this.getValues(id)),
      ...(values.children || []),
    ];
  }

  allChildrenCount() {
    return (
      this.props.transaction.child_transactions.length +
      (
        (this.state.edits[this.props.transaction.id] &&
          this.state.edits[this.props.transaction.id].children) ||
        []
      ).length
    );
  }

  childrenCount() {
    // TODO: optimize
    return this.getChildren().length;
  }

  hasChildren() {
    // TODO: optimize
    return !!this.childrenCount();
  }

  getId(id) {
    return this.getValue(id, 'id');
  }

  getAmount(id) {
    return this.getValue(id, 'amount');
  }

  setAmount(id, value, callback) {
    this.setValue(id, 'amount', value, callback);
  }

  getType(id) {
    return this.getValue(id, 'type');
  }

  setType(id, value, callback) {
    this.setValue(id, 'type', value, callback);
  }

  getApproved(id) {
    return this.getValue(id, 'approved');
  }

  setApproved(id, value, callback) {
    this.setValue(id, 'approved', value, callback, false);
  }

  getPayee(id) {
    return this.getValue(id, 'payee');
  }

  setPayee(id, value, callback) {
    this.setValue(id, 'payee', value, callback);
  }

  getCategory(id) {
    return this.getValue(id, 'category');
  }

  setCategory(id, value, callback) {
    this.setValue(id, 'category', value, callback);
  }

  getLtbNext(id) {
    return this.getValue(id, 'ltb_next');
  }

  setLtbNext(id, value, callback) {
    this.setValue(id, 'ltb_next', value, callback);
  }

  getDate(id) {
    return this.getValue(id, 'date');
  }

  setDate(id, value, callback) {
    this.setValue(id, 'date', value, callback);
  }

  getRepeat(id) {
    return this.getValue(id, 'repeat');
  }

  setRepeat(id, value, callback) {
    this.setValue(id, 'repeat', value, callback);
  }

  getMemo(id) {
    return this.getValue(id, 'memo');
  }

  setMemo(id, value, callback) {
    this.setValue(id, 'memo', value, callback);
  }

  getInflow(id) {
    return this.getType(id) === 'inflow' ? this.getAmount(id) : 0;
  }

  getOutflow(id) {
    return this.getType(id) === 'outflow' ? this.getAmount(id) : 0;
  }

  addLtbOptionsToCategoryList = (categoryList) => {
    const updatedCategoryList = categoryList || [];
    const givenDate = this.state.dateSelected;
    const currentDate = givenDate ? givenDate : moment();

    const monthsToDisplay = thisMonthAndNextMonthInMMMM(currentDate);

    const ltbOptions = [
      {
        label: `Income for ${monthsToDisplay.currentMonthInMMMM}`,
        value: 'Left to Budget',
      },
      {
        label: `Income for ${monthsToDisplay.nextMonthInMMMM}`,
        value: 'Left to Budget',
      },
    ];

    // if inflow section doesnt exist yet
    if (!updatedCategoryList[0] || updatedCategoryList[0].label !== 'Inflow') {
      updatedCategoryList.unshift({
        label: 'Inflow',
        options: ltbOptions,
      });
    } else {
      // if inflow section exists and needs to be updated instead
      updatedCategoryList[0].options = ltbOptions;
    }

    return updatedCategoryList;
  };

  renderSplitRemaining(amountRemaining) {
    const { type } = this.getValues(0);
    const [xFlow, yFlow] = this.props.inflowFirst
      ? ['inflow', 'outflow']
      : ['outflow', 'inflow'];

    return (
      <div className="columns transaction-row-desktop-inner transaction-row-desktop-inner-child">
        <div className="transaction-column date-column" />
        <div className="transaction-column payee-column" />
        <div className="transaction-column category-column" />
        <div className="transaction-column notes-column remaining-column">
          <span className="editable-field placeholder-text transaction-info-text">
            Amount Remaining:
          </span>
        </div>
        <div className={`transaction-column amount-column ${xFlow}-column`}>
          {this.renderAmountField(
            true,
            0,
            type === xFlow
              ? sub(amountRemaining.totalAmount, amountRemaining.childAmount)
              : '',
            {},
            greaterThan(
              amountRemaining.childAmount,
              amountRemaining.totalAmount
            )
              ? `transaction-neg-amount-${xFlow}`
              : `transaction-pos-amount-${xFlow}`,
            () => {},
            () => {},
            true
          )}
        </div>
        <div className={`transaction-column amount-column ${yFlow}-column`}>
          {this.renderAmountField(
            true,
            0,
            type === yFlow
              ? sub(amountRemaining.totalAmount, amountRemaining.childAmount)
              : '',
            {},
            greaterThan(
              amountRemaining.childAmount,
              amountRemaining.totalAmount
            )
              ? `transaction-neg-amount-${yFlow}`
              : `transaction-pos-amount-${yFlow}`,
            () => {},
            () => {},
            true
          )}
        </div>
        <div className="transaction-column approved-column" />
      </div>
    );
  }

  getPreloadedCategory = (categoryName, subcategories, ltb_next) => {
    const { is_transfer } = this.props.transaction;
    const givenDate = this.state.dateSelected;
    const currentDate = givenDate ? givenDate : this.props.transaction.date;

    const monthsToDisplay = thisMonthAndNextMonthInMMMM(currentDate);

    if (!categoryName && !is_transfer) {
      return null;
    }
    if (categoryName === 'Left to Budget') {
      const ltbLabel = !ltb_next
        ? `Income for ${monthsToDisplay.currentMonthInMMMM}`
        : `Income for ${monthsToDisplay.nextMonthInMMMM}`;

      return {
        label: ltbLabel,
        value: 'Left to Budget',
      };
    }
    if (categoryName === 'Split Transaction') {
      return {
        value: 'Split Transaction',
        label: 'Split Transaction',
      };
    }

    if (this.props.transaction && this.props.transaction.is_transfer) {
      return {
        label: (
          <Fragment>
            <i className="fas fa-exchange-alt fa-fw transfer-type-icon" />
            Transfer
          </Fragment>
        ),
        value: 'Transfer',
      };
    }

    if (categoryName !== '' && subcategories && subcategories.length) {
      const subcategoryIdx = subcategories.findIndex(
        (obj) => obj.name === categoryName
      );
      const selectedSubcategory = subcategories[subcategoryIdx];
      return {
        label: categoryName,
        value: {
          ...selectedSubcategory,
        },
      };
    }
    return '';
  };

  getPreloadedAccount = () => {
    let accountIdx, selectedAccount;

    if (
      this.props.transaction &&
      this.props.transaction.is_transfer &&
      this.props.accounts &&
      this.props.accounts.length
    ) {
      if (this.props.transaction && this.props.transaction.type === 'outflow') {
        accountIdx = this.props.accounts.findIndex(
          (obj) => obj.id === this.props.transaction.transfer_target
        );

        selectedAccount = this.props.accounts[accountIdx];
        return {
          label:
            (selectedAccount && `Transfer to: ${selectedAccount.name}`) ||
            'Deleted Account',
          value: {
            ...selectedAccount,
          },
        };
      }
      accountIdx = this.props.accounts.findIndex(
        (obj) => obj.id === this.props.transaction.transfer_origin
      );

      selectedAccount = this.props.accounts[accountIdx];

      const accountName = selectedAccount
        ? selectedAccount.name
        : 'Deleted Account';
      return {
        label: `Transfer from: ${accountName}`,
        value: {
          ...selectedAccount,
        },
      };
    }
    return '';
  };

  transferOriginatedHere = () => {
    const { is_transfer, type } = this.props.transaction;
    return is_transfer && type === 'outflow';
  };

  onMemoKeyDown = (event, child) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      // (this.getType(child) === 'inflow' ? this.inflowInput : this.outflowInput)[
      //   child
      // ].focus();
    }
  };

  onDateKeyDown = (event, child) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      // this.payeeInput[child].focus();
    }
  };

  handleDayChange = (date, child) => {
    const setDate = date || moment().utc().local().toISOString();

    this.setDate(child, setDate);
    this.setState(
      {
        dateSelected: setDate,
      },
      () => {
        this.setState({
          categoryList: this.addLtbOptionsToCategoryList(
            this.props.categoryAndAccountList
          ),
        });
      }
    );

    localStorage.setItem('lastDateSelected', setDate);

    this.props.toggleEditingRow(this.props.transaction.id);
  };

  handleCancelSeries = ({ recurringId }) => {
    this.props.onCancelSeries(recurringId, this.props.transaction.date);
    this.handleCancel();
  };

  handleRestrictDays = (restrictedDays) => {
    this.setState({ restrictedDays });
  };

  handleRecurringChange(recurring, child) {
    if (recurring.isRecurring) {
      this.setValues(
        child,
        {
          repeat:
            {
              daily: { frequency: 'daily', interval: recurring.dailyInterval },
              weekly: { frequency: 'weekly', interval: 1 },
              biweekly: { frequency: 'weekly', interval: 2 },
              qtrweekly: { frequency: 'weekly', interval: 4 },
              monthly: { frequency: 'monthly', interval: 1 },
              quarterly: { frequency: 'monthly', interval: 3 },
              half_yearly: { frequency: 'monthly', interval: 6 },
              yearly: { frequency: 'monthly', interval: 12 },
            }[recurring.repeatFrequency] || true,
        },
        { weekday: recurring.selectedWeekdays }
      );
    } else {
      this.setRepeat(child, false);
    }
  }

  renderDate(child) {
    const { is_transfer } = this.props.transaction;
    if (!child) {
      const { settings } = this.props;
      const { isNew, weekday } = this.state;
      const { id, date, repeat } = this.getValues(child);

      let repeatFrequency = '',
        dailyInterval = '',
        convertedWeekday = [];
      if (repeat) {
        if (repeat.frequency === 'daily') {
          repeatFrequency = 'daily';
          dailyInterval = repeat.interval;
        } else if (repeat.frequency === 'weekly') {
          if (repeat.interval === 1) {
            repeatFrequency = 'weekly';
          } else if (repeat.interval === 2) {
            repeatFrequency = 'biweekly';
          } else if (repeat.interval === 4) {
            repeatFrequency = 'qtrweekly';
          }
          convertedWeekday = weekday || [new Date(date).getDay()];
        } else if (repeat.frequency === 'monthly') {
          if (repeat.interval === 1) {
            repeatFrequency = 'monthly';
          } else if (repeat.interval === 3) {
            repeatFrequency = 'quarterly';
          } else if (repeat.interval === 6) {
            repeatFrequency = 'half_yearly';
          } else if (repeat.interval === 12) {
            repeatFrequency = 'yearly';
          }
        }
      }

      const localDate = localStorage.getItem('lastDateSelected');

      return (
        <div className="transaction-column date-column">
          {/* <label className="transaction-checkbox-container">
            <input
              type="checkbox"
              checked={this.props.checked}
              onChange={(e) =>
                this.props.onSelected(
                  this.props.transaction,
                  !this.props.checked
                )
              }
            />
            <span className="transaction-checkmark" />
          </label> */}
          <DayPickerInput
            parseDate={parseDate}
            formatDate={formatDate}
            inputProps={{
              disabled: id && is_transfer ? true : false,
              ref: (input) => (this.dateInput[child] = input),
              onKeyDown: (e) => this.onDateKeyDown(e, child),
            }}
            dayPickerProps={{
              cancelSeries: this.handleCancelSeries,
              numberOfMonths: settings.num_of_months,
              firstDayOfWeek: settings.first_day,
              restrictDays: this.handleRestrictDays,
              newTransaction: isNew,
              recurringId: this.props.transaction.recurring_transaction_id,
              modifiers: { disabled: this.state.restrictedDays },
            }}
            onDayChange={(date) => this.handleDayChange(date, child)}
            format={settings.date_format}
            showOverlay={false}
            overlayComponent={(props) => (
              <DayPickerWithRecurrence
                isRecurring={!!repeat}
                dailyInterval={dailyInterval}
                repeatFrequency={repeatFrequency}
                weekday={convertedWeekday}
                onRecurringChange={(e) => this.handleRecurringChange(e, child)}
                {...props}
              >
                {props.children}
              </DayPickerWithRecurrence>
            )}
            placeholder="---"
            value={
              localDate && !this.props.transaction.created
                ? formatDate(localDate, settings.date_format)
                : date
                ? formatDate(date, settings.date_format)
                : formatDate(new Date(), settings.date_format)
            }
          >
            <Portal node={document && document.getElementById('test-portal')}>
              <Fragment>{this.props.children}</Fragment>
            </Portal>
          </DayPickerInput>
        </div>
      );
    }
    return (
      <div className="transaction-column date-column">
        {this.state.isEditable && (
          <ButtonBase
            onClick={(e) => this.handleRemoveChild(e, child)}
            className=""
          >
            <i className="fas fa-times-circle fa-fw split-remove-row-btn" />
          </ButtonBase>
        )}
      </div>
    );
  }

  splitAmountRemaining() {
    const { amount } = this.getValues(0);
    const children = this.getChildren();

    const childAmount = sum(children, ({ amount }) => amount);
    let totalAmount = amount;
    if (
      !this.state.edits[this.props.transaction.id] ||
      this.state.edits[this.props.transaction.id].amount === undefined
    ) {
      totalAmount = add(
        amount,
        sum(this.props.transaction.child_transactions, ({ amount }) => amount)
      );
    }

    return {
      childAmount,
      totalAmount,
    };
  }

  handleCreatePayee = (inputValue, child) => {
    this.setState({ payeesLoading: true });
    setTimeout(() => {
      const newPayee = this.createOption(inputValue);
      this.props.onSavePayee(newPayee.label);

      this.setValues(child, { payee: newPayee.label }, ({ payees }) => ({
        payees: [...payees, newPayee],
        payeesLoading: false,
      }));
    }, 0);

    this.props.toggleEditingRow(this.props.transaction.id);
    // this.categoryInput[child].focus();
  };

  createOption = (label = '') => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
  });

  handlePayeeChange = (value, child) => {
    this.setValues(
      child,
      { payee: value.label || null },
      {
        createdOptions: value,
      }
    );

    this.props.toggleEditingRow(this.props.transaction.id);
    // this.categoryInput[child] && this.categoryInput[child].focus();
  };

  handleCategoryChange = (value, child) => {
    const givenDate = this.state.dateSelected;
    const currentDate = givenDate ? givenDate : this.props.transaction.date;

    const monthsToDisplay = thisMonthAndNextMonthInMMMM(currentDate);

    let category,
      ltb_next = false;

    if (value.value !== 'Left to Budget') {
      category = value.label || null;
    } else if (
      value.label === `Income for ${monthsToDisplay.currentMonthInMMMM}`
    ) {
      category = 'Left to Budget';
    } else if (
      value.label === `Income for ${monthsToDisplay.nextMonthInMMMM}`
    ) {
      category = 'Left to Budget';
      ltb_next = true;
    }

    const category_id = typeof value.value === 'object' ? value.value.id : null;
    const hasChildren = this.hasChildren();
    const isSplit = value.value === 'Split Transaction';
    if (
      (!hasChildren && isSplit) ||
      (hasChildren && !isSplit) ||
      ((category !== this.getCategory(child) ||
        ltb_next !== this.getLtbNext(child)) &&
        !hasChildren &&
        !isSplit)
    ) {
      if (child === 0) {
        if (isSplit) {
          this.props.onChildChange(2);
          this.setValues(child, {
            category,
            ltb_next,
            children: [
              {
                amount: '0.00',
                category: null,
                memo: null,
              },
              {
                amount: '0.00',
                category: null,
                memo: null,
              },
            ],
          });
        } else {
          this.props.onChildChange(-this.childrenCount());
          this.setState(({ edits }) => ({
            isEditable: true,
            edits: this.props.transaction.child_transactions.reduce(
              (acc, { id }) => ({ ...acc, [id]: null }),
              {
                [this.props.transaction.id]: {
                  ...edits[this.props.transaction.id],
                  category,
                  ltb_next,
                  category_id,
                  amount: this.splitAmountRemaining().totalAmount,
                  children: [],
                },
              }
            ),
          }));
        }
      } else this.setValues(child, { category, category_id, ltb_next });

      this.props.toggleEditingRow(this.props.transaction.id);
    }

    // this.memoInput[child].focus();
  };

  handleNotesChange = (evt, child) => {
    const memo = evt.target.value;
    this.setMemo(child, memo);

    this.props.toggleEditingRow(this.props.transaction.id);
  };

  renderPayee(child) {
    const { is_transfer } = this.props.transaction;

    if (!child) {
      const { payeesLoading, payees } = this.state;
      const { id, payee } = this.getValues(child);

      return (
        <div className="transaction-column column payee-column">
          {is_transfer ? (
            <Select
              placeholder="Select Account"
              value={
                this.props.transaction.transfer_target
                  ? this.getPreloadedAccount()
                  : null
              }
              className="editable-cell-input"
              ref={(input) => (this.payeeInput[child] = input)}
              styles={customSelectStyles}
              onChange={this.handleTransferAcctChange}
              components={{ DropdownIndicator: null }}
              isDisabled={id}
              openMenuOnFocus={true}
              blurInputOnSelect={true}
              options={this.props.accountList}
            />
          ) : (
            <Creatable
              styles={customSelectStyles}
              className="editable-cell-input"
              components={{ DropdownIndicator: null }}
              placeholder="No Payees"
              ref={(input) => (this.payeeInput[child] = input)}
              onChange={(e) => this.handlePayeeChange(e, child)}
              value={payee && { value: payee, label: payee }}
              isDisabled={payee === 'Initial Balance' ? true : false}
              isLoading={payeesLoading}
              onCreateOption={(e) => this.handleCreatePayee(e, child)}
              openMenuOnFocus={true}
              blurInputOnSelect={true}
              options={payees}
            />
          )}
        </div>
      );
    }
    return <div className="transaction-column column payee-column" />;
  }

  renderCategory(child) {
    const { is_transfer, off_budget } = this.props.accountDetails;
    const { category, payee, ltb_next } = this.getValues(child);

    if (off_budget) {
      return null;
    }

    return (
      <div className="transaction-column category-column">
        <Select
          placeholder="Uncategorized"
          defaultValue={{ label: null, value: null }}
          value={this.getPreloadedCategory(
            !child && this.hasChildren() ? 'Split Transaction' : category,
            this.props.subcategories,
            ltb_next
          )}
          className="editable-cell-input"
          styles={customSelectStyles}
          onChange={(e) => this.handleCategoryChange(e, child)}
          ref={(input) => (this.categoryInput[child] = input)}
          isDisabled={
            payee === 'Initial Balance' || this.props.transaction.is_transfer
              ? true
              : false
          }
          components={{ ClearIndicator: null, DropdownIndicator: null }}
          openMenuOnFocus={true}
          isClearable={true}
          // defaultMenuIsOpen
          disableAutoFocus={true}
          blurInputOnSelect={true}
          options={
            child
              ? this.props.categoryAndAccountList
              : [
                  {
                    value: 'Split Transaction',
                    label: 'Split Transaction',
                    style: 'button',
                  },
                  ...this.props.categoryAndAccountList,
                ]
          }
        />
      </div>
    );
  }

  renderNotes(child) {
    const { off_budget } = this.props.accountDetails;
    const notesExtendedClass = off_budget ? 'is-4' : '';
    const memo = this.getMemo(child);

    return (
      <div className={`transaction-column notes-column ${notesExtendedClass}`}>
        <input
          type="text"
          value={memo || ''}
          name="memo"
          placeholder="Add Notes"
          ref={(input) => (this.memoInput[child] = input)}
          onKeyDown={(e) => this.onMemoKeyDown(e, child)}
          onChange={(e) => this.handleNotesChange(e, child)}
          className="editable-cell-input-standalone"
        />
      </div>
    );
  }

  renderAmount(child) {
    const { is_transfer } = this.props.transaction;
    const { id, amount } = this.getValues(child);
    const parentType = this.getType(0);
    const inflow = {
      name: 'inflow',
      getInputRef: (input) => (this.inflowInput[child] = input),
      onChange: this.handleInflowChange,
    };
    const outflow = {
      name: 'outflow',
      getInputRef: (input) => (this.outflowInput[child] = input),
      onChange: this.handleOutflowChange,
    };
    const [xAmount, yAmount] = this.props.inflowFirst
      ? [inflow, outflow]
      : [outflow, inflow];

    let totalAmount = amount;
    if (!child) {
      if (
        !this.state.edits[this.props.transaction.id] ||
        this.state.edits[this.props.transaction.id].amount === undefined
      ) {
        totalAmount = add(
          amount,
          sum(this.props.transaction.child_transactions, ({ amount }) => amount)
        );
      }
    }

    return (
      <Fragment>
        <div
          className={`transaction-column amount-column ${xAmount.name}-column`}
        >
          {(is_transfer && this.transferOriginatedHere()) ||
          (child && parentType !== xAmount.name)
            ? null
            : this.renderAmountField(
                is_transfer && id,
                child,
                parentType === xAmount.name ? totalAmount : '0.00',
                {},
                `transaction-pos-amount-${xAmount.name}`,
                xAmount.getInputRef,
                xAmount.onChange
              )}
        </div>
        <div
          className={`transaction-column amount-column ${yAmount.name}-column`}
        >
          {(is_transfer && !this.transferOriginatedHere()) ||
          (child && parentType !== yAmount.name)
            ? null
            : this.renderAmountField(
                is_transfer && id,
                child,
                parentType === yAmount.name ? totalAmount : '0.00',
                {},
                `transaction-pos-amount-${yAmount.name}`,
                yAmount.getInputRef,
                yAmount.onChange
              )}
        </div>
      </Fragment>
    );
  }

  renderAmountField(
    disabled,
    child,
    value,
    styles,
    classes,
    getInputRef,
    onChange,
    allowNegative = false
  ) {
    const { settings } = this.props;
    const isNil = value === '' || value === '0.00' || value === undefined;
    return (
      <NumberFormat
        className={`editable-cell-input-standalone ${isNil ? '' : classes}`}
        allowNegative={allowNegative}
        style={isNil ? {} : styles}
        value={value}
        getInputRef={getInputRef}
        isNumericString={true}
        onFocus={(e) => e.target.select()}
        placeholder=""
        onKeyDown={this.onKeyDown.bind(this)}
        onValueChange={(v, e) => onChange(v, e, child)}
        // decimalScale={2}
        disabled={disabled}
        thousandSeparator={settings.thousands}
        decimalSeparator={settings.decimal}
      />
    );
  }

  handleInflowChange = ({ formattedValue, value, floatValue }, evt, child) => {
    this.setValues(child, {
      type: 'inflow',
      amount: floatValue,
    });

    this.props.toggleEditingRow(this.props.transaction.id);
  };

  handleOutflowChange = ({ formattedValue, value, floatValue }, evt, child) => {
    this.setValues(child, {
      type: 'outflow',
      amount: floatValue,
    });

    this.props.toggleEditingRow(this.props.transaction.id);
  };

  handleRemoveChild = (e, child) => {
    this.props.onChildChange(-1);
    let transaction = this.props.transaction.child_transactions[child - 1];
    if (transaction) {
      this.setState(({ edits }) => ({
        edits: {
          ...edits,
          ...(this.childrenCount() - 1
            ? {}
            : {
                [this.props.transaction.id]: {
                  ...edits[this.props.transaction.id],
                  category: '',
                  amount: this.splitAmountRemaining().totalAmount,
                },
              }),
          [transaction.id]: null,
        },
        isEditable: true,
      }));
    } else {
      const index =
        child - (1 + this.props.transaction.child_transactions.length);
      this.setValues(0, ({ children }) => ({
        ...(this.childrenCount() - 1
          ? {}
          : { category: '', amount: this.splitAmountRemaining().totalAmount }),
        children: children.filter((c, i) => i != index),
      }));
    }

    this.props.toggleEditingRow(this.props.transaction.id);
  };

  handleAddChild = (e) => {
    this.props.onChildChange(1);
    this.setValues(0, ({ children }) => ({
      children: [
        ...(children || []),
        {
          amount: '0.00',
          category: null,
          memo: null,
        },
      ],
    }));

    this.props.toggleEditingRow(this.props.transaction.id);
  };

  handleCancel = () => {
    if (!this.props.transaction.created) {
      this.props.cancelEditableRow();
    } else {
      this.props.onChildChange(
        ((this.state.edits[this.props.transaction.id] &&
          this.state.edits[this.props.transaction.id].children &&
          -this.state.edits[this.props.transaction.id].children.length) ||
          0) +
          this.props.transaction.child_transactions.reduce(
            (acc, { id }) => (this.state.edits[id] === null ? acc + 1 : acc),
            0
          )
      );
      this.setState({ edits: {}, isEditable: false });
      this.props.toggleEditingRow(null);
    }
  };

  handleApproval = (child) => {
    this.setApproved(
      child,
      (approved) => !approved,
      (approved) => {
        this.props.onApproveTransaction({ id: this.getId(child) }, approved);
      }
    );
  };

  renderApproved(child) {
    if (!child) {
      const { settings, transaction } = this.props;
      const { isNew } = this.state;
      const { id, approved, payee } = this.getValues(child);

      return (
        <div className="transaction-column approved-column">
          {isNew ? null : payee !== 'Initial Balance' && id ? ( // </span> //   </button> //     X //   > //     onClick={this.handleCancel} //     className="button is-outline is-small transaction-mini-button" //   <button //   </button> //     Save //   > //     onClick={this.saveTransaction} //     className="button is-info is-small transaction-mini-button" //     disabled={!isEditable} //   <button // <span>
            <span onClick={() => this.handleApproval(child)}>
              <span>
                <i
                  data-balloon-pos="left"
                  aria-label="Cleared"
                  className={classNames('fas fa-check-circle fa-fw', {
                    approved: approved,
                  })}
                />
              </span>
            </span>
          ) : (
            ''
          )}
          {shouldRenderIncludeNow(settings.global_ltb, transaction.date) && (
            <i
              data-balloon-pos="left"
              aria-label="Include in Budget Now"
              className={includeNowIconClass(transaction.include_now)}
              onClick={(e) => {
                e.stopPropagation();
                this.props.toggleIncludeNow(
                  transaction,
                  !transaction.include_now
                );
              }}
            />
          )}
        </div>
      );
    }
    return (
      <div className="transaction-column has-text-centered column approved-column" />
    );
  }

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      this.onSaveTransaction();
      // this.handleBlur();
    }
  };

  onSaveTransaction = () => {
    // TODO: if split send save transaction requests for each child
    // evt.preventDefault();
    // evt.stopPropagation();

    const transactionPayload = this.getValues(this.props.transaction.id);
    let childAmount = 0;
    const children = this.getChildren().map((c) => {
      childAmount = add(childAmount, c.amount);
      if (c.id) {
        return { ...c, accountType: this.props.accountDetails.type };
      }
      return {
        ...c,
        id: uuid(),
        date: transactionPayload.date,
        parent_transaction_id: transactionPayload.id,
        is_transfer: false,
        accountType: this.props.accountDetails.type,
      };
    });

    const changes = Object.keys(this.state.edits).reduce(
      ({ parent, update, remove }, t) =>
        t !== transactionPayload.id
          ? this.state.edits[t] === null
            ? { parent, update, remove: [...remove, t] }
            : {
                parent,
                update: [...update, { id: t, ...this.state.edits[t] }],
                remove,
              }
          : {
              parent: {
                ...this.state.edits[t],
                amount: childAmount ? 0 : this.state.edits[t].amount,
              },
              update,
              remove,
            },
      { parent: undefined, update: [], remove: [] }
    );
    this.props.onSaveTransaction({
      ...transactionPayload,
      children: undefined,
      child_transactions: children,
      amount: childAmount ? 0 : transactionPayload.amount,
      accountType: this.props.accountDetails.type,
      changes,
    });

    this.props.deselectEditTransaction();

    //TODO: blur all refs in the objects or maybe could keep track of last focused, and simply blur that
    Object.values(this.dateInput || {}).forEach((e) => e && e.blur());
    Object.values(this.payeeInput || {}).forEach((e) => e && e.blur());
    Object.values(this.categoryInput || {}).forEach((e) => e && e.blur());
    Object.values(this.memoInput || {}).forEach((e) => e && e.blur());
    Object.values(this.inflowInput || {}).forEach((e) => e && e.blur());
    Object.values(this.outflowInput || {}).forEach((e) => e && e.blur());

    // this.setState({
    //   isEditable: false,
    // });

    this.saveAccountScrollPosition();
  };

  saveAccountScrollPosition = () => {
    const { accountDetails, startIndex, stopIndex, currentView } = this.props;
    this.props.saveAccountScrollPosition(
      accountDetails.id,
      startIndex,
      stopIndex,
      currentView
    );
  };

  // handleSelect = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // };

  renderTransaction(child = 0) {
    if (child) {
      const transaction = this.props.transaction.child_transactions[child - 1];
      if (transaction) {
        if (this.state.edits[transaction.id] === null) return undefined;
      }
    }

    return (
      <div
        key={child}
        className={`columns ${
          child
            ? `transaction-row-desktop-inner transaction-row-desktop-inner-child child-expanded`
            : 'transaction-row-desktop-inner'
        }`}
        onDoubleClick={child ? this.doubleClickToEdit : () => {}}
      >
        {/* <div className="transaction-column select-column"> */}
        {/* <label className="transaction-checkbox-container">
            <input
              type="checkbox"
              onChange={this.handleSelect}
              // checked={this.props.checked}
              // onChange={(e) =>
              //   this.props.onSelected(
              //     this.props.transaction,
              //     !this.props.checked
              //   )
              // }
            />
            <span className="transaction-checkmark" />
          </label> */}
        {/* </div> */}
        {this.renderDate(child)}
        {this.renderPayee(child)}
        {this.renderCategory(child)}
        {this.renderNotes(child)}
        {this.renderAmount(child)}
        {this.renderApproved(child)}
      </div>
    );
  }

  render() {
    const hasChildren = this.hasChildren();
    // TODO: optimize: prepare state here (getchildren) and pass it to other render functions?

    const amountRemaining = hasChildren ? this.splitAmountRemaining() : {};

    return (
      <Fragment>
        <div className="transaction-row transaction-row-editable">
          {this.renderTransaction()}
          {new Array(this.allChildrenCount())
            .fill()
            .map((t, index) => this.renderTransaction(index + 1))}
          {this.props.transaction &&
            (!this.props.transaction.created ||
              this.props.transaction.id ===
                this.props.transactionBeingEdited) && (
              <Fragment>
                {hasChildren
                  ? this.renderSplitRemaining(amountRemaining)
                  : undefined}
                <div className="transaction-row transaction-row-expanded-toolbar">
                  <ButtonBase
                    onClick={(e) => {
                      this.props.toggleEditingRow(null);
                      this.props.deselectEditTransaction();
                      this.handleCancel(e);
                    }}
                    className=""
                  >
                    <i className="fas fa-minus-circle fa-fw button-icon" />
                    Cancel
                  </ButtonBase>
                  {hasChildren ? (
                    <ButtonSecondary onClick={this.handleAddChild} className="">
                      <i className="fas fa-plus-circle fa-fw button-icon" />
                      Add Row
                    </ButtonSecondary>
                  ) : undefined}
                  <ButtonPrimary onClick={this.onSaveTransaction} className="">
                    <i className="fas fa-save fa-fw button-icon" />
                    Save
                  </ButtonPrimary>
                </div>
              </Fragment>
            )}
        </div>
      </Fragment>
    );
  }
}

export default TransactionListItemEditableView;
