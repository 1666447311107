import React, { PureComponent } from 'react';
import BudgetTableCCCategoryGroupMonthView from './budget-table-cc-category-group-month-view';

class BudgetTableCCCategoryGroupMonthContainer extends PureComponent {
  render() {
    const {
      creditCardGroupTotalActivityByMonth,
      creditCardGroupTotalBudgetedByMonth,
      creditCardGroupTotalRemainingByMonth,
      isMiddleMonth,
      settings,
    } = this.props;

    return (
      <BudgetTableCCCategoryGroupMonthView
        creditCardGroupTotalActivityByMonth={
          creditCardGroupTotalActivityByMonth
        }
        creditCardGroupTotalBudgetedByMonth={
          creditCardGroupTotalBudgetedByMonth
        }
        creditCardGroupTotalRemainingByMonth={
          creditCardGroupTotalRemainingByMonth
        }
        isMiddleMonth={isMiddleMonth}
        settings={settings}
      />
    );
  }
}

export default BudgetTableCCCategoryGroupMonthContainer;
