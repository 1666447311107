import React, { useState } from 'react';
import cn from 'classnames';
import axios from 'axios';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Link as RRLink } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { LoggedOutFormContainer } from '../../layout';
import { FASTAPI_API } from '../../../../constants';
import { Box } from '../box';
import { Input } from '../input';
import { Button } from '../button';
import { Heading } from '../heading';
import { Link } from '../link';

interface InputFields {
  newPassword: string;
  newPasswordRepeat: string;
}

interface ChangePasswordProps {
  token: string;
}

function ChangePassword({ token }: ChangePasswordProps) {
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [tokenIsValid, setTokenIsValid] = useState(false);

  const methods = useForm<InputFields>({
    defaultValues: {
      newPassword: '',
      newPasswordRepeat: '',
    },
  });
  const { watch, handleSubmit } = methods;

  const mutation = useMutation({
    // @ts-ignore
    mutationFn: (payload) =>
      axios
        .post(`${FASTAPI_API}/change-password`, payload)
        .then((response) => response.data),
    onSuccess: (data) => {
      setPasswordChanged(true);
    },
  });

  const tokenQuery = useQuery({
    queryKey: ['change-password-token', { token }],
    queryFn: () =>
      axios
        .get(`${FASTAPI_API}/change-password`, {
          params: {
            token,
          },
        })
        .then((response) => response.data),
    onSuccess: (data) => {
      setTokenIsValid(false);
    },
    onError: () => {
      setTokenIsValid(true);
    },
  });

  const newPassword = watch('newPassword', '');

  // @ts-ignore
  const onSubmit = ({ newPassword, newPasswordRepeat }) => {
    // @ts-ignore
    mutation.mutate({
      new_password: newPassword,
      new_password_repeat: newPasswordRepeat,
      token,
    });
  };

  if (tokenQuery.isLoading) {
    return (
      <LoggedOutFormContainer>
        <Heading h2 className={cn('text-center', 'mb-6')}>
          Validating link
        </Heading>
        <Box className={cn('mb-6')}>
          <p>Please wait...</p>
        </Box>
      </LoggedOutFormContainer>
    );
  }

  if (tokenIsValid) {
    return (
      <LoggedOutFormContainer>
        <Heading h2 className={cn('text-center', 'mb-6')}>
          Link invalid
        </Heading>
        <Box className={cn('mb-6')}>
          <p>Please try to reset your password again.</p>
        </Box>
        <RRLink to={'/login'} component={Link}>
          Back to login
        </RRLink>
      </LoggedOutFormContainer>
    );
  }

  if (passwordChanged) {
    return (
      <LoggedOutFormContainer>
        <Heading h2 className={cn('text-center', 'mb-6')}>
          Password changed!{' '}
          <span role="img" aria-hidden={true}>
            🎉
          </span>
        </Heading>
        <Box className={cn('mb-6')}>
          <p>Please login with your new password.</p>
        </Box>
        <RRLink to={'/login'} component={Link}>
          Back to login
        </RRLink>
      </LoggedOutFormContainer>
    );
  }

  return (
    <LoggedOutFormContainer>
      <Heading h2 className={cn('text-center', 'mb-6')}>
        Change your password
      </Heading>
      <Box className={cn('mb-4')}>
        <p>Your new password must be at least 10 characters long.</p>
      </Box>
      <FormProvider {...methods}>
        <form className={cn('space-y-6')} onSubmit={handleSubmit(onSubmit)}>
          <Box className={cn('mb-4')}>
            <Controller
              rules={{
                required: 'Password is required',
                minLength: {
                  value: 10,
                  message: 'The password must be at least 10 characters long',
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  label={'New Password'}
                  type={'password'}
                  error={fieldState.error && fieldState.error.message}
                />
              )}
              name={'newPassword'}
            />
          </Box>

          <Box className={cn('mb-8')}>
            <Controller
              rules={{
                required: 'Please type your password again',
                minLength: {
                  value: 10,
                  message: 'The password must be at least 10 characters long',
                },
                validate: (value) =>
                  value === newPassword || 'The passwords do not match',
              }}
              render={({ field, fieldState }) => {
                console.log('field', fieldState.error);
                return (
                  <Input
                    {...field}
                    label={'Confirm New Password'}
                    type={'password'}
                    error={fieldState.error && fieldState.error.message}
                  />
                );
              }}
              name={'newPasswordRepeat'}
            />
          </Box>

          <Button isLoading={tokenQuery.isLoading || mutation.isLoading} fill>
            Reset Password
          </Button>
        </form>
      </FormProvider>
    </LoggedOutFormContainer>
  );
}

export { ChangePassword };
