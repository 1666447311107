import React, { memo } from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import moment from 'moment';

import './transaction-list-item-mobile.css';

const getPayeeName = (transaction, getPreloadedAccount) => {
  if (transaction.transfer_target) {
    return getPreloadedAccount(transaction);
  }

  if (transaction.payee) {
    return transaction.payee;
  }

  return null;
};

const TransactionListItemMobile = ({
  budgetDetails,
  editMobileTransaction,
  settings,
  transaction,
  getPreloadedAccount,
}) => {
  const { approved, amount, payee, type, category, date } = transaction;

  return (
    <div
      className="transaction-row-mobile"
      onClick={() => editMobileTransaction(transaction)}
    >
      <div className="transaction-mobile-top-row">
        <span className="mobile-label-payee">
          {getPayeeName(transaction, getPreloadedAccount) || '---'}
          <i
            className={classNames('fas fa-check-circle fa-fw fa-sm', {
              approved,
            })}
          />
        </span>
        <span
          className={classNames('mobile-label-amount', {
            'inflow-transaction': type === 'inflow',
          })}
        >
          {type === 'inflow' ? '+' : '-'}
          <NumberFormat
            displayType="text"
            name="transactionAmount"
            value={amount}
            isNumericString={true}
            allowNegative={true}
            placeholder="0.00"
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={settings.thousands}
            decimalSeparator={settings.decimal}
            prefix={settings.show_symbol ? `${settings.symbol}` : ''}
          />
        </span>
      </div>
      <div className="transaction-mobile-bottom-row">
        <div className="mobile-label-category">
          {category || 'Uncategorized'}
        </div>
        <div className="mobile-label-date">
          {date ? moment(date).format(settings.date_format) : '---'}
        </div>
      </div>
      <i className="fal fa-chevron-right fa-fw mobile-transaction-details-icon" />
    </div>
  );
};

export default memo(TransactionListItemMobile);
