import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import './left-to-budget-tablet.css';

const printSign = (amount) => {
  const printedSign = Number(amount) >= 0 ? '+' : '-';

  return printedSign;
};

class LeftToBudgetTabletView extends Component {
  state = {
    ltbLoading: true,
  };

  componentDidMount() {
    setTimeout(() => {
      //Start the timer
      this.setState({ ltbLoading: false }); //After 1 second, set render to true
    }, 1000);
  }

  renderLtb = () => {
    const {
      lastMonthString,
      ltbBreakdown,
      settings,
      thisMonthString,
    } = this.props;

    return (
      <div className="ltb-wrapper">
        <div className="ltb-section">
          <div className="ltb-section-inner-wrapper">
            <p className="ltb-line-item">
              <span>Fwd from {lastMonthString}: </span>
              <NumberFormat
                displayType="text"
                className="ltb-amount"
                name="fwdFromLastMonth"
                value={(ltbBreakdown && ltbBreakdown.fwdFromLastMonth) || 0}
                isNumericString={true}
                allowNegative={true}
                placeholder="0.00"
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={settings.thousands}
                decimalSeparator={settings.decimal}
                prefix={
                  settings.show_symbol
                    ? `${printSign(
                        ltbBreakdown && ltbBreakdown.fwdFromLastMonth
                      )} ${settings.symbol}`
                    : `${printSign(
                        ltbBreakdown && ltbBreakdown.fwdFromLastMonth
                      )}`
                }
              />
            </p>
            <p className="ltb-line-item">
              <span>Income for {thisMonthString}: </span>
              <NumberFormat
                displayType="text"
                className="ltb-amount"
                name="fwdFromLastMonth"
                value={(ltbBreakdown && ltbBreakdown.incomeForMonth) || 0}
                isNumericString={true}
                allowNegative={true}
                placeholder="0.00"
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={settings.thousands}
                decimalSeparator={settings.decimal}
                prefix={
                  settings.show_symbol
                    ? `${printSign(
                        ltbBreakdown && ltbBreakdown.incomeForMonth
                      )} ${settings.symbol}`
                    : `${printSign(
                        ltbBreakdown && ltbBreakdown.incomeForMonth
                      )}`
                }
              />
            </p>
          </div>
        </div>
        <div className="ltb-section">
          <div className="ltb-section-inner-wrapper">
            <p className="ltb-line-item">
              <span>Budgeted in {thisMonthString}: </span>
              <NumberFormat
                displayType="text"
                className="ltb-amount"
                name="fwdFromLastMonth"
                value={(ltbBreakdown && ltbBreakdown.budgetedForMonth) || 0}
                isNumericString={true}
                allowNegative={true}
                placeholder="0.00"
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={settings.thousands}
                decimalSeparator={settings.decimal}
                prefix={settings.show_symbol ? `- ${settings.symbol}` : '-'}
              />
            </p>
            {settings.global_ltb && (
              <p className="ltb-line-item">
                <span>
                  Budgeted Ahead:{' '}
                  <i
                    data-balloon-pos="left"
                    aria-label="Total Budgeted in the Future"
                    className="fas fa-question-circle fa-fw fa-sm"
                  />
                </span>
                <NumberFormat
                  displayType="text"
                  className="ltb-amount"
                  name="fwdFromLastMonth"
                  value={(ltbBreakdown && ltbBreakdown.budgetedAhead) || 0}
                  isNumericString={true}
                  allowNegative={true}
                  placeholder="0.00"
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={settings.thousands}
                  decimalSeparator={settings.decimal}
                  prefix={settings.show_symbol ? `- ${settings.symbol}` : '-'}
                />
              </p>
            )}
          </div>
        </div>
        <div className="ltb-section left-to-budget-section">
          <p className="ltb-line-item left-to-budget-line-item">
            <span>Left to Budget: </span>
            <NumberFormat
              displayType="text"
              className="ltb-amount"
              name="fwdFromLastMonth"
              value={(ltbBreakdown && ltbBreakdown.leftToBudget) || 0}
              isNumericString={true}
              allowNegative={true}
              placeholder="0.00"
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={settings.thousands}
              decimalSeparator={settings.decimal}
              prefix={settings.show_symbol ? `${settings.symbol}` : ''}
            />
          </p>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="ltb-tablet-month">
        {this.state.ltbLoading ? (
          <i className="far fa-spinner-third fa-spin ltb-loading" />
        ) : (
          this.renderLtb()
        )}
      </div>
    );
  }
}

export default LeftToBudgetTabletView;
