import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import mixpanel from 'mixpanel-browser';
import BudgetTitleDropdownRedux from './budget-title-dropdown-redux';
import SidebarAccountsConnector from './sidebar-accounts/sidebar-accounts-connector';
import SidebarOffBudgeTitleConnector from './sidebar-off-budget-title-connector';
import SidebarOffBudgetAccountsConnector from './sidebar-off-budget-accounts/sidebar-off-budget-accounts-connector';
import ModalBase from '../../common/modal/modal-base';
import NewAccountForm from '../../forms/new-account-form';
import './sidebar.css';

function Sidebar({ onSaveAccount, settings, routes, isActive }) {
  const addNewAccount = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ModalBase onClose={onClose} title={'New Account'}>
            <NewAccountForm
              onClose={onClose}
              onSaveAccount={onSaveAccount}
              settings={settings}
            />
          </ModalBase>
        );
      },
    });
  };
  return (
    <aside className={`sidebar is-${isActive ? 'active' : 'disabled'}`}>
      <BudgetTitleDropdownRedux />
      <ul className="menu-list">
        {routes.map((route) => {
          if (route.onSidebar) {
            return (
              <li key={route.label}>
                <NavLink
                  to={route.path}
                  exact={route.exact}
                  activeClassName="is-active"
                  onClick={() => mixpanel.track(`Navigate to ${route.label}`)}
                >
                  {route.icon}
                  {route.label}
                </NavLink>
              </li>
            );
          }
        })}
      </ul>
      <section className="new-account-section">
        <h2 className="sidebar-accounts-section-title">Accounts</h2>
        <i
          className="fas fa-plus fa-fw fa-lg new-account-button"
          alt="Add new account"
          onClick={addNewAccount}
        />
      </section>
      <SidebarAccountsConnector />

      <section className="new-account-section">
        <SidebarOffBudgeTitleConnector />
      </section>
      <SidebarOffBudgetAccountsConnector />
    </aside>
  );
}

export default withRouter(Sidebar);
