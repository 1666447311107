import React from 'react';
// @ts-ignore
import TelegramLoginButton from 'react-telegram-login';
import { Layout } from '../../components/layout';
import { api } from '../../api';
import { FASTAPI_API, TELEGRAM_BOT_NAME } from '../../../constants';

// Example of the response
// const response = {
//   id: 1193057361,
//   first_name: 'John',
//   last_name: 'Doex',
//   username: 'jhandoex',
//   auth_date: 1673223033,
//   hash: '060416319dff6ae4e1055d09723295f5207bbab658a7249d45d28e89c7bbc61b',
// };

// console.log('TELEGRAM', TELEGRAM_BOT_NAME);

interface TelegramResponse {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  auth_date: number;
  hash: string;
}

function PageTelegram() {
  // @ts-ignore
  const handleTelegramResponse = (response: TelegramResponse) => {
    api
      .post(`${FASTAPI_API}/telegram/connect`, {
        chat_id: response.id,
        hash: response.hash,
        auth_date: response.auth_date,
      })
      .then((response) => console.log('response', response))
      .catch((error) => console.log('error', error));
  };

  return (
    <Layout
      title="Telegram integration"
      subtitle="
      Add transactions from your mobile device using our Telegram integration. Login with your Telegram account and follow the instructions from the bot.
      "
    >
      <TelegramLoginButton
        dataOnauth={handleTelegramResponse}
        botName={TELEGRAM_BOT_NAME}
      />
    </Layout>
  );
}

export { PageTelegram };
