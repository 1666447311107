import React, { memo } from 'react';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import { isMobile } from '../../../../../utilities/helpers';

const BudgetTableCCCategoryGroupMonthView = ({
  creditCardGroupTotalActivityByMonth,
  creditCardGroupTotalBudgetedByMonth,
  creditCardGroupTotalRemainingByMonth,
  isMiddleMonth,
  settings,
}) => {
  return (
    <div
      className={classNames('month-group', {
        'is-middle-column': isMiddleMonth,
      })}
    >
      <div className="budget-column-budgeted">
        <span className="credit-card-row-header-label">
          Budgeted
          <br />
        </span>
        <span className="credit-card-row-header-amount">
          <NumberFormat
            displayType="text"
            className="budget-category-input budget-category-readonly"
            name="budgeted"
            value={creditCardGroupTotalBudgetedByMonth || '0'}
            isNumericString={true}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={settings.thousands}
            decimalSeparator={settings.decimal}
          />
        </span>
      </div>
      {!isMobile() && (
        <div className="budget-column-activity">
          <span className="credit-card-row-header-label">
            Activity
            <br />
          </span>

          <span className="credit-card-row-header-amount">
            <NumberFormat
              displayType="text"
              className="budget-category-input budget-category-readonly"
              name="budgeted"
              value={creditCardGroupTotalActivityByMonth || '0'}
              isNumericString={true}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={settings.thousands}
              decimalSeparator={settings.decimal}
            />
          </span>
        </div>
      )}
      <div className="budget-column-remaining">
        <span className="credit-card-row-header-label">
          Amount Due
          <br />
        </span>
        <span className="credit-card-row-header-amount">
          <NumberFormat
            displayType="text"
            className="budget-category-input budget-category-readonly"
            name="budgeted"
            value={creditCardGroupTotalRemainingByMonth || '0'}
            isNumericString={true}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={settings.thousands}
            decimalSeparator={settings.decimal}
          />
        </span>
      </div>
    </div>
  );
};

export default memo(BudgetTableCCCategoryGroupMonthView);
