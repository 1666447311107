import React, { Fragment, useRef, Component, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import AccountHeader from './account-header';
import TransactionList from './transaction-list';
import { useTransactionsView } from '../../store/transactionsView';
import { usePreviousState } from '../../utilities/use-previous-state';

function AccountContainer({ match }) {
  const mounted = useRef(null);
  const [isSelectEnabled, setSelectEnabled] = useState(false);
  const { transactionsView, setTransactionsView } = useTransactionsView(
    (state) => state
  );
  const [selectedTransactions, setSelectedTransactions] = useState([]);

  const previousMatch = usePreviousState(match);

  useEffect(() => {
    if (!mounted.current) {
      // Component did mount

      const accountId = match && match.params && match.params.id;
      const prevAccountId =
        previousMatch && previousMatch.params && previousMatch.params.id;
      if (prevAccountId && prevAccountId !== accountId) {
        setSelectEnabled(false);
        setSelectedTransactions([]);
      }

      mounted.current = true;
    } else {
      // Component did update
    }
  }, []);

  const handleToggleSelect = () => {
    if (isSelectEnabled) {
      setSelectEnabled(!isSelectEnabled);
      setSelectedTransactions([]);
    } else {
      setSelectEnabled(!isSelectEnabled);
    }
  };

  const handleToggleView = (currentView) => {
    setTransactionsView(currentView);
  };

  const handleOnSelected = (transaction, isSelected) => {
    const selectedTransactionList = [...selectedTransactions];
    if (isSelected) {
      selectedTransactionList.push(transaction);
      setSelectedTransactions(selectedTransactionList);
    } else {
      const reducedTransactionList = selectedTransactionList.filter(
        (selectedItem) => selectedItem.id !== transaction.id
      );
      setSelectedTransactions(reducedTransactionList);
    }
  };

  return (
    <>
      <AccountHeader
        currentView={transactionsView}
        onToggleSelect={handleToggleSelect}
        handleToggleView={handleToggleView}
        isSelectEnabled={isSelectEnabled}
        selectedTransactions={selectedTransactions}
      />
      <main className="inner-wrapper account-wrapper">
        <TransactionList
          currentView={transactionsView}
          isSelectEnabled={isSelectEnabled}
          onSelected={handleOnSelected}
          selectedTransactions={selectedTransactions}
        />
      </main>
    </>
  );
}

export default withRouter(AccountContainer);
