import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Text,
  Row,
  Input,
  Spacer,
  Button,
  Link,
  Loading,
} from '@nextui-org/react';
import { Controller, useForm } from 'react-hook-form';
import { FaEnvelope, FaLock } from 'react-icons/all';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';

import { useUserSignup, UserPayload } from '../../../user';

function Signup() {
  const history = useHistory();
  const { mutate, isLoading } = useUserSignup();
  const { control, handleSubmit } = useForm<UserPayload>();

  const displayGeneralSignupError = (error: AxiosError, toastId: string) => {
    const message = `${error.message}. Please try again.`;
    toast.error(message, {
      id: toastId,
    });
  };

  const onSubmit = (payload: UserPayload) => {
    const signupToast = toast.loading('Creating account...');

    mutate(payload, {
      onSuccess: () => {
        toast.success('Account created!', {
          id: signupToast,
        });

        toast('Now you can login with your email and password. 👇', {
          duration: 15000,
        });

        history.push('/login');
      },
      onError: (error, instance, context) => {
        if (error.response) {
          // Server responded with a status code
          const { data, status } = error.response;

          switch (status) {
            // User already exists
            case 409:
              toast.error(data.detail, { id: signupToast });
              break;
            default:
              // Signup general error
              displayGeneralSignupError(error, signupToast);
          }

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // Request sent, but server didn't respond
          displayGeneralSignupError(error, signupToast);
        } else {
          // Something happened in setting up the request that triggered an Error
          displayGeneralSignupError(error, signupToast);
        }
      },
    });
  };

  return (
    <Row
      css={{
        flexDirection: 'column',
        mw: '25rem',
      }}
    >
      <Row
        css={{
          fd: 'column',
          bg: '$gray50',
          p: '$xl',
          br: '$sm',
        }}
      >
        <Row css={{ textAlign: 'center' }}>
          <Text size={22} css={{ mx: 'auto' }}>
            Create Account
          </Text>
        </Row>

        <Spacer y={1} />

        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ flexDirection: 'column', width: '100%' }}
        >
          <Row css={{ flexDirection: 'column' }}>
            <Controller
              name="email"
              rules={{ required: 'Email field required' }}
              control={control}
              render={({ field, fieldState }) => {
                const hasErrors = !!fieldState.error;
                return (
                  <Input
                    fullWidth
                    bordered
                    color="primary"
                    size="lg"
                    placeholder="Email"
                    contentLeft={<FaEnvelope />}
                    status={hasErrors ? 'error' : 'default'}
                    helperText={
                      fieldState.error ? fieldState.error.message : ''
                    }
                    helperColor={hasErrors ? 'error' : 'default'}
                    {...field}
                  />
                );
              }}
            />

            <Spacer y={1.5} />

            <Controller
              name="password"
              rules={{ required: 'Password field required' }}
              control={control}
              render={({ field, fieldState }) => {
                const hasErrors = !!fieldState.error;
                return (
                  <Input.Password
                    fullWidth
                    bordered
                    color="primary"
                    size="lg"
                    placeholder="Password"
                    contentLeft={<FaLock />}
                    status={hasErrors ? 'error' : 'default'}
                    helperText={
                      fieldState.error ? fieldState.error.message : ''
                    }
                    helperColor={hasErrors ? 'error' : 'default'}
                    {...field}
                  />
                );
              }}
            />
          </Row>

          <Spacer y={1} />

          <Row justify="space-between" align="center">
            <Link href="#">Forgot password?</Link>
            <Button auto type="submit" css={{ br: '$xs' }}>
              {isLoading ? (
                <>
                  <Loading
                    color="currentColor"
                    size="xs"
                    loadingCss={{ mr: '$xs' }}
                  />
                  Sign up
                </>
              ) : (
                <>Sign up</>
              )}
            </Button>
          </Row>
        </form>
      </Row>
      <Spacer y={1} />
      <Row>
        <Text size={12} color="$gray50" css={{ ta: 'center' }}>
          By clicking <em style={{ fontWeight: 'bold' }}>"Sign Up"</em>, you
          agree to our Terms of Use, Privacy Policy, and also to periodically
          receive feature announcements and updates via email, which you can
          unsubscribe from at any time.
        </Text>
      </Row>
    </Row>
  );
}

export { Signup };
