import Decimal from 'decimal.js';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { roundToTwoDecimals } from './number-formatting';

const moment = extendMoment(Moment);

const emptyArr = [];

export const calculateRemaining = (budgeted, spent) => {
  const totalSpent = roundToTwoDecimals(spent);
  const totalBudgeted = roundToTwoDecimals(budgeted);
  return new Decimal(totalSpent).add(totalBudgeted).toFixed(2);
};

export const sumTimeframeSubcategories = (
  currentTimeframe,
  timeframes,
  timeframeSubcategories,
  filter
) => {
  if (
    timeframes &&
    timeframes.length &&
    timeframeSubcategories &&
    timeframeSubcategories.length
  ) {
    let totalRemaining = new Decimal(0);

    const minDate = moment(timeframes[0].firstOfMonth);
    const maxDate = moment(currentTimeframe, 'MMYYYY')
      .utc()
      .endOf('month');

    const range = moment.range(minDate, maxDate);

    for (const month of range.by('month')) {
      totalRemaining = timeframeSubcategories
        .filter((subCat) => filter(subCat, month))
        .reduce((acc, subCat) => {
          return acc.add(calculateRemaining(subCat.budgeted, subCat.spent));
        }, totalRemaining);
    }
    return totalRemaining.toFixed(2);
  }

  return '0.00';
};

const propertySelector = (selector) => {
  switch (Object.prototype.toString.call(selector)) {
    case '[object String]':
    case '[object Number]':
      return (e) => e[selector];

    case '[object Function]':
      return selector;
  }

  return undefined;
};

export const sum = (array, selector = (e) => e, map = (e) => e) => {
  const select = propertySelector(selector);
  return (array || emptyArr)
    .reduce((acc, e) => {
      const value = select(e, acc);
      if (value !== undefined) {
        return acc.add(map(new Decimal(value || 0), e, acc));
      }

      return acc;
    }, new Decimal(0))
    .toFixed(2);
};

export const add = (a, b) => {
  return new Decimal(a || 0).add(b || 0).toFixed(2);
};

export const sub = (a, b) => {
  return new Decimal(a || 0).sub(b || 0).toFixed(2);
};

export const equals = (a, b) => {
  return new Decimal(a || 0).equals(b || 0);
};

export const lessThan = (a, b) => {
  return new Decimal(a || 0).lessThan(b || 0);
};

export const lessThanOrEqual = (a, b) => {
  return new Decimal(a || 0).lessThanOrEqualTo(b || 0);
};

export const greaterThan = (a, b) => {
  return new Decimal(a || 0).greaterThan(b || 0);
};

export const greaterThanOrEqual = (a, b) => {
  return new Decimal(a || 0).greaterThanOrEqualTo(b || 0);
};
