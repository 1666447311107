import React from 'react';
import { connect } from 'react-redux';
import BudgetTableCCCategoryGroupMonthContainer from './budget-table-cc-category-group-month-container';
import {
  getCreditCardGroupTotalActivityByMonthFactory,
  getCreditCardGroupTotalBudgetedByMonthFactory,
  getCreditCardGroupTotalRemainingByMonthFactory,
} from '../../../../../selectors/credit-card-group-totals';

const BudgetTableCCCategoryGroupMonthConnector = ({
  creditCardGroupTotalActivityByMonth,
  creditCardGroupTotalBudgetedByMonth,
  creditCardGroupTotalRemainingByMonth,
  isMiddleMonth,
  monthToDisplay,
  settings,
}) => {
  return (
    <BudgetTableCCCategoryGroupMonthContainer
      creditCardGroupTotalActivityByMonth={creditCardGroupTotalActivityByMonth}
      creditCardGroupTotalBudgetedByMonth={creditCardGroupTotalBudgetedByMonth}
      creditCardGroupTotalRemainingByMonth={
        creditCardGroupTotalRemainingByMonth
      }
      isMiddleMonth={isMiddleMonth}
      monthToDisplay={monthToDisplay}
      settings={settings}
    />
  );
};

const makeMapStateToProps = () => {
  const getCreditCardGroupTotalBudgetedByMonth = getCreditCardGroupTotalBudgetedByMonthFactory();
  const getCreditCardGroupTotalActivityByMonth = getCreditCardGroupTotalActivityByMonthFactory();
  const getCreditCardGroupTotalRemainingByMonth = getCreditCardGroupTotalRemainingByMonthFactory();

  const mapStateToProps = (state, props) => {
    return {
      creditCardGroupTotalBudgetedByMonth: getCreditCardGroupTotalBudgetedByMonth(
        state,
        props
      ),
      creditCardGroupTotalActivityByMonth: getCreditCardGroupTotalActivityByMonth(
        state,
        props
      ),
      creditCardGroupTotalRemainingByMonth: getCreditCardGroupTotalRemainingByMonth(
        state,
        props
      ),
      settings: state.settings,
    };
  };
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  null
)(BudgetTableCCCategoryGroupMonthConnector);
