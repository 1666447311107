import {
  MONTH_NEXT,
  MONTH_PREVIOUS,
  SET_INITIAL_LTB_BREAKDOWN_BY_MONTH,
  REFRESH_LTB_BREAKDOWN_BY_MONTH,
} from '../action-list';
import moment from 'moment';

const splitAt = (index) => (x) => [x.slice(0, index), x.slice(index)];

export default function(state = {}, action) {
  let ltbBreakdownByMonth;

  switch (action.type) {
    case SET_INITIAL_LTB_BREAKDOWN_BY_MONTH:
      const ordered = {};

      Object.keys(action.ltbBreakdownByMonth)
        .sort(function(a, b) {
          const aDate = splitAt(2)(a)
            .reverse()
            .join('');
          const bDate = splitAt(2)(b)
            .reverse()
            .join('');

          if (aDate < bDate) return -1;
          if (aDate > bDate) return 1;
          return 0;
        })
        .forEach(function(key) {
          ordered[key] = action.ltbBreakdownByMonth[key];
        });

      return { ...ordered };

    case REFRESH_LTB_BREAKDOWN_BY_MONTH:
      return { ...state };

    // MONTH_NEXT and MONTH_PREVIOUS will check if the key exists in the timeframe list. If not, it'll add it
    case MONTH_NEXT:
      const formattedNextMonth = moment(
        action.stateOfActiveMonths.currentMonth
      ).format('MMYYYY');

      ltbBreakdownByMonth = { ...state };

      if (ltbBreakdownByMonth[formattedNextMonth] === undefined) {
        return {
          ...ltbBreakdownByMonth,
          [formattedNextMonth]: {
            incomeByMonth: 0,
          },
        };
      }

      return ltbBreakdownByMonth;

    case MONTH_PREVIOUS:
      const formattedPrevMonth = moment(
        action.stateOfActiveMonths.currentMonth
      ).format('MMYYYY');
      ltbBreakdownByMonth = { ...state };

      if (ltbBreakdownByMonth[formattedPrevMonth] === undefined) {
        return {
          ...ltbBreakdownByMonth,
          [formattedPrevMonth]: {
            incomeByMonth: 0,
          },
        };
      }

      return ltbBreakdownByMonth;

    default:
      return state;
  }
}
