import React, { memo } from 'react';
import classNames from 'classnames';
import ButtonBase from './button-base';

import './button-secondary.css';

const ButtonSecondary = (props) => {
  const { className, ...extraProps } = props;

  return (
    <ButtonBase
      className={classNames('button-secondary', className)}
      {...extraProps}
    />
  );
};

export default memo(ButtonSecondary);
