import React, { Fragment } from 'react';
import { Toaster } from 'react-hot-toast';
import ReactDOM from 'react-dom';
import { NextUIProvider } from '@nextui-org/react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { ToastContainer, Slide } from 'react-toastify';
import mixpanel from 'mixpanel-browser';
import store from './store';
import App from './components/layout/app-container';
import { theme } from './theme';

import './index.css';
// import './app.css';

// Translation catalogs
import common_de from './translations/de/common.json';
import common_es from './translations/es/common.json';
import common_en from './translations/en/common.json';

Sentry.init({
  dsn: 'https://4b35bb9f44e54831800c0f0111415a92@o1136487.ingest.sentry.io/4504433103077376',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // tracesSampleRate: 1.0,
  tracesSampleRate: 0.25,
});

mixpanel.init('3bd4e47ea7adc535b989f060444d1758');

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en', // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    es: {
      common: common_es, // 'common' is our custom namespace
    },
    de: {
      common: common_de,
    },
  },
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <Fragment>
        <div id="test-portal" />
        <ToastContainer
          className="toast-container"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick={true}
          position="top-center"
          transition={Slide}
        />

        <NextUIProvider theme={theme}>
          <App />
        </NextUIProvider>
      </Fragment>
      <Toaster />
    </Provider>
  </I18nextProvider>,
  document.getElementById('budgetwise')
);
