import create from 'zustand';

interface UserStoreState {
  budget: null | string;
  setDefaultBudgetId: (budget: string) => void;
}

// This store grabs data from the first render and puts it in a global
// state. We will use this while we transition to react-query
const useUserStore = create<UserStoreState>()((set) => ({
  budget: null,
  setDefaultBudgetId: (budget) => set((state) => ({ budget })),
}));

export { useUserStore };
