import React, { Fragment, Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import ModalBase from '../../common/modal/modal-base';
import ButtonWarning from '../../common/button/button-warning';
import ButtonLink from '../../common/button/button-link';
import ManageBudgetsView from './manage-budgets-view';

import './manage-budgets.css';

class ManageBudgetsContainer extends Component {
  state = {
    defaultBudget: this.props.defaultBudget,
  };

  componentDidMount() {
    this.props.fetchAllBudgets(this.props.userId);
  }

  handleSetDefaultBudget = (budgetId) => {
    const { userId } = this.props;

    this.setState({ defaultBudget: budgetId });

    this.props.setDefaultBudget(budgetId, userId);
  };

  deleteBudgetAlert = (budget) => {
    confirmAlert({
      customUI: ({ onClose: onCloseWarning }) => {
        return (
          <ModalBase
            onClose={onCloseWarning}
            title={`Delete Budget - ${budget.name}`}
            className="recurring-cancel"
          >
            <i
              className="alert-icon fas fa-exclamation-circle fa-fw fa-lg"
              style={{ color: '#c26e6b' }}
            />
            <div className="alert-text-wrapper">
              <span className="alert-title">
                Are you sure you want to delete this budget?
              </span>
              <span className="alert-text">
                All of your budget data will be completely erased and this
                action cannot be undone.
              </span>
              <div
                className="has-text-right alert-button-container"
                style={{ marginTop: '24px' }}
              >
                <ButtonLink
                  className="button is-outlined"
                  onClick={() => onCloseWarning()}
                >
                  Cancel
                </ButtonLink>
                <ButtonWarning
                  className="button is-danger"
                  onClick={() => {
                    this.props.deleteBudget(budget.id);
                    onCloseWarning();
                  }}
                >
                  {`Delete Budget`}
                </ButtonWarning>
              </div>
            </div>
          </ModalBase>
        );
      },
    });
  };

  render() {
    const { budgetDetails, onClose } = this.props;
    const { defaultBudget } = this.state;

    return (
      <ManageBudgetsView
        budgetDetails={budgetDetails}
        defaultBudget={defaultBudget}
        deleteBudgetAlert={this.deleteBudgetAlert}
        setDefaultBudget={this.handleSetDefaultBudget}
        onClose={onClose}
      />
    );
  }
}

export default ManageBudgetsContainer;
