import React, { PureComponent, memo } from 'react';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import MoveMoneyView from './move-money-view';

const selectButtonStyles = (backgroundColor, color) => ({
  backgroundColor,
  padding: '0px 0px 0px 0px',
  margin: '6px 6px 6px 6px',
  border: `2px solid ${backgroundColor}`,
  borderRadius: '2px',
  width: 'auto',
  textAlign: 'center',
  color,
});

const customSelectStyles = {
  option: (base, state) => {
    if (state.data.style === 'button') {
      return {
        ...base,
        ...selectButtonStyles('#1b97b3', '#fff'),
      };
    }
    return {
      ...base,
      backgroundColor: state.isFocused ? '#F4F5F6' : '',
      padding: '4px 8px',
      color: state.isSelected ? '#565a5c' : '#565a5c',
    };
  },
  control: (base, state) => ({}),
  groupHeading: (base, state) => ({
    ...base,
    borderBottom: '1px solid #D8D8D8',
    borderTop: '1px solid #D8D8D8',
    padding: '4px 6px',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '10px',
    color: '#384f66',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  }),
  group: (base, state) => ({
    paddingTop: '0 !important',
    marginTop: '0 !important',
  }),
  menu: (base, state) => ({
    ...base,
    width: 'auto',
    minWidth: '125px',
    wrap: 'none !important',
  }),
  menuList: (base, state) => ({
    ...base,
    width: 'auto',
    minWidth: 'auto',
    wrap: 'none !important',
    paddingTop: '0 !important',
    marginTop: '0 !important',
  }),
  singleValue: (base, state) => ({
    ...base,
    ...(state.data.style === 'button'
      ? { ...selectButtonStyles('#2fbfc4', '#fff'), top: '30%', left: '0' }
      : {}),
  }),
};

class MoveMoneyContainer extends PureComponent {
  state = {
    rowsLength: 1,
    selectedCategories: [],
  };

  sortCategoriesAlphabetically = (categoriesList) => {
    return categoriesList.sort(function (a, b) {
      let itemA = a.name.toLowerCase();
      let itemB = b.name.toLowerCase();

      return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
    });
  };

  listAvailableCategories = () => {
    const { categoryId, allCategories } = this.props;
    const { selectedCategories } = this.state;

    const filteredList = allCategories.filter(
      (c) =>
        c.id !== categoryId && !selectedCategories.some((sc) => sc.id === c.id)
    );

    return this.sortCategoriesAlphabetically(filteredList);
  };

  handleCategoryChange = (value, index) => {
    const categoryList = this.state.selectedCategories;

    if (categoryList[index - 1] && !categoryList[index - 1].id) {
      categoryList.push({
        id: value.value,
        name: value.label,
        amt: null,
      });
    } else {
      categoryList[index - 1] = {
        id: value.value,
        name: value.label,
        amt: null,
      };
    }

    this.setState({
      selectedCategories: [...categoryList],
    });
  };

  handleAmtChange = (value, event, index) => {
    const categoryList = [...this.state.selectedCategories];
    categoryList[index - 1].amt = value.value;
  };

  renderAvailableCategories = () => {
    const { settings } = this.props;
    const { selectedCategories, rowsLength } = this.state;

    const availableCategories = this.listAvailableCategories();
    let availableCategoriesInDropdown = [];

    for (var i = 0, length = rowsLength; i < length; i++) {
      availableCategoriesInDropdown.push(
        <div
          className="move-money-category-row"
          key={i}
          // We need this to prevent the popover from closing
          onClick={(e) => e.stopPropagation()}
        >
          <Select
            placeholder="Select a category"
            styles={customSelectStyles}
            // value={options[value] && { label: options[value], value: value }}
            className="editable-cell-input"
            onChange={(v) => this.handleCategoryChange(v, i)}
            openMenuOnFocus={true}
            blurInputOnSelect={true}
            options={availableCategories.map((category) => ({
              label: category.name,
              value: category.id,
            }))}
          />
          <NumberFormat
            displayType="input"
            className="budget-category-input-editable"
            name="budgeted"
            value={selectedCategories[i] && selectedCategories[i].amt}
            isNumericString={true}
            placeholder={`0${settings.decimal}00`}
            decimalScale={2}
            allowNegative={true}
            onValueChange={(v, e) => this.handleAmtChange(v, e, i)}
            disabled={!selectedCategories[i] || !selectedCategories[i].id}
            fixedDecimalScale={true}
            thousandSeparator={settings.thousands}
            decimalSeparator={settings.decimal}
            onFocus={this.handleBudgetFieldFocus}
          />
        </div>
      );
    }

    return availableCategoriesInDropdown;
  };

  handleIncrementRows = () =>
    this.setState({ rowsLength: this.state.rowsLength + 1 });

  handleDecrementRows = () =>
    this.setState({ rowsLength: this.state.rowsLength - 1 || 1 });

  render() {
    const { amtRemaining } = this.props;

    const isNegativeAmount = parseFloat(amtRemaining) < 0;

    return (
      <MoveMoneyView
        isNegativeAmount={isNegativeAmount}
        decrementRows={this.handleDecrementRows}
        incrementRows={this.handleIncrementRows}
      >
        {this.renderAvailableCategories()}
      </MoveMoneyView>
    );
  }
}

export default memo(MoveMoneyContainer);
