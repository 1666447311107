import { SET_SYNC_STATUS } from '../action-list';

export default function(state = {}, action) {
  switch (action.type) {
    case SET_SYNC_STATUS:
      return action.payload;

    default:
      return state;
  }
}
