import React, { ReactNode } from 'react';
import cn from 'classnames';

interface HeadingProps {
  className?: string;
  children: ReactNode;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
}

function Heading(props: HeadingProps) {
  const { className, children, ...rest } = props;

  const extendedClasses = cn('text-gray-600', 'dark:text-gray-300', className);

  if (rest.h2) {
    const classes = cn('text-2xl', 'mb-2', extendedClasses);

    return (
      <h2 className={classes} {...rest}>
        {children}
      </h2>
    );
  }

  if (rest.h3) {
    const classes = cn('text-xl', 'mb-4', extendedClasses);

    return (
      <h3 className={classes} {...rest}>
        {children}
      </h3>
    );
  }

  if (rest.h4) {
    const classes = cn('text-lg', extendedClasses);

    return (
      <h4 className={classes} {...rest}>
        {children}
      </h4>
    );
  }

  return <h5 {...props}>{children}</h5>;
}

export { Heading };
