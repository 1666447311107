import { useQueryClient, useMutation, useQuery } from 'react-query';
import { api } from '../../../api';
import { FASTAPI_API } from '../../../../constants';

// Queries

async function getSubscriptionPlan() {
  const response = await api.get(`${FASTAPI_API}/sub/info`);

  return response.data;
}

function useSubscriptionPlan() {
  return useQuery({
    queryKey: ['subscriptionPlan'],
    queryFn: getSubscriptionPlan,
  });
}

// Mutations
async function updateSubscriptionPlan(planId: number) {
  const response = await api.post(`${FASTAPI_API}/sub/update`, {
    planId,
  });

  return response.data;
}

function useMutateSubscriptionPlan() {
  const queryClient = useQueryClient();

  return useMutation((planId: number) => updateSubscriptionPlan(planId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['subscriptionPlan']);
    },
  });
}

export { useSubscriptionPlan, useMutateSubscriptionPlan };
