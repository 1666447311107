import React, { Component } from 'react';
import {
  accountTypesOffBudgetAssets,
  accountTypesOffBudgetLiabilities,
} from '../../../../utilities/account-types';
import SidebarAccountsSectionContainer from '../sidebar-accounts/sidebar-accounts-section-container';

class SidebarOffBudgetAccountsContainer extends Component {
  deleteAccount = (accountId, reorderedList) => {
    this.props.deleteAccount(
      this.props.budgetDetails.id,
      accountId,
      reorderedList
    );
  };

  sortedAccounts = (filterType) => {
    const { accounts } = this.props;

    // Checks if the account type matches the types in the filter array
    return (
      accounts &&
      accounts.filter((account) => {
        return filterType.includes(account.type);
      })
    );
  };

  render() {
    const { accountsById, budgetDetails, settings, updateAccount } = this.props;

    return (
      <aside className="sidebar-accounts-list">
        <SidebarAccountsSectionContainer
          sectionTitle="Assets"
          slug="off-budget-assets"
          accounts={this.sortedAccounts(accountTypesOffBudgetAssets)}
          accountsById={accountsById}
          updateAccount={updateAccount}
          onDeleteAccount={this.deleteAccount}
          budgetDetails={budgetDetails}
          settings={settings}
        />
        <SidebarAccountsSectionContainer
          sectionTitle="Liabilities"
          slug="off-budget-liabilities"
          accounts={this.sortedAccounts(accountTypesOffBudgetLiabilities)}
          accountsById={accountsById}
          updateAccount={updateAccount}
          onDeleteAccount={this.deleteAccount}
          budgetDetails={budgetDetails}
          settings={settings}
        />
      </aside>
    );
  }
}

export default SidebarOffBudgetAccountsContainer;
