import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface SignupState {
  signingUp: boolean;
  setSigningUp: (isSigningUp: boolean) => void;
  savedToasts: Array<string>;
  setSavedToasts: (toasts: Array<string>) => void;
}

const useSignupStore = create<SignupState>()(
  devtools((set) => ({
    signingUp: false,
    setSigningUp: (isSigningUp: boolean) =>
      set(() => ({ signingUp: isSigningUp })),
    savedToasts: [],
    setSavedToasts: (toasts: Array<string>) =>
      set(() => ({ savedToasts: toasts })),
  }))
);

export { useSignupStore };
