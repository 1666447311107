import create from 'zustand';

const useTransactionsView = create((set) => ({
  transactionsView: 'current',
  clearTransactionsView: () => set(() => ({ transactionsView: 'current' })),
  setTransactionsView: (view) =>
    set(() => {
      if (view != 'current' && view != 'scheduled') {
        throw new Error(
          'Transaction view should be either "current" or "scheduled" only.'
        );
      }
      return { transactionsView: view };
    }),
  toggleTransactionsView: () =>
    set(({ currentView }) => {
      switch (currentView) {
        case 'current':
          return { transactionsView: 'scheduled' };
        case 'scheduled':
          return { transactionsView: 'current' };
        default:
          return { transactionsView: 'scheduled' };
      }
    }),
}));

export { useTransactionsView };
