import React, { FunctionComponent, memo, Fragment } from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

import { thisMonthAndNextMonthInMMMM } from '../../../../utilities/helpers';
import { sum } from '../../../../utilities/budgeting-formulas';

const getPayeeName = (transaction, getPreloadedAccount) => {
  if (transaction.transfer_target) {
    return getPreloadedAccount(transaction);
  }

  if (transaction.payee) {
    return transaction.payee;
  }

  return 'No Payee';
};

const transferCategory = () => {
  return (
    <Fragment>
      <i className="fas fa-exchange-alt fa-fw transfer-type-icon" />
      Transfer
    </Fragment>
  );
};

const isApproved = (approved) => {
  if (approved || undefined) {
    return true;
  }

  return false;
};

const includeNowIconClass = (includeNow) => {
  if (includeNow) {
    return `fas fa-hourglass-end fa-fw include-now-icon-filled`;
  } else {
    return `fas fa-hourglass-start fa-fw include-now-icon`;
  }
};

const shouldRenderIncludeNow = (global_ltb, transactionDate) => {
  if (!global_ltb && moment(transactionDate).isAfter(moment().endOf('day'))) {
    return true;
  }

  return false;
};

// TODO: extract these two functions out for reuse
const formatLtbCategory = (
  category,
  date,
  ltbNext,
  formattedCategoryAccountList
) => {
  if (category === 'Left to Budget') {
    const monthsToDisplay = thisMonthAndNextMonthInMMMM(date);

    return !ltbNext
      ? `Income for ${monthsToDisplay.currentMonthInMMMM}`
      : `Income for ${monthsToDisplay.nextMonthInMMMM}`;
  } else {
    return category;
  }
};

const TransactionListItemReadOnlyView = ({
  accountDetails,
  isSelectEnabled,
  isRowSelected,
  formattedCategoryAccountList,
  onSelected,
  getPreloadedAccount,
  onChildCollapse,
  childCollapsed,
  onApproveTransaction,
  settings,
  toggleIncludeNow,
  toggleEditTransaction,
  transaction,
  inflowFirst = false,
}) => {
  const hasChildren = transaction.child_transactions.length;
  const uncategorizedChildren =
    hasChildren && transaction.child_transactions.filter((c) => !c.category);

  const totalAmount = hasChildren
    ? sum(transaction.child_transactions, ({ amount }) => amount)
    : transaction.amount;
  const [xFlow, yFlow] = inflowFirst
    ? ['inflow', 'outflow']
    : ['outflow', 'inflow'];

  return (
    <Fragment>
      <div
        className={classNames('transaction-row transaction-row-readonly', {
          'row-selected': isRowSelected,
          'uncategorized-row':
            (!hasChildren &&
              !transaction.is_transfer &&
              !transaction.off_budget &&
              !transaction.category) ||
            uncategorizedChildren.length > 0,
        })}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggleEditTransaction();
          if (isSelectEnabled) {
            onSelected(transaction, !isRowSelected);
          }
        }}
      >
        {isSelectEnabled && (
          <div className="transaction-column select-column">
            <label className="transaction-checkbox-container">
              <input
                type="checkbox"
                checked={isRowSelected}
                onChange={() => {
                  onSelected(transaction, isRowSelected);
                }}
              />
              <span className="transaction-checkmark" />
            </label>
          </div>
        )}
        <div className="transaction-column date-column">
          {/* // TODO: We need to change this to use date-fns, but date_format needs to match formatting as they need it differently */}
          {/* {format(parseISO(transaction.date), 'MM/dd/yyyy')} */}
          {moment(transaction.date).format(settings.date_format)}
        </div>
        <div className="transaction-column payee-column">
          {getPayeeName(transaction, getPreloadedAccount)}
        </div>
        {!accountDetails.off_budget && (
          <div className="transaction-column category-column">
            {formatLtbCategory(
              transaction.category,
              transaction.date,
              transaction.ltb_next,
              formattedCategoryAccountList
            ) ||
              (hasChildren
                ? 'Split Transaction'
                : transaction.is_transfer
                ? transferCategory()
                : 'Uncategorized')}
            {hasChildren ? (
              <i
                className={`fas ${
                  childCollapsed ? 'fa-plus-circle' : 'fa-minus-circle'
                } fa-fw icon-toggle-split-children`}
                onClick={(e) => {
                  e.stopPropagation();
                  onChildCollapse(!childCollapsed);
                }}
              />
            ) : (
              undefined
            )}
          </div>
        )}
        <div className="transaction-column notes-column">
          {transaction.memo}
        </div>
        <div className={`transaction-column amount-column ${xFlow}-column`}>
          {transaction.type === xFlow && (
            <NumberFormat
              displayType="text"
              name="transactionAmount"
              value={totalAmount}
              isNumericString={true}
              allowNegative={true}
              placeholder="0.00"
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={settings.thousands}
              decimalSeparator={settings.decimal}
              prefix={settings.show_symbol ? `${settings.symbol}` : ''}
            />
          )}
        </div>
        <div className={`transaction-column amount-column ${yFlow}-column`}>
          {transaction.type === yFlow && (
            <NumberFormat
              displayType="text"
              name="transactionAmount"
              value={totalAmount}
              isNumericString={true}
              allowNegative={true}
              placeholder="0.00"
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={settings.thousands}
              decimalSeparator={settings.decimal}
              prefix={settings.show_symbol ? `${settings.symbol}` : ''}
            />
          )}
        </div>
        <div className="transaction-column approved-column">
          <i
            data-balloon-pos="left"
            aria-label="Cleared"
            className={classNames('fas fa-check-circle fa-fw', {
              approved: isApproved(transaction.approved),
            })}
            onClick={(e) => {
              e.stopPropagation();
              onApproveTransaction(transaction, !transaction.approved);
            }}
          />
          {shouldRenderIncludeNow(settings.global_ltb, transaction.date) && (
            <i
              data-balloon-pos="left"
              aria-label="Include in Budget Now"
              className={includeNowIconClass(transaction.include_now)}
              onClick={(e) => {
                e.stopPropagation();
                toggleIncludeNow(transaction, !transaction.include_now);
              }}
            />
          )}

          {/* {transaction.approved} */}
        </div>
        {/*{!childCollapsed && transaction.child_transactions.map((t: any, index: number) => renderChildTransaction(t, index, childCollapsed, xFlow, yFlow, settings, toggleEditTransaction))}*/}
      </div>
      {!childCollapsed &&
        transaction.child_transactions.map((t, index) =>
          renderChildTransaction(
            t,
            index,
            childCollapsed,
            xFlow,
            yFlow,
            settings,
            toggleEditTransaction,
            formattedCategoryAccountList
          )
        )}
    </Fragment>
  );
};

const renderChildTransaction = (
  transaction,
  index,
  collapsed,
  xFlow,
  yFlow,
  settings,
  toggleEditTransaction,
  formattedCategoryAccountList
) => (
  <div
    key={index}
    className={`transaction-row transaction-row-readonly transaction-row-desktop-inner-child child-expanded`}
    onClick={() => toggleEditTransaction()}
  >
    {/*<div className="transaction-column select-column" />*/}
    <div className="transaction-column date-column" />
    <div className="transaction-column payee-column" />
    <div className="transaction-column category-column">
      {formatLtbCategory(
        transaction.category,
        transaction.date,
        transaction.ltb_next,
        formattedCategoryAccountList
      ) || 'Uncategorized'}
    </div>
    <div className="transaction-column notes-column">{transaction.memo}</div>
    <div className={`transaction-column amount-column ${xFlow}-column`}>
      {transaction.type === xFlow && (
        <NumberFormat
          displayType="text"
          name="transactionAmount"
          value={transaction.amount}
          isNumericString={true}
          allowNegative={true}
          placeholder="0.00"
          decimalScale={2}
          fixedDecimalScale={true}
          thousandSeparator={settings.thousands}
          decimalSeparator={settings.decimal}
          prefix={settings.show_symbol ? `${settings.symbol}` : ''}
        />
      )}
    </div>
    <div className={`transaction-column amount-column ${yFlow}-column`}>
      {transaction.type === yFlow && (
        <NumberFormat
          displayType="text"
          name="transactionAmount"
          value={transaction.amount}
          isNumericString={true}
          allowNegative={true}
          placeholder="0.00"
          decimalScale={2}
          fixedDecimalScale={true}
          thousandSeparator={settings.thousands}
          decimalSeparator={settings.decimal}
          prefix={settings.show_symbol ? `${settings.symbol}` : ''}
        />
      )}
    </div>
    <div className="transaction-column approved-column" />
  </div>
);

export default memo(TransactionListItemReadOnlyView);
