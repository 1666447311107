export const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);
  return result;
};

export const listOrderByIds = (list) => {
  return list.map((listItem) => {
    return listItem.id;
  });
};

export const sortByOrderOfIds = (list, listOrderByIds) => {
  if (listOrderByIds && list) {
    return list.sort((a, b) => {
      return listOrderByIds.indexOf(a.id) - listOrderByIds.indexOf(b.id);
    });
  }

  return list;
};
