import { TOGGLE_EDIT_TRANSACTION } from '../action-list';

const initialState = null;

export default function(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_EDIT_TRANSACTION:
      return action.transactionId;

    default:
      return state;
  }
}
