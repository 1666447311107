import React, { memo } from 'react';
import classNames from 'classnames';
import ModalBase from './modal-base';

import './modal-fixed-height.css';

const ModalFixedHeight = (props) => {
  const { className, ...extraProps } = props;

  return (
    <ModalBase
      className={classNames('modal-fixed-height', className)}
      {...extraProps}
    />
  );
};

export default memo(ModalFixedHeight);
