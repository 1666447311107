import { createSelector } from 'reselect';
import Moment from 'moment';
import parse from 'date-fns/parse';
import isSameMonth from 'date-fns/isSameMonth';
import endOfMonth from 'date-fns/endOfMonth';
import endOfDay from 'date-fns/endOfDay';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';
import addMonths from 'date-fns/addMonths';
import { extendMoment } from 'moment-range';
import { add, sub } from '../../src/utilities/budgeting-formulas';
import { getLastMonthInMMYYY } from '../../src/utilities/helpers';

const moment = extendMoment(Moment);

const getAllLtbBreakdownsByMonth = ({ ltbBreakdownByMonth }) => {
  return { ...ltbBreakdownByMonth };
};

const getAllTransactionsByMonth = ({ transactionsByMonth }) => {
  return { ...transactionsByMonth };
};

const getGlobalLtbSetting = ({ settings }) => {
  // Grabs the account ID from the route params
  return settings.global_ltb;
};

const getAllTimeframeSubcategoriesByMonth = ({
  timeframeSubcategoriesByMonth,
}) => {
  return { ...timeframeSubcategoriesByMonth };
};

const isPresentMonth = (date) => {
  return isSameMonth(date, new Date());
  // return moment(date, 'MMYYYY').format('MMMM') === moment().format('MMMM');
};

const sumAllLtbForMonth = (currentMonth, transactions, globalLtb) => {
  const parsedCurrentMonth = parse(currentMonth, 'MMyyyy', new Date());
  const prevMonth = moment(currentMonth, 'MMYYYY')
    .subtract(1, 'month')
    .format('MMYYYY');
  // 1. Get all transactions between beginning of month and last day of month, or today if current month that are marked 'Left to Budget'
  // 2. Sum all of those transactions
  // const firstDayOfTheMonth = moment(currentMonth, 'MMYYYY').startOf('month');
  const lastDayInMonth = isPresentMonth(parsedCurrentMonth)
    ? endOfDay(new Date())
    : endOfMonth(parsedCurrentMonth);

  const transactionsToSum =
    (transactions[currentMonth] &&
      transactions[currentMonth].length &&
      transactions[currentMonth].filter((transaction) => {
        const transactionDate = parseISO(transaction.date);
        const transactionDateNextMonth = addMonths(transactionDate, 1);

        return (
          (isBefore(transactionDate, lastDayInMonth) &&
            transaction.category === 'Left to Budget' &&
            transaction.ltb_next === false &&
            transaction.off_budget === false) ||
          (isBefore(transactionDate, endOfMonth(parsedCurrentMonth)) &&
            transaction.category === 'Left to Budget' &&
            transaction.ltb_next === false &&
            transaction.off_budget === false &&
            transaction.include_now === true) ||
          (isSameMonth(
            transactionDateNextMonth,
            endOfMonth(transactionDateNextMonth)
          ) &&
            transaction.category === 'Left to Budget' &&
            transaction.ltb_next === true &&
            transaction.off_budget === false &&
            transaction.include_now === true)
        );
      })) ||
    [];

  const totalSumOfLastMonthLtb = sumAllLtbNextFromLastMonth(
    prevMonth,
    transactions,
    false,
    parsedCurrentMonth
  );

  const totalSumOfThisMonthLtb = transactionsToSum.reduce(
    (prevTransaction, currTransaction) => {
      if (!currTransaction.ltb_next) {
        return currTransaction.type === 'outflow'
          ? sub(prevTransaction, currTransaction.amount)
          : add(prevTransaction, currTransaction.amount);
      }
    },
    0
  );

  // if global_ltb is TRUE, only return this months LTB income. Otherwise, the sum of last month and this month LTB income
  return add(totalSumOfThisMonthLtb, totalSumOfLastMonthLtb);
};

const sumAllLtbNextFromLastMonth = (
  lastMonth,
  transactions,
  globalLtb,
  currentMonth
) => {
  const transactionsToSum =
    (transactions[lastMonth] &&
      transactions[lastMonth].length &&
      transactions[lastMonth].filter((transaction) => {
        const transactionDate = parseISO(transaction.date);
        const transactionDateNextMonth = addMonths(transactionDate, 1);

        return (
          isSameMonth(currentMonth, endOfMonth(transactionDateNextMonth)) &&
          transaction.category === 'Left to Budget' &&
          transaction.ltb_next === true &&
          transaction.off_budget === false
        );
      })) ||
    [];

  return transactionsToSum.reduce((prevTransaction, currTransaction) => {
    return currTransaction.type === 'outflow'
      ? sub(prevTransaction, currTransaction.amount)
      : add(prevTransaction, currTransaction.amount);
  }, 0);
};

const sumAllBudgetedForMonth = (currentMonth, timeframeSubcategories) => {
  if (
    timeframeSubcategories[currentMonth] &&
    timeframeSubcategories[currentMonth].length
  ) {
    return timeframeSubcategories[currentMonth].reduce(
      (timeframeSubs, prevTimeframeSubcategory) => {
        return add(timeframeSubs, prevTimeframeSubcategory.budgeted);
      },
      0
    );
  }

  return 0;
};

const sumBudgetedAhead = (currentMonth, allLtbBreakdownsByMonth) => {
  let totalBudgetedAhead = 0;

  const parsedCurrentMonth = parse(currentMonth, 'MMyyyy', new Date());
  const isPresentMonth = isSameMonth(parsedCurrentMonth, new Date());

  // We only want to get budgetedAhead value if its the current month, or a future month. No past months
  // if (isPresentMonth || isAfter(parsedCurrentMonth, new Date())) {
  if (isPresentMonth || isAfter(parsedCurrentMonth, new Date())) {
    for (const month in allLtbBreakdownsByMonth) {
      const parsedMonth = parse(month, 'MMyyyy', new Date());
      const isAfterPresentMonth = isAfter(parsedMonth, parsedCurrentMonth);

      //optional check for properties from prototype chain
      if (
        allLtbBreakdownsByMonth.hasOwnProperty(month) &&
        isAfterPresentMonth
      ) {
        totalBudgetedAhead = add(
          totalBudgetedAhead,
          parseFloat(allLtbBreakdownsByMonth[month].budgetedForMonth)
        );
      }
    }
  }

  return totalBudgetedAhead;
};

export const sumTotalIncomeByMonthsFactory = () => {
  return createSelector(
    [
      getAllLtbBreakdownsByMonth,
      getAllTransactionsByMonth,
      getAllTimeframeSubcategoriesByMonth,
      getGlobalLtbSetting,
    ],
    (
      ltbBreakdownByMonth,
      allTransactionsByMonth,
      allTimeframeSubcategoriesByMonth,
      globalLtb
    ) => {
      for (const month in ltbBreakdownByMonth) {
        const lastMonthInMMYYYY = getLastMonthInMMYYY(month);
        const allLtbForMonth = sumAllLtbForMonth(
          month,
          allTransactionsByMonth,
          globalLtb
        );

        const fwdFromLastMonth =
          (ltbBreakdownByMonth[lastMonthInMMYYYY] &&
            ltbBreakdownByMonth[lastMonthInMMYYYY].leftToBudget) ||
          '0';

        const allBudgetedForMonth = sumAllBudgetedForMonth(
          month,
          allTimeframeSubcategoriesByMonth
        );

        const budgetedAhead = sumBudgetedAhead(month, ltbBreakdownByMonth);

        const totalRemaining = sub(allLtbForMonth, allBudgetedForMonth);

        const leftToBudget = add(totalRemaining, fwdFromLastMonth);

        ltbBreakdownByMonth[month].fwdFromLastMonth = fwdFromLastMonth;
        ltbBreakdownByMonth[month].incomeForMonth = allLtbForMonth;
        ltbBreakdownByMonth[month].budgetedForMonth = allBudgetedForMonth;
        ltbBreakdownByMonth[month].totalRemaining = totalRemaining;
        ltbBreakdownByMonth[month].budgetedAhead = budgetedAhead;

        ltbBreakdownByMonth[month].leftToBudget = leftToBudget;
        ltbBreakdownByMonth[month].globalLeftToBudget = sub(
          leftToBudget,
          budgetedAhead
        );
      }

      return { ...ltbBreakdownByMonth };
    }
  );
};

// ltbMonth.incomeForMonth = sumAllLtbForMonth(ltbMonth, allTransactions);
