import React, { Component } from 'react';
import ButtonLink from '../../common/button/button-link';
import './move-money.sass';

class MoveMoneyView extends Component {
  renderTitle = () => {
    const { isNegativeAmount } = this.props;

    return isNegativeAmount ? 'Cover overspend using:' : 'Move surplus to:';
  };

  render() {
    const { incrementRows, decrementRows } = this.props;

    return (
      <div className="move-money-popover">
        <div className="move-money-header">
          <span className="move-money-title">{this.renderTitle()}</span>
          <div className="move-money-row-add-delete">
            <ButtonLink title="Add category row" onClick={incrementRows}>
              <i className="fas fa-plus fa-fw fa-sm" />
            </ButtonLink>
            <ButtonLink title="Remove category row" onClick={decrementRows}>
              <i className="fas fa-minus fa-fw fa-sm" />
            </ButtonLink>
          </div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export default MoveMoneyView;
