import React, { memo } from 'react';
import BudgetHeader from './budget-header';
import BudgetTable from './budget-table';

const BudgetContainer = () => {
  return (
    <main className="inner-wrapper budget-wrapper">
      <BudgetHeader />
      <BudgetTable />
    </main>
  );
};

export default memo(BudgetContainer);
