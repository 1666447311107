import { createSelector } from 'reselect';
import moment from 'moment';
import { add, sub, sum } from '../utilities/budgeting-formulas';

const filterByValue = (array, string) => {
  return array.filter((o) =>
    Object.keys(o).some((k) =>
      String(o[k])
        .toLowerCase()
        .includes(string.toLowerCase())
    )
  );
};

const getSearchString = ({ searchString }) => searchString;

const getAllTransactionsByAccountId = (
  { accountsById },
  { currentView, match }
) => {
  const compareDates = (a, b) => {
    const dateA = moment.utc(a.date);
    const dateB = moment.utc(b.date);

    return currentView === 'current' ? dateB.diff(dateA) : dateA.diff(dateB);
  };

  // Grabs the account ID from the route params
  const accountIdFromRoute = match.params.id;
  const transactions =
    (accountsById &&
      accountsById[accountIdFromRoute] &&
      accountsById[accountIdFromRoute].transactions) ||
    [];

  const transactionsToRender = transactions.filter((transaction) => {
    const dateComparison =
      currentView === 'current'
        ? moment.utc(transaction.date).isSameOrBefore(moment().endOf('day'))
        : moment.utc(transaction.date).isAfter(moment().endOf('day'));
    return dateComparison;
  });

  return transactionsToRender.sort(compareDates);
};

export const getTransactionListFactory = () => {
  return createSelector(
    getAllTransactionsByAccountId,
    getSearchString,
    (allTransactions, searchString) => {
      return !searchString
        ? allTransactions
        : filterByValue(allTransactions, searchString);
    }
  );
};
