import React from 'react';
import { connect } from 'react-redux';
import BudgetTableBodyContainer from './budget-table-body-container';

const BudgetTableBodyConnector = ({
  activeMonths,
  accounts,
  budget,
  settings,
}) => {
  return (
    <BudgetTableBodyContainer
      activeMonths={activeMonths}
      accounts={accounts}
      categories={budget.categories}
      settings={settings}
    />
  );
};

const mapStateToProps = ({ activeMonths, accounts, budget, settings }) => ({
  activeMonths,
  accounts,
  budget,
  settings,
});

export default connect(
  mapStateToProps,
  null
)(BudgetTableBodyConnector);
