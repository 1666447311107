export const ACCOUNT_ADDED = 'ACCOUNT_ADDED';
export const ACCOUNT_LIST = 'ACCOUNT_LIST';
export const ACCOUNT_SAVED = 'ACCOUNT_SAVED';
export const ACCOUNT_ORDER_CHANGE = 'ACCOUNT_ORDER_CHANGE';
export const ACCOUNT_RECEIVED = 'ACCOUNT_RECEIVED';
export const ACCOUNT_UPDATE_NOTES = 'ACCOUNT_UPDATE_NOTES';
export const ACCOUNT_FETCH_SUCCESS = 'ACCOUNT_FETCH_SUCCESS';
export const ACCOUNTS_SET_BY_ID = 'ACCOUNTS_SET_BY_ID';
export const ACCOUNTS_BY_ID_ADD_ACCOUNT = 'ACCOUNTS_BY_ID_ADD_ACCOUNT';
export const ACCOUNTS_BY_ID_UPDATE_ACCOUNT = 'ACCOUNTS_BY_ID_UPDATE_ACCOUNT';
export const ACCOUNTS_BY_ID_SET_TRANSACTIONS =
  'ACCOUNTS_BY_ID_SET_TRANSACTIONS';
export const ACCOUNTS_BY_ID_SAVE_TRANSACTION =
  'ACCOUNTS_BY_ID_SAVE_TRANSACTION';
export const ACCOUNTS_BY_ID_UPDATE_TRANSACTION =
  'ACCOUNTS_BY_ID_UPDATE_TRANSACTION';
export const ACCOUNTS_BY_ID_REMOVE_TRANSACTIONS =
  'ACCOUNTS_BY_ID_REMOVE_TRANSACTIONS';
export const ACCOUNTS_BY_ID_ADD_TRANSACTION = 'ACCOUNTS_BY_ID_ADD_TRANSACTION';
export const ACCOUNTS_BY_ID_CANCEL_TRANSACTION =
  'ACCOUNTS_BY_ID_CANCEL_TRANSACTION';
export const ACCOUNT_LIST_BY_IDS_UPDATE = 'ACCOUNT_LIST_BY_IDS_UPDATE';
export const BUDGET_IS_LOADING = 'BUDGET_IS_LOADING';
export const BUDGET_FETCH_SUCCESS = 'BUDGET_FETCH_SUCCESS';
export const BUDGET_LIST_RECEIVED = 'BUDGET_LIST_RECEIVED';
export const BUDGET_TABLE_SET = 'BUDGET_TABLE_SET';
export const BUDGET_RENAMED = 'BUDGET_RENAMED';
export const BUDGET_DEFAULT_RECEIVED = 'BUDGET_DEFAULT_RECEIVED';
export const BUDGET_UPDATE_ACCOUNTS_ORDER = 'BUDGET_UPDATE_ACCOUNTS_ORDER';
export const BUDGET_UPDATE_CATEGORIES_ORDER = 'BUDGET_UPDATE_CATEGORIES_ORDER';
export const BUDGET_UPDATE_SUBCATEGORIES_ORDER =
  'BUDGET_UPDATE_SUBCATEGORIES_ORDER';
export const BUDGET_UPDATE_CREDIT_CARD_ORDER =
  'BUDGET_UPDATE_CREDIT_CARD_ORDER';
export const BUDGET_ADD_NEW_CC_TO_ORDER = 'BUDGET_ADD_NEW_CC_TO_ORDER';
export const BUDGET_LIST_FETCH_SUCCESS = 'BUDGET_LIST_FETCH_SUCCESS';
export const CATEGORY_GROUP_UPDATE_SUBCAT_ORDER =
  'CATEGORY_GROUP_UPDATE_SUBCAT_ORDER';
export const CATEGORY_GROUP_MULTI_UPDATE_SUBCAT_ORDER =
  'CATEGORY_GROUP_MULTI_UPDATE_SUBCAT_ORDER';
export const CATEGORY_GROUP_ADD_TEMP_CATEGORY_TO_ORDER =
  'CATEGORY_GROUP_ADD_TEMP_CATEGORY_TO_ORDER';
export const CATEGORY_UPDATE_PARENT_ID = 'CATEGORY_UPDATE_PARENT_ID';
export const CATEGORY_AND_ACCOUNT_LIST = 'CATEGORY_AND_ACCOUNT_LIST';
export const FORM_IS_SUBMITTING = 'FORM_IS_SUBMITTING';
export const SECTIONS_FETCHING = 'SECTIONS_FETCHING';
export const SECTIONS_RECEIVED = 'SECTIONS_RECEIVED';
export const SECTION_SAVED = 'SECTIONS_SAVED';
export const SECTION_ADDED = 'SECTION_ADDED';
export const SECTION_ORDER_CHANGE = 'SECTION_ORDER_CHANGE';
export const CATEGORY_ADDED = 'CATEGORY_ADDED';
export const CATEGORIES_RECEIVED = 'CATEGORIES_RECEIVED';
export const CATEGORY_GROUP_SAVED = 'CATEGORY_GROUP_SAVED';
export const CATEGORY_SAVED = 'CATEGORY_SAVED';
export const CATEGORY_UPDATED = 'CATEGORY_UPDATED';
export const CATEGORY_ORDER_CHANGE = 'CATEGORY_ORDER_CHANGE';
export const CREDIT_CATEGORY_ORDER_CHANGE = 'CREDIT_CATEGORY_ORDER_CHANGE';
export const MONTH_PREVIOUS = 'MONTH_PREVIOUS';
export const MONTH_NEXT = 'MONTH_NEXT';
export const SERVICE_FETCH_SUCCESS = 'SERVICE_FETCH_SUCCESS';
export const SETTINGS_RECEIVED = 'SETTINGS_RECEIVED';
export const SUBCATEGORIES_RECEIVED = 'SUBCATEGORIES_RECEIVED';
export const SUBCATEGORY_ADDED = 'SUBCATEGORY_ADDED';
export const SUBCATEGORY_SAVED = 'SUBCATEGORY_SAVED';
export const SUBCATEGORY_ORDER_CHANGE = 'SUBCATEGORY_ORDER_CHANGE';
export const SUBSCRIPTION_INFO_FETCH_SUCCESS =
  'SUBSCRIPTION_INFO_FETCH_SUCCESS';
export const SET_SYNC_STATUS = 'SET_SYNC_STATUS';
export const TRANSACTIONS_FETCHING = 'TRANSACTIONS_FETCHING';
export const TRANSACTIONS_RECEIVED = 'TRANSACTIONS_RECEIVED';
export const TRANSACTION_SAVED = 'TRANSACTION_SAVED';
export const TRANSACTION_DELETED = 'TRANSACTION_DELETED';
export const TRANSACTION_ADDED = 'TRANSACTION_ADDED';
export const TRANSACTION_REMOVED = 'TRANSACTION_REMOVED';
export const TRANSACTIONS_LOADING = 'TRANSACTIONS_LOADING';
export const TIMEFRAME_SUBCATEGORIES_RECEIVED =
  'TIMEFRAME_SUBCATEGORIES_RECEIVED';
export const TIMEFRAME_SUBCATEGORY_SAVED = 'TIMEFRAME_SUBCATEGORY_SAVED';
export const TIMEFRAMES_RECEIVED = 'TIMEFRAMES_RECEIVED';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_TIMEFRAME_SUBCATEGORY = 'UPDATE_TIMEFRAME_SUBCATEGORY';
export const UPDATE_ACCOUNT_SCROLL_POSITION = 'UPDATE_ACCOUNT_SCROLL_POSITION';
export const PAYEE_FETCH_SUCCESS = 'PAYEE_FETCH_SUCCESS';

// NEW ACTIONS FOR REWRITE HERE
export const BUDGET_ADD_CATEGORY_ROW = '@@BUDGET/ADD_CATEGORY_ROW';
export const BUDGET_ADD_CATEGORY_GROUP_ROW = '@@BUDGET/ADD_CATEGORY_GROUP_ROW';
export const BUDGET_DELETE_CATEGORY_ROW = '@@BUDGET/DELETE_CATEGORY_ROW';
export const BUDGET_DELETE_CATEGORY_GROUP_ROW =
  '@@BUDGET/DELETE_CATEGORY_GROUP_ROW';
export const SET_INITIAL_LTB_BREAKDOWN_BY_MONTH =
  '@@BUDGET/SET_INITIAL_LTB_BREAKDOWN_BY_MONTH';
export const REFRESH_LTB_BREAKDOWN_BY_MONTH =
  '@@BUDGET/REFRESH_LTB_BREAKDOWN_BY_MONTH';
export const SET_INITIAL_TIMEFRAME_SUBCATEGORIES_BY_MONTH =
  '@@BUDGET/SET_INITIAL_TIMEFRAME_SUBCATEGORIES_BY_MONTH';
export const TIMEFRAME_SUBCATEGORY_UPDATE_BY_MONTH =
  '@@BUDGET/TIMEFRAME_SUBCATEGORY_UPDATE_BY_MONTH';
export const TIMEFRAME_SUBCATEGORY_UPDATE_BY_MONTH_WITH_ID =
  '@@BUDGET/TIMEFRAME_SUBCATEGORY_UPDATE_BY_MONTH_WITH_ID';

export const SET_TRANSACTIONS_BY_MONTH = '@@ACCOUNT/SET_TRANSACTIONS_BY_MONTH';
export const SET_TRANSACTIONS_BY_MONTH_AND_CATEGORY =
  '@@ACCOUNT/SET_TRANSACTIONS_BY_MONTH_AND_CATEGORY';
export const TOGGLE_EDIT_TRANSACTION = '@@ACCOUNT/TOGGLE_EDIT_TRANSACTION';

export const CLEAR_SEARCH_STRING = 'CLEAR_SEARCH_STRING';
export const UPDATE_SEARCH_STRING = 'UPDATE_SEARCH_STRING';

// global ltb toggle branch
