import { PAYEE_FETCH_SUCCESS } from '../action-list';

export default function(state = [], action) {
  switch (action.type) {
    case PAYEE_FETCH_SUCCESS:
      return [...action.payload];

    default:
      return state;
  }
}
