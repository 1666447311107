import React, { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import BudgetTableCategoryGroupView from './budget-table-category-group-view';
import BudgetTableCategory from '../budget-table-category';
import { sortByOrderOfIds } from '../../../../utilities/drag-and-drop-reorder';
import { getMonthsToDisplay } from '../../../../utilities/helpers';

function BudgetTableCategoryGroupContainer({
  index,
  activeMonths,
  addCategory,
  categories,
  categoryGroupDetails,
  deleteCategoryGroupRow,
  saveCategoryGroup,
  settings,
  updateSubcategoryOrderInGroupLocally,
  updateSubcategoryOrderInGroup,
}) {
  const [categoriesExpanded, setCategoriesExpanded] = useState(true);

  const handleAddCategory = () => {
    const { id: categoryGroupId, budget_id: budgetId } = categoryGroupDetails;
    addCategory(categoryGroupId, budgetId);
  };

  const handleDeleteCategoryGroupRow = () => {
    const { budget_id: budgetId, id: categoryGroupId } = categoryGroupDetails;
    deleteCategoryGroupRow(budgetId, categoryGroupId);
  };

  const handleSaveCategoryGroup = (savedValue) => {
    const { budget_id: budgetId } = categoryGroupDetails;

    const updatedCategoryGroup = {
      ...categoryGroupDetails,
      ...savedValue,
    };

    saveCategoryGroup(updatedCategoryGroup, budgetId);
  };

  const handleCollapseCategories = () =>
    setCategoriesExpanded(!categoriesExpanded);

  const renderCategories = (categories, monthsDisplayed) => {
    const {
      id: groupId,
      budget_id: budgetId,
      subcategories_order,
    } = categoryGroupDetails;

    const orderedCategories =
      subcategories_order && subcategories_order.length
        ? sortByOrderOfIds(categories, subcategories_order)
        : categories;

    if (!subcategories_order) {
      updateSubcategoryOrderInGroupLocally(categoryGroupDetails, categories);
      updateSubcategoryOrderInGroup(
        budgetId,
        groupId,
        categories,
        subcategories_order
      );
    }

    return orderedCategories.map((category, index) => {
      return (
        <BudgetTableCategory
          key={category.id}
          index={index}
          categoryDetails={category}
          monthsDisplayed={monthsDisplayed}
          categoriesExpanded
          settings={settings}
        />
      );
    });
  };

  const monthsDisplayed = getMonthsToDisplay(activeMonths, settings.month_view);

  return (
    <BudgetTableCategoryGroupView
      settings={settings}
      categoriesExpanded={categoriesExpanded}
      addNewCategory={handleAddCategory}
      categoryGroupDetails={categoryGroupDetails}
      index={index}
      handleSaveField={handleSaveCategoryGroup}
      monthsDisplayed={monthsDisplayed}
      collapseCategories={handleCollapseCategories}
      onDeleteCategoryGroupRow={handleDeleteCategoryGroupRow}
    >
      <Droppable droppableId={categoryGroupDetails.id} type="category">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ minHeight: '5px', backgroundColor: 'rgba(0,0,0,0)' }}
          >
            {!categories.length && (
              <span style={{ padding: '4px 24px', display: 'inline-block' }}>
                No subcategories added
              </span>
            )}
            {categoriesExpanded &&
              renderCategories(categories, monthsDisplayed)}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </BudgetTableCategoryGroupView>
  );
}

export default BudgetTableCategoryGroupContainer;
