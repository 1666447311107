import React, { Fragment, Component } from 'react';
import ManagePayeesView from './manage-payees-view';

import './manage-payees.css';

class ManagePayeesContainer extends Component {
  state = {
    selectedPayees: [],
  };

  handleUpdateSelectedPayees = (selectedPayees) => {
    this.setState({ selectedPayees });
  };

  handleDeletePayees = () => {
    const { budgetDetails } = this.props;
    const { selectedPayees } = this.state;

    const payeeListofIds = selectedPayees.map((p) => p.id);

    this.props.deleteBulkPayees(budgetDetails.id, payeeListofIds);
    this.setState({ selectedPayees: [] });
    this.props.onClose();
  };

  render() {
    const { onClose, payees } = this.props;
    const { selectedPayees } = this.state;

    return (
      <ManagePayeesView
        onClose={onClose}
        deletePayees={this.handleDeletePayees}
        payees={payees}
        selectedPayees={selectedPayees}
        updateSelectedPayees={this.handleUpdateSelectedPayees}
      />
    );
  }
}

export default ManagePayeesContainer;
