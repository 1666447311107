import { createSelector } from 'reselect';
import moment from 'moment';
import parse from 'date-fns/parse';
import isSameMonth from 'date-fns/isSameMonth';
import isBefore from 'date-fns/isBefore';
import endOfDay from 'date-fns/endOfDay';
import parseISO from 'date-fns/parseISO';
import {
  sum,
  sub,
  add,
  sumTimeframeSubcategories,
} from '../utilities/budgeting-formulas';

const getCurrentTimeframe = (state, { monthToDisplay }) => monthToDisplay;

const getAllTimeframes = ({ timeframes }) => timeframes;

const getGlobalLtbSetting = ({ settings }) => {
  // Grabs the account ID from the route params
  return settings.global_ltb;
};

const getCategoriesByGroupId = ({ budget }, { categoryGroupDetails }) => {
  if (budget.subcategories && budget.subcategories.length) {
    return budget.subcategories.filter((category) => {
      return category.section_id === categoryGroupDetails.id;
    });
  }

  return [];
};

const getAllTimeframeSubcategoriesByCategory = (
  { budget },
  { categoryDetails }
) => {
  if (budget.timeframe_subcategories && budget.timeframe_subcategories.length) {
    return budget.timeframe_subcategories.filter((timeframe_subcategory) => {
      return timeframe_subcategory.category_id === categoryDetails.id;
    });
  }

  return [];
};

const getTimeframeSubcategoryByMonth = (
  { budget },
  { categoryDetails, monthToDisplay }
) => {
  if (budget.timeframe_subcategories && budget.timeframe_subcategories.length) {
    const prevMonth = moment(monthToDisplay, 'MMYYYY')
      .subtract(1, 'month')
      .format('MMYYYY');

    const cherryPickedTimeframeSubcategory = budget.timeframe_subcategories.filter(
      (timeframe_subcategory) => {
        return (
          timeframe_subcategory.category_id === categoryDetails.id &&
          timeframe_subcategory.timeframe === monthToDisplay
        );
      }
    );

    const cherryPickedLastMonthTFSubcategory = budget.timeframe_subcategories.filter(
      (timeframe_subcategory) => {
        return (
          timeframe_subcategory.category_id === categoryDetails.id &&
          timeframe_subcategory.timeframe === prevMonth
        );
      }
    );

    return {
      ...cherryPickedTimeframeSubcategory[0],
      lastMonthBudgeted:
        cherryPickedLastMonthTFSubcategory[0] &&
        cherryPickedLastMonthTFSubcategory[0].budgeted,
    };
  }

  return [];
};

const isPresentMonth = (date) => {
  return isSameMonth(date, new Date());
};

const getTransactionsInMonthAndCategory = (
  { transactionsByMonthCategory },
  { categoryDetails, monthToDisplay }
) => {
  const parsedMonth = parse(monthToDisplay, 'MMyyyy', new Date());
  // If its the current realtime month, only return up until today
  const isCurrentMonth = isPresentMonth(parsedMonth);

  // here is where we want to also include when a transaction should impact the budget right away
  if (
    transactionsByMonthCategory[monthToDisplay] &&
    transactionsByMonthCategory[monthToDisplay][categoryDetails.id]
  ) {
    if (isCurrentMonth) {
      const endOfToday = endOfDay(new Date());
      return transactionsByMonthCategory[monthToDisplay][
        categoryDetails.id
      ].filter((transaction) => {
        const transactionDate = parseISO(transaction.date);

        return isBefore(transactionDate, endOfToday);
      });
    } else {
      return (
        transactionsByMonthCategory[monthToDisplay][categoryDetails.id] || []
      );
    }
  }

  return [];
};

const transactionAmountsWithChildren = ({ amount, child_transactions }) => {
  return child_transactions && child_transactions.length
    ? sum(child_transactions, ({ amount }) => amount)
    : amount;
};

const sumTransactionActivityInMonthCategory = (transactions, globalLtb) => {
  let totalActivity;

  for (let i = 0; i < transactions.length; i++) {
    if (
      moment(transactions[i].date).isSameOrBefore(
        moment()
          .local()
          .endOf('day')
      ) ||
      (!globalLtb && transactions[i].include_now)
    )
      if (transactions[i].type === 'outflow') {
        totalActivity = sub(
          totalActivity,
          transactionAmountsWithChildren(transactions[i])
        );
      } else {
        totalActivity = add(
          totalActivity,
          transactionAmountsWithChildren(transactions[i])
        );
      }
  }

  return totalActivity;
};

export const getCategoriesByGroupIdFactory = () => {
  return createSelector(
    getCategoriesByGroupId,
    (categories) => {
      return categories;
    }
  );
};

export const getCategoryDetailsByMonthFactory = () => {
  return createSelector(
    getTimeframeSubcategoryByMonth,
    (timeframeSubcategory) => {
      return timeframeSubcategory;
    }
  );
};

export const getCategoryActivityByMonthFactory = () => {
  return createSelector(
    getTransactionsInMonthAndCategory,
    getGlobalLtbSetting,
    (transactionsByMonthCategory, globalLtb) => {
      return {
        transactionsByMonthCategory,
        activity: sumTransactionActivityInMonthCategory(
          transactionsByMonthCategory,
          globalLtb
        ),
      };
    }
  );
};

export const getCategoryTotalRemainingByMonthFactory = () => {
  return createSelector(
    [
      getCurrentTimeframe,
      getAllTimeframeSubcategoriesByCategory,
      getAllTimeframes,
    ],
    (currentTimeframe, timeframeSubcategoriesByCategory, timeframes) => {
      return sumTimeframeSubcategories(
        currentTimeframe,
        timeframes,
        timeframeSubcategoriesByCategory,
        (subCat, month) => {
          return (
            subCat.timeframe === month.format('MMYYYY') &&
            moment(subCat.timeframe, 'MMYYYY').isSameOrBefore(
              moment(currentTimeframe, 'MMYYYY')
            )
          );
        }
      );
    }
  );
};
