import moment from 'moment';
import parse from 'date-fns/parse';
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';
import format from 'date-fns/format';

export const isDeepEqual = (a: Object, b: Object) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

export const isObjectEmpty = (obj: Object) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const isMobile = () => {
  return window.innerWidth < 1024;
};

export const formatTimeframe = (timeframe: moment.Moment) => {
  const formattedTimeframe = timeframe.format('MMYYYY');

  return formattedTimeframe;
};

export const getMonthsToDisplay = (activeMonths: any, month_view: string) => {
  if (month_view === 'single' || isMobile()) {
    return [formatTimeframe(activeMonths.currentMonth)];
  } else {
    return [
      formatTimeframe(activeMonths.prevMonth),
      formatTimeframe(activeMonths.currentMonth),
      formatTimeframe(activeMonths.nextMonth),
    ];
  }
};

export const getLastMonthInMMYYY = (date: string) => {
  const parsedCurrentMonth = parse(date, 'MMyyyy', new Date());
  const lastMonth = format(subMonths(parsedCurrentMonth, 1), 'MMyyyy');

  return lastMonth;
};

export const thisMonthAndNextMonthInMMMM = (date: string) => {
  const currentMonthInMMMM = moment(date).format('MMMM');
  const nextMonthInMMMM = moment(date)
    .add(1, 'month')
    .format('MMMM');

  return {
    currentMonthInMMMM,
    nextMonthInMMMM,
  };
};

export const thisMonthAndNextMonthInMMM = (date: string) => {
  const currentMonthInMMM = moment(date).format('MMM');
  const nextMonthInMMM = moment(date)
    .add(1, 'month')
    .format('MMM');

  return {
    currentMonthInMMM,
    nextMonthInMMM,
  };
};
