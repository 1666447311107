import React, { Component } from 'react';
import {
  accountTypesSpending,
  accountTypesDebt,
} from '../../../../utilities/account-types';
import { sortByOrderOfIds } from '../../../../utilities/drag-and-drop-reorder';
import SidebarAccountsSectionContainer from './sidebar-accounts-section-container';
import budget from '../../../../reducers/budget';

class SidebarAccountsContainer extends Component {
  state = {
    accountsOrder: this.props.budgetDetails.accounts_order,
  };

  onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;
    const { accountsOrder } = this.state;

    if (!destination) {
      return;
    }

    // see if location of draggable changed
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newAccountIdsOrder = Array.from(accountsOrder);

    //order new index ids from old index to new index
    // removes from old location
    newAccountIdsOrder.splice(source.index, 1);
    // adds at new destination index, the draggable item's itd
    newAccountIdsOrder.splice(destination.index, 0, draggableId);

    // replaces state with new column and new column array ids
    const newState = {
      ...this.state,
      accountsOrder: newAccountIdsOrder,
    };

    this.setState(newState);
  };

  deleteAccount = (accountId, reorderedList) => {
    this.props.deleteAccount(
      this.props.budgetDetails.id,
      accountId,
      reorderedList
    );
  };

  sortedAccounts = (filterType) => {
    const { accounts, budgetDetails } = this.props;
    const { accountsOrder } = this.state;

    // Checks if the account type matches the types in the filter array
    return sortByOrderOfIds(
      accounts &&
        accounts.filter((account) => {
          return filterType.includes(account.type);
        }),
      accountsOrder
    );
  };

  render() {
    const {
      accounts,
      accountsById,
      budgetDetails,
      clearSearchString,
      searchString,
      settings,
      updateAccount,
    } = this.props;

    return (
      <aside
        className="sidebar-accounts-list"
        style={{ paddingBottom: '20px' }}
      >
        {accounts && accounts.length <= 0 && (
          <span className="empty-state-msg-accounts">
            No budget accounts created yet.
          </span>
        )}
        <SidebarAccountsSectionContainer
          sectionTitle="Spending & Savings"
          slug="spending-savings"
          accounts={this.sortedAccounts(accountTypesSpending)}
          accountsById={accountsById}
          onDeleteAccount={this.deleteAccount}
          removeCCAccountFromOrder={this.props.removeCCAccountFromOrder}
          updateAccount={updateAccount}
          budgetDetails={budgetDetails}
          searchString={searchString}
          clearSearchString={clearSearchString}
          settings={settings}
        />
        <SidebarAccountsSectionContainer
          sectionTitle="Credit Cards & Loans"
          slug="credit-cards-loans"
          accounts={this.sortedAccounts(accountTypesDebt)}
          accountsById={accountsById}
          onDeleteAccount={this.deleteAccount}
          removeCCAccountFromOrder={this.props.removeCCAccountFromOrder}
          updateAccount={updateAccount}
          budgetDetails={budgetDetails}
          searchString={searchString}
          clearSearchString={clearSearchString}
          settings={settings}
        />
      </aside>
    );
  }
}

export default SidebarAccountsContainer;
