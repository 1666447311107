import React, { ReactNode } from 'react';
import {
  Container,
  Text,
  Row,
  Button,
  Radio,
  Spacer,
  Card,
} from '@nextui-org/react';
import { PageHeader } from './PageHeader';

interface PageLayoutProps {
  children: ReactNode;
}

enum Plans {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  LIFETIME = 'LIFETIME',
}

function PageLayout({ children }: PageLayoutProps) {
  // const [planSelected, setPlanSelected] = useState(Plans.MONTHLY);
  const headingStyles = { color: '#626e7a' };
  return (
    <Container fluid css={{ border: '1px solid #ccc' }}>
      <PageHeader
        title="Plan & Billing"
        subtitle="
      Update your billing information. Please note that updating your
          location could affect your tax rates.
      "
      />

      <Spacer y={2} />

      {children}
    </Container>
  );
}

export { PageLayout };
