import React, { ReactNode } from 'react';
import cn from 'classnames';
import { Box } from '../ui/box';

interface LoggedOutFormContainerProps {
  children: ReactNode;
}

function LoggedOutFormContainer({ children }: LoggedOutFormContainerProps) {
  return (
    <div
      className={cn(
        'absolute',
        'top-[50%]',
        'left-[50%]',
        '-translate-x-2/4',
        '-translate-y-2/4',
        'w-full'
      )}
    >
      <div
        className={cn(
          'bg-white',
          'w-11/12',
          'mx-auto',
          'sm:w-[380px]',
          'text-gray-400',
          'py-8',
          'px-8',
          'shadow',
          'rounded-md',
          'sm:rounded-lg',
          'sm:px-10'
        )}
      >
        {children}
      </div>
    </div>
  );
}

export { LoggedOutFormContainer };
