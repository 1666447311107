import React, { PureComponent } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import ModalBase from '../../../common/modal/modal-base';
import EditDebtNotesForm from '../../../forms/edit-debt-notes-form';
import BudgetTableCCCategoryView from './budget-table-cc-category-view';
import { dueDateOptions } from '../../../../utilities/due-date-options';

class BudgetTableCCCategoryContainer extends PureComponent {
  renderDueDate = (dueDate) => {
    if (!dueDate) {
      return null;
    }

    // Checks if the due date exists in the available options and grabs that index
    const dueDateIndex = dueDateOptions.findIndex(
      (obj) => obj.value === dueDate
    );

    return dueDateOptions[dueDateIndex];
  };

  saveDebAccttNotes = (accountId, notes, budgetId) => {
    this.props.saveDebAccttNotes(accountId, notes, budgetId);
  };

  handleEditNotes = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ModalBase onClose={onClose} title={'Edit Notes'}>
            <EditDebtNotesForm
              accountDetails={this.props.accountDetails}
              onSave={this.saveDebAccttNotes}
              onClose={onClose}
            />
          </ModalBase>
        );
      },
    });
  };

  render() {
    const { accountDetails, index, monthsDisplayed, settings } = this.props;
    const dueDateToRender = this.renderDueDate(accountDetails.due_date);

    return (
      <BudgetTableCCCategoryView
        dueDate={dueDateToRender}
        index={index}
        settings={settings}
        accountDetails={accountDetails}
        onEditNotes={this.handleEditNotes}
        monthsDisplayed={monthsDisplayed}
      />
    );
  }
}

export default BudgetTableCCCategoryContainer;
