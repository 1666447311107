import axios from 'axios';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import mixpanel from 'mixpanel-browser';
import {
  ACCOUNT_RECEIVED,
  ACCOUNT_FETCH_SUCCESS,
  ACCOUNTS_SET_BY_ID,
  ACCOUNTS_BY_ID_ADD_ACCOUNT,
  ACCOUNTS_BY_ID_UPDATE_ACCOUNT,
  ACCOUNTS_BY_ID_SET_TRANSACTIONS,
  ACCOUNTS_BY_ID_ADD_TRANSACTION,
  ACCOUNTS_BY_ID_SAVE_TRANSACTION,
  ACCOUNTS_BY_ID_UPDATE_TRANSACTION,
  ACCOUNTS_BY_ID_REMOVE_TRANSACTIONS,
  ACCOUNTS_BY_ID_CANCEL_TRANSACTION,
  BUDGET_ADD_NEW_CC_TO_ORDER,
  SET_SYNC_STATUS,
  SET_TRANSACTIONS_BY_MONTH,
  SET_TRANSACTIONS_BY_MONTH_AND_CATEGORY,
  TOGGLE_EDIT_TRANSACTION,
  TRANSACTIONS_RECEIVED,
  REFRESH_LTB_BREAKDOWN_BY_MONTH,
  TRANSACTION_SAVED,
  TRANSACTION_DELETED,
  TRANSACTIONS_LOADING,
  UPDATE_ACCOUNT_SCROLL_POSITION,
  UPDATE_SEARCH_STRING,
  CLEAR_SEARCH_STRING,
} from '../action-list';
import { headers } from '../api';
import { ELIXIR_API } from '../constants';
import { add } from '../utilities/budgeting-formulas';
import { sortByOrderOfIds } from '../utilities/drag-and-drop-reorder';
import {
  fetchCategories,
  fetchTimeframeSubcategories,
  getBudgetById,
  refreshBudgetById,
} from './budget';
import { fetchPayees } from './payee';

export const updateSearchString = (searchString) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH_STRING,
      searchString,
    });
  };
};

export const clearSearchString = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SEARCH_STRING,
      searchString: null,
    });
  };
};

export function formIsSubmitting(bool) {
  return {
    type: 'FORM_IS_SUBMITTING',
    isLoading: bool,
  };
}

export function accountsFetchSuccess(payload) {
  return {
    type: ACCOUNT_FETCH_SUCCESS,
    payload,
  };
}

export function setAccountsById(payload) {
  const accountsListById = payload.reduce((accumulator, current) => {
    const currentAccountWithEmptyTransactions = {
      transactions: current.transactions || [],
      ...current,
    };

    accumulator[current.id] = currentAccountWithEmptyTransactions;
    return accumulator;
  }, {});

  return {
    type: ACCOUNTS_SET_BY_ID,
    payload: accountsListById,
  };
}

export const fetchAccounts = (budgetId, accountsOrder) => {
  return (dispatch) => {
    axios
      .get(`${ELIXIR_API}/budgets/${budgetId}/accounts`, { headers: headers() })
      .then((res) => {
        const accountsByOrder = sortByOrderOfIds(res.data.data, accountsOrder);

        dispatch(setAccountsById(res.data.data));
        dispatch(accountsFetchSuccess(accountsByOrder));
        dispatch(fetchAllTransactions(budgetId));
      });
  };
};

export const fetchAllAccountsAndTransactions = (budgetId) => {
  return (dispatch) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
      isLoading: true,
    });

    axios
      .get(`${ELIXIR_API}/budgets/${budgetId}/accounts`, { headers: headers() })
      .then((res) => {
        const accountsByOrder = res.data.data;

        dispatch(setAccountsById(res.data.data));
        dispatch(accountsFetchSuccess(accountsByOrder));
        return new Promise((resolve) => setTimeout(resolve, 850));
      })
      .then(() => {
        dispatch(fetchAllTransactions(budgetId));
      });
  };
};

export const fetchAccount = (budgetId, accountId) => {
  const currentDate = moment().utc().endOf('day').toISOString();

  return (dispatch) => {
    dispatch({
      type: TRANSACTIONS_LOADING,
      isLoading: true,
    });

    return axios
      .get(
        `${ELIXIR_API}/budgets/${budgetId}/accounts/${accountId}?current_date=${currentDate}`,
        {
          headers: headers(),
        }
      )
      .then((res) => {
        dispatch({
          type: ACCOUNT_RECEIVED,
          payload: res.data.data,
        });

        dispatch({
          type: ACCOUNTS_BY_ID_UPDATE_ACCOUNT,
          payload: res.data.data,
        });

        dispatch({
          type: TRANSACTIONS_LOADING,
          isLoading: false,
        });
      });
  };
};

export const deleteAccount = (budget_id, account_id) => {
  // TODO: remove deleted account id from cc_order list as it doesnt do optimistic UI update on deletion
  return (dispatch) => {
    return axios({
      method: 'delete',
      url: `${ELIXIR_API}/budgets/${budget_id}/accounts/${account_id}`,
      headers: headers(),
    }).then(() => {
      dispatch(fetchAllAccountsAndTransactions(budget_id));
      // saveAccountReorder(accountsOrder, budget_id);
      dispatch(fetchTimeframeSubcategories(budget_id));
    });
  };
};

export const updateAccountBalance = (current_bal, account_id, budget_id) => {
  return (dispatch) => {
    // returns balance
    return axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budget_id}/accounts/${account_id}`,
      headers: headers(),
      data: {
        account: {
          current_bal,
        },
      },
    }).then(() => {
      return dispatch(fetchAccounts(budget_id));
    });
  };
};

export const updateAccount = (
  account_id,
  budget_id,
  accountsOrder,
  { name, type, apr, min_payment }
) => {
  return (dispatch) => {
    return axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budget_id}/accounts/${account_id}`,
      headers: headers(),
      data: {
        account: {
          name,
          type,
          apr,
          min_payment,
        },
      },
    }).then(() => {
      return dispatch(fetchAccounts(budget_id, accountsOrder));
    });
  };
};

export const syncAccount = (accountId, budgetId) => {
  return (dispatch) => {
    return axios({
      method: 'get',
      url: `${ELIXIR_API}/budgets/${budgetId}/accounts/${accountId}/sync`,
      headers: headers(),
    }).then(() => {
      dispatch(checkSyncStatus(accountId, budgetId));
    });
  };
};

export const checkSyncStatus = (accountId, budgetId) => {
  return (dispatch) => {
    return axios({
      method: 'get',
      url: `${ELIXIR_API}/budgets/${budgetId}/accounts/${accountId}/sync_status`,
      headers: headers(),
    }).then((res) => {
      dispatch({
        type: SET_SYNC_STATUS,
        payload: res.data,
      });

      if (res.data === 'syncing') {
        setTimeout(() => {
          dispatch(checkSyncStatus(accountId, budgetId));
          dispatch(fetchAllTransactions(budgetId));
          dispatch(fetchAccount(budgetId, accountId));
          dispatch(getBudgetById(budgetId));
        }, 600);
      }
    });
  };
};

export const saveAccount = (
  { name, current_bal, type, apr, min_payment, mx_account_id, off_budget },
  budget
) => {
  return (dispatch) => {
    dispatch(formIsSubmitting(true));

    const checkedBalance = current_bal === '' ? 0 : current_bal;
    const totalUnallocated = budget.unassigned;
    const newUnallocatedAmount = add(totalUnallocated, checkedBalance);
    mixpanel.track('Add new account', {
      Type: type,
      'Off budget': off_budget,
    });

    return axios({
      method: 'post',
      url: `${ELIXIR_API}/budgets/${budget.id}/accounts`,
      headers: headers(),
      data: {
        current_bal: checkedBalance,
        budget_id: budget.id,
        min_payment,
        apr,
        type,
        name,
        mx_account_id,
        off_budget,
      },
    })
      .then((res) => {
        dispatch(formIsSubmitting(false));

        return res;
      })
      .then((response) => {
        const { id, current_bal } = response.data.data;
        let initialTransaction;
        if (
          type === 'creditcard' ||
          type === 'debtother' ||
          type === 'mortgage' ||
          type === 'offbudget_liability'
        ) {
          initialTransaction = {
            id: uuid(),
            account_id: id,
            payee: 'Initial Balance',
            date: moment().utc().toISOString(true),
            category: 'No Category Needed',
            approved: true,
            amount: current_bal,
            type: 'outflow',
            off_budget,
          };
        } else {
          initialTransaction = {
            id: uuid(),
            account_id: id,
            payee: 'Initial Balance',
            date: moment().utc().toISOString(true),
            category: 'Left to Budget',
            approved: true,
            amount: current_bal,
            type: 'inflow',
            off_budget,
          };
        }

        dispatch({
          type: ACCOUNTS_BY_ID_ADD_ACCOUNT,
          payload: response.data.data,
        });

        dispatch({
          type: BUDGET_ADD_NEW_CC_TO_ORDER,
          ccId: id,
        });

        dispatch(saveTransaction(initialTransaction, budget.id, id));
        dispatch(
          fetchAllAccountsAndTransactions(budget.id, budget.accounts_order)
        );

        // dispatch(updateBudgetUnallocatedAmount(budget, newUnallocatedAmount));

        // TODO: navigate to
        // to={`/${budgetDetails.id}/accounts/${account.id}`}
        // eslint-disable-next-line no-restricted-globals
        window.location.href = `/${budget.id}/accounts/${id}`;

        if (mx_account_id) {
          dispatch(syncAccount(id, budget.id));
        }
      });
  };
};

// TODO create a fetchTransactionsByAccount so we don't fetch all transactions all the time
export const fetchTransactions = (budgetId, accountId) => {
  return async (dispatch) => {
    await axios
      .get(`${ELIXIR_API}/budgets/${budgetId}/transactions`, {
        headers: headers(),
      })
      .then((res) => {
        const transactionsPayload = res.data.data.filter((transaction) => {
          return transaction.account_id === accountId;
        });

        dispatch({
          type: TRANSACTIONS_RECEIVED,
          payload: transactionsPayload,
        });
      })
      .catch((err) => {});
  };
};

export const groupTransactionsByMonth = (dispatch, transactions) => {
  const transactionsGroupedByMonth = transactions.reduce(
    (transactionList, currentTransaction) => {
      const transactionMonth = moment(currentTransaction.date).format('MMYYYY');

      transactionList[transactionMonth] =
        transactionList[transactionMonth] || [];
      transactionList[transactionMonth].push(currentTransaction);

      // move child transactions to the grouped object
      if (currentTransaction.child_transactions.length) {
        for (let i = 0; i < currentTransaction.child_transactions.length; i++) {
          const currentChild = currentTransaction.child_transactions[i];

          transactionList[transactionMonth].push(currentChild);
        }
      }

      return transactionList;
    },
    {}
  );

  dispatch({
    type: SET_TRANSACTIONS_BY_MONTH,
    transactionsGroupedByMonth,
  });
};

export const groupTransactionsByMonthAndCategory = (dispatch, transactions) => {
  const groupedByMonthCategory = transactions.reduce((prev, curr) => {
    let transactionCategory;

    const transactionMonth = moment(curr.date).format('MMYYYY');

    if (curr.category === 'Left to Budget') {
      transactionCategory = curr.category;
    } else {
      // if its a split transaction
      if (curr.child_transactions.length) {
        let childCategory;

        for (let i = 0; i < curr.child_transactions.length; i++) {
          const currentChild = curr.child_transactions[i];
          currentChild.payee = curr.payee;

          if (currentChild.category === 'Left to Budget') {
            childCategory = currentChild.category;
          } else {
            childCategory = currentChild.category_id || 'uncategorized';
          }

          if (!prev[transactionMonth]) {
            prev[transactionMonth] = {
              [childCategory]: [currentChild],
            };
          } else {
            if (!Array.isArray(prev[transactionMonth][childCategory])) {
              prev[transactionMonth][childCategory] = [];
            }
            prev[transactionMonth][childCategory].push(currentChild);
          }
        }
      }
      transactionCategory = curr.category_id || 'uncategorized';
    }

    if (!prev[transactionMonth]) {
      prev[transactionMonth] = {
        [transactionCategory]: [curr],
      };
    } else {
      if (!Array.isArray(prev[transactionMonth][transactionCategory])) {
        prev[transactionMonth][transactionCategory] = [];
      }
      prev[transactionMonth][transactionCategory].push(curr);
    }

    return prev;
  }, {});

  dispatch({
    type: SET_TRANSACTIONS_BY_MONTH_AND_CATEGORY,
    groupedByMonthCategory,
  });
};

export const fetchAllTransactions = (budgetId) => {
  return async (dispatch) => {
    await axios
      .get(`${ELIXIR_API}/budgets/${budgetId}/transactions`, {
        headers: headers(),
      })
      .then((res) => {
        groupTransactionsByMonth(dispatch, res.data.data);
        groupTransactionsByMonthAndCategory(dispatch, res.data.data);

        dispatch({
          type: TRANSACTIONS_RECEIVED,
          payload: res.data.data,
        });

        dispatch({
          type: ACCOUNTS_BY_ID_SET_TRANSACTIONS,
          transactions: res.data.data,
        });

        dispatch({
          type: TRANSACTIONS_LOADING,
          isLoading: false,
        });

        dispatch({
          type: REFRESH_LTB_BREAKDOWN_BY_MONTH,
        });
      })
      .catch((err) => {});
  };
};

export const saveApproveTransaction = (
  transaction,
  isApproved,
  budget_id,
  account_id,
  accountsOrder
) => {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNTS_BY_ID_UPDATE_TRANSACTION,
      payload: {
        id: transaction.id,
        account_id,
        date: transaction.date,
        category_id:
          transaction.category_id || transaction.category || 'uncategorized',
        approved: isApproved,
      },
    });

    await axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budget_id}/transactions/${transaction.id}`,
      headers: headers(),
      data: {
        parent: {
          approved: isApproved,
        },
      },
    }).then((res) => {
      // dispatch(fetchTransactions(budget_id, account_id));
      // dispatch(getBudgetById(budget_id, accountsOrder));
    });
  };
};

export const toggleIncludeNow = (
  transaction,
  includeNow,
  budget_id,
  account_id,
  accountsOrder
) => {
  return async (dispatch) => {
    dispatch({
      type: ACCOUNTS_BY_ID_UPDATE_TRANSACTION,
      payload: {
        id: transaction.id,
        account_id,
        date: transaction.date,
        category_id:
          transaction.category_id || transaction.category || 'uncategorized',
        include_now: includeNow,
      },
    });

    await axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budget_id}/transactions/${transaction.id}`,
      headers: headers(),
      data: {
        parent: {
          include_now: includeNow,
        },
      },
    }).then(() => {
      dispatch(fetchAllTransactions(budget_id));
      dispatch(fetchTimeframeSubcategories(budget_id));
    });
  };
};

export const saveTransaction = (
  transaction,
  budget_id,
  account_id,
  accountsOrder
) => {
  const transactionDate = moment(transaction.date);
  // const convertedDate = moment(transactionDate).toISOString();

  const utcDate = moment(transaction.date);

  const transactionId = transaction.is_transfer ? uuid() : transaction.id;
  const isApproved =
    transaction.approved === undefined ? true : transaction.approved;

  return (dispatch) => {
    if (!transaction.created) {
      return axios({
        method: 'post',
        url: `${ELIXIR_API}/budgets/${budget_id}/transactions`,
        headers: headers(),
        data: {
          parent: {
            id: transactionId,
            date: transaction.date,
            payee: transaction.payee,
            category:
              transaction.child_transactions &&
              transaction.child_transactions.length
                ? null
                : transaction.category,
            category_id:
              transaction.child_transactions &&
              transaction.child_transactions.length
                ? null
                : transaction.category_id,
            memo: transaction.memo,
            ltb_next: transaction.ltb_next || false,
            amount: transaction.amount,
            type: transaction.type,
            approved: isApproved,
            is_transfer: transaction.is_transfer,
            transfer_target: transaction.transfer_target,
            transfer_target_type: transaction.transfer_target_type,
            transfer_origin: transaction.transfer_origin,
            transfer_origin_type: transaction.transfer_origin_type,
            off_budget: transaction.off_budget,
            deleted: false,
            budget_id,
            account_id,
            ...(transaction.repeat ? { repeat: transaction.repeat } : {}),
          },
          children: transaction.child_transactions || [],
        },
      }).then((res) => {
        if (transaction.is_transfer) {
          dispatch(
            saveTransferToTarget(
              budget_id,
              account_id,
              transaction,
              transactionId,
              accountsOrder
            )
          );
        }

        if (transaction.repeat && transaction.repeat.frequency) {
          dispatch(fetchAllTransactions(budget_id));
        } else if (
          transaction.child_transactions &&
          transaction.child_transactions.length
        ) {
          dispatch(fetchTransactions(budget_id, account_id));
        }

        // const samplePayload = {
        //   ...transaction,
        //   account_id,
        //   created: res.data.data.created,
        // };

        dispatch({
          type: ACCOUNTS_BY_ID_SAVE_TRANSACTION,
          payload: {
            ...transaction,
            account_id,
            created: res.data.data.created,
          },
        });

        dispatch(fetchAllTransactions(budget_id));
        dispatch(fetchAccount(budget_id, account_id));
        // dispatch(fetchCategories(budget_id));
        // dispatch(getBudgetById(budget_id, accountsOrder));
        dispatch(fetchPayees(budget_id));
        dispatch(fetchTimeframeSubcategories(budget_id));
      });
    }

    dispatch({
      type: ACCOUNTS_BY_ID_SAVE_TRANSACTION,
      payload: transaction,
    });

    return axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budget_id}/transactions/${transaction.id}`,
      headers: headers(),
      data: transaction.changes
        ? {
            parent: transaction.changes.parent && {
              ...transaction.changes.parent,
              ...((transaction.changes.parent &&
                transaction.changes.parent.date && {
                  date: moment(transaction.changes.parent.date)
                    .utc()
                    .local()
                    .toISOString(),
                }) ||
                {}),
              ...(transaction.child_transactions &&
              transaction.child_transactions.length
                ? { category: null }
                : {}),
            },
            update: transaction.changes.update || undefined,
            delete: transaction.changes.remove || undefined,
          }
        : {
            parent: {
              date: transaction.date,
              payee: transaction.payee,
              category: transaction.category,
              category_id: transaction.category_id,
              ltb_next: transaction.ltb_next || false,
              amount: transaction.amount,
              memo: transaction.memo,
              type: transaction.type,
              approved: transaction.approved,
              is_transfer: transaction.is_transfer,
              transfer_target: transaction.transfer_target,
              transfer_target_type: transaction.transfer_target_type,
              transfer_origin: transaction.transfer_origin,
              transfer_origin_type: transaction.transfer_origin_type,
              ...(transaction.repeat ? { repeat: transaction.repeat } : {}),
            },
          },
    }).then((res) => {
      if (
        transaction.changes &&
        ((transaction.changes.parent &&
          transaction.changes.parent.children &&
          transaction.changes.parent.children.length) ||
          (transaction.changes.update && transaction.changes.update.length) ||
          (transaction.changes.remove && transaction.changes.remove.length))
      )
        dispatch(fetchTransactions(budget_id, account_id));
      // dispatch(fetchAccount(budget_id, account_id));
      // // dispatch(fetchTransactions(budget_id, account_id));
      // dispatch(fetchCategories(budget_id));
      // dispatch(getBudgetById(budget_id, accountsOrder));
      dispatch(fetchAccount(budget_id, account_id));
      dispatch(fetchPayees(budget_id));
      dispatch(fetchTimeframeSubcategories(budget_id));
    });
  };
};

export const saveTransferToTarget = (
  budget_id,
  account_id,
  transaction,
  origin_trans_id,
  accountsOrder
) => {
  return (dispatch) => {
    const newTransferId = uuid();

    return axios({
      method: 'post',
      url: `${ELIXIR_API}/budgets/${budget_id}/transactions`,
      headers: headers(),
      data: {
        parent: {
          id: newTransferId,
          date: transaction.date,
          payee: transaction.payee,
          memo: transaction.memo,
          amount: transaction.amount,
          type: 'inflow',
          approved: transaction.approved,
          is_transfer: true,
          transfer_target: transaction.transfer_target,
          transfer_target_type: transaction.transfer_target_type,
          transfer_origin: transaction.transfer_origin,
          transfer_origin_type: transaction.transfer_origin_type,
          transfer_origin_transaction_id: origin_trans_id,
          deleted: false,
          budget_id,
          account_id: transaction.transfer_target,
        },
      },
    }).then((res) => {
      dispatch(
        updateTransferOrigin(
          origin_trans_id,
          budget_id,
          account_id,
          newTransferId
        )
      );
      dispatch(getBudgetById(budget_id, accountsOrder));
      dispatch(fetchAccount(budget_id, transaction.transfer_target));
    });
  };
};

export const updateTransferOrigin = (
  transaction,
  budget_id,
  account_id,
  target_trans_id
) => {
  return (dispatch) => {
    return axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budget_id}/transactions/${transaction}`,
      headers: headers(),
      data: {
        parent: {
          transfer_target_transaction_id: target_trans_id,
        },
      },
    }).then((res) => {
      dispatch({
        type: TRANSACTION_SAVED,
        payload: transaction,
      });
      dispatch(fetchAllTransactions(budget_id));
      // dispatch(fetchTransactions(budget_id, account_id));
      // dispatch(fetchCategories(budget_id));
      // dispatch(getBudgetById(budget_id));
      // dispatch(fetchTimeframeSubcategories(budget_id));
    });
  };
};

export const updateScrollPosition = (
  accountId,
  startIndex,
  stopIndex,
  currentView
) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACCOUNT_SCROLL_POSITION,
      accountId,
      startIndex,
      stopIndex,
      currentView,
    });
  };
};

export const cancelSeries = (
  budgetId,
  accountId,
  recurringId,
  transactionDate
) => {
  return (dispatch) => {
    return axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budgetId}/accounts/${accountId}/recurring_transactions/${recurringId}`,
      headers: headers(),
      data: {
        recurring_transaction: {
          end_date: transactionDate,
        },
      },
    }).then((res) => {
      dispatch(fetchAllTransactions(budgetId));
      dispatch(fetchAccount(budgetId, accountId));
    });
  };
};

export const deleteTransaction = (transaction, budget_id, account_id) => {
  return (dispatch) => {
    axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budget_id}/transactions/${transaction.id}`,
      headers: headers(),
      data: {
        parent: {
          deleted: true,
        },
      },
    }).then((res) => {
      dispatch({
        type: TRANSACTION_DELETED,
        payload: transaction,
      });
      if (transaction.is_transfer) {
        dispatch(deleteTransfer(budget_id, transaction));
      } else {
        dispatch(fetchTransactions(budget_id, account_id));
        dispatch(fetchCategories(budget_id));
        dispatch(getBudgetById(budget_id));
        dispatch(fetchTimeframeSubcategories(budget_id));
      }
    });
  };
};

export const deleteBulkTransactions = (
  budgetId,
  transactionsSelected,
  accountId,
  transactionsDetailedList,
  accountsOrder
) => {
  return (dispatch) => {
    dispatch({
      type: ACCOUNTS_BY_ID_REMOVE_TRANSACTIONS,
      transactionIdList: transactionsSelected,
      accountId,
    });

    return axios({
      method: 'post',
      url: `${ELIXIR_API}/budgets/${budgetId}/transactions/bulk_delete`,
      headers: headers(),
      data: {
        payload: transactionsSelected,
      },
    }).then((res) => {
      transactionsDetailedList.map((t) => {
        if (t.is_transfer) {
          dispatch(deleteTransfer(budgetId, t, accountsOrder));
        }
      });

      // dispatch(fetchAllTransactions(budgetId));
      // dispatch(fetchAccount(budgetId, accountId));
      dispatch(refreshBudgetById(budgetId));
      dispatch(fetchAccount(budgetId, accountId));
      dispatch(fetchAllTransactions(budgetId));
      // dispatch(fetchCategories(budgetId));
      // dispatch(fetchSubcategories(budgetId));
      dispatch(fetchTimeframeSubcategories(budgetId));
      // dispatch(fetchSectionsWithCategories(budgetId));

      // dispatch(deleteTransfer(budget_id, transaction));
    });
  };
};

export const bulkCategorize = (
  accountId,
  budgetId,
  transactionsSelected,
  categoryName,
  categoryId,
  accountsOrder
) => {
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${ELIXIR_API}/budgets/${budgetId}/transactions/bulk_categorize`,
      headers: headers(),
      data: {
        payload: transactionsSelected,
        category_name: categoryName,
        category_id: categoryId,
      },
    }).then((res) => {
      dispatch(fetchAllTransactions(budgetId));
      dispatch(fetchAccount(budgetId, accountId));
      dispatch(getBudgetById(budgetId, accountsOrder));
      dispatch(fetchTimeframeSubcategories(budgetId));
    });
  };
};

export const deleteTransfer = (budget_id, transaction, accountsOrder) => {
  return (dispatch) => {
    if (transaction.type === 'inflow') {
      axios({
        method: 'patch',
        url: `${ELIXIR_API}/budgets/${budget_id}/transactions/${transaction.transfer_origin_transaction_id}`,
        headers: headers(),
        data: {
          parent: {
            deleted: true,
          },
        },
      }).then((res) => {
        dispatch({
          type: TRANSACTION_DELETED,
          payload: transaction,
        });
        dispatch(fetchCategories(budget_id));
        dispatch(getBudgetById(budget_id, accountsOrder));
        dispatch(fetchTimeframeSubcategories(budget_id));
        dispatch(fetchAccounts(budget_id, accountsOrder));
        dispatch(fetchAccount(budget_id, transaction.transfer_origin));
      });
    } else if (transaction.type === 'outflow') {
      axios({
        method: 'patch',
        url: `${ELIXIR_API}/budgets/${budget_id}/transactions/${transaction.transfer_target_transaction_id}`,
        headers: headers(),
        data: {
          parent: {
            deleted: true,
          },
        },
      }).then((res) => {
        dispatch({
          type: TRANSACTION_DELETED,
          payload: transaction,
        });
        dispatch(getBudgetById(budget_id, accountsOrder));
        dispatch(fetchTimeframeSubcategories(budget_id));
        dispatch(fetchAccounts(budget_id));
        dispatch(fetchAllTransactions(budget_id));
        dispatch(fetchAccount(budget_id, transaction.transfer_target));
      });
    }
  };
};

export const savePayee = (name, budget_id) => {
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${ELIXIR_API}/budgets/${budget_id}/payees`,
      headers: headers(),
      data: {
        name,
        budget_id,
      },
    });
  };
};

const currentDate = moment().utc().local().toISOString();
const emptyArray = [];

export const addTransaction = (accountId, is_transfer = false) => {
  const generatedTransactionId = uuid();
  return (dispatch) => {
    const flow = is_transfer ? 'outflow' : null;
    dispatch({
      type: ACCOUNTS_BY_ID_ADD_TRANSACTION,
      payload: {
        id: generatedTransactionId,
        account_id: accountId,
        isEditable: true,
        date: currentDate,
        deleted: false,
        amount: 0,
        type: flow,
        is_transfer,
        child_transactions: emptyArray,
      },
    });

    dispatch({
      type: TOGGLE_EDIT_TRANSACTION,
      transactionId: generatedTransactionId,
    });
  };
};

export const cancelAddTransaction = (accountId, index) => {
  return (dispatch) => {
    dispatch({
      type: ACCOUNTS_BY_ID_CANCEL_TRANSACTION,
      payload: {
        account_id: accountId,
        index,
      },
    });
  };
};

export const toggleEditTransaction = (transactionId) => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_EDIT_TRANSACTION,
      transactionId,
    });
  };
};

// export const removeTemporaryTransaction = (index) => {
//   return (dispatch) => {
//     dispatch({
//       type: TRANSACTION_REMOVED,
//       payload: {
//         index,
//       },
//     });
//   };
// };
