import React from 'react';
import { connect } from 'react-redux';
import BudgetTableCCCategoryContainer from './budget-table-cc-category-container';
import { saveDebAccttNotes } from '../../../../actions/budget';

const BudgetTableCCategoryConnector = ({
  accountDetails,
  index,
  monthsDisplayed,
  saveDebAccttNotes,
  settings,
}) => {
  return (
    <BudgetTableCCCategoryContainer
      accountDetails={accountDetails}
      index={index}
      monthsDisplayed={monthsDisplayed}
      saveDebAccttNotes={saveDebAccttNotes}
      settings={settings}
    />
  );
};

const mapStateToProps = (state, props) => {
  return {
    activeMonths: state.activeMonths,
    settings: state.settings,
  };
};

export default connect(
  mapStateToProps,
  { saveDebAccttNotes }
)(BudgetTableCCategoryConnector);
