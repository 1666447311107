import React, { PureComponent } from 'react';
import BudgetTableCategoryGroupMonthView from './budget-table-category-group-month-view';

class BudgetTableCategoryGroupMonthContainer extends PureComponent {
  render() {
    const {
      categoryGroupTotalActivityByMonth,
      categoryGroupTotalBudgetedByMonth,
      categoryGroupTotalRemainingByMonth,
      isMiddleColumn,
      settings,
    } = this.props;

    return (
      <BudgetTableCategoryGroupMonthView
        categoryGroupTotalActivityByMonth={categoryGroupTotalActivityByMonth}
        categoryGroupTotalBudgetedByMonth={categoryGroupTotalBudgetedByMonth}
        categoryGroupTotalRemainingByMonth={categoryGroupTotalRemainingByMonth}
        isMiddleColumn={isMiddleColumn}
        settings={settings}
      />
    );
  }
}

export default BudgetTableCategoryGroupMonthContainer;
