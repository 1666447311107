import React, { ReactNode, createElement } from 'react';
import { CgSpinner } from 'react-icons/cg';
import { FaRegThumbsUp } from 'react-icons/fa';
import cn from 'classnames';
import { buttonBaseStyles } from './Button.styles';

interface ButtonProps {
  as?: 'link' | 'button';
  children: ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  fill?: boolean;
  onPress?: () => void;
  onClick?: () => void;
  ok?: boolean;
}

// TODO:
// - [x] Add disabled styles
// - [ ] Add size
// - [ ] Add error or styles

const ButtonIconWrapper = ({ children }: { children: ReactNode }) => (
  <span
    className={cn(
      'absolute',
      'top-[50%]',
      'left-[50%]',
      '-translate-x-2/4',
      '-translate-y-2/4'
    )}
  >
    {children}
  </span>
);

function Button(props: ButtonProps) {
  const { children, isLoading, as = 'button', ...rest } = props;

  return (
    <button
      className={cn(
        buttonBaseStyles,
        'bg-brand-green',
        'hover:bg-brand-green',
        'focus:ring-brand-green',
        {
          'w-full': rest.fill,
          'text-center': rest.fill,
          'opacity-50': rest.disabled,
          'hover:cursor-default': rest.disabled,
          'bg-green-600': rest.ok,
          'hover:bg-green-600': rest.ok,
        }
      )}
      {...rest}
    >
      {rest.ok && (
        <ButtonIconWrapper>
          <FaRegThumbsUp />
        </ButtonIconWrapper>
      )}
      {isLoading && (
        <ButtonIconWrapper>
          <CgSpinner className={cn('fa-spin')} />
        </ButtonIconWrapper>
      )}
      <div
        className={cn({
          'opacity-0': isLoading || rest.ok,
          'mx-auto': rest.fill,
        })}
      >
        {children}
      </div>
    </button>
  );
}

export { Button };
