import React from 'react';
import classNames from 'classnames';
import { Droppable } from 'react-beautiful-dnd';
import BudgetTableCategoryGroup from '../budget-table-category-group';
import BudgetTableCCCategoryGroup from '../budget-table-cc-category-group';

function BudgetTableContainer({ accounts, categories = [], settings }) {
  const hasCreditCardAccounts = () => {
    return (
      accounts &&
      accounts.filter((account) => {
        return account.type === 'creditcard' || account.type === 'debtother';
      }).length > 0
    );
  };

  const renderCategoryGroups = () => {
    return categories.map((category, index) => {
      return (
        <BudgetTableCategoryGroup
          key={`${category.id}${index}`}
          index={index}
          categoryGroupDetails={category}
        />
      );
    });
  };

  const renderCreditCardGroupAndCategories = () => {
    return hasCreditCardAccounts() ? <BudgetTableCCCategoryGroup /> : null;
  };

  return (
    <section
      className={classNames('budget-table-body-wrapper', {
        'table-body-wrapper-multi-month':
          settings.month_view === 'multi' && window.innerWidth > 1199,
      })}
    >
      <Droppable droppableId="category-groups" type="category-group">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ minHeight: '35px' }}
          >
            {renderCategoryGroups(provided)}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {renderCreditCardGroupAndCategories()}
    </section>
  );
}

export default BudgetTableContainer;
