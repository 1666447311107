import { api } from '../api';
import axios from 'axios';
import { UserLoginError } from './User.errors';
import { FASTAPI_API, ELIXIR_API } from '../../constants';
import { UserPayload } from './User.types';

async function userSignup(payload: UserPayload) {
  const response = await api.post(`${FASTAPI_API}/users`, payload);
  return response;
}

async function userLogin(payload: UserPayload) {
  // Why we are using plain axios and not the `api` instance? because if we
  // use it before login, the instance created will have `null` as a token,
  // because we haven't saved it on `localStorage`.
  const elixirLogin = axios.post(`${ELIXIR_API}/sessions`, payload);
  const fastApiLogin = api.post(`${FASTAPI_API}/token`, payload);

  let results: Array<Record<string, string>> = [];

  try {
    const responses = await Promise.all([elixirLogin, fastApiLogin]);
    // We are leaving this `forEach` because we used to loop through two type of
    // status codes, `200` and `201` and we'd manipulate data. However, that changed,
    // but I'm hesitant to change the API.
    responses.forEach((response) => {
      if (response) {
        results.push(response.data);
      }
    });
  } catch (error) {
    // @ts-ignore
    switch (error.response.status) {
      case 401:
        throw new UserLoginError('Invalid credentials');
      default:
        throw new UserLoginError('Unknown error');
    }
  }

  return results;
}

export { userSignup, userLogin };
