import React from 'react';
import { connect } from 'react-redux';
import { fetchBudget } from '../../../../actions/budget';
import ManageBudgetItemContainer from './manage-budget-item-container';

const ManageBudgetItemConnector = ({
  budget,
  defaultBudget,
  deleteBudgetAlert,
  fetchBudget,
  session,
  onClose,
  setDefaultBudget,
}) => {
  return (
    <ManageBudgetItemContainer
      budget={budget}
      defaultBudget={defaultBudget}
      deleteBudgetAlert={deleteBudgetAlert}
      fetchBudget={fetchBudget}
      onClose={onClose}
      setDefaultBudget={setDefaultBudget}
      userId={session.currentUser.id}
    />
  );
};

const mapStateToProps = ({ session, budgetDetails }) => ({
  session,
  budgetDetails,
});

export default connect(
  mapStateToProps,
  { fetchBudget }
)(ManageBudgetItemConnector);
