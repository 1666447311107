import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Spacer } from '@nextui-org/react';
import { PageHeader } from './PageHeader';
import { Container } from '../ui/container';

interface LayoutProps {
  children: ReactNode;
  title?: string;
  subtitle?: string;
}

function Layout({ children, title, subtitle }: LayoutProps) {
  const classes = classNames('flex');

  return (
    <Container className={classes}>
      {title && (
        <>
          <PageHeader title={title} subtitle={subtitle} />
          <Spacer y={1} />
        </>
      )}
      {children}
    </Container>
  );
}

export { Layout };
