import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import applicationStatus from './application-status';
import session from './session';
import budget from './budget';
import accounts from './accounts';
import payees from './payees';
import sync from './sync';
import accountsById from './accounts-by-id';
import accountDetails from './account-details';
import activeMonths from './active-months';
import budgetDetails from './budget-details';
import ltbBreakdownByMonth from './ltb-breakdown-by-month';
import syncStatus from './sync-status';
import settings from './settings';
import searchString from './search-string';
import subscriptionDetails from './subscription-details';
import timeframeSubcategoriesByMonth from './timeframe-subcategories-by-month';
import transactions from './transactions';
import transactionBeingEdited from './transaction-being-edited';
import transactionsByMonth from './transactions-by-month';
import transactionsByMonthCategory from './transactions-by-month-category';
import timeframes from './timeframes';
import { budgetIsLoading, formIsSubmitting } from './ui-state';

const appReducer = combineReducers({
  formSubmitting: formIsSubmitting,
  accounts,
  accountsById,
  accountDetails,
  applicationStatus,
  activeMonths,
  budget,
  budgetDetails,
  form,
  ltbBreakdownByMonth,
  payees,
  searchString,
  session,
  settings,
  subscriptionDetails,
  sync,
  syncStatus,
  timeframeSubcategoriesByMonth,
  transactions,
  transactionBeingEdited,
  transactionsByMonth,
  transactionsByMonthCategory,
  budgetIsLoading,
  timeframes,
});

export default function(state, action) {
  if (action.type === 'LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}
