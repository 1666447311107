import React from 'react';
import { connect } from 'react-redux';
import BudgetTableCategoryContainer from './budget-table-category-container';
import {
  deleteCategoryRow,
  saveCategory,
  saveCategoryNotes,
} from '../../../../actions/budget';

const BudgetTableCategoryConnector = ({
  accountsById,
  budgetDetails,
  categoryDetails,
  deleteCategoryRow,
  index,
  monthsDisplayed,
  saveCategory,
  saveCategoryNotes,
  settings,
}) => {
  return (
    <BudgetTableCategoryContainer
      accountsById={accountsById}
      budgetDetails={budgetDetails}
      categoryDetails={categoryDetails}
      deleteCategoryRow={deleteCategoryRow}
      index={index}
      monthsDisplayed={monthsDisplayed}
      saveCategory={saveCategory}
      saveCategoryNotes={saveCategoryNotes}
      settings={settings}
    />
  );
};

const mapStateToProps = (state, props) => {
  return {
    accountsById: state.accountsById,
    budgetDetails: state.budgetDetails,
    activeMonths: state.activeMonths,
    settings: state.settings,
  };
};

export default connect(
  mapStateToProps,
  { deleteCategoryRow, saveCategory, saveCategoryNotes }
)(BudgetTableCategoryConnector);
