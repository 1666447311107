import React from 'react';
import { connect } from 'react-redux';
import LeftToBudgetContainer from './left-to-budget-container';
import moment from 'moment';

// We want to get the following data to calculate LTB:
// - Amount forwarded from last month (positive or negative)
// - Income for this month
// - Budgeted for this month
// (!) Budgeted in the future (notice only, dont include in calculation). this should only be displayed in the CURRENT month
const LeftToBudgetConnector = (props) => {
  return (
    <LeftToBudgetContainer
      activeMonths={props.activeMonths}
      currentMonth={props.currentMonth}
      ltbBreakdownByMonth={props.ltbBreakdownByMonth}
      settings={props.settings}
    />
  );
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state) => {
    return {
      activeMonths: state.activeMonths,
      currentMonth: moment(state.activeMonths.currentMonth).format('MMYYYY'),
      ltbBreakdownByMonth: { ...state.ltbBreakdownByMonth },
      settings: state.settings,
    };
  };
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  null
)(LeftToBudgetConnector);
