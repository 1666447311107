import React, { Component } from 'react';
import moment from 'moment';
import CurrencyInput from 'react-currency-input';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Select from 'react-select';
import { formatDate, parseDate } from 'react-day-picker/moment';
import ButtonPrimary from '../common/button/button-primary';
import ButtonSecondary from '../common/button/button-secondary';

const selectButtonStyles = (backgroundColor, color) => ({
  backgroundColor,
  padding: '0px 0px 0px 0px',
  margin: '6px 6px 6px 6px',
  border: `2px solid ${backgroundColor}`,
  borderRadius: '2px',
  width: 'auto',
  textAlign: 'center',
  color,
});

const customSelectStyles = {
  option: (base, state) => {
    if (state.data.style === 'button') {
      return {
        ...base,
        ...selectButtonStyles('#1b97b3', '#fff'),
      };
    }
    return {
      ...base,
      backgroundColor: state.isFocused ? '#F4F5F6' : '',
      padding: '4px 8px',
      color: state.isSelected ? '#565a5c' : '#565a5c',
    };
  },
  control: (base, state) => ({}),
  groupHeading: (base, state) => ({
    ...base,
    borderBottom: '1px solid #D8D8D8',
    borderTop: '1px solid #D8D8D8',
    padding: '4px 6px',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '10px',
    color: '#384f66',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  }),
  group: (base, state) => ({
    paddingTop: '0 !important',
    marginTop: '0 !important',
  }),
  menu: (base, state) => ({
    ...base,
    width: 'auto',
    minWidth: '125px',
    wrap: 'none !important',
  }),
  menuList: (base, state) => ({
    ...base,
    width: 'auto',
    minWidth: 'auto',
    wrap: 'none !important',
    paddingTop: '0 !important',
    marginTop: '0 !important',
  }),
  singleValue: (base, state) => ({
    ...base,
    ...(state.data.style === 'button'
      ? { ...selectButtonStyles('#2fbfc4', '#fff'), top: '30%', left: '0' }
      : {}),
  }),
};

class NewTransferForm extends Component {
  state = {
    date: moment()
      .utc()
      .local()
      .toISOString(true),
    type: 'outflow',
    memo: '',
    is_transfer: true,
  };

  handleDayChange = (date) => {
    if (date) {
      this.setState({
        date,
      });
    } else {
      this.setState({
        date: '',
      });
    }
  };

  handleChange = (event, maskedvalue, floatvalue) => {
    this.setState({
      amount: maskedvalue,
      amount_unmasked: floatvalue,
    });
  };

  handleTransferAcctChange = (value) => {
    this.setState({
      account: value,
      transfer_target: value.value || null,
      transfer_target_type: value.value.type || null,
      transfer_origin: this.props.accountDetails.id,
      transfer_origin_type: this.props.accountDetails.type,
    });
  };

  handleNotesChange = (evt) => {
    this.setState({
      memo: evt.target.value,
    });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { date, amount_unmasked, type, memo } = this.state;

    const transactionDetails = {
      ...this.state,
      amount: amount_unmasked,
      type,
      date,
      memo,
      deleted: false,
    };

    this.props.onSaveMobileTransaction(transactionDetails);

    this.props.onClose();
  };

  render() {
    const { account, amount, date, memo } = this.state;

    const { accounts, settings } = this.props;

    // Disable transfers to off budget accounts. Users will have to do this transfers manually.
    const onBudgetAccounts = accounts.filter(({off_budget}) => !off_budget)

    const formattedListOfAccounts = onBudgetAccounts.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    return (
      <div className="form-body">
        <div className="form-group">
          <label className="input-label">Date</label>
          <DayPickerInput
            parseDate={parseDate}
            formatDate={formatDate}
            inputProps={{
              className: 'input-field mobile-date-picker',
            }}
            dayPickerProps={{
              numberOfMonths: settings.num_of_months,
              firstDayOfWeek: settings.first_day,
            }}
            onDayChange={this.handleDayChange}
            // showOverlay={true}
            format={settings.date_format}
            placeholder="---"
            value={date ? formatDate(date, settings.date_format) : ''}
          />
        </div>
        <div className="form-group">
          <label className="input-label">Amount</label>
          <CurrencyInput
            value={amount || null}
            allowNegative={false}
            inputType="decimal"
            autoFocus={true}
            allowEmpty={true}
            placeholder="0"
            thousandSeparator={settings.thousands}
            decimalSeparator={settings.decimal}
            prefix={(settings.show_symbol && settings.symbol) || ''}
            className="input-field"
            onChangeEvent={this.handleChange}
          />
        </div>
        <div className="form-group">
          <label className="input-label">Transfer to:</label>
          <Select
            placeholder="Select Account"
            value={account}
            className="editable-cell-input"
            styles={customSelectStyles}
            ref={(input) => (this.categoryInput = input)}
            onChange={this.handleTransferAcctChange}
            components={{ DropdownIndicator: null }}
            openMenuOnFocus={true}
            blurInputOnSelect={true}
            options={formattedListOfAccounts}
          />
        </div>
        <div className="form-group">
          <label className="input-label">Notes</label>
          <input
            className="input-field"
            name="budget-name"
            placeholder="Enter notes"
            ref={(input) => (this.nameField = input)}
            value={memo}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleNotesChange}
          />
        </div>
        <div className="form-controls-group">
          <ButtonSecondary onClick={this.props.onClose}>Cancel</ButtonSecondary>
          <ButtonPrimary
            onClick={this.handleFormSubmit}
            isDisabled={
              !this.state.amount && (!account || account.length === 0)
            }
          >
            Save Transfer
          </ButtonPrimary>
        </div>
      </div>
    );
  }
}

export default NewTransferForm;
