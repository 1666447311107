const {
  REACT_APP_ELIXIR_API,
  REACT_APP_FASTAPI_API,
  REACT_APP_PADDLE_MONTH,
  REACT_APP_PADDLE_YEAR,
  REACT_APP_PADDLE_LIFETIME,
  REACT_APP_PADDLE_TRIAL,
  REACT_APP_TELEGRAM_BOT_NAME,
} = process.env;

const ELIXIR_API = `${REACT_APP_ELIXIR_API}/api`;
const FASTAPI_API = REACT_APP_FASTAPI_API;
const PADDLE_MONTH = REACT_APP_PADDLE_MONTH;
const PADDLE_YEAR = REACT_APP_PADDLE_YEAR;
const PADDLE_LIFETIME = REACT_APP_PADDLE_LIFETIME;
const PADDLE_TRIAL = REACT_APP_PADDLE_TRIAL;
const TELEGRAM_BOT_NAME = REACT_APP_TELEGRAM_BOT_NAME;

export {
  TELEGRAM_BOT_NAME,
  ELIXIR_API,
  FASTAPI_API,
  PADDLE_MONTH,
  PADDLE_YEAR,
  PADDLE_LIFETIME,
  PADDLE_TRIAL,
};
