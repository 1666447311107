import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TransactionListContainer from './transaction-list-container';
import { getTransactionListFactory } from '../../../selectors/transaction-list';

const TransactionListConnector = (props) => {
  const accountId = props.match && props.match.params && props.match.params.id;

  return (
    <TransactionListContainer
      accountsById={props.accountsById}
      accountDetails={props.accountsById && props.accountsById[accountId]}
      categoryAndAccountList={props.budgetDetails.categoryAndAccountList}
      isSelectEnabled={props.isSelectEnabled}
      onSelected={props.onSelected}
      settings={props.settings}
      selectedTransactions={props.selectedTransactions}
      transactionBeingEdited={props.transactionBeingEdited}
      transactionList={props.transactionList}
    />
  );
};

const makeMapStateToProps = () => {
  const getTransactionList = getTransactionListFactory();

  const mapStateToProps = (state, props) => {
    return {
      budgetDetails: state.budgetDetails,
      transactionList: getTransactionList(state, props),
      transactionBeingEdited: state.transactionBeingEdited,
      accountsById: state.accountsById,
      settings: state.settings,
    };
  };

  return mapStateToProps;
};

export default withRouter(
  connect(
    makeMapStateToProps,
    null
  )(TransactionListConnector)
);
