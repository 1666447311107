import React from 'react';
import { connect } from 'react-redux';

const SidebarOffBudgeTitleConnector = ({ accounts }) => {
  const hasOffBudgetAccounts = (accounts) => {
    return (
      accounts && accounts.filter((account) => account.off_budget).length > 0
    );
  };

  return hasOffBudgetAccounts(accounts) ? (
    <h2 className="sidebar-accounts-section-title">Off-Budget Accounts</h2>
  ) : null;
};

const mapStateToProps = ({ accounts }) => ({
  accounts,
});

export default connect(mapStateToProps)(SidebarOffBudgeTitleConnector);
