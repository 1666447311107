import {
  ACCOUNT_LIST,
  BUDGET_FETCH_SUCCESS,
  BUDGET_LIST_FETCH_SUCCESS,
  BUDGET_DEFAULT_RECEIVED,
  BUDGET_UPDATE_ACCOUNTS_ORDER,
  BUDGET_UPDATE_CATEGORIES_ORDER,
  BUDGET_UPDATE_SUBCATEGORIES_ORDER,
  BUDGET_UPDATE_CREDIT_CARD_ORDER,
  BUDGET_ADD_NEW_CC_TO_ORDER,
  BUDGET_TABLE_SET,
  BUDGET_RENAMED,
  CATEGORY_AND_ACCOUNT_LIST,
} from '../action-list';

export default function(state = {}, action) {
  switch (action.type) {
    case BUDGET_DEFAULT_RECEIVED:
      return {
        ...state,
        ...action.payload,
      };

    case BUDGET_UPDATE_ACCOUNTS_ORDER:
      const accountsOrder = state.accounts_order
        ? state.accounts_order
        : action.accountIdsInOrder;
      return {
        ...state,
        accounts_order: accountsOrder,
      };

    case BUDGET_UPDATE_CATEGORIES_ORDER:
      const categoriesOrder = action.categoryIdsInOrder;
      return {
        ...state,
        categories_order: categoriesOrder,
      };

    case BUDGET_UPDATE_CREDIT_CARD_ORDER:
      const ccOrder = action.listOfCCIds;
      return {
        ...state,
        cc_order: ccOrder,
      };

    case BUDGET_ADD_NEW_CC_TO_ORDER:
      const newCCOrder = [...((state && state.cc_order) || [])];

      newCCOrder.push(action.ccId);

      return {
        ...state,
        cc_order: newCCOrder,
      };

    case BUDGET_UPDATE_SUBCATEGORIES_ORDER:
      const subcategoriesOrder = action.subcategoryIdsInOrder;
      return {
        ...state,
        subcategories_order: subcategoriesOrder,
      };

    case BUDGET_TABLE_SET:
      return {
        ...state,
        budgetTable: action.payload,
      };

    case CATEGORY_AND_ACCOUNT_LIST:
      return {
        ...state,
        categoryAndAccountList: action.payload,
      };

    case ACCOUNT_LIST:
      return {
        ...state,
        accountList: action.payload,
      };

    case BUDGET_RENAMED:
      return action.payload;

    case BUDGET_FETCH_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case BUDGET_LIST_FETCH_SUCCESS:
      return {
        ...state,
        userBudgets: action.payload,
      };

    default:
      return state;
  }
}
