import React from 'react';
import { connect } from 'react-redux';
import { deleteBulkPayees } from '../../../actions/payee';
import ManagePayeesContainer from './manage-payees-container';

const ManagePayeesConnector = ({
  budgetDetails,
  deleteBulkPayees,
  onClose,
  payees,
}) => {
  return (
    <ManagePayeesContainer
      budgetDetails={budgetDetails}
      deleteBulkPayees={deleteBulkPayees}
      onClose={onClose}
      payees={payees}
    />
  );
};

const mapStateToProps = ({ budgetDetails, payees }) => ({
  budgetDetails,
  payees,
});

export default connect(
  mapStateToProps,
  { deleteBulkPayees }
)(ManagePayeesConnector);
