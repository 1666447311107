import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface SearchBarState {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
}

const useSearchBarStore = create<SearchBarState>()(
  devtools((set) => ({
    searchTerm: '',
    setSearchTerm: (searchTerm) => set(() => ({ searchTerm })),
  }))
);

export { useSearchBarStore };
