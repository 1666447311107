import React from 'react';
import { isMobile } from '../../../utilities/helpers';
import './header.css';

const Header = ({ userEmail, toggleSidebar }) => {
  return (
    <header className="header">
      {/* hiding on desktop for now as this is not functional anymore  */}
      {isMobile() && (
        <i
          className="far fa-bars fa-fw fa-lg sidebar-collapse-icon"
          onClick={toggleSidebar}
        />
      )}

      <div className="header-right-side">
        {/* <ul className="header-nav desktop-only">
          <li className="header-nav-item">
            <span>Report a Bug</span>
          </li>
          <li className="header-nav-item">
            <span>Help Center</span>
          </li>
          <li className="header-nav-item">
            <span>
              {userEmail}
              <i className="fas fa-caret-down fa-fw" />
            </span>
          </li>
        </ul> */}
      </div>
    </header>
  );
};

export default Header;
