import { SUBSCRIPTION_INFO_FETCH_SUCCESS } from '../action-list';

export default function(state = {}, action) {
  switch (action.type) {
    case SUBSCRIPTION_INFO_FETCH_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
