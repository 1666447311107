import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from '../../actions/session';
import RecoverPassword from './recover-password';

class RecoverPasswordConnector extends Component {
  handleLogin = (data) => this.props.login(data, this.context.router);

  render() {
    return <RecoverPassword onSubmit={this.handleLogin} />;
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

export default connect(mapStateToProps, { login })(RecoverPasswordConnector);
