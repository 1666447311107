import { CLEAR_SEARCH_STRING, UPDATE_SEARCH_STRING } from '../action-list';

export default function(state = null, action) {
  switch (action.type) {
    case UPDATE_SEARCH_STRING:
      return action.searchString;

    case CLEAR_SEARCH_STRING:
      return null;

    default:
      return state;
  }
}
