import React, { Fragment, memo } from 'react';

const ManageBudgetItemView = ({
  budget,
  defaultBudget,
  deleteBudgetAlert,
  fetchBudget,
  setDefaultBudget,
}) => {
  return (
    <div className="manage-budget-item">
      <div className="manage-budget-item-name">{budget.name}</div>
      <div className="manage-budget-item-actions">
        {/* {budget.id !== defaultBudget ? (
          <i
            className="far fa-square fa-fw"
            data-balloon-pos="left"
            aria-label="Make default budget"
            onClick={() => setDefaultBudget(budget.id)}
          />
        ) : (
          <i
            className="fas fa-check-square fa-fw default-budget"
            data-balloon-pos="left"
            aria-label="Default budget"
          />
        )} */}
        <i
          className="fas fa-external-link fa-fw"
          data-balloon-pos="left"
          aria-label="Switch to this budget"
          onClick={() => {
            fetchBudget(budget.id)
            setDefaultBudget(budget.id)
            // window.location.reload()
          }
          }
        />
        {budget.id !== defaultBudget ? (
          <i
            className="fas fa-trash-alt fa-fw delete-budget"
            data-balloon-pos="left"
            aria-label="Delete budget"
            onClick={() => deleteBudgetAlert(budget)}
          />
        ) : (
          <i
            className="fas fa-trash-alt fa-fw"
            style={{ visibility: 'hidden' }}
          />
        )}
      </div>
    </div>
  );
};

export default memo(ManageBudgetItemView);
