import moment from 'moment';
import {
  ACCOUNTS_BY_ID_SAVE_TRANSACTION,
  SET_TRANSACTIONS_BY_MONTH,
} from '../action-list';

export default function(state = {}, action) {
  switch (action.type) {
    case SET_TRANSACTIONS_BY_MONTH:
      return { ...action.transactionsGroupedByMonth };

    case ACCOUNTS_BY_ID_SAVE_TRANSACTION:
      const currentState = { ...state } || {};
      const transaction = action.payload;
      const transactionDateInMMYYYY = moment(transaction.date).format('MMYYYY');

      if (!currentState[transactionDateInMMYYYY]) {
        currentState[transactionDateInMMYYYY] = [];
      }

      const transactionIdx =
        currentState[transactionDateInMMYYYY] &&
        currentState[transactionDateInMMYYYY].findIndex(
          (obj) => obj.id === action.payload.id
        );

      if (transactionIdx === -1) {
        currentState[transactionDateInMMYYYY].push(transaction);
      } else {
        currentState[transactionDateInMMYYYY][transactionIdx] = transaction;
      }

      return currentState;

    default:
      return state;
  }
}
