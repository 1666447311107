import axios from 'axios';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import mixpanel from 'mixpanel-browser';
import {
  ACCOUNT_LIST,
  ACCOUNT_ORDER_CHANGE,
  ACCOUNT_LIST_BY_IDS_UPDATE,
  ACCOUNT_UPDATE_NOTES,
  BUDGET_ADD_CATEGORY_ROW,
  BUDGET_ADD_CATEGORY_GROUP_ROW,
  BUDGET_DELETE_CATEGORY_ROW,
  BUDGET_DELETE_CATEGORY_GROUP_ROW,
  BUDGET_TABLE_SET,
  BUDGET_RENAMED,
  BUDGET_LIST_FETCH_SUCCESS,
  BUDGET_UPDATE_CATEGORIES_ORDER,
  BUDGET_UPDATE_CREDIT_CARD_ORDER,
  CATEGORY_AND_ACCOUNT_LIST,
  SECTION_ORDER_CHANGE,
  CATEGORY_ORDER_CHANGE,
  CATEGORY_ADDED,
  CATEGORY_UPDATED,
  CATEGORIES_RECEIVED,
  CATEGORY_GROUP_SAVED,
  CATEGORY_GROUP_UPDATE_SUBCAT_ORDER,
  CATEGORY_GROUP_MULTI_UPDATE_SUBCAT_ORDER,
  CATEGORY_GROUP_ADD_TEMP_CATEGORY_TO_ORDER,
  CATEGORY_UPDATE_PARENT_ID,
  MONTH_NEXT,
  MONTH_PREVIOUS,
  REFRESH_LTB_BREAKDOWN_BY_MONTH,
  SET_INITIAL_LTB_BREAKDOWN_BY_MONTH,
  SET_INITIAL_TIMEFRAME_SUBCATEGORIES_BY_MONTH,
  SETTINGS_RECEIVED,
  SUBCATEGORIES_RECEIVED,
  TIMEFRAME_SUBCATEGORIES_RECEIVED,
  TIMEFRAME_SUBCATEGORY_UPDATE_BY_MONTH,
  TIMEFRAME_SUBCATEGORY_SAVED,
  TIMEFRAME_SUBCATEGORY_UPDATE_BY_MONTH_WITH_ID,
  TIMEFRAMES_RECEIVED,
  UPDATE_CATEGORY,
  UPDATE_TIMEFRAME_SUBCATEGORY,
  SUBCATEGORY_ORDER_CHANGE,
} from '../action-list';
import { ELIXIR_API, FASTAPI_API } from '../constants';
import { api } from '../v1/api';
import { headers } from '../api';
import {
  listOrderByIds,
  sortByOrderOfIds,
} from '../utilities/drag-and-drop-reorder';
import {
  fetchAccounts,
  fetchAllAccountsAndTransactions,
  fetchAllTransactions,
} from './account';

export const createNewBudget = (payload, currentUser) => {
  return (dispatch) => {
    axios({
      method: 'post',
      url: `${ELIXIR_API}/budgets`,
      headers: headers(),
      data: {
        budget: {
          name: payload.name,
          primary: payload.makeDefault,
        },
      },
    }).then((res) => {
      const { id: newBudgetId, _ } = res.data.data;

      dispatch(saveInitialSettings(currentUser, newBudgetId));

      // TODO:
      // Save received settings
      // return dispatch({
      //   type: SETTINGS_RECEIVED,
      //   payload: res.data.data[0],
      // });

      dispatch(fetchBudget(newBudgetId));

      // TODO:
      // Save received budget actions

      dispatch(fetchAllAccountsAndTransactions(newBudgetId));
    });
  };
};

export const setDefaultBudget = (newBudgetId, currentUser) => {
  return (dispatch) => {
    axios({
      method: 'patch',
      url: `${ELIXIR_API}/users/set_default_budget/${currentUser}`,
      headers: headers(),
      data: {
        user: {
          default_budget: newBudgetId,
        },
      },
    }).then((res) => {});
  };
};

export const fetchAllBudgets = () => {
  return (dispatch) => {
    axios({
      method: 'get',
      url: `${ELIXIR_API}/budgets`,
      headers: headers(),
    }).then((res) => {
      dispatch(budgetListFetchSuccess(res.data.data));
    });
  };
};

export const fetchBudget = (budgetId) => {
  return (dispatch) => {
    axios({
      method: 'get',
      url: `${ELIXIR_API}/budgets/${budgetId}`,
      headers: headers(),
    }).then((res) => {
      dispatch(fetchAllAccountsAndTransactions(budgetId));
      dispatch(budgetFetchSuccess(res.data.data));
      dispatch(fetchCategories(budgetId, res.data.data.categories_order));
      dispatch(fetchSubcategories(budgetId, res.data.data.subcategories_order));
      dispatch(fetchTimeframeSubcategories(budgetId));

      if (res.data.data.accounts_order) {
        dispatch({
          type: ACCOUNT_LIST_BY_IDS_UPDATE,
          payload: res.data.data.accounts_order,
        });
      }
    });
  };
};

export const deleteBudget = (budgetId) => {
  return (dispatch) => {
    axios({
      method: 'delete',
      url: `${ELIXIR_API}/budgets/${budgetId}`,
      headers: headers(),
    }).then((res) => {
      dispatch(fetchAllBudgets());
    });
  };
};

export function budgetIsLoading(bool) {
  return {
    type: 'BUDGET_IS_LOADING',
    isLoading: bool,
  };
}

export function budgetFetchSuccess(budget) {
  return {
    type: 'BUDGET_FETCH_SUCCESS',
    payload: budget,
  };
}

export const budgetUpdateCategoriesOrder = (
  categories,
  categoryIdsInOrder,
  budgetId
) => {
  return (dispatch) => {
    dispatch({
      type: CATEGORY_ORDER_CHANGE,
      payload: sortByOrderOfIds(categories, categoryIdsInOrder),
    });
    dispatch({
      type: 'BUDGET_UPDATE_CATEGORIES_ORDER',
      categoryIdsInOrder,
    });

    saveCategoryGroupReorder(categoryIdsInOrder, budgetId);
  };
};

export const updateSubcategoryOrder = (
  budgetId,
  catGroupId,
  updatedPrevCatGroup,
  updatedNewCatGroup
) => {
  return (dispatch) => {
    if (!updatedNewCatGroup) {
      dispatch({
        type: CATEGORY_GROUP_UPDATE_SUBCAT_ORDER,
        updatedCatGroup: updatedPrevCatGroup,
        catGroupId,
      });
    } else {
      dispatch({
        type: CATEGORY_GROUP_MULTI_UPDATE_SUBCAT_ORDER,
        updatedCatGroup: updatedPrevCatGroup,
        updatedNewCatGroup,
        catGroupId,
      });
    }

    // saveSubcategoryReorder(subcategoryIdsInOrder, budgetId);
  };
};

export const budgetUpdateSubcategoriesOrder = (
  subcategories,
  subcategoryIdsInOrder,
  budgetId,
  subcategoryId,
  oldCategoryGroupId,
  newCategoryGroupId
) => {
  return (dispatch) => {
    dispatch({
      type: SUBCATEGORY_ORDER_CHANGE,
      payload: sortByOrderOfIds(subcategories, subcategoryIdsInOrder),
      subcategoryId,
      newCategoryGroupId,
    });
    dispatch({
      type: 'BUDGET_UPDATE_SUBCATEGORIES_ORDER',
      subcategoryIdsInOrder,
    });

    saveSubcategoryReorder(subcategoryIdsInOrder, budgetId);
    if (oldCategoryGroupId !== newCategoryGroupId) {
      dispatch(
        updateSubcategoryParentId(budgetId, subcategoryId, newCategoryGroupId)
      );
    }
  };
};

export function budgetListFetchSuccess(budgets) {
  return {
    type: BUDGET_LIST_FETCH_SUCCESS,
    payload: budgets,
  };
}

export const refreshBudget = (budget) => {
  return (dispatch) => {
    axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budget.id}`,
      headers: headers(),
      data: {
        budget: {
          budget_group: budget.budget_group,
          name: budget.name,
        },
      },
    }).then((res) => {
      dispatch(budgetFetchSuccess(res.data.data));
    });
  };
};

export const resetBudget = (budgetId) => {
  return () => {
    return axios({
      method: 'post',
      url: `${ELIXIR_API}/budgets/${budgetId}/reset`,
      headers: headers(),
    }).then(() => {
      window.location.reload();
    });
  };
};

// Same thing as refreshBudget, but doesn't need the entire budget object. Instead just takes an ID
export const refreshBudgetById = (budgetId) => {
  return (dispatch) => {
    return axios({
      method: 'get',
      url: `${ELIXIR_API}/budgets/${budgetId}`,
      headers: headers(),
    }).then((res) => {
      return dispatch(budgetFetchSuccess(res.data.data));
    });
  };
};

export const getBudgetById = (budgetId, accountsOrder) => {
  return (dispatch) => {
    axios({
      method: 'get',
      url: `${ELIXIR_API}/budgets/${budgetId}`,
      headers: headers(),
    }).then((res) => {
      dispatch(budgetFetchSuccess(res.data.data));
      dispatch(fetchAccounts(budgetId, accountsOrder));
    });
  };
};

export const fetchDefaultBudget = () => {
  return (dispatch) => {
    dispatch(budgetIsLoading(true));
    // const firstDayOfCurrentMonth = moment().startOf('month').format('MM-DD-YYYY');
    // const lastDayOfCurrentMonth = moment().endOf('month').format('MM-DD-YYYY');
    // const query = `?startdate=${firstDayOfCurrentMonth}&enddate=${lastDayOfCurrentMonth}`;

    // This will only pull the basic budget information like name and budget id
    // need to add a "current_budget" or something to user so that we know which budget to pull from
    // since we wont rely on dates anymore
    // axios.get(`${ELIXIR_API}/budgets${query}`, {headers: headers()})
    //   .then((res) => {
    //     dispatch(budgetIsLoading(false));

    //     return res;
    //   })
    //   .then((res) => {
    //     dispatch(budgetFetchSuccess(res.data.data));
    //   })
  };
};

export const updateBudgetUnallocatedAmount = (budget, amount) => {
  return (dispatch) => {
    axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budget.id}`,
      headers: headers(),
      data: {
        budget: {
          unassigned: amount,
        },
      },
    }).then((res) => {
      dispatch(fetchCategories(budget.id));
      const combinedBudgetDetails = {
        ...budget,
        ...res.data.data,
      };

      dispatch(refreshBudget(combinedBudgetDetails));
    });
  };
};

export const fetchCategories = (budgetId, categoriesOrder) => {
  return (dispatch) => {
    axios
      .get(`${ELIXIR_API}/budgets/${budgetId}/sections`, { headers: headers() })
      .then(({ data: res }) => {
        if (!categoriesOrder) {
          const initialOrder = res.data.map((category) => category.id);

          saveCategoryGroupReorder(initialOrder, budgetId);
          dispatch(fetchBudget(budgetId));

          dispatch({
            type: CATEGORIES_RECEIVED,
            payload: res.data,
          });
        } else {
          const reorderedCategories = sortByOrderOfIds(
            res.data,
            categoriesOrder
          );

          dispatch({
            type: CATEGORIES_RECEIVED,
            payload: reorderedCategories,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// export const addCategory = () => {
//   return (dispatch) => {
//     dispatch({
//       type: CATEGORY_ADDED,
//       payload: {
//         id: uuid(),
//       },
//     });
//   };
// };

export const addCategoryGroup = (budgetId) => {
  return (dispatch) => {
    dispatch({
      type: BUDGET_ADD_CATEGORY_GROUP_ROW,
      payload: {
        id: uuid(),
        budget_id: budgetId,
        subcategories_order: [],
      },
    });
  };
};

export const setBudgetTable = (budgetTable) => {
  return (dispatch) => {
    dispatch({
      type: BUDGET_TABLE_SET,
      payload: budgetTable,
    });
  };
};

export const setCategoryAndAccountList = (fullList) => {
  return (dispatch) => {
    dispatch({
      type: CATEGORY_AND_ACCOUNT_LIST,
      payload: fullList,
    });
  };
};

export const setAccountList = (list) => {
  return (dispatch) => {
    dispatch({
      type: ACCOUNT_LIST,
      payload: list,
    });
  };
};

const getSections = (budgetId) => {
  return axios.get(`${ELIXIR_API}/budgets/${budgetId}/sections`, {
    headers: headers(),
  });
};

const getCategories = (budgetId) => {
  return axios.get(`${ELIXIR_API}/budgets/${budgetId}/categories`, {
    headers: headers(),
  });
};

export const deleteCategoryGroupRow = (budgetId, categoryGroupId) => {
  return (dispatch) => {
    dispatch({
      type: BUDGET_DELETE_CATEGORY_GROUP_ROW,
      categoryGroupId,
    });

    return axios({
      method: 'delete',
      url: `${ELIXIR_API}/budgets/${budgetId}/sections/${categoryGroupId}`,
      headers: headers(),
    }).then(() => {
      dispatch(fetchTimeframeSubcategories(budgetId));
    });
  };
};

export const deleteCategoryRow = (budgetId, categoryId) => {
  return (dispatch) => {
    dispatch({
      type: BUDGET_DELETE_CATEGORY_ROW,
      categoryId,
    });

    return axios({
      method: 'delete',
      url: `${ELIXIR_API}/budgets/${budgetId}/categories/${categoryId}`,
      headers: headers(),
    }).then(() => {
      dispatch(fetchTimeframeSubcategories(budgetId));
      dispatch(fetchAllTransactions(budgetId));
    });
  };
};

export const fetchSectionsWithCategories = (budgetId) => {
  return (dispatch) => {
    return axios.all([getSections(budgetId), getCategories(budgetId)]).then(
      axios.spread((sections, categories) => {
        const categoryTable = [];
        const budgetTable = [];

        const categoryList = categories.data.data.map((category, idx) => {
          categoryTable.push({
            value: category,
            label: category.name,
          });
        });

        const sectionList = sections.data.data.map((section, idx) => {
          budgetTable.push({
            label: section.name,
            options: [],
          });

          const categoriesToRender = categoryTable.filter((category) => {
            return category.value.section_id === section.id;
          });

          budgetTable[idx].options = categoriesToRender;
        });

        // thisMonthAndNextMonthInMMM

        // budgetTable.unshift({
        //   label: 'Inflow',
        //   options: [{ label: 'Left to Budget', value: 'Left to Budget' }],
        // });
        dispatch(setBudgetTable(budgetTable));
        dispatch(setCategoryAndAccountList(budgetTable));
      })
    );
  };
};

export const buildAccountList = (budgetId, currentAcctId, accounts) => {
  return (dispatch) => {
    const accountSections = ['Spending & Savings', 'Credit Cards & Loans'];
    const accountTable = [];
    const accountsList = [];

    const accountList = accounts.map((account) => {
      return accountTable.push({
        value: account,
        label: account.name,
      });
    });

    const sectionList = accountSections.map((acctSection, idx) => {
      accountsList.push({
        label: acctSection,
        options: [],
      });

      const accountsToRender = accountTable.filter((account) => {
        if (idx === 0) {
          return (
            (account.value.type === 'checking' ||
              account.value.type === 'cash' ||
              account.value.type === 'giftcard' ||
              account.value.type === 'spending' ||
              account.value.type === 'savings') &&
            account.value.id !== currentAcctId
          );
        }
        if (idx === 1) {
          return (
            account.value.type === 'creditcard' ||
            (account.value.type === 'debtother' &&
              account.value.id !== currentAcctId)
          );
        }
      });

      accountsList[idx].options = accountsToRender;
    });
    dispatch(setAccountList(accountsList));
  };
};

export const fetchSettings = (budgetId) => {
  return (dispatch) => {
    axios
      .get(`${ELIXIR_API}/budgets/${budgetId}/settings`, { headers: headers() })
      .then((res) => {
        return dispatch({
          type: SETTINGS_RECEIVED,
          payload: res.data.data[0],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const updateSubcategoryParentId = (
  budgetId,
  subcategoryId,
  categoryGroupId
) => {
  return (dispatch) => {
    dispatch({
      type: CATEGORY_UPDATE_PARENT_ID,
      subcategoryId,
      categoryGroupId,
    });

    axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budgetId}/categories/${subcategoryId}`,
      headers: headers(),
      data: {
        category: {
          section_id: categoryGroupId,
        },
      },
    }).then(() => {
      // dispatch(fetchBudget(budgetId));
    });
  };
};

export const updateSubcategoryOrderInGroup = (
  budgetId,
  categoryGroupId,
  subcategories,
  subcategoriesOrder
) => {
  return async (dispatch) => {
    let subcatsToUpdate;

    if (!subcategoriesOrder) {
      subcatsToUpdate = subcategories.map((subcat, index) => ({
        category_id: subcat.id,
        section_id: subcat.section_id,
        index,
      }));
    }

    await axios({
      method: 'post',
      url: `${ELIXIR_API}/budgets/${budgetId}/categories/order`,
      headers: headers(),
      data: {
        changes: subcatsToUpdate,
      },
    }).then(() => {});
  };
};

export const removeCCAccountFromOrder = (budgetId, updatedAccountList) => {
  return async (dispatch) => {
    dispatch(updateCCOrderByList(updatedAccountList));

    await axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budgetId}`,
      headers: headers(),
      data: {
        budget: {
          cc_order: updatedAccountList,
        },
      },
    });
  };
};

export const updateCCOrderInGroup = (
  budgetId,
  creditCards,
  creditCardsOrder
) => {
  return async (dispatch) => {
    let creditCardsToUpdate;

    if (!creditCardsOrder) {
      creditCardsToUpdate = creditCards.map((cc) => cc.id);
    } else {
      creditCardsToUpdate = creditCardsOrder;
    }

    await axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budgetId}`,
      headers: headers(),
      data: {
        budget: {
          cc_order: creditCardsToUpdate,
        },
      },
    });
  };
};

// Saves a subcategory's order under a group/section
export const updateSingleSubcategoryOrderInGroup = (
  budgetId,
  categoryId,
  sectionId,
  newIndex
) => {
  return async (dispatch) => {
    const updatedSubcatOrder = {
      category_id: categoryId,
      section_id: sectionId,
      index: newIndex,
    };

    await axios({
      method: 'post',
      url: `${ELIXIR_API}/budgets/${budgetId}/categories/order`,
      headers: headers(),
      data: {
        changes: [updatedSubcatOrder],
      },
    });
  };
};

export const updateSubcategoryOrderInGroupLocally = (
  categoryGroup,
  subcategories
) => {
  return async (dispatch) => {
    const listOfSubcatIds = subcategories.map((subcat) => subcat.id);

    const updatedCatGroup = {
      ...categoryGroup,
      subcategories_order: listOfSubcatIds,
    };

    dispatch({
      type: CATEGORY_GROUP_UPDATE_SUBCAT_ORDER,
      updatedCatGroup,
    });
  };
};

export const updateCCOrderByList = (creditCardsListByIds) => {
  return async (dispatch) => {
    dispatch({
      type: BUDGET_UPDATE_CREDIT_CARD_ORDER,
      listOfCCIds: creditCardsListByIds,
    });
  };
};

export const updateCCOrderInGroupLocally = (creditCards) => {
  return async (dispatch) => {
    const listOfCCIds = creditCards.map((cc) => cc.id);

    dispatch({
      type: BUDGET_UPDATE_CREDIT_CARD_ORDER,
      listOfCCIds,
    });
  };
};

export const saveInitialSettings = (userId, budgetId) => {
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${ELIXIR_API}/budgets/${budgetId}/settings`,
      headers: headers(),
      data: {
        user_id: userId,
        budget_id: budgetId,
      },
    }).then(() => {
      return dispatch(fetchSettings(budgetId));
    });
  };
};

export const saveSettings = (
  budgetId,
  settingsId,
  prevSettings,
  newSettings
) => {
  return (dispatch) => {
    const updatedSettings = {
      ...prevSettings,
      ...newSettings,
      budget_id: budgetId,
    };

    return axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budgetId}/settings/${settingsId}`,
      headers: headers(),
      data: { setting: { ...updatedSettings } },
    }).then(() => {
      return dispatch(fetchSettings(budgetId));
    });
  };
};

// similar to the above but doesn't need the response to update state
export const updateSettings = (
  budgetId,
  settingsId,
  prevSettings,
  newSettings
) => {
  return (dispatch) => {
    const updatedSettings = {
      ...prevSettings,
      ...newSettings,
      budget_id: budgetId,
    };

    dispatch({
      type: SETTINGS_RECEIVED,
      payload: updatedSettings,
    });

    return axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budgetId}/settings/${settingsId}`,
      headers: headers(),
      data: { setting: { ...updatedSettings } },
    });
  };
};

export const saveCategoryGroup = (category, budgetId) => {
  return (dispatch) => {
    if (!category.created) {
      axios({
        method: 'post',
        url: `${ELIXIR_API}/budgets/${budgetId}/sections`,
        headers: headers(),
        data: {
          id: category.id,
          name: category.name,
          budget_id: budgetId,
        },
      }).then((res) => {
        const refreshedCategory = {
          ...category,
          created: res.data.data.created,
        };

        dispatch(updateCategory(refreshedCategory));
        dispatch(fetchCategories(budgetId));
        // dispatch(fetchSectionsWithCategories(budgetId));
      });
    } else {
      dispatch({
        type: CATEGORY_GROUP_SAVED,
        payload: category,
      });

      axios({
        method: 'patch',
        url: `${ELIXIR_API}/budgets/${budgetId}/sections/${category.id}`,
        headers: headers(),
        data: {
          section: {
            name: category.name,
          },
        },
      }).then((res) => {
        // dispatch({
        //   type: CATEGORY_SAVED,
        //   payload: category,
        // });
        // dispatch(fetchSectionsWithCategories(budgetId));
      });
    }
  };
};

export function updateCategory(refreshedCategory) {
  return {
    type: UPDATE_CATEGORY,
    payload: refreshedCategory,
  };
}

export const fetchSubcategories = (budgetId, subcategoriesOrder) => {
  return async (dispatch) => {
    await axios
      .get(`${ELIXIR_API}/budgets/${budgetId}/categories`, {
        headers: headers(),
      })
      .then(({ data: res }) => {
        dispatch({
          type: SUBCATEGORIES_RECEIVED,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const addCategory = (categoryGroupId, budgetId) => {
  return (dispatch) => {
    let generatedCategoryId = uuid();

    dispatch({
      type: BUDGET_ADD_CATEGORY_ROW,
      // add the uuid here so that we can go POST by null name instead of is
      payload: {
        id: generatedCategoryId,
        budget_id: budgetId,
        name: null,
        budgeted: null,
        section_id: categoryGroupId,
        date: null,
      },
    });

    // dispatch({
    //   type: CATEGORY_GROUP_ADD_TEMP_CATEGORY_TO_ORDER,
    //   categoryId: generatedCategoryId,
    //   categoryGroupId,
    // });
  };
};

export const saveCategory = (subcategory, budgetId, subcategoriesOrder) => {
  return async (dispatch) => {
    if (!subcategory.created) {
      await axios({
        method: 'post',
        url: `${ELIXIR_API}/budgets/${budgetId}/categories`,
        headers: headers(),
        data: {
          id: subcategory.id,
          name: subcategory.name,
          budget_id: budgetId,
          section_id: subcategory.section_id,
        },
      }).then((res) => {
        // dispatch(
        //   fetchSubcategories(budgetId, [
        //     ...subcategoriesOrder,
        //     res.data.data.id,
        //   ])
        // );
        dispatch({
          type: CATEGORY_UPDATED,
          payload: res.data.data,
        });
        dispatch(fetchSectionsWithCategories(budgetId));
      });
    } else {
      await axios({
        method: 'patch',
        url: `${ELIXIR_API}/budgets/${budgetId}/categories/${subcategory.id}`,
        headers: headers(),
        data: {
          category: {
            name: subcategory.name,
            due_date: subcategory.due_date,
            section_id: subcategory.section_id,
          },
        },
      }).then((res) => {
        // dispatch({
        //   type: SUBCATEGORY_SAVED,
        //   payload: subcategory,
        // });
        dispatch({
          type: CATEGORY_UPDATED,
          payload: res.data.data,
        });
        dispatch(fetchSectionsWithCategories(budgetId));
        dispatch(fetchAllTransactions(budgetId));
      });
    }

    dispatch({
      type: CATEGORY_GROUP_ADD_TEMP_CATEGORY_TO_ORDER,
      categoryId: subcategory.id,
      categoryGroupId: subcategory.section_id,
    });
  };
};

export const saveCategoryNotes = (
  categoryId,
  notes,
  budgetId,
  subcategoriesOrder
) => {
  return (dispatch) => {
    if (!categoryId) {
      // axios({
      //   method: 'post',
      //   url: `${ELIXIR_API}/budgets/${budgetId}/categories`,
      //   headers: headers(),
      //   data: {
      //     budget_id: budgetId,
      //     section_id: categoryId,
      //     notes,
      //   },
      // }).then((res) => {
      //   dispatch(fetchSubcategories(budgetId, subcategoriesOrder));
      //   dispatch(fetchSectionsWithCategories(budgetId));
      // });
    } else {
      axios({
        method: 'patch',
        url: `${ELIXIR_API}/budgets/${budgetId}/categories/${categoryId}`,
        headers: headers(),
        data: {
          category: {
            notes,
          },
        },
      }).then((res) => {
        dispatch(fetchSubcategories(budgetId, subcategoriesOrder));
        dispatch(fetchSectionsWithCategories(budgetId));
      });
    }
  };
};

export const saveDebAccttNotes = (accountId, budgetNotes, budgetId) => {
  return (dispatch) => {
    dispatch({
      type: ACCOUNT_UPDATE_NOTES,
      accountId,
      budgetNotes,
    });

    axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budgetId}/accounts/${accountId}`,
      headers: headers(),
      data: {
        account: {
          budget_notes: budgetNotes,
        },
      },
    });
  };
};

export const timeframesReceived = (payload) => {
  const ltbBreakdownByMonth = payload.reduce((timeframes, currentTimeframe) => {
    const timeframeMonthYear = moment(currentTimeframe.firstOfMonth).format(
      'MMYYYY'
    );
    timeframes[timeframeMonthYear] = {
      incomeForMonth: null,
    };
    return timeframes;
  }, {});

  return (dispatch) => {
    // dispatch({
    //   type: SET_INITIAL_LTB_BREAKDOWN_BY_MONTH,
    //   ltbBreakdownByMonth,
    // });

    dispatch({
      type: TIMEFRAMES_RECEIVED,
      payload,
    });
  };
};

export const setFirstAndLastTimeframe = (budgetId, tfSubcategories) => {
  const filteredTimes = tfSubcategories.map((t) =>
    moment(t.timeframe, 'MMYYYY')
  );
  const maxDate = moment.max(filteredTimes).format('MM-DD-YYYY');
  const minDate = moment
    .min(filteredTimes)
    .subtract(6, 'months')
    .format('MM-DD-YYYY');

  return async (dispatch) => {
    const res = await api.get(`${FASTAPI_API}/transactions/left_to_budget`, {
      params: {
        start_date: minDate,
        end_date: maxDate,
      },
    });
    return dispatch(timeframesReceived(res.data));
  };
};

export const groupTimeframeSubsByMonth = (dispatch, timeframeSubcategories) => {
  const timeframeSubsGroupedByMonth = timeframeSubcategories.reduce(
    (timeframeSubsList, currentTimeframeSub) => {
      const timeframeSubMonth = currentTimeframeSub.timeframe;

      timeframeSubsList[timeframeSubMonth] =
        timeframeSubsList[timeframeSubMonth] || [];
      timeframeSubsList[timeframeSubMonth].push(currentTimeframeSub);

      return timeframeSubsList;
    },
    {}
  );

  dispatch({
    type: SET_INITIAL_TIMEFRAME_SUBCATEGORIES_BY_MONTH,
    timeframeSubcategoriesByMonth: timeframeSubsGroupedByMonth,
  });
};

export const fetchInitialLtbBreakdownByMonth = (budgetId) => {
  const userDate = new Date();
  const userDateInISO = userDate.toISOString();

  return async (dispatch) => {
    await axios
      .get(
        `${ELIXIR_API}/budgets/${budgetId}/ltb_breakdown?date=${userDateInISO}`,
        {
          headers: headers(),
        }
      )
      .then((res) => {
        dispatch({
          type: SET_INITIAL_LTB_BREAKDOWN_BY_MONTH,
          ltbBreakdownByMonth: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

const filteredUniqueTfc = (tfcList) => {
  return tfcList.reduce((acc, current) => {
    const x = acc.find(
      (tfc) =>
        tfc.category_id === current.category_id &&
        tfc.timeframe === current.timeframe
    );
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
};

export const fetchTimeframeSubcategories = (budgetId) => {
  const userDate = new Date();
  const userDateInISO = userDate.toISOString();

  return async (dispatch) => {
    await axios
      .get(
        `${ELIXIR_API}/budgets/${budgetId}/timeframe_categories?date=${userDateInISO}`,
        {
          headers: headers(),
        }
      )
      .then((res) => {
        dispatch({
          type: TIMEFRAME_SUBCATEGORIES_RECEIVED,
          tfc: filteredUniqueTfc(res.data.data[0]),
          cc_tfc: res.data.data[1],
        });

        groupTimeframeSubsByMonth(dispatch, res.data.data[0]);

        dispatch(setFirstAndLastTimeframe(budgetId, res.data.data[0]));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const saveTimeframeSubcategory = (timeframeSubcategory, budgetId) => {
  return async (dispatch) => {
    dispatch({
      type: REFRESH_LTB_BREAKDOWN_BY_MONTH,
    });

    const timeframeSubcategoryModel = {
      budgeted: timeframeSubcategory.budgeted || '0',
      date: timeframeSubcategory.date,
      timeframe: timeframeSubcategory.timeframe,
      section_id: timeframeSubcategory.section_id,
      subcategoryId: timeframeSubcategory.subcategoryId,
      category_id: timeframeSubcategory.category_id,
      categoryId: timeframeSubcategory.categoryId,
      budget_id: timeframeSubcategory.budgetId,
      is_cc: timeframeSubcategory.is_cc || false,
      cc_account_id: timeframeSubcategory.cc_account_id,
    };

    // dispatch({
    //   type: TIMEFRAME_SUBCATEGORY_SAVED,
    //   payload: timeframeSubcategory,
    // });

    //Need to save timeframe subcategories with the optimistic UUID
    if (!timeframeSubcategory.id) {
      dispatch({
        type: TIMEFRAME_SUBCATEGORY_SAVED,
        payload: timeframeSubcategoryModel,
      });

      await axios({
        method: 'post',
        url: `${ELIXIR_API}/budgets/${budgetId}/timeframe_categories`,
        headers: headers(),
        data: timeframeSubcategoryModel,
      }).then((res) => {
        const refreshedTimeframeSubcategory = {
          ...timeframeSubcategoryModel,
          id: res.data.data.id,
        };

        mixpanel.track('Create new budget in month/category');

        //need to replace this fetch to calculate LTB without waiting for DB
        dispatch(updateTimeframeSubcategory(refreshedTimeframeSubcategory));

        dispatch({
          type: TIMEFRAME_SUBCATEGORY_UPDATE_BY_MONTH_WITH_ID,
          month: timeframeSubcategory.timeframe,
          updatedTimeframeSubcategory: refreshedTimeframeSubcategory,
        });

        dispatch({
          type: REFRESH_LTB_BREAKDOWN_BY_MONTH,
        });
      });
    } else {
      const refreshedTimeframeSubcategory = {
        ...timeframeSubcategoryModel,
        id: timeframeSubcategory.id,
      };

      dispatch({
        type: TIMEFRAME_SUBCATEGORY_UPDATE_BY_MONTH,
        month: timeframeSubcategory.timeframe,
        updatedTimeframeSubcategory: refreshedTimeframeSubcategory,
      });

      dispatch({
        type: TIMEFRAME_SUBCATEGORY_UPDATE_BY_MONTH_WITH_ID,
        month: timeframeSubcategory.timeframe,
        updatedTimeframeSubcategory: refreshedTimeframeSubcategory,
      });

      dispatch({
        type: TIMEFRAME_SUBCATEGORY_SAVED,
        payload: timeframeSubcategory,
      });

      await axios({
        method: 'patch',
        url: `${ELIXIR_API}/budgets/${budgetId}/timeframe_categories/${timeframeSubcategory.id}`,
        headers: headers(),
        data: {
          timeframe_category: {
            budgeted: timeframeSubcategory.budgeted,
            date: timeframeSubcategory.date,
            category_id: timeframeSubcategory.category_id,
            section_id: timeframeSubcategory.section_id,
            is_cc: timeframeSubcategory.is_cc || false,
            cc_account_id: timeframeSubcategory.cc_account_id,
          },
        },
      }).then((res) => {
        dispatch(updateTimeframeSubcategory(res.data.data));
        mixpanel.track('Update budget in month/category');
      });
    }
    if (timeframeSubcategory.is_cc) {
      dispatch(fetchTimeframeSubcategories(budgetId));
    }
  };
};

export function updateTimeframeSubcategory(refreshedTimeframeSubcategory) {
  return {
    type: UPDATE_TIMEFRAME_SUBCATEGORY,
    payload: refreshedTimeframeSubcategory,
  };
}

export const saveBudget = (generatedBudget) => {
  return (dispatch) => {
    axios({
      method: 'post',
      url: `${ELIXIR_API}/budgets`,
      headers: headers(),
      data: {
        budget: generatedBudget,
      },
    }).then((res) => {
      dispatch(budgetFetchSuccess(res.data.data));
      dispatch(fetchCategories(res.data.data.id));
    });
  };
};

export const sectionSortChange = (newSectionOrder) => {
  return (dispatch) => {
    dispatch({
      type: SECTION_ORDER_CHANGE,
      payload: newSectionOrder,
    });
  };
};

// export const categorySortChange = (newCategoryOrder) => {
//   return (dispatch) => {
//     dispatch({
//       type: CATEGORY_ORDER_CHANGE,
//       payload: newCategoryOrder,
//     });
//   };
// };

export const subcategorySortChange = (
  newCategoryOrder,
  newListByIds,
  budgetId
) => {
  return (dispatch) => {
    dispatch({
      type: SUBCATEGORY_ORDER_CHANGE,
      payload: newCategoryOrder,
    });

    saveSubcategoryReorder(listOrderByIds(newCategoryOrder), budgetId);
  };
};

export async function saveCategoryGroupReorder(newListByIds, budgetId) {
  try {
    await axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budgetId}`,
      headers: headers(),
      data: {
        budget: {
          categories_order: newListByIds,
        },
      },
    });
  } catch (error) {
    throw await error.json();
  }
}

export async function saveSubcategoryReorder(newListByIds, budgetId) {
  try {
    await axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budgetId}`,
      headers: headers(),
      data: {
        budget: {
          subcategories_order: newListByIds,
        },
      },
    });
  } catch (error) {
    throw await error.json();
  }
}

export const accountSortChange = (newAccountOrder, newListByIds, budgetId) => {
  return (dispatch) => {
    dispatch({
      type: ACCOUNT_ORDER_CHANGE,
      payload: newAccountOrder,
    });

    // dispatch({
    //   type: ACCOUNT_LIST_BY_IDS_UPDATE,
    //   payload: newListByIds,
    // });

    saveAccountReorder(listOrderByIds(newAccountOrder), budgetId);
  };
};

export async function saveAccountReorder(newListByIds, budgetId) {
  try {
    await axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budgetId}`,
      headers: headers(),
      data: {
        budget: {
          accounts_order: newListByIds,
        },
      },
    });
  } catch (error) {
    throw await error.json();
  }
}

export const updateCategoryUsedAmount = (budgetId, category, amount) => {
  return (dispatch) => {
    axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budgetId}/categories/${category.id}`,
      headers: headers(),
      data: {
        category: {
          spent: amount,
        },
      },
    }).then((res) => {
      dispatch(fetchCategories(budgetId));
    });
  };
};

export const renameBudget = (budget) => {
  return (dispatch) => {
    axios({
      method: 'patch',
      url: `${ELIXIR_API}/budgets/${budget.id}`,
      headers: headers(),
      data: {
        budget: {
          name: budget.name,
        },
      },
    }).then((res) => {
      dispatch({
        type: BUDGET_RENAMED,
        payload: budget,
      });
    });
  };
};

export const monthNext = () => {
  return (dispatch, getState) => {
    const { activeMonths } = getState();

    dispatch({
      type: MONTH_NEXT,
      stateOfActiveMonths: activeMonths,
    });
  };
};

export const monthPrevious = () => {
  return (dispatch, getState) => {
    const { activeMonths } = getState();

    dispatch({
      type: MONTH_PREVIOUS,
      stateOfActiveMonths: activeMonths,
    });
  };
};
