import React, { memo } from 'react';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import BudgetTableCategoryGroupMonth from './budget-table-category-group-month';
import BudgetTableCategoryGroupDropdown from './budget-table-category-group-dropdown';
import EditableInput from '../../../common/input/editable-input';
import { isMobile } from '../../../../utilities/helpers';

const BudgetTableCategoryGroupView = ({
  addNewCategory,
  categoryGroupDetails,
  categoriesExpanded,
  children,
  handleSaveField,
  index,
  ref,
  monthsDisplayed,
  collapseCategories,
  onDeleteCategoryGroupRow,
  settings,
}) => {
  const { name } = categoryGroupDetails;

  return (
    <Draggable draggableId={categoryGroupDetails.id} index={index}>
      {(provided, snapshot) => (
        <section
          className={classNames('budget-category-group', {
            'category-group-is-dragging': snapshot.isDragging,
          })}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="budget-table-row category-group-row">
            <i
              className={
                categoriesExpanded
                  ? 'far fa-angle-down fa-fw fa-lg button-icon collapse-icon'
                  : 'far fa-angle-right fa-fw fa-lg button-icon collapse-icon'
              }
              title="Expand/collapse categories"
              onClick={collapseCategories}
            />
            <div className="budget-column-name">
              <EditableInput
                fieldName="name"
                placeholder="Category Group Name"
                value={name || ''}
                onSave={handleSaveField}
              />
              <i
                className="fas fa-plus fa-fw button-icon"
                onClick={addNewCategory}
              />
            </div>
            {!isMobile() && !settings.hide_due_date && (
              <div className="budget-column-date" />
            )}
            {monthsDisplayed.map((monthToDisplay, index) => {
              return (
                <BudgetTableCategoryGroupMonth
                  key={monthToDisplay}
                  isMiddleColumn={index === 1}
                  categoryGroupId={categoryGroupDetails.id}
                  monthToDisplay={monthToDisplay}
                />
              );
            })}
            <BudgetTableCategoryGroupDropdown
              deleteCategoryGroupRow={onDeleteCategoryGroupRow}
            />
          </div>
          <div className="budget-table-category-body">{children}</div>
        </section>
      )}
    </Draggable>
  );
};

export default memo(BudgetTableCategoryGroupView);
