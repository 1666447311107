import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as accountActions from '../../actions/account';
import SummaryView from './summary-view';

const SummaryContainer = (props) => (
  <SummaryView settings={props.settings} budgetDetails={props.budgetDetails} />
);

const mapStateToProps = ({ budgetDetails, settings }) => ({
  budgetDetails,
  settings,
});

export default connect(mapStateToProps, null)(SummaryContainer);
