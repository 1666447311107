import moment from 'moment';
import {
  ACCOUNTS_BY_ID_SAVE_TRANSACTION,
  ACCOUNTS_BY_ID_UPDATE_TRANSACTION,
  SET_TRANSACTIONS_BY_MONTH_AND_CATEGORY,
} from '../action-list';

export default function(state = {}, action) {
  switch (action.type) {
    case SET_TRANSACTIONS_BY_MONTH_AND_CATEGORY:
      return { ...action.groupedByMonthCategory };

    case ACCOUNTS_BY_ID_UPDATE_TRANSACTION:
      if (
        action.payload.include_now !== undefined ||
        action.payload.include_now === null
      ) {
        const newState = { ...state };

        const transactionDate = moment(action.payload.date).format('MMYYYY');

        const transactionIndex =
          newState[transactionDate] &&
          newState[transactionDate][action.payload.category_id].findIndex(
            (obj) => obj.id === action.payload.id
          );

        newState[transactionDate][action.payload.category_id][
          transactionIndex
        ] = {
          ...newState[transactionDate][action.payload.category_id][
            transactionIndex
          ],
          ...action.payload,
        };

        return newState;
      }

      return state;

    case ACCOUNTS_BY_ID_SAVE_TRANSACTION:
      // TODO: refactor this horrific mess
      const currentState = { ...state } || {};
      const transaction = action.payload;
      const transactionDateInMMYYYY = moment(transaction.date).format('MMYYYY');
      let transactionCategory;

      if (currentState[transactionDateInMMYYYY] === undefined) {
        currentState[transactionDateInMMYYYY] = {};
      }

      if (transaction.category === 'Left to Budget') {
        transactionCategory = transaction.category;
      } else {
        transactionCategory = transaction.category_id || 'uncategorized';
      }

      if (!currentState[transactionDateInMMYYYY][transactionCategory]) {
        currentState[transactionDateInMMYYYY] = {
          ...currentState[transactionDateInMMYYYY],
          [transactionCategory]: [],
        };
      }

      const transactionIdx =
        currentState[transactionDateInMMYYYY] &&
        currentState[transactionDateInMMYYYY][transactionCategory].findIndex(
          (obj) => obj.id === action.payload.id
        );

      if (transactionIdx === -1) {
        currentState[transactionDateInMMYYYY][transactionCategory].push(
          transaction
        );
      } else {
        currentState[transactionDateInMMYYYY][transactionCategory][
          transactionIdx
        ] = transaction;
      }

      return currentState;

    default:
      return state;
  }
}
