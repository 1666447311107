import React from 'react';
import cn from 'classnames';
import { Text } from '@nextui-org/react';
import { Heading } from '../ui/heading';
import { Flex } from '../ui/box';

interface PageHeaderProps {
  title: string;
  subtitle?: string;
}

function PageHeader({ title, subtitle }: PageHeaderProps) {
  return (
    <Flex className={cn('flex-col')}>
      <Heading h2>{title}</Heading>
      {subtitle && <Text css={{ color: '$gray700' }}>{subtitle}</Text>}
    </Flex>
  );
}

export { PageHeader };
