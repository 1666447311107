import React, { memo } from 'react';
import './transactions-header.css';

const TransactionsHeader = ({ accountDetails, width, inflowFirst }) => {
  const [xFlow, yFlow] = inflowFirst
    ? ['inflow', 'outflow']
    : ['outflow', 'inflow'];
  return (
    <div className="transaction-header" style={{ width }}>
      <div className="transaction-row transaction-header-row transaction-row-readonly">
        <div className="transaction-column date-column">Date</div>
        <div className="transaction-column payee-column">Payee</div>
        {accountDetails && !accountDetails.off_budget && (
          <div className="transaction-column category-column">Category</div>
        )}
        <div className="transaction-column notes-column">Notes</div>
        <div className={`transaction-column amount-column ${xFlow}-column`}>
          {xFlow}
        </div>
        <div className={`transaction-column amount-column ${yFlow}-column`}>
          {yFlow}
        </div>
        <div className="transaction-column approved-column" />
      </div>
    </div>
  );
};

export default memo(TransactionsHeader);
