import React from 'react';
import { connect } from 'react-redux';
import {
  clearSearchString,
  updateAccount,
  deleteAccount,
} from '../../../../actions/account';
import { removeCCAccountFromOrder } from '../../../../actions/budget';
import SidebarAccountsContainer from './sidebar-accounts-container';

const SidebarAccountsConnector = ({
  accounts,
  accountsById,
  budgetDetails,
  clearSearchString,
  deleteAccount,
  removeCCAccountFromOrder,
  searchString,
  updateAccount,
  settings,
}) => {
  return (
    <SidebarAccountsContainer
      accounts={accounts}
      accountsById={accountsById}
      budgetDetails={budgetDetails}
      clearSearchString={clearSearchString}
      deleteAccount={deleteAccount}
      removeCCAccountFromOrder={removeCCAccountFromOrder}
      searchString={searchString}
      updateAccount={updateAccount}
      settings={settings}
    />
  );
};

const mapStateToProps = ({
  accounts,
  accountsById,
  budgetDetails,
  searchString,
  settings,
}) => ({
  accounts,
  accountsById,
  budgetDetails,
  searchString,
  settings,
});

export default connect(
  mapStateToProps,
  { clearSearchString, updateAccount, deleteAccount, removeCCAccountFromOrder }
)(SidebarAccountsConnector);
