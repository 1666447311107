import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import ButtonPrimary from '../common/button/button-primary';
import ButtonSecondary from '../common/button/button-secondary';
import Select from 'react-select';
import { accountTypeOptions } from '../../utilities/settings-options';

import './new-account-form.css';

const customSelectStyles = {
  option: (base, state) => {
    return {
      ...base,
      backgroundColor: state.isFocused ? '#F4F5F6' : '',
      padding: '4px 8px',
      color: state.isSelected ? '#565a5c' : '#565a5c',
    };
  },
  control: (base, state) => ({
    ...base,
    justifyContent: 'center',
  }),
  placeholder: (base, state) => ({
    ...base,
  }),
  container: (base, state) => ({
    ...base,
    justifyContent: 'center',
  }),
  groupHeading: (base, state) => ({
    ...base,
    borderBottom: '1px solid #eaeaea',
    borderTop: '1px solid #eaeaea',
    padding: '4px 8px',
    // textTransform: 'uppercase',
    fontWeight: 600,
    color: '#384f66',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  }),
  group: (base, state) => ({
    paddingTop: '0 !important',
    marginTop: '0 !important',
  }),
  input: (base, state) => ({
    ...base,
    justifyContent: 'center',
    textAlign: 'center',
  }),
  menu: (base, state) => ({
    ...base,
    width: 'auto',
    wrap: 'none !important',
  }),
  menuList: (base, state) => ({
    ...base,
    width: 'auto',
    minWidth: '90px',
    wrap: 'none !important',
    paddingTop: '0 !important',
    marginTop: '0 !important',
  }),
  singleValue: (base, state) => ({
    ...base,
    justifyContent: 'center',
  }),
};

class NewAccountForm extends Component {
  state = {
    name: '',
    current_bal: '',
    type: 'checking',
    off_budget: false,
  };

  componentDidMount() {
    this.nameField.focus();
  }

  handleNameChange = (evt) => {
    const name = evt.target.value;

    this.setState({
      name,
    });
  };

  handleBalanceChange = ({ formattedValue, value, floatValue }, evt) => {
    this.setState({
      current_bal: floatValue,
    });
  };

  handleInterestChange = ({ formattedValue, value, floatValue }, evt) => {
    this.setState({
      apr: floatValue,
    });
  };

  handleMinPmtChange = ({ formattedValue, value, floatValue }, evt) => {
    this.setState({
      min_payment: floatValue,
    });
  };

  handleTypeChange = (typeSelected) => {
    let off_budget = false;
    const type = typeSelected.value;

    if (
      type === 'mortgage' ||
      type === 'investment' ||
      type === 'offbudget_asset' ||
      type === 'offbudget_liability'
    ) {
      off_budget = true;
      this.setState({ off_budget });
    }

    if (type !== 'creditcard' || type !== 'debtother') {
      this.setState({
        off_budget,
        min_payment: null,
        apr: null,
      });
    }

    this.setState({
      off_budget,
      type,
    });
  };

  handleFocus = ({ target }) => target.select();

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      this.onSaveAccount();
    }
  };

  onSaveAccount = () => {
    this.props.onSaveAccount(this.state);
    this.props.onClose();
  };

  render() {
    const { settings } = this.props;
    const { type } = this.state;

    return (
      <div className="form-body new-account-form">
        <div className="form-group">
          <label className="input-label" htmlFor="budget-name">
            Account Name
          </label>
          <input
            className="input-field"
            name="budget-name"
            placeholder="Enter account name"
            ref={(input) => (this.nameField = input)}
            // value={this.state.name || ''}
            // onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleNameChange}
          />
        </div>

        <div className="form-group form-column">
          <div className="form-split-column">
            <label className="input-label" htmlFor="account-types">
              Account Type
            </label>
            <Select
              className="input-select"
              classNamePrefix="select"
              isClearable={true}
              isSearchable={true}
              backspaceRemovesValue={true}
              styles={customSelectStyles}
              placeholder="---"
              // defaultMenuIsOpen={true}
              onChange={this.handleTypeChange}
              components={{ ClearIndicator: null, IndicatorSeparator: null }}
              name="account-types"
              options={accountTypeOptions}
            />
          </div>
          <div className="form-split-column">
            <label className="input-label" htmlFor="budget-name">
              Current Balance
            </label>
            <NumberFormat
              className="input-field"
              inputmode="decimal"
              onValueChange={this.handleBalanceChange}
              decimalScale={2}
              allowNegative={true}
              onKeyDown={this.handleKeyDown}
              placeholder="0.00"
              prefix={settings.show_symbol ? `${settings.symbol}` : ''}
              thousandSeparator={settings.thousands}
              decimalSeparator={settings.decimal}
            />
          </div>
        </div>

        {type === 'creditcard' ||
          (type === 'debtother' && (
            <div className="form-group form-column">
              <div className="form-split-column">
                <label className="input-label">Interest Rate</label>
                <NumberFormat
                  className="input-field"
                  onValueChange={this.handleInterestChange}
                  onKeyDown={this.handleKeyDown}
                  placeholder="0%"
                  suffix="%"
                  thousandSeparator={settings.thousands}
                  decimalSeparator={settings.decimal}
                />
              </div>
              <div className="form-split-column">
                <label className="input-label">Minimum Payment</label>
                <NumberFormat
                  className="input-field"
                  onValueChange={this.handleMinPmtChange}
                  onKeyDown={this.handleKeyDown}
                  decimalScale={2}
                  placeholder="0.00"
                  prefix={settings.show_symbol ? `${settings.symbol}` : ''}
                  thousandSeparator={settings.thousands}
                  decimalSeparator={settings.decimal}
                />
              </div>
            </div>
          ))}

        <div className="form-controls-group">
          <ButtonSecondary onClick={this.props.onClose}>Cancel</ButtonSecondary>
          <ButtonPrimary
            onClick={this.onSaveAccount}
            // isDisabled={!this.state.name.length}
          >
            Save Account
          </ButtonPrimary>
        </div>
      </div>
    );
  }
}

export default NewAccountForm;
