import React from 'react';
import { AutoSizer } from 'react-virtualized';
import TransactionsVirtualizedList from './transactions-virtualized-list';
import { isMobile } from '../../../utilities/helpers';

function TransactionListContainer({
  accountsById,
  accountDetails,
  isSelectEnabled,
  onSelected,
  selectedTransactions,
  transactionBeingEdited,
  transactionList,
}) {
  return (
    <AutoSizer>
      {({ height, width }) => (
        <TransactionsVirtualizedList
          accountDetails={accountDetails}
          accountsById={accountsById}
          height={height}
          isMobile={isMobile()}
          isSelectEnabled={isSelectEnabled}
          onSelected={onSelected}
          width={width}
          selectedTransactions={selectedTransactions}
          transactionBeingEdited={transactionBeingEdited}
          transactions={transactionList}
        />
      )}
    </AutoSizer>
  );
}

export default TransactionListContainer;
