import React, { Component } from 'react';
import BudgetTableCCCategoryMonthView from './budget-table-cc-category-month-view';

class BudgetTableCCCategoryMonthContainer extends Component {
  render() {
    const {
      accountDetails,
      creditCardDetailsByMonth,
      creditCardTotalRemainingByMonth,
      isMiddleColumn,
      monthToDisplay,
      saveTimeframeSubcategory,
      settings,
    } = this.props;

    return (
      <BudgetTableCCCategoryMonthView
        accountDetails={accountDetails}
        creditCardDetailsByMonth={creditCardDetailsByMonth}
        creditCardTotalRemainingByMonth={creditCardTotalRemainingByMonth}
        isMiddleColumn={isMiddleColumn}
        monthToDisplay={monthToDisplay}
        saveTimeframeSubcategory={saveTimeframeSubcategory}
        settings={settings}
      />
    );
  }
}

export default BudgetTableCCCategoryMonthContainer;
