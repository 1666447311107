import React, { memo } from 'react';
import classNames from 'classnames';

import './modal-base.css';

const ModalBase = (props) => {
  const { children, className, onClose, title, ...extraProps } = props;

  return (
    <div className={classNames('modal-base', className)} {...extraProps}>
      <div className="modal-header">
        <span className="modal-title">{title}</span>
        <i
          className="fal fa-times-circle fa-2x fa-fw"
          onClick={() => onClose()}
        />
      </div>
      <div className="modal-body">{children}</div>
    </div>
  );
};

export default memo(ModalBase);
