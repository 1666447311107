import React from 'react';
import { connect } from 'react-redux';
import {
  fetchInitialLtbBreakdownByMonth,
  fetchTimeframeSubcategories,
  renameBudget,
  resetBudget,
  saveSettings,
} from '../../../actions/budget';
import { logout } from '../../../actions/session';

import BudgetTitleDropdownView from './budget-title-dropdown-view';

const BudgetTitleDropdownRedux = (props) => {
  return (
    <BudgetTitleDropdownView
      accountsById={props.accountsById}
      budgetDetails={props.budgetDetails}
      currentUser={props.currentUser}
      logOut={props.logout}
      fetchInitialLtbBreakdownByMonth={props.fetchInitialLtbBreakdownByMonth}
      fetchTimeframeSubcategories={props.fetchTimeframeSubcategories}
      resetBudget={props.resetBudget}
      saveSettings={props.saveSettings}
      settings={props.settings}
      subscriptionDetails={props.subscriptionDetails}
      renameBudget={props.renameBudget}
    />
  );
};

const mapStateToProps = ({
  accountsById,
  budgetDetails,
  session,
  settings,
  subscriptionDetails,
}) => ({
  accountsById,
  budgetDetails,
  settings,
  subscriptionDetails,
  currentUser: session.currentUser,
});

export default connect(
  mapStateToProps,
  {
    fetchInitialLtbBreakdownByMonth,
    fetchTimeframeSubcategories,
    logout,
    renameBudget,
    resetBudget,
    saveSettings,
  }
)(BudgetTitleDropdownRedux);
