import React, { Fragment, Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Portal } from 'react-portal';
import classNames from 'classnames';
import ModalBase from '../../../common/modal/modal-base';
import ButtonWarning from '../../../common/button/button-warning';
import ButtonLink from '../../../common/button/button-link';
import RenameAccountForm from '../../../forms/rename-account-form';

interface Props {
  accountId: string;
  accountDetails: any;
  budgetDetails: any;
  deleteAccount: (accountId: string) => void;
  removeCCAccountFromOrder: any;
  updateAccount: any;
}

interface State {
  dropdownOpen: boolean;
}

class SidebarAccountDropdown extends Component<Props, State> {
  state = {
    dropdownOpen: false,
  };

  toggleDropdown = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const { dropdownOpen } = this.state;

    this.setState({ dropdownOpen: !dropdownOpen });
  };

  saveAccountRename = (acctId: any, accountDetails: any) => {
    const { id: budgetId, accounts_order: accountsOrder } =
      this.props.budgetDetails;

    this.props.updateAccount(acctId, budgetId, accountsOrder, accountDetails);
  };

  editAccountForm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ModalBase onClose={onClose} title={'Rename Account'}>
            <RenameAccountForm
              accountDetails={this.props.accountDetails}
              onSave={this.saveAccountRename}
              onClose={onClose}
            />
          </ModalBase>
        );
      },
    });
  };

  deleteAccountAlert = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ModalBase
            onClose={onClose}
            title={'Delete Account'}
            className="recurring-cancel"
          >
            <i
              className="alert-icon fas fa-exclamation-circle fa-fw fa-lg"
              style={{ color: '#c26e6b' }}
            />
            <div className="alert-text-wrapper">
              <span className="alert-title">
                Are you sure you want to delete this account?
              </span>
              <div
                className="has-text-right alert-button-container"
                style={{ marginTop: '24px' }}
              >
                <ButtonLink
                  className="button is-outlined"
                  onClick={() => onClose()}
                >
                  Cancel
                </ButtonLink>
                <ButtonWarning
                  className="button is-danger"
                  onClick={() => {
                    this.onDeleteAccount();
                    onClose();
                  }}
                >
                  {`Delete Account`}
                </ButtonWarning>
              </div>
            </div>
          </ModalBase>
        );
      },
    });
  };

  onDeleteAccount = () => {
    const { accountId, accountDetails, budgetDetails } = this.props;
    const { type: accountType } = accountDetails;

    if (accountType === 'creditcard' || accountType === 'debtother') {
      const updatedAccountList =
        budgetDetails.cc_order &&
        budgetDetails.cc_order.filter((acct: any) => acct.id !== accountId);

      this.props.removeCCAccountFromOrder(budgetDetails.id, updatedAccountList);
    }
    this.props.deleteAccount(accountId);
    this.setState({ dropdownOpen: false });
  };

  render() {
    const { dropdownOpen } = this.state;

    return (
      <>
        <div
          className="category-dropdown-toggle mobile-hidden"
          onClick={this.toggleDropdown}
        >
          <i className="far fa-angle-down fa-fw button-icon" />
          <ul
            className={classNames(
              'category-dropdown-menu category-dropdown-size',
              {
                [`category-dropdown-open`]: dropdownOpen,
              }
            )}
          >
            <li onClick={this.editAccountForm}>
              <i className="fas fa-pencil fa-fw button-icon" />
              <span className="">Edit Account</span>
            </li>
            <li onClick={this.deleteAccountAlert}>
              <i className="fas fa-minus-octagon fa-fw button-icon is-warning" />
              <span className="is-warning">Delete Account</span>
            </li>
          </ul>
          {dropdownOpen && (
            <Fragment>
              <Portal>
                <div
                  className="dropdown-backdrop"
                  onClick={this.toggleDropdown}
                />
              </Portal>
              <div
                className="dropdown-backdrop"
                onClick={this.toggleDropdown}
              />
            </Fragment>
          )}
        </div>
        <div
          className="category-dropdown-toggle mobile-only category-dropdown-toggle-mobile"
          onClick={this.toggleDropdown}
        >
          <i className="far fa-ellipsis-v fa-fw fa-lg button-icon" />
          <ul
            className={classNames(
              'category-dropdown-menu category-dropdown-size',
              {
                [`category-dropdown-open`]: dropdownOpen,
              }
            )}
          >
            <li onClick={this.editAccountForm}>
              <i className="fas fa-pencil fa-fw button-icon" />
              <span className="">Edit Account</span>
            </li>
            <li onClick={this.deleteAccountAlert}>
              <i className="fas fa-minus-octagon fa-fw button-icon is-warning" />
              <span className="is-warning">Delete Account</span>
            </li>
          </ul>
          {dropdownOpen && (
            <Fragment>
              <Portal>
                <div
                  className="dropdown-backdrop"
                  onClick={this.toggleDropdown}
                />
              </Portal>
              <div
                className="dropdown-backdrop"
                onClick={this.toggleDropdown}
              />
            </Fragment>
          )}
        </div>
      </>
    );
  }
}

export default SidebarAccountDropdown;
