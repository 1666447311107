import React from 'react';
import { Spacer } from '@nextui-org/react';
import { Layout } from '../../components/layout';
import {
  SelectPlan,
  useSubscriptionPlan,
} from '../../components/ui/subscription';
import { Button } from '../../components/ui/button';
import { Heading } from '../../components/ui/heading';
import { Box } from '../../components/ui/box';

function PageBilling() {
  const {
    data: subscription,
    isLoading,
    isSuccess,
    isError,
  } = useSubscriptionPlan();

  const updatePaymentMethod = () => {
    if (isSuccess && subscription.update_url) {
      window.Paddle.Checkout.open({
        product: subscription.current_plan,
        override: subscription.update_url,
      });
    }
  };

  const cancelSubscription = () => {
    if (isSuccess && subscription.cancel_url) {
      window.Paddle.Checkout.open({
        product: subscription.current_plan,
        override: subscription.cancel_url,
      });
    }
  };

  return (
    <Layout
      title="Subscription"
      subtitle="
      Update your billing information. Please note that updating your
          location could affect your tax rates.
      "
    >
      <Heading h3>Upgrade or Downgrade Subscriptions</Heading>

      <div style={{ maxWidth: '50%' }}>
        <SelectPlan
          key={isSuccess && subscription.current_plan}
          isLoading={isLoading}
          selectedPlan={isSuccess && subscription.current_plan}
          daysLeft={isSuccess && subscription.days_left}
          email={isSuccess && subscription.email}
        />
      </div>

      {isSuccess && subscription.current_plan !== 'LIFETIME' && (
        <Box>
          {subscription.update_url && (
            <>
              <Spacer y={2} />

              <Heading h3>Update Payment Details</Heading>
              <Button onClick={updatePaymentMethod}>Update Payment</Button>
            </>
          )}

          {subscription.cancel_url && (
            <>
              <Spacer y={2} />

              <Heading h3>Cancel Subscription</Heading>
              <Button onClick={cancelSubscription}>Cancel Subscription</Button>
            </>
          )}
        </Box>
      )}
    </Layout>
  );
}

export { PageBilling };
