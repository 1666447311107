import React, { PureComponent } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import BudgetTableCCCategoryGroupView from './budget-table-cc-category-group-view';
import BudgetTableCCCategory from '../budget-table-cc-category';
import { getMonthsToDisplay } from '../../../../utilities/helpers';
import { sortByOrderOfIds } from '../../../../utilities/drag-and-drop-reorder';

class BudgetTableCCCategoryGroupContainer extends PureComponent {
  renderCCAccounts = (accounts, monthsDisplayed) => {
    const { budgetDetails, settings } = this.props;

    const creditCards = accounts || [];
    const creditCardsToRender = creditCards.filter((creditCard) => {
      return (
        creditCard.type === 'creditcard' || creditCard.type === 'debtother'
      );
    });

    if (!creditCardsToRender.length) {
      return null;
    }

    // If no credit card order has been saved
    if (creditCardsToRender.length > 0 && !budgetDetails.cc_order) {
      this.props.updateCCOrderInGroupLocally(creditCardsToRender);
      this.props.updateCCOrderInGroup(
        budgetDetails.id,
        creditCardsToRender,
        budgetDetails.cc_order
      );
    }

    const orderedCreditCards =
      budgetDetails.cc_order && budgetDetails.cc_order.length > 0
        ? sortByOrderOfIds(creditCardsToRender, budgetDetails.cc_order)
        : creditCardsToRender;

    return orderedCreditCards.map((creditCardAccount, index) => {
      return (
        <BudgetTableCCCategory
          key={creditCardAccount.id}
          index={index}
          accountDetails={creditCardAccount}
          monthsDisplayed={monthsDisplayed}
          settings={settings}
        />
      );
    });
  };

  render() {
    const { activeMonths, accounts, settings } = this.props;

    const monthsDisplayed = getMonthsToDisplay(
      activeMonths,
      settings.month_view
    );

    return (
      <BudgetTableCCCategoryGroupView
        settings={settings}
        monthsDisplayed={monthsDisplayed}
      >
        <Droppable droppableId="credit-card" type="credit-card">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {!accounts.length && (
                <span style={{ padding: '4px 24px', display: 'inline-block' }}>
                  No subcategories added
                </span>
              )}
              {this.renderCCAccounts(accounts, monthsDisplayed)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </BudgetTableCCCategoryGroupView>
    );
  }
}

export default BudgetTableCCCategoryGroupContainer;
