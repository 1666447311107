import React, { PureComponent } from 'react';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import TransactionListItemReadOnlyView from './transaction-list-item-readonly-view';
import TransactionListItemEditableView from './transaction-list-item-editable-view';
import TransactionListItemMobile from './transaction-list-item-mobile';
import MobileEditTransactionForm from '../../../forms/mobile-edit-transaction-form';
import ModalBase from '../../../common/modal/modal-base';

class TransactionListItemContainer extends PureComponent {
  formatPayees = (payees) => {
    const formattedPayees = [];

    for (let i = 0; i < payees.length; i++) {
      if (payees[i].name) {
        formattedPayees.push({
          value: payees[i].name,
          label: payees[i].name,
        });
      }
    }

    return formattedPayees;
  };

  getPreloadedAccount = (transaction) => {
    let accountIdx, selectedAccount;

    if (
      transaction &&
      transaction.is_transfer &&
      this.props.accounts &&
      this.props.accounts.length
    ) {
      if (transaction && transaction.type === 'outflow') {
        accountIdx = this.props.accounts.findIndex(
          (obj) => obj.id === transaction.transfer_target
        );

        selectedAccount = this.props.accounts[accountIdx];

        return selectedAccount
          ? `Transfer to: ${selectedAccount.name}`
          : 'Deleted Account';
      }
      accountIdx = this.props.accounts.findIndex(
        (obj) => obj.id === transaction.transfer_origin
      );

      selectedAccount = this.props.accounts[accountIdx];
      const accountName = selectedAccount
        ? selectedAccount.name
        : 'Deleted Account';

      return `Transfer from: ${accountName}`;
    }
  };

  handleSavePayee = (newPayee) => {
    const { id: budgetId } = this.props.budgetDetails;

    this.props.savePayee(newPayee, budgetId);
  };

  handleEditMobileTransaction = () => {
    const {
      accountDetails,
      categoryAndAccountList,
      budget,
      budgetDetails,
      settings,
      payees,
      transaction,
    } = this.props;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ModalBase
            onClose={onClose}
            title={'Edit Transaction'}
            className="new-transaction-modal"
          >
            <MobileEditTransactionForm
              accountDetails={accountDetails}
              budget={budget}
              budgetDetails={budgetDetails}
              payees={this.formatPayees(payees)}
              settings={settings}
              transaction={transaction}
              onSaveMobileTransaction={this.handleSaveTransaction}
              onClose={onClose}
            />
          </ModalBase>
        );
      },
    });
  };

  createTimeframeSubcatIfNoneExists(transaction, budget_id) {
    const { category_id, date } = transaction;
    const dateAsTimeframe = moment(date).format('MMYYYY');

    if (!this.checkIfTimeframeExists(dateAsTimeframe, category_id)) {
      const categoryIdx = this.props.budget.subcategories.findIndex(
        (obj) => obj.id === category_id
      );
      const selectedCategory =
        this.props.budget.subcategories[categoryIdx] || {};
      const timeframeSubcategory = {
        timeframe: dateAsTimeframe,
        section_id: selectedCategory.section_id || null,
        category_id,
        budget_id,
      };

      this.props.saveTimeframeSubcategory(timeframeSubcategory, budget_id);
    }
  }

  createCCTimeframeSubcatIfNoneExists(transaction, budget_id) {
    const { account_id, date } = transaction;
    const dateAsTimeframe = moment(date).format('MMYYYY');

    if (!this.checkIfCCTimeframeExists(dateAsTimeframe, account_id)) {
      const timeframeSubcategory = {
        timeframe: dateAsTimeframe,
        cc_account_id: this.props.accountDetails.id,
        is_cc: true,
        budget_id,
      };

      this.props.saveTimeframeSubcategory(timeframeSubcategory, budget_id);
    }
  }

  checkIfTimeframeExists(timeframe, categoryId) {
    const timeframe_subcategories =
      this.props.budget.timeframe_subcategories || [];
    const timeframe_subcategoriesToRender = timeframe_subcategories.filter(
      (timeframe_subcategory) => {
        return (
          timeframe_subcategory.category_id === categoryId &&
          timeframe_subcategory.timeframe === timeframe
        );
      }
    );

    if (timeframe_subcategoriesToRender.length) {
      return timeframe_subcategoriesToRender[0];
    }
    return null;
  }

  checkIfCCTimeframeExists(timeframe, accountId) {
    const cc_timeframe_subcategories =
      this.props.budget.cc_timeframe_subcategories || [];
    const timeframe_subcategoriesToRender = cc_timeframe_subcategories.filter(
      (cc_timeframe_subcategory) => {
        return (
          cc_timeframe_subcategory.cc_account_id === accountId &&
          cc_timeframe_subcategory.timeframe === timeframe
        );
      }
    );

    if (timeframe_subcategoriesToRender.length) {
      return timeframe_subcategoriesToRender[0];
    }
    return null;
  }

  onToggleEditTransaction = () => {
    const { id: currentTransactionId } = this.props.transaction;

    this.props.handleToggleEditTransaction(currentTransactionId);
    this.props.toggleEditingRow(currentTransactionId);
  };

  clearEditTransaction = () => {
    this.props.handleToggleEditTransaction('');
  };

  handleSaveTransaction = (transactionPayload) => {
    const { accountDetails, budgetDetails } = this.props;

    // If the account is off budget, don't create a timeframe category, just save it.
    if (this.props.accountDetails.off_budget) {
      // Make sure the off_budget field is set to true for all saved transactions as added security
      const updatedTransaction = {
        ...transactionPayload,
        off_budget: true,
      };

      this.props.saveTransaction(
        updatedTransaction,
        budgetDetails.id,
        accountDetails.id
      );
    }

    if (transactionPayload.category === 'Left to Budget') {
      this.props.saveTransaction(
        transactionPayload,
        budgetDetails.id,
        accountDetails.id
      );
    } else if (
      transactionPayload.accountType === 'creditcard' ||
      transactionPayload.accountType === 'debtother'
    ) {
      if (transactionPayload.child_transactions.length) {
        const childrenWithDistinctCategories = [];
        const mapOfChildren = new Map();

        for (const child of transactionPayload.child_transactions) {
          if (!mapOfChildren.has(child.category_id)) {
            mapOfChildren.set(child.category_id, true);
            childrenWithDistinctCategories.push({
              ...child,
            });
          }
        }

        for (const child of childrenWithDistinctCategories || []) {
          this.createTimeframeSubcatIfNoneExists(child, budgetDetails.id);
          this.createCCTimeframeSubcatIfNoneExists(child, budgetDetails.id);
        }
      } else {
        this.createCCTimeframeSubcatIfNoneExists(
          transactionPayload,
          budgetDetails.id
        );
        this.createTimeframeSubcatIfNoneExists(
          transactionPayload,
          budgetDetails.id
        );
      }

      this.props.saveTransaction(
        transactionPayload,
        budgetDetails.id,
        accountDetails.id
      );
    } else if (
      transactionPayload.is_transfer === false &&
      !this.props.accountDetails.off_budget
    ) {
      if (
        transactionPayload.child_transactions &&
        transactionPayload.child_transactions.length
      ) {
        const childrenWithDistinctCategories = [];
        const mapOfChildren = new Map();

        for (const child of transactionPayload.child_transactions) {
          if (!mapOfChildren.has(child.category_id)) {
            mapOfChildren.set(child.category_id, true);
            childrenWithDistinctCategories.push({
              ...child,
            });
          }
        }

        for (const child of childrenWithDistinctCategories || []) {
          this.createTimeframeSubcatIfNoneExists(child, budgetDetails.id);
        }
      } else {
        this.createTimeframeSubcatIfNoneExists(
          transactionPayload,
          budgetDetails.id
        );
      }

      this.props.saveTransaction(
        transactionPayload,
        budgetDetails.id,
        accountDetails.id
      );
    } else if (transactionPayload.is_transfer) {
      this.props.saveTransaction(
        transactionPayload,
        budgetDetails.id,
        accountDetails.id
      );
    }
  };

  handleSaveAccountScrollPosition = (
    accountId,
    startIndex,
    stopIndex,
    currentView
  ) => {
    this.props.updateScrollPosition(
      accountId,
      startIndex,
      stopIndex,
      currentView
    );
  };

  // If its in select mode, we want to select the row, not click to edit
  handleClickToEditOrSelectRow = () => {
    const { isSelectEnabled } = this.props;

    if (isSelectEnabled) {
      return;
    } else {
      this.onToggleEditTransaction();
    }
  };

  handleCancelEditableRow = () => {
    this.props.cancelEditableRow(this.props.accountDetails.id, 0);
  };

  handleApproveTransaction = (transaction, approved) => {
    const { budgetDetails, accountDetails } = this.props;

    const budgetId = budgetDetails.id;
    const accountId = accountDetails.id;

    this.props.saveApproveTransaction(
      transaction,
      approved,
      budgetId,
      accountId,
      budgetDetails.accounts_order
    );
  };

  handleToggleIncludeNow = (transaction, includeNow) => {
    const { budgetDetails, accountDetails } = this.props;

    const budgetId = budgetDetails.id;
    const accountId = accountDetails.id;

    this.props.toggleIncludeNow(
      transaction,
      includeNow,
      budgetId,
      accountId,
      budgetDetails.accounts_order
    );
  };

  handleCancelSeries = (recurringId, formattedDate) => {
    const { accountDetails } = this.props;
    this.props.cancelSeries(
      accountDetails.budget_id,
      accountDetails.id,
      recurringId,
      formattedDate
    );
  };

  renderTransactionRow = () => {
    const {
      accountDetails,
      categoryAndAccountList,
      formattedCategoryAccountList,
      isSelectEnabled,
      isMobile,
      payees,
      onSelected,
      settings,
      selectedTransactions,
      subcategories,
      transaction,
      transactionBeingEdited,
      lastSelectedDate,
      updateLastSelectedDate,
    } = this.props;

    // TODO if mobile, render mobile transactions. if not, do these next two flows
    if (isMobile) {
      return (
        <TransactionListItemMobile
          editMobileTransaction={this.handleEditMobileTransaction}
          settings={settings}
          budgetDetails={this.props.budgetDetails}
          transaction={transaction}
          getPreloadedAccount={this.getPreloadedAccount}
        />
      );
    }

    if (transactionBeingEdited !== transaction.id) {
      return (
        <TransactionListItemReadOnlyView
          isSelectEnabled={isSelectEnabled}
          isRowSelected={
            selectedTransactions &&
            selectedTransactions.findIndex((e) => e.id === transaction.id) !==
              -1
          }
          onSelected={onSelected}
          getPreloadedAccount={this.getPreloadedAccount}
          onChildCollapse={this.props.onChildCollapse}
          formattedCategoryAccountList={formattedCategoryAccountList}
          childCollapsed={this.props.childCollapsed}
          toggleIncludeNow={this.handleToggleIncludeNow}
          onApproveTransaction={this.handleApproveTransaction}
          toggleEditTransaction={this.handleClickToEditOrSelectRow}
          settings={settings}
          accountDetails={accountDetails}
          transaction={transaction}
        />
      );
    }

    return (
      <TransactionListItemEditableView
        accountDetails={accountDetails}
        categoryAndAccountList={categoryAndAccountList}
        cancelEditableRow={this.handleCancelEditableRow}
        deselectEditTransaction={this.clearEditTransaction}
        onSavePayee={this.handleSavePayee}
        accounts={this.props.accounts}
        onChildChange={this.props.onChildChange}
        onChildCollapse={this.props.onChildCollapse}
        childCollapsed={this.props.childCollapsed}
        onCancelSeries={this.handleCancelSeries}
        onSaveTransaction={this.handleSaveTransaction}
        toggleIncludeNow={this.handleToggleIncludeNow}
        onApproveTransaction={this.handleApproveTransaction}
        payees={this.formatPayees(payees)}
        settings={settings}
        saveAccountScrollPosition={this.handleSaveAccountScrollPosition}
        startIndex={this.props.startIndex}
        stopIndex={this.props.stopIndex}
        subcategories={subcategories}
        transaction={transaction}
        transactionBeingEdited={transactionBeingEdited}
        toggleEditingRow={this.props.toggleEditingRow}
      />
    );
  };

  render() {
    return this.renderTransactionRow();
  }
}

export default TransactionListItemContainer;
