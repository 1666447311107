import React from 'react';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import { add } from '../../../../utilities/budgeting-formulas';
import { isMobile } from '../../../../utilities/helpers';

const BudgetTableHeaderMonthGroupView = ({
  isMiddleMonth,
  settings,
  totalActivity,
  totalBudgeted,
  totalRemaining,
  totalUncategorized,
}) => {
  return (
    <div
      className={classNames('month-group', {
        'is-middle-column': isMiddleMonth,
      })}
    >
      <div className="budget-column-budgeted">
        Budgeted
        <br />
        <span className="header-amount">
          {/* <i
            className="fas fa-angle-double-right fa-lg fa-fw copy-last-month-budget-icon copy-all-last-month-budget-icon"
            onClick={() => {}}
            data-balloon-pos="left"
            aria-label={`Copy last month's budget of 0.00`}
          />
          <i
            className="fas fa-times fa-lg fa-fw copy-last-month-budget-icon copy-all-last-month-budget-icon clear-all-budget-icon"
            onClick={() => {}}
            data-balloon-pos="right"
            aria-label={`Set all budgeted values to 0`}
          /> */}
          <NumberFormat
            displayType="text"
            className="budget-category-input budget-category-readonly"
            name="budgeted"
            value={totalBudgeted}
            isNumericString={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={settings.show_symbol ? `${settings.symbol}` : ''}
            thousandSeparator={settings.thousands}
            decimalSeparator={settings.decimal}
          />
        </span>
      </div>
      {!isMobile() && (
        <div className="budget-column-activity">
          Activity
          <br />
          <span className="header-amount">
            <NumberFormat
              displayType="text"
              className="budget-category-input budget-category-readonly"
              name="budgeted"
              value={
                !totalUncategorized
                  ? totalActivity
                  : add(totalActivity, totalUncategorized)
              }
              isNumericString={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={settings.show_symbol ? `${settings.symbol}` : ''}
              thousandSeparator={settings.thousands}
              decimalSeparator={settings.decimal}
            />
          </span>
        </div>
      )}
      <div className="budget-column-remaining">
        Remaining
        <br />
        <span className="header-amount">
          {parseInt(totalUncategorized) !== 0 &&
          !isNaN(parseInt(totalUncategorized)) ? (
            <>
              <i
                aria-label={
                  totalUncategorized
                    ? `Total Uncategorized: ${
                        settings.show_symbol ? `${settings.symbol}` : ''
                      }${totalUncategorized}`
                    : null
                }
                data-balloon-pos="left"
                className="fas fa-exclamation-circle fa-fw uncategorized-icon"
              />{' '}
            </>
          ) : null}
          <NumberFormat
            displayType="text"
            className="budget-category-input budget-category-readonly"
            name="budgeted"
            value={
              !totalUncategorized
                ? totalRemaining
                : add(totalRemaining, totalUncategorized)
            }
            isNumericString={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={settings.show_symbol ? `${settings.symbol}` : ''}
            thousandSeparator={settings.thousands}
            decimalSeparator={settings.decimal}
          />
        </span>
      </div>
    </div>
  );
};

export default BudgetTableHeaderMonthGroupView;
