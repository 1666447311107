import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.min.css';
import { Signup } from '../../v1/components/ui/signup';

function SignupForm() {
  return (
    <Fragment>
      <a className="registration-logo" href="https://www.budgetwise.io/">
        <img
          src="/img/budgetwise_logo_light.png"
          alt="Budgetwise - Zero Sum Budgeting and Debt Management"
        />
      </a>
      <div className="registration-wrapper">
        <div className="container is-fluid">
          <div className="registration-area columns is-centered is-mobile">
            <div className="column card-column mx-auto">
              <Signup />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default connect(null, null)(SignupForm);
