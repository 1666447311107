import React, { Fragment, PureComponent, memo } from 'react';
import NumberFormat from 'react-number-format';
import { add } from '../../../../utilities/budgeting-formulas';

class SidebarAccountItemView extends PureComponent {
  render() {
    const {
      accountName,
      accountBalanceSheet,
      isOffBudget,
      settings,
      totalUncategorized,
    } = this.props;

    const totalBal = add(
      accountBalanceSheet.totalApproved,
      accountBalanceSheet.totalUnapproved
    );

    return (
      <Fragment>
        <h3 className="account-name">
          {accountName}{' '}
          {totalUncategorized && !isOffBudget ? (
            <i
              data-balloon-pos="right"
              aria-label={`${totalUncategorized} uncategorized transaction${
                totalUncategorized > 1 ? 's' : ''
              }`}
              className="fas fa-exclamation-circle fa-fw fa-sm uncategorized-icon"
            />
          ) : null}
        </h3>
        <NumberFormat
          displayType="text"
          className={`${'account-balance'} ${
            Number(totalBal) === 0
              ? ''
              : Number(totalBal) >= 0
              ? 'account-pos'
              : 'account-neg'
          }`}
          name="remaining"
          prefix={settings.show_symbol ? `${settings.symbol}` : ''}
          value={totalBal || 0}
          isNumericString={true}
          decimalScale={2}
          fixedDecimalScale={true}
          thousandSeparator={settings.thousands}
          decimalSeparator={settings.decimal}
        />
      </Fragment>
    );
  }
}

export default memo(SidebarAccountItemView);
