import React, { Fragment, Component } from 'react';
import { Portal } from 'react-portal';
import { confirmAlert } from 'react-confirm-alert';
import { Provider } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import store from '../../../store';
import ModalBase from '../../common/modal/modal-base';
import NewBudgetForm from '../../forms/new-budget-form';
import RenameBudgetForm from '../../forms/rename-budget-form';
import ManageBudgetsForm from '../../forms/manage-budgets-form';
import ManagePayeesForm from '../../forms/manage-payees-form';
import SettingsForm from '../../forms/settings-form';
import ButtonWarning from '../../common/button/button-warning';
import ButtonLink from '../../common/button/button-link';

import 'react-confirm-alert/src/react-confirm-alert.css'; // PageImport css
import './budget-title-dropdown.css';

class BudgetTitleDropdownView extends Component {
  state = {
    isDropdownToggled: false,
  };

  componentDidMount() {
    let nightModeToggled;

    if (typeof localStorage.theme !== 'undefined') {
      nightModeToggled = localStorage.getItem('theme');
    } else {
      nightModeToggled = 'light';
    }

    localStorage.theme = document.documentElement.dataset.theme =
      nightModeToggled;
    this.setState({ nightModeToggled });
  }

  handleSaveSettings = (newSettings, prevSettings) => {
    const { id: budgetId } = this.props.budgetDetails;

    if (newSettings.global_ltb !== prevSettings.global_ltb) {
      this.props.fetchInitialLtbBreakdownByMonth(budgetId);
      this.props.fetchTimeframeSubcategories(budgetId);
    }

    this.props.saveSettings(
      budgetId,
      prevSettings.id,
      prevSettings,
      newSettings
    );
  };

  handleResetBudget = () => {
    const { id: budgetId } = this.props.budgetDetails;

    this.props.resetBudget(budgetId);
  };

  toggleDarkMode = (e) => {
    e.stopPropagation();
    const nightModeToggled = document.documentElement.dataset.theme;
    const newTheme = nightModeToggled === 'dark' ? 'light' : 'dark';
    localStorage.theme = document.documentElement.dataset.theme = newTheme;
    localStorage.setItem('theme', newTheme);

    this.setState({ nightModeToggled: newTheme });
  };

  toggleDetailsDropdown = () => {
    const { isDropdownToggled } = this.state;
    this.setState({ isDropdownToggled: !isDropdownToggled });
  };

  renderNightModeToggle = () => {
    const { nightModeToggled } = this.state;

    if (nightModeToggled === 'dark') {
      return (
        <a onClick={this.toggleDarkMode}>
          <i className="fas fa-moon fa-fw dropdown-icon dark-mode-icon-on" />
          Night Mode: On
        </a>
      );
    }
    return (
      <a onClick={this.toggleDarkMode}>
        <i className="far fa-moon fa-fw dropdown-icon" />
        Night Mode: Off
      </a>
    );
  };

  renameBudget = () => {
    this.setState({ isDropdownToggled: false });
    mixpanel.track('Navigate to Rename Budget');

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ModalBase onClose={onClose} title={'Rename Budget'}>
            <RenameBudgetForm
              budgetDetails={this.props.budgetDetails}
              onSave={this.saveBudgetRename}
              onClose={onClose}
            />
          </ModalBase>
        );
      },
    });
  };

  newBudget = () => {
    mixpanel.track('Navigate to New Budget');

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Provider store={store}>
            <ModalBase onClose={onClose} title={'New Budget'}>
              <NewBudgetForm
                budgetDetails={this.props.budgetDetails}
                onClose={onClose}
              />
            </ModalBase>
          </Provider>
        );
      },
    });

    this.setState({ isDropdownToggled: false });
  };

  manageBudgets = () => {
    mixpanel.track('Navigate to Manage Budgets');

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Provider store={store}>
            <ModalBase onClose={onClose} title={'Manage Budgets'}>
              <ManageBudgetsForm
                budgetDetails={this.props.budgetDetails}
                onClose={onClose}
              />
            </ModalBase>
          </Provider>
        );
      },
    });

    this.setState({ isDropdownToggled: false });
  };

  managePayees = () => {
    mixpanel.track('Navigate to Manage Payees');

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Provider store={store}>
            <ModalBase onClose={onClose} title={'Manage Payees'}>
              <ManagePayeesForm onClose={onClose} />
            </ModalBase>
          </Provider>
        );
      },
    });

    this.setState({ isDropdownToggled: false });
  };

  resetBudgetAlert = () => {
    this.setState({ isDropdownToggled: false });

    mixpanel.track('Navigate to Reset Budget');

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ModalBase
            onClose={onClose}
            title={'Reset Budget'}
            className="recurring-cancel"
          >
            <i
              className="alert-icon fas fa-exclamation-circle fa-fw fa-lg"
              style={{ color: '#c26e6b' }}
            />
            <div className="alert-text-wrapper">
              <span className="alert-title">
                Are you sure you want to reset this budget?
              </span>
              <span className="alert-text">
                Resetting will delete all of your transactions and budget data,
                but leave your accounts and budget categories intact.
              </span>
              <div
                className="has-text-right alert-button-container"
                style={{ marginTop: '24px' }}
              >
                <ButtonLink
                  className="button is-outlined"
                  onClick={() => onClose()}
                >
                  Cancel
                </ButtonLink>
                <ButtonWarning
                  className="button is-danger"
                  onClick={() => {
                    this.handleResetBudget();
                    onClose();
                  }}
                >
                  {`Reset Budget`}
                </ButtonWarning>
              </div>
            </div>
          </ModalBase>
        );
      },
    });
  };

  changeSettings = () => {
    this.setState({ isDropdownToggled: false });
    mixpanel.track('Navigate to Settings');

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ModalBase onClose={onClose} title={'General Settings'}>
            <SettingsForm
              onClose={onClose}
              onSaveSettings={this.handleSaveSettings}
              settings={this.props.settings}
            />
          </ModalBase>
        );
      },
    });
  };

  saveBudgetRename = (budgetDetails) => {
    this.props.renameBudget(budgetDetails);
  };

  render() {
    const { budgetDetails, currentUser, resetBudget, logOut } = this.props;
    const { isDropdownToggled } = this.state;

    return (
      <header className="budget-details-menu">
        {isDropdownToggled && (
          <Fragment>
            <div
              className="dropdown-backdrop"
              onClick={this.toggleDetailsDropdown}
            />
            <Portal>
              <div
                className="dropdown-backdrop"
                onClick={this.toggleDetailsDropdown}
              />
            </Portal>
          </Fragment>
        )}
        <div className="budget-details-dropdown">
          <div
            className="budget-details-wrapper"
            onClick={this.toggleDetailsDropdown}
          >
            <img
              src="/img/budgetwise_badge.png"
              alt="Logo"
              className="budget-details-logo"
            />
            <h1 className="budget-details-name">
              {budgetDetails.name} <i className="fas fa-caret-down fa-fw" />
            </h1>
          </div>
          <div
            className={`budget-details-dropdown-list ${
              isDropdownToggled ? 'display-dropdown' : ''
            }`}
            ref={(node) => (this.node = node)}
          >
            {this.renderNightModeToggle()}
            <hr className="dropdown-divider" />

            <a onClick={this.renameBudget}>
              <i className="far fa-pencil fa-fw dropdown-icon" />
              Rename Budget
            </a>
            <a onClick={this.resetBudgetAlert}>
              <i className="far fa-recycle fa-fw dropdown-icon" />
              Reset Budget
            </a>
            <hr className="dropdown-divider" />
            <a onClick={this.newBudget}>
              <i className="far fa-file-plus fa-fw dropdown-icon" />
              New Budget
            </a>
            <a onClick={this.manageBudgets}>
              <i className="far fa-folder-open fa-fw dropdown-icon" />
              Manage Budgets
            </a>
            <a onClick={this.managePayees}>
              <i className="far fa-user-edit fa-fw dropdown-icon" />
              Manage Payees
            </a>
            <hr className="dropdown-divider" />
            <a
              href="https://help.budgetwise.io/"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                mixpanel.track('Go to Help Center');
                this.toggleDetailsDropdown();
              }}
            >
              <i className="far fa-book fa-fw dropdown-icon" />
              Help Center
            </a>
            <a
              href="https://www.budgetwise.io/release-notes"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="far fa-external-link fa-fw dropdown-icon" />
              Release Notes
            </a>
            <a
              href="mailto:support@budgetwise.io"
              target="_blank"
              rel="noopener noreferrer"
              onClick={this.toggleDetailsDropdown}
            >
              <i className="far fa-bug fa-fw dropdown-icon" />
              Report a Bug
            </a>
            {/* TODO Switch to RR4 for this link */}
            <a
              href="/subscribe"
              onClick={() => mixpanel.track('Navigate to Subscribe page')}
            >
              <i className="far fa-star fa-fw dropdown-icon" />
              {this.props.subscriptionDetails.plan === 'free_trial'
                ? 'Subscribe'
                : 'Edit Subscription'}
            </a>
            <a onClick={this.changeSettings}>
              <i className="far fa-cogs fa-fw dropdown-icon" />
              Settings
            </a>
            <a href="/telegram">
              <i className="fab fa-telegram-plane fa-fw dropdown-icon" />
              Telegram
            </a>
            <hr className="dropdown-divider" />

            <span className="dropdown-email-wrapper">
              <span className="dropdown-email">{currentUser.email}</span>
            </span>
            <a onClick={logOut}>
              <i className="far fa-sign-out-alt fa-fw dropdown-icon" />
              Log Out
            </a>
          </div>
        </div>
      </header>
    );
  }
}

export default BudgetTitleDropdownView;
