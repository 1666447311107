import React, { memo } from 'react';
import ButtonWarning from '../../common/button/button-warning';
import ButtonSecondary from '../../common/button/button-secondary';
import VirtualizedCheckbox from '../../virtualized-checkbox';

const ManagePayeesView = ({
  onClose,
  deletePayees,
  payees,
  selectedPayees,
  updateSelectedPayees,
}) => {
  return (
    <div className="manage-payees">
      <div className="payee-list">
        <VirtualizedCheckbox
          items={payees.sort((a, b) => a.name.localeCompare(b.name))}
          labelKey="name"
          hasOkButton={false}
          hasCancelButton={false}
          onChange={updateSelectedPayees}
        />
      </div>
      <div className="form-controls-group">
        <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>
        <ButtonWarning
          onClick={deletePayees}
          isDisabled={!selectedPayees.length}
        >
          Delete {selectedPayees.length !== 0 && selectedPayees.length} Payee
          {selectedPayees.length > 1 ? 's' : null}
        </ButtonWarning>
      </div>
    </div>
  );
};

export default memo(ManagePayeesView);
