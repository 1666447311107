import React from 'react';
import { connect } from 'react-redux';
import { deleteAccount, updateAccount } from '../../../../actions/account';
import SidebarOffBudgetAccountsContainer from './sidebar-off-budget-accounts-container';

const SidebarOffBudgetAccountsConnector = ({
  accounts,
  accountsById,
  budgetDetails,
  deleteAccount,
  updateAccount,
  settings,
}) => {
  return (
    <SidebarOffBudgetAccountsContainer
      accounts={accounts}
      accountsById={accountsById}
      budgetDetails={budgetDetails}
      deleteAccount={deleteAccount}
      updateAccount={updateAccount}
      settings={settings}
    />
  );
};

const mapStateToProps = ({
  accounts,
  accountsById,
  budgetDetails,
  settings,
  updateAccount,
}) => ({
  accounts,
  accountsById,
  budgetDetails,
  settings,
  updateAccount,
});

export default connect(mapStateToProps, { deleteAccount, updateAccount })(
  SidebarOffBudgetAccountsConnector
);
