import React, { memo } from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { isMobile } from '../../../../../utilities/helpers';

const BudgetTableCategoryGroupMonthView = ({
  categoryGroupTotalActivityByMonth,
  categoryGroupTotalBudgetedByMonth,
  categoryGroupTotalRemainingByMonth,
  isMiddleColumn,
  settings,
}) => {
  return (
    <div
      className={classNames('month-group', {
        'is-middle-column': isMiddleColumn,
      })}
    >
      <div className="budget-column-budgeted">
        <span className="">
          <NumberFormat
            displayType="text"
            className="budget-category-input budget-category-readonly"
            name="budgeted"
            value={categoryGroupTotalBudgetedByMonth}
            isNumericString={true}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={settings.thousands}
            decimalSeparator={settings.decimal}
          />
        </span>
      </div>
      {!isMobile() && (
        <div className="budget-column-activity">
          <span className="">
            <NumberFormat
              displayType="text"
              className="budget-category-input budget-category-readonly"
              name="budgeted"
              value={categoryGroupTotalActivityByMonth}
              isNumericString={true}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={settings.thousands}
              decimalSeparator={settings.decimal}
            />
          </span>
        </div>
      )}
      <div className="budget-column-remaining">
        <span className="">
          <NumberFormat
            displayType="text"
            className={classNames(
              'budget-category-input budget-category-readonly',
              { 'neg-val': categoryGroupTotalRemainingByMonth < 0 }
            )}
            name="budgeted"
            value={categoryGroupTotalRemainingByMonth}
            isNumericString={true}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={settings.thousands}
            decimalSeparator={settings.decimal}
          />
        </span>
      </div>
    </div>
  );
};

export default memo(BudgetTableCategoryGroupMonthView);
