import { TRANSACTIONS_LOADING } from '../action-list';

const initialState = {
  transactionsLoading: true,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TRANSACTIONS_LOADING:
      return {
        ...state,
        transactionsLoading: action.isLoading,
      };

    default:
      return state;
  }
}
