import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

const printSign = (amount) => {
  const printedSign = Number(amount) >= 0 ? '+' : '-';

  return printedSign;
};

const renderLtb = (ltbBreakdown, globalLtbEnabled) => {
  if (globalLtbEnabled) {
    return (ltbBreakdown && ltbBreakdown.globalLeftToBudget) || 0;
  } else {
    return (ltbBreakdown && ltbBreakdown.leftToBudget) || 0;
  }
};

class LeftToBudgetMultiMonthView extends Component {
  state = {
    ltbLoading: true,
  };

  componentDidMount() {
    setTimeout(() => {
      //Start the timer
      this.setState({ ltbLoading: false }); //After 1 second, set render to true
    }, 1400);
  }

  amountLtb = () => {
    return renderLtb(this.props.ltbBreakdown, this.props.settings.global_ltb);
  };

  renderNumbers = () => {
    const {
      isMiddleMonth,
      fullMonthString,
      lastMonthString,
      thisMonthString,
      settings,
      ltbBreakdown,
    } = this.props;

    return (
      <>
        <span className="multi-month-date">{fullMonthString}</span>

        <div className="multi-month-line-item">
          <span className="multi-month-left-item">
            Fwd from {lastMonthString}
          </span>
          <span className="multi-month-right-item">
            <NumberFormat
              displayType="text"
              className="ltb-amount"
              name="fwdFromLastMonth"
              value={(ltbBreakdown && ltbBreakdown.fwdFromLastMonth) || 0}
              isNumericString={true}
              allowNegative={true}
              placeholder="0.00"
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={settings.thousands}
              decimalSeparator={settings.decimal}
              prefix={
                settings.show_symbol
                  ? `${printSign(
                      ltbBreakdown && ltbBreakdown.fwdFromLastMonth
                    )} ${settings.symbol}`
                  : `${printSign(
                      ltbBreakdown && ltbBreakdown.fwdFromLastMonth
                    )}`
              }
            />
          </span>
        </div>

        <div className="multi-month-line-item">
          <span className="multi-month-left-item">
            Income for {thisMonthString}
          </span>
          <span className="multi-month-right-item">
            <NumberFormat
              displayType="text"
              className="ltb-amount"
              name="fwdFromLastMonth"
              value={(ltbBreakdown && ltbBreakdown.incomeForMonth) || 0}
              isNumericString={true}
              allowNegative={true}
              placeholder="0.00"
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={settings.thousands}
              decimalSeparator={settings.decimal}
              prefix={
                settings.show_symbol
                  ? `${printSign(
                      ltbBreakdown && ltbBreakdown.incomeForMonth
                    )} ${settings.symbol}`
                  : `${printSign(ltbBreakdown && ltbBreakdown.incomeForMonth)}`
              }
            />
          </span>
        </div>

        <div className="multi-month-line-item">
          <span className="multi-month-left-item">
            Budgeted in {thisMonthString}
          </span>
          <span className="multi-month-right-item">
            <NumberFormat
              displayType="text"
              className="ltb-amount"
              name="fwdFromLastMonth"
              value={(ltbBreakdown && ltbBreakdown.budgetedForMonth) || 0}
              isNumericString={true}
              allowNegative={true}
              placeholder="0.00"
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={settings.thousands}
              decimalSeparator={settings.decimal}
              prefix={settings.show_symbol ? `- ${settings.symbol}` : '-'}
            />
          </span>
        </div>

        {settings.global_ltb && (
          <div className="multi-month-line-item">
            <span className="multi-month-left-item">
              Budgeted Ahead{' '}
              <i
                data-balloon-pos="left"
                aria-label="Total Budgeted in the Future"
                className="fas fa-question-circle fa-fw fa-sm"
              />
            </span>
            <span className="multi-month-right-item">
              <NumberFormat
                displayType="text"
                className="ltb-amount"
                name="fwdFromLastMonth"
                value={(ltbBreakdown && ltbBreakdown.budgetedAhead) || 0}
                isNumericString={true}
                allowNegative={true}
                placeholder="0.00"
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={settings.thousands}
                decimalSeparator={settings.decimal}
                prefix={settings.show_symbol ? `- ${settings.symbol}` : '-'}
              />
            </span>
          </div>
        )}

        <div className="multi-month-line-item ltb-line-item">
          <span className="multi-month-left-item">
            Left to Budget{' '}
            {this.amountLtb() < 0 && (
              <i
                data-balloon-pos="left"
                data-balloon-length="large"
                data-balloon-break
                aria-label="It looks like you've budgeted more than the cash you have on hand. To correct, move money from positive categories until Left to Budget is zeroed out."
                className="fas fa-question-circle fa-fw fa-sm"
              />
            )}
          </span>
          <span className="multi-month-right-item">
            <NumberFormat
              displayType="text"
              className={classNames('ltb-amount', {
                'neg-val': this.amountLtb() && this.amountLtb() < 0,
              })}
              name="fwdFromLastMonth"
              value={this.amountLtb()}
              isNumericString={true}
              allowNegative={true}
              placeholder="0.00"
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={settings.thousands}
              decimalSeparator={settings.decimal}
              prefix={settings.show_symbol ? `${settings.symbol}` : ''}
            />
          </span>
        </div>
      </>
    );
  };

  render() {
    return (
      <div
        className={classNames('ltb-multi-month-wrapper', {
          is: this.props.isMiddleMonth,
        })}
      >
        <div className="ltb-multi-month-inner">
          {/* {this.state.ltbLoading ? (
            <i className="far fa-spinner-third fa-spin ltb-loading" />
          ) : ( */}
          {this.renderNumbers()}
          {/* )} */}
        </div>
      </div>
    );
  }
}

export default LeftToBudgetMultiMonthView;
