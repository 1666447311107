import React from 'react';
import BudgetView from './components/budget';
import SummaryContainer from './components/summary/summary-container';
import Summary from './components/summary/summary';
import { PageImportConnected as PageImport } from './v1/pages/page-import/page-import';
import { PageTelegram } from './v1/pages/telegram';

const routes = [
  {
    exact: true,
    path: '/summary',
    label: 'Summary',
    onSidebar: true,
    icon: <i className="fas fa-chart-pie fa-fw sidebar-icon" />,
    render: () => <SummaryContainer />,
  },
  {
    path: '/',
    exact: true,
    label: 'Budget',
    onSidebar: true,
    icon: <i className="fas fa-calculator fa-fw sidebar-icon" />,
    render: () => <BudgetView />,
  },
  {
    path: '/import',
    exact: true,
    label: 'Import',
    onSidebar: true,
    icon: <i className="fas fa-file-import fa-fw sidebar-icon" />,
    render: () => <PageImport />,
  },
  {
    path: '/telegram',
    exact: true,
    label: 'Telegram',
    onSidebar: false,
    icon: <i className="fas fa-telegram fa-fw sidebar-icon" />,
    render: () => <PageTelegram />,
  },
];

export default routes;
