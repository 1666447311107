import {
  ACCOUNTS_SET_BY_ID,
  ACCOUNTS_BY_ID_UPDATE_ACCOUNT,
  ACCOUNTS_BY_ID_ADD_ACCOUNT,
  ACCOUNTS_BY_ID_ADD_TRANSACTION,
  ACCOUNTS_BY_ID_CANCEL_TRANSACTION,
  ACCOUNTS_BY_ID_SAVE_TRANSACTION,
  ACCOUNTS_BY_ID_UPDATE_TRANSACTION,
  ACCOUNTS_BY_ID_REMOVE_TRANSACTIONS,
  ACCOUNTS_BY_ID_SET_TRANSACTIONS,
  UPDATE_ACCOUNT_SCROLL_POSITION,
} from '../action-list';
import _ from 'lodash';

export default function(state = null, action) {
  switch (action.type) {
    case ACCOUNTS_SET_BY_ID:
      return { ...action.payload };

    case ACCOUNTS_BY_ID_ADD_ACCOUNT: {
      const newAcctObj = _.cloneDeep(state);

      newAcctObj[action.payload.id] = {
        ...newAcctObj[action.payload.account_id],
        ...action.payload,
      };

      newAcctObj[action.payload.id].transactions = [];

      return newAcctObj;
    }

    case ACCOUNTS_BY_ID_UPDATE_ACCOUNT: {
      const newAcctObj = _.cloneDeep(state);

      newAcctObj[action.payload.id] = {
        ...newAcctObj[action.payload.id],
        ...action.payload,
      };

      return newAcctObj;
    }

    case ACCOUNTS_BY_ID_SET_TRANSACTIONS: {
      const newState = action.transactions.reduce((newState, transaction) => {
        if (newState[transaction.account_id]) {
          if (
            newState[transaction.account_id].transactions.findIndex(
              (e) => e.id === transaction.id
            ) === -1
            // newState[transaction.account_id].transactions.length <= 50
          ) {
            newState[transaction.account_id].transactions.push(transaction);
          }
        } else {
          newState[transaction.account_id] = {
            ...state[transaction.account_id],
            transactions: [transaction],
          };
        }

        // newState[transaction.account_id].transactions = newState[
        //   transaction.account_id
        // ].transactions.slice(0, 60);

        return newState;
      }, {});

      return { ...state, ...newState };
    }

    case ACCOUNTS_BY_ID_SAVE_TRANSACTION: {
      const newAcctObj = _.cloneDeep(state);

      if (
        newAcctObj[action.payload.account_id] &&
        newAcctObj[action.payload.account_id].transactions.length
      ) {
        const transactionIdx = newAcctObj[
          action.payload.account_id
        ].transactions.findIndex((obj) => obj.id === action.payload.id);

        newAcctObj[action.payload.account_id].transactions[transactionIdx] =
          action.payload;
      } else {
        newAcctObj[action.payload.account_id].transactions.push(action.payload);
      }

      return newAcctObj;
    }

    // Similar to the save above, but only requires the transaction id and whatever values you want to update
    case ACCOUNTS_BY_ID_UPDATE_TRANSACTION: {
      const newAcctObj = _.cloneDeep(state);

      if (
        newAcctObj[action.payload.account_id] &&
        newAcctObj[action.payload.account_id].transactions.length
      ) {
        const transactionIdx = newAcctObj[
          action.payload.account_id
        ].transactions.findIndex((obj) => obj.id === action.payload.id);

        newAcctObj[action.payload.account_id].transactions[transactionIdx] = {
          ...newAcctObj[action.payload.account_id].transactions[transactionIdx],
          ...action.payload,
        };
      }

      return newAcctObj;
    }

    case ACCOUNTS_BY_ID_ADD_TRANSACTION: {
      // Uncomment this if you only want to let them add one new transaction at a time
      // if (newState[action.payload.account_id].transactions[0].created) {
      // newState[action.payload.account_id].transactions.unshift(action.payload);
      // }
      const newAccObj = _.cloneDeep(state);

      newAccObj[action.payload.account_id].transactions = [action.payload].concat(
        newAccObj[action.payload.account_id].transactions
      );

      return newAccObj;
    }

    case ACCOUNTS_BY_ID_CANCEL_TRANSACTION: {
      const newAcctObj = _.cloneDeep(state);

      newAcctObj[action.payload.account_id].transactions.splice(
        action.payload.index,
        1
      );

      return newAcctObj;
    }

    case ACCOUNTS_BY_ID_REMOVE_TRANSACTIONS: {
      const newAcctObj = _.cloneDeep(state);

      const idListLength = action.transactionIdList.length;

      // Goes through the list of deleted transaction ids, and removes them from the account
      for (let i = 0; i < idListLength; i++) {
        const index = newAcctObj[action.accountId].transactions
          .map((transaction) => {
            return transaction.id;
          })
          .indexOf(action.transactionIdList[i]);

        if (index !== -1) {
          newAcctObj[action.accountId].transactions.splice(index, 1);
        }
      }

      return newAcctObj;
    }

    case UPDATE_ACCOUNT_SCROLL_POSITION: {
      const newAcctObj = _.cloneDeep(state);

      if (newAcctObj[action.accountId]) {
        newAcctObj[action.accountId].startIndex = action.startIndex;
        newAcctObj[action.accountId].stopIndex = action.stopIndex;
        newAcctObj[action.accountId].currentView = action.currentView;
      }

      return newAcctObj;
    }

    default:
      return state;
  }
}
