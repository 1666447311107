import { createTheme } from '@nextui-org/react';

const theme = createTheme({
  type: 'light',
  theme: {
    colors: {
      primary: '#04B5C9',
    },
    fontSizes: {
      base: '14px',
    },
  },
});

export { theme };
