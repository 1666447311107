import {
  SET_INITIAL_TIMEFRAME_SUBCATEGORIES_BY_MONTH,
  TIMEFRAME_SUBCATEGORY_SAVED,
  TIMEFRAME_SUBCATEGORY_UPDATE_BY_MONTH,
  TIMEFRAME_SUBCATEGORY_UPDATE_BY_MONTH_WITH_ID,
} from '../action-list';

export default function(state = {}, action) {
  switch (action.type) {
    case SET_INITIAL_TIMEFRAME_SUBCATEGORIES_BY_MONTH:
      return { ...action.timeframeSubcategoriesByMonth };

    // Adds the timeframe sub when its first created, without an id
    case TIMEFRAME_SUBCATEGORY_SAVED:
      let updatedTimeframeSubList;
      let updatedState = { ...state };

      if (state[action.payload.timeframe]) {
        updatedTimeframeSubList = [...state[action.payload.timeframe]];

        const indexOfTimeframeSub = updatedTimeframeSubList.findIndex(
          (timeframeSub) => {
            return timeframeSub.id === action.payload.id;
          }
        );

        if (indexOfTimeframeSub === -1) {
          updatedTimeframeSubList.push(action.payload);
        } else {
          updatedTimeframeSubList[indexOfTimeframeSub] = action.payload;
        }
      } else {
        // If the current month doesnt exist in the state yet, we need to add it as an empty array before pushing the new timeframe sub in
        updatedState[action.payload.timeframe] = [];
        updatedTimeframeSubList = [...updatedState[action.payload.timeframe]];
        updatedTimeframeSubList.push(action.payload);
      }

      return {
        ...updatedState,
        [action.payload.timeframe]: updatedTimeframeSubList,
      };

    // Updates the timeframe subcategory with the generated UUID
    case TIMEFRAME_SUBCATEGORY_UPDATE_BY_MONTH_WITH_ID:
      const spreadState = { ...state };

      const timeframeSubIdx = spreadState[action.month].findIndex(
        (obj) =>
          obj.timeframe === action.updatedTimeframeSubcategory.timeframe &&
          obj.category_id === action.updatedTimeframeSubcategory.category_id
      );

      const timeframeSubcategories = spreadState[action.month];
      if (!timeframeSubcategories[timeframeSubIdx]) {
        timeframeSubcategories[timeframeSubIdx] = {};
      }
      timeframeSubcategories[timeframeSubIdx].id =
        action.updatedTimeframeSubcategory.id;

      return spreadState;

    case TIMEFRAME_SUBCATEGORY_UPDATE_BY_MONTH:
      const newState = { ...state };
      // need to get index of the item in array that matches this timeframeSubcat id
      let timeframeSubList = newState[action.month];
      const timeframeSubIndex = timeframeSubList.findIndex(
        (obj) => obj.id === action.updatedTimeframeSubcategory.id
      );

      timeframeSubList[timeframeSubIndex] = action.updatedTimeframeSubcategory;

      return {
        ...newState,
        [action.month]: timeframeSubList,
      };

    default:
      return state;
  }
}
