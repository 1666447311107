import {
  BUDGET_ADD_CATEGORY_ROW,
  BUDGET_ADD_CATEGORY_GROUP_ROW,
  BUDGET_DELETE_CATEGORY_ROW,
  BUDGET_DELETE_CATEGORY_GROUP_ROW,
  CATEGORY_ORDER_CHANGE,
  CATEGORIES_RECEIVED,
  CATEGORY_GROUP_SAVED,
  CATEGORY_GROUP_UPDATE_SUBCAT_ORDER,
  CATEGORY_GROUP_MULTI_UPDATE_SUBCAT_ORDER,
  CATEGORY_GROUP_ADD_TEMP_CATEGORY_TO_ORDER,
  CATEGORY_UPDATE_PARENT_ID,
  CATEGORY_UPDATED,
  SUBCATEGORIES_RECEIVED,
  TIMEFRAME_SUBCATEGORIES_RECEIVED,
  TIMEFRAME_SUBCATEGORY_SAVED,
  UPDATE_TIMEFRAME_SUBCATEGORY,
  SUBCATEGORY_ORDER_CHANGE,
} from '../action-list';

export default function(state = [], action) {
  switch (action.type) {
    case CATEGORIES_RECEIVED:
      return {
        ...state,
        categories: action.payload,
      };

    case BUDGET_ADD_CATEGORY_GROUP_ROW:
      return {
        ...state,
        categories: [...state.categories, action.payload],
      };

    case BUDGET_DELETE_CATEGORY_GROUP_ROW:
      const categoryGroupIndex = state.categories.findIndex(
        (obj) => obj.id === action.categoryGroupId
      );

      const updatedCategoryGroups = [...state.categories];
      updatedCategoryGroups.splice(categoryGroupIndex, 1);

      const updatedTimeframeSubcategories = (
        state.timeframe_subcategories || []
      ).filter((tfsc) => {
        return tfsc.section_id !== action.categoryGroupId;
      });

      return {
        ...state,
        categories: updatedCategoryGroups,
        timeframe_subcategories: updatedTimeframeSubcategories,
      };

    case BUDGET_DELETE_CATEGORY_ROW:
      const categoryIndex = state.subcategories.findIndex(
        (obj) => obj.id === action.categoryId
      );

      const updatedCategories = [...state.subcategories];
      updatedCategories.splice(categoryIndex, 1);

      const updatedTimeframeSubcategories2 = state.timeframe_subcategories.filter(
        (tfsc) => {
          return tfsc.category_id !== action.categoryId;
        }
      );

      return {
        ...state,
        subcategories: updatedCategories,
        timeframe_subcategories: updatedTimeframeSubcategories2,
      };

    case CATEGORY_ORDER_CHANGE:
      const newState = { ...state, categories: action.payload };
      return newState;

    case CATEGORY_GROUP_SAVED:
      const categoryGroupIdx = state.categories.findIndex(
        (obj) => obj.id === action.payload.id
      );

      const newCategoryGroups = [
        ...state.categories.slice(0, categoryGroupIdx),
        action.payload,
        ...state.categories.slice(categoryGroupIdx + 1),
      ];

      return {
        ...state,
        categories: newCategoryGroups,
      };

    case CATEGORY_UPDATED:
      const categoryIdx = state.subcategories.findIndex(
        (obj) => obj.id === action.payload.id
      );

      const newCategories = [...state.subcategories];
      newCategories[categoryIdx] = action.payload;

      return {
        ...state,
        subcategories: newCategories,
      };

    case CATEGORY_UPDATE_PARENT_ID:
      const catIdx = state.subcategories.findIndex(
        (obj) => obj.id === action.subcategoryId
      );

      const newCats = [...state.subcategories];

      newCats[catIdx].section_id = action.categoryGroupId;

      return {
        ...state,
        subcategories: newCats,
      };

    case CATEGORY_GROUP_UPDATE_SUBCAT_ORDER:
      const catGroupIdx = state.categories.findIndex(
        (obj) => obj.id === action.updatedCatGroup.id
      );

      const newCatGroups = [...state.categories];

      newCatGroups[catGroupIdx] = action.updatedCatGroup;

      return {
        ...state,
        categories: newCatGroups,
      };

    case CATEGORY_GROUP_ADD_TEMP_CATEGORY_TO_ORDER:
      const catGroupIndex = state.categories.findIndex(
        (obj) => obj.id === action.categoryGroupId
      );

      const newCatGroups2 = [...state.categories];

      newCatGroups2[catGroupIndex].subcategories_order.push(action.categoryId);

      return {
        ...state,
        categories: newCatGroups2,
      };

    case CATEGORY_GROUP_MULTI_UPDATE_SUBCAT_ORDER:
      const prevCatGroupIdx = state.categories.findIndex(
        (obj) => obj.id === action.updatedCatGroup.id
      );

      const newCatGroupIdx = state.categories.findIndex(
        (obj) => obj.id === action.updatedNewCatGroup.id
      );

      const catGroups = [...state.categories];

      // Adds the two newly updated category groups with the changed subcategories_order
      catGroups[prevCatGroupIdx] = action.updatedCatGroup;
      catGroups[newCatGroupIdx] = action.updatedNewCatGroup;

      return {
        ...state,
        categories: catGroups,
      };

    case BUDGET_ADD_CATEGORY_ROW:
      return {
        ...state,
        subcategories: [...state.subcategories, action.payload],
      };

    case SUBCATEGORIES_RECEIVED:
      return {
        ...state,
        subcategories: action.payload,
      };

    case SUBCATEGORY_ORDER_CHANGE:
      const subcategoryIdx = action.payload.findIndex(
        (obj) => obj.id === action.subcategoryId
      );

      const newSubcategories = [...action.payload];
      newSubcategories[subcategoryIdx].section_id = action.newCategoryGroupId;

      return {
        ...state,
        subcategories: newSubcategories,
      };

    case TIMEFRAME_SUBCATEGORIES_RECEIVED:
      return {
        ...state,
        timeframe_subcategories: [...action.tfc],
        cc_timeframe_subcategories: [...action.cc_tfc],
      };

    case UPDATE_TIMEFRAME_SUBCATEGORY:
      // var t0 = performance.now();

      if (action.payload.is_cc === false) {
        // Since it's not a credit card, search for an associated subcategory
        const timeframe_subcategoryIdx = state.timeframe_subcategories.findIndex(
          (obj) =>
            obj.timeframe === action.payload.timeframe &&
            obj.category_id === action.payload.category_id
        );

        const timeframeSubcategories = state.timeframe_subcategories;
        timeframeSubcategories[timeframe_subcategoryIdx].id = action.payload.id;

        return {
          ...state,
          timeframe_subcategories: timeframeSubcategories,
        };
      }
      if (action.payload.is_cc === true) {
        // If it is a credit card, we need to search the cc_timeframe_subcategories instead
        const timeframe_subcategoryIdx = state.cc_timeframe_subcategories.findIndex(
          (obj) =>
            obj.timeframe === action.payload.timeframe &&
            obj.cc_account_id === action.payload.cc_account_id
        );

        const timeframeSubcategories = state.cc_timeframe_subcategories;
        timeframeSubcategories[timeframe_subcategoryIdx].id = action.payload.id;

        return {
          ...state,
          cc_timeframe_subcategories: timeframeSubcategories,
        };
      }

      return state;

    case TIMEFRAME_SUBCATEGORY_SAVED:
      if (action.payload.is_cc === false) {
        if (action.payload.id) {
          const timeframe_subcategoryIdx = state.timeframe_subcategories.findIndex(
            (obj) => obj.id === action.payload.id
          );

          const newTimeframeSubcategories = [
            ...state.timeframe_subcategories.slice(0, timeframe_subcategoryIdx),
            action.payload,
            ...state.timeframe_subcategories.slice(
              timeframe_subcategoryIdx + 1
            ),
          ];

          return {
            ...state,
            timeframe_subcategories: newTimeframeSubcategories,
          };
        }
        const timeframesArray = [...state.timeframe_subcategories];
        timeframesArray.push(action.payload);

        return {
          ...state,
          timeframe_subcategories: timeframesArray,
        };
      }
      if (action.payload.is_cc === true) {
        const timeframe_subcategoryIdx = state.cc_timeframe_subcategories.findIndex(
          (obj) => obj.id === action.payload.id
        );

        const newTimeframeSubcategories = [
          ...state.cc_timeframe_subcategories.slice(
            0,
            timeframe_subcategoryIdx
          ),
          action.payload,
          ...state.cc_timeframe_subcategories.slice(
            timeframe_subcategoryIdx + 1
          ),
        ];

        return {
          ...state,
          cc_timeframe_subcategories: newTimeframeSubcategories,
        };
      }

    default:
      return state;
  }
}
