import React from 'react';
import { connect } from 'react-redux';
import BudgetTableHeaderView from './budget-table-header-view';

const BudgetTableHeaderConnector = ({ activeMonths, settings }) => {
  return (
    <BudgetTableHeaderView activeMonths={activeMonths} settings={settings} />
  );
};

const mapStateToProps = (state) => {
  return {
    activeMonths: state.activeMonths,
    settings: state.settings,
  };
};

export default connect(mapStateToProps)(BudgetTableHeaderConnector);
