import React, { PureComponent } from 'react';
import classNames from 'classnames';
import './editable-input.css';

class EditableInput extends PureComponent {
  state = {
    editing: false,
    value: this.props.value || '',
  };

  componentDidMount() {
    if (!this.props.value) this.inputField.focus();
  }

  inputValueChange = (evt) => {
    this.setState({
      value: evt.target.value,
    });
  };

  cancelEditing = () => {
    this.setState({
      editing: false,
    });
  };

  saveField = () => {
    this.setState({
      editing: false,
    });

    if (this.state.value) {
      this.props.onSave({
        [`${this.props.fieldName}`]: this.state.value,
      });
    }
  };

  toggleEditing = (e) => {
    e.stopPropagation();
    if (this.state.editing) {
      this.cancelEditing();
    } else {
      this.setState({ editing: true });
    }
  };

  handleKeyDown = (e) => {
    const { key } = e;
    switch (key) {
      case 'Enter':
      case 'Escape':
        this.saveField();
        break;
    }
  };

  render() {
    const { editing, value } = this.state;

    return (
      <input
        className={classNames('standard-editable-input', {
          'is-editing': editing,
        })}
        ref={(input) => {
          this.inputField = input;
        }}
        placeholder={this.props.placeholder}
        onChange={this.inputValueChange}
        onFocus={this.toggleEditing}
        onBlur={this.saveField}
        onKeyDown={this.handleKeyDown}
        value={value}
      />
    );
  }
}

export default EditableInput;
