export const accountTypesSpending = [
  'checking',
  'cash',
  'giftcard',
  'spending',
  'savings',
];

export const accountTypesDebt = ['creditcard', 'debtother'];

export const accountTypesOffBudgetAssets = ['investment', 'offbudget_asset'];

export const accountTypesOffBudgetLiabilities = [
  'mortgage',
  'offbudget_liability',
];

// Pass in the account type value, and itll return the formatted, printable version
export const formattedAccountType = (type) => {
  if (accountTypesSpending.includes(type)) {
    return 'Spending & Savings';
  }

  if (accountTypesDebt.includes(type)) {
    return 'Credit Cards & Loans';
  }

  if (accountTypesOffBudgetAssets.includes(type)) {
    return 'Off-Budget Assets';
  }

  if (accountTypesOffBudgetLiabilities.includes(type)) {
    return 'Off-Budget Liabilities';
  }
};
