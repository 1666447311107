import React, { memo } from 'react';
import classNames from 'classnames';
import ButtonBase from './button-base';

import './button-warning.css';

const ButtonWarning = (props) => {
  const { className, ...extraProps } = props;

  return (
    <ButtonBase
      className={classNames('button-warning', className)}
      {...extraProps}
    />
  );
};

export default memo(ButtonWarning);
