import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import {
  cancelAddTransaction,
  toggleEditTransaction,
  toggleIncludeNow,
  savePayee,
  saveApproveTransaction,
  saveTransaction,
  updateScrollPosition,
  cancelSeries,
} from '../../../../actions/account';
import { saveTimeframeSubcategory } from '../../../../actions/budget';
import TransactionListItemContainer from './transaction-list-item-container';
import budget from '../../../../reducers/budget';

const TransactionListItemConnector = (props) => {
  return (
    <TransactionListItemContainer
      accountDetails={props.accountDetails}
      accounts={props.accounts}
      accountList={props.budgetDetails.accountList}
      budget={props.budget}
      budgetDetails={props.budgetDetails}
      cancelEditableRow={props.cancelAddTransaction}
      categoryAndAccountList={props.budgetDetails.categoryAndAccountList}
      formattedCategoryAccountList={props.formattedCategoryAccountList}
      handleToggleEditTransaction={props.toggleEditTransaction}
      isSelectEnabled={props.isSelectEnabled}
      isMobile={props.isMobile}
      onSelected={props.onSelected}
      payees={props.payees}
      savePayee={props.savePayee}
      saveApproveTransaction={props.saveApproveTransaction}
      saveTransaction={props.saveTransaction}
      saveTimeframeSubcategory={props.saveTimeframeSubcategory}
      settings={props.settings}
      selectedTransactions={props.selectedTransactions}
      startIndex={props.startIndex}
      stopIndex={props.stopIndex}
      subcategories={props.budget.subcategories}
      toggleEditingRow={props.toggleEditingRow}
      toggleIncludeNow={props.toggleIncludeNow}
      transaction={props.transaction}
      transactionBeingEdited={props.transactionBeingEdited}
      updateScrollPosition={props.updateScrollPosition}
      onChildChange={props.onChildChange}
      onChildCollapse={props.onChildCollapse}
      childCollapsed={props.childCollapsed}
      cancelSeries={props.cancelSeries}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    accounts: state.accounts,
    budget: state.budget,
    budgetDetails: state.budgetDetails,
    payees: state.payees,
    settings: state.settings,
    transactionBeingEdited: state.transactionBeingEdited,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    cancelAddTransaction,
    savePayee,
    toggleEditTransaction,
    toggleIncludeNow,
    saveApproveTransaction,
    saveTimeframeSubcategory,
    saveTransaction,
    updateScrollPosition,
    cancelSeries,
  })(TransactionListItemConnector)
);
