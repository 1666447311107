import React, { Fragment, memo } from 'react';
import ManageBudgetItem from './manage-budget-item';

const ManageBudgetsView = ({
  budgetDetails,
  defaultBudget,
  deleteBudgetAlert,
  setDefaultBudget,
  onClose,
}) => {
  if (!budgetDetails.userBudgets) {
    return <i className="far fa-spinner-third fa-spin centered-loading" />;
  }

  return (
    <div className="manage-budgets">
      {budgetDetails &&
        budgetDetails.userBudgets &&
        budgetDetails.userBudgets.map((budget) => {
          return (
            <ManageBudgetItem
              key={budget.id}
              defaultBudget={defaultBudget}
              deleteBudgetAlert={deleteBudgetAlert}
              budget={budget}
              setDefaultBudget={setDefaultBudget}
              onClose={onClose}
            />
          );
        })}
    </div>
  );
};

export default memo(ManageBudgetsView);
