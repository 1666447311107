import { createSelector } from 'reselect';
import { add, sub, sum } from '../utilities/budgeting-formulas';
import moment from 'moment';

const emptyArray = [];

const getAllTransactionsByAccountId = ({ accountsById }, { match }) => {
  // Grabs the account ID from the route params
  const accountIdFromRoute = match.params.id;

  return (
    (accountsById &&
      accountsById[accountIdFromRoute] &&
      accountsById[accountIdFromRoute].transactions) ||
    emptyArray
  );
};

const getGlobalLtbSetting = ({ settings }) => {
  // Grabs the account ID from the route params
  return settings.global_ltb;
};

const transactionAmountsWithChildren = ({ amount, child_transactions }) => {
  return child_transactions && child_transactions.length
    ? sum(child_transactions, ({ amount }) => amount)
    : amount;
};

const calculateBalances = (transactions, globalLtb) => {
  const transactionsList = [...transactions];
  // TODO: Possibly refactor this for more performance
  let totalApproved = 0;
  let totalUnapproved = 0;

  for (let i = 0; i < transactionsList.length; i++) {
    if (
      !transactionsList[i].is_imported &&
      (moment(transactionsList[i].date).isSameOrBefore(
        moment()
          .local()
          .endOf('day')
      ) ||
        (!globalLtb && transactionsList[i].include_now))
    ) {
      if (transactionsList[i].approved) {
        if (transactionsList[i].type === 'outflow') {
          totalApproved = sub(
            totalApproved,
            transactionAmountsWithChildren(transactionsList[i])
          );
        } else {
          totalApproved = add(
            totalApproved,
            transactionAmountsWithChildren(transactionsList[i])
          );
        }
      } else {
        if (transactionsList[i].type === 'outflow') {
          totalUnapproved = sub(
            totalUnapproved,
            transactionAmountsWithChildren(transactionsList[i])
          );
        } else {
          totalUnapproved = add(
            totalUnapproved,
            transactionAmountsWithChildren(transactionsList[i])
          );
        }
      }
    }
  }

  return {
    totalUnapproved,
    totalApproved,
  };
};

export const getAccountBalanceSheetFactory = () => {
  return createSelector(
    getAllTransactionsByAccountId,
    getGlobalLtbSetting,
    (allTransactions, globalLtb) => {
      return calculateBalances(allTransactions, globalLtb);
    }
  );
};
