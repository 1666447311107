import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createUser, signup } from '../../actions/session';
import SignupForm from '../forms/signup-form';
import Navbar from '../layout/navbar-view';

class Signup extends Component {
  handleSignup = (data) => this.props.signup(data, this.context.router);

  render() {
    return <SignupForm onSubmit={this.handleSignup} />;
  }
}

export default connect(
  null,
  { signup }
)(Signup);
