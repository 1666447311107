import React, { Component } from 'react';
import ButtonPrimary from '../../common/button/button-primary';
import ButtonSecondary from '../../common/button/button-secondary';

class NewBudgetView extends Component {
  state = {
    name: '',
    makeDefault: false,
  };

  componentDidMount() {
    this.nameField.focus();
  }

  setAsDefaultBudget = ({ target }) => {
    this.setState({ makeDefault: target.checked });
  };

  handleNameChange = ({ target }) => {
    const name = target.value;

    this.setState({
      name,
    });
  };

  handleFocus = ({ target }) => target.select();

  onKeyDown = (event) => {
    if (event.key === 'Enter' && this.state.name !== '') {
      event.preventDefault();
      event.stopPropagation();

      this.props.createNewBudget(this.state, this.props.currentUserId);
      this.props.onClose();
    }
  };

  handleSave = () => {
    this.props.createNewBudget(this.state, this.props.currentUserId);
    this.props.onClose();
  };

  render() {
    const { name, makeDefault } = this.state;

    return (
      <div className="form-body new-budget-form">
        <div className="form-group">
          <label className="input-label" htmlFor="budget-name">
            Budget Name
          </label>
          <input
            className="input-field"
            name="budget-name"
            placeholder="Name your budget"
            ref={(input) => (this.nameField = input)}
            value={name || ''}
            onFocus={this.handleFocus}
            onKeyDown={this.onKeyDown}
            onChange={this.handleNameChange}
          />
        </div>
        <div className="form-group form-column">
          <input
            type="checkbox"
            name="is-default"
            checked={makeDefault}
            onChange={this.setAsDefaultBudget}
          />
          <span className="input-label default-budget" htmlFor="is-default">
            Make this my default budget
          </span>
        </div>
        <div className="form-controls-group">
          <ButtonSecondary onClick={this.props.onClose}>Cancel</ButtonSecondary>
          <ButtonPrimary onClick={this.handleSave} isDisabled={!name.length}>
            Create Budget
          </ButtonPrimary>
        </div>
      </div>
    );
  }
}

export default NewBudgetView;
