import React from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import './category-activity-table.css';

const formatTransactionDate = (transactionDate, settings) => {
  return moment(transactionDate).format(settings.date_format);
};

const formatCurrencyAndSign = (transactionType, settings) => {
  const sign = transactionType === 'inflow' ? '+' : '-';
  const currencySymbol = (settings.show_symbol && settings.symbol) || '';

  return `${sign}${currencySymbol}`;
};

function CategoryActivityTableView({ accountsById, settings, transactions }) {
  const renderTableRows = (transactions, settings) => {
    return (
      transactions &&
      transactions.map((transaction) => {
        return (
          <tr key={transaction.id}>
            <td className="date-column">
              {formatTransactionDate(transaction.date, settings)}
            </td>
            <td className="account-column">
              {accountsById[transaction.account_id].name}
            </td>
            <td className="payee-column">{transaction.payee}</td>
            <td className="notes-column">{transaction.memo}</td>
            <td className="amount-column">
              <NumberFormat
                displayType="text"
                value={transaction.amount}
                prefix={formatCurrencyAndSign(transaction.type, settings) || ''}
                thousandSeparator={settings.thousands}
                decimalSeparator={settings.decimal}
                decimalScale={2}
                isNumericString={true}
                fixedDecimalScale={true}
              />
            </td>
          </tr>
        );
      })
    );
  };

  return (
    <div className="category-activity-table">
      <table>
        <thead>
          <tr>
            <th className="date-column">Date</th>
            <th className="account-column">Account</th>
            <th className="payee-column">Payee</th>
            <th className="notes-column">Notes</th>
            <th className="amount-column">Amount</th>
          </tr>
        </thead>
        <tbody>{renderTableRows(transactions, settings)}</tbody>
      </table>
    </div>
  );
}

export default CategoryActivityTableView;
