import React, { Component } from 'react';
import moment from 'moment';
import LeftToBudgetDesktopView from './left-to-budget-desktop-view';
import LeftToBudgetTabletView from './left-to-budget-tablet-view';
import LeftToBudgetMobileView from './left-to-budget-mobile-view';
import { getMonthsToDisplay } from '../../../utilities/helpers';
import LeftToBudgetMultiMonthView from './left-to-budget-multimonth-view';

class LeftToBudgetContainer extends Component {
  renderLTBByResolution = () => {
    const {
      activeMonths,
      currentMonth,
      ltbBreakdownByMonth,
      settings,
    } = this.props;

    const windowSize = window.innerWidth;
    const monthsDisplayed = getMonthsToDisplay(
      activeMonths,
      settings.month_view
    );

    if (settings.month_view === 'multi' && windowSize > 1199) {
      return (
        <div className="ltb-row">
          <div className="ltb-spacer-1" />
          <div className="ltb-spacer-2" />
          {monthsDisplayed.map((monthToDisplay, index) => {
            const lastMonthInMMM = moment(monthToDisplay, 'MMYYYY')
              .subtract(1, 'month')
              .format('MMM');
            const thisMonthInMMM = moment(monthToDisplay, 'MMYYYY').format(
              'MMM'
            );

            return (
              <LeftToBudgetMultiMonthView
                fullMonthString={moment(monthToDisplay, 'MMYYYY').format(
                  'MMMM YYYY'
                )}
                isMiddleMonth={index === 1}
                key={monthToDisplay}
                lastMonthString={lastMonthInMMM}
                thisMonthString={thisMonthInMMM}
                ltbBreakdown={ltbBreakdownByMonth[monthToDisplay]}
                settings={settings}
              />
            );
          })}
        </div>
      );
    }

    const lastMonthInMMM = moment(activeMonths.prevMonth).format('MMM');
    const thisMonthInMMM = moment(activeMonths.currentMonth).format('MMM');

    if (windowSize < 1025) {
      // mobile LTB view
      return (
        <LeftToBudgetMobileView
          lastMonthString={lastMonthInMMM}
          thisMonthString={thisMonthInMMM}
          ltbBreakdown={ltbBreakdownByMonth[currentMonth]}
          settings={settings}
        />
      );
    } else if (windowSize > 1024 && windowSize < 1440) {
      // tablet and small monitor view
      return (
        <LeftToBudgetTabletView
          lastMonthString={lastMonthInMMM}
          thisMonthString={thisMonthInMMM}
          ltbBreakdown={ltbBreakdownByMonth[currentMonth]}
          settings={settings}
        />
      );
    } else if (windowSize >= 1440) {
      // desktop view
      return (
        <LeftToBudgetDesktopView
          lastMonthString={lastMonthInMMM}
          thisMonthString={thisMonthInMMM}
          ltbBreakdown={ltbBreakdownByMonth[currentMonth]}
          settings={settings}
        />
      );
    }
  };

  render() {
    return this.renderLTBByResolution();
  }
}

export default LeftToBudgetContainer;
