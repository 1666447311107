import React, { useState } from 'react';
import isUndefined from 'lodash.isundefined';
import { Card, Loading, Radio, Row, Spacer, Text } from '@nextui-org/react';
import { Plans } from './Subscription.types';
import { useMutateSubscriptionPlan } from './SelectPlan.queries';
import { Button } from '../button';
import {
  PADDLE_LIFETIME,
  PADDLE_MONTH,
  PADDLE_TRIAL,
  PADDLE_YEAR,
} from '../../../../constants';
import toast from 'react-hot-toast';

const getPlanId = (plan: Plans) => {
  if (isUndefined(PADDLE_YEAR)) {
    return 0;
  }

  switch (plan) {
    case Plans.TRIAL:
      return Number(PADDLE_TRIAL);
    case Plans.MONTH:
      return Number(PADDLE_MONTH);
    case Plans.YEAR:
      return Number(PADDLE_YEAR);
    case Plans.LIFETIME:
      return Number(PADDLE_LIFETIME);

    default:
      return Number(PADDLE_TRIAL);
  }
};

interface SelectPlanProps {
  email: string;
  selectedPlan?: Plans;
  isLoading?: boolean;
  daysLeft?: number;
}

function SelectPlan({
  email,
  selectedPlan = Plans.TRIAL,
  isLoading = false,
  daysLeft,
}: SelectPlanProps) {
  const [plan, setPlan] = useState<Plans>(() => selectedPlan);

  const updateSubscriptionPlan = useMutateSubscriptionPlan();

  const isLifetime = selectedPlan === Plans.LIFETIME;
  const isMonthly = selectedPlan === Plans.MONTH;
  const isYearly = selectedPlan === Plans.YEAR;
  const isTrial = selectedPlan === Plans.TRIAL;

  const label = isTrial ? 'Subscribe' : 'Update Subscription';

  const planId = getPlanId(plan);

  const updateSubscription = () => {
    // Create a subscription
    if (selectedPlan === Plans.TRIAL) {
      if (!isUndefined(planId)) {
        window.Paddle.Checkout.open({
          product: planId,
          email,
          passthrough: JSON.stringify({
            email,
          }),
        });
      }
      // Update a subscription
    } else {
      // window.Paddle.Checkout

      if (!isUndefined(planId)) {
        if (
          window.confirm(
            'We will charge the card on file for the prorated rate and you will receive an email with the confirmation and additional information.'
          )
        ) {
          const updateSubscriptionToast = toast.loading(
            'Updating your subscription.'
          );

          updateSubscriptionPlan.mutate(planId, {
            onSuccess() {
              toast.success('Subscription updated!', {
                id: updateSubscriptionToast,
              });
            },
            onError() {
              toast.error(
                'There was an erryr while trying to update the subscription.',
                {
                  id: updateSubscriptionToast,
                }
              );
            },
          });
        }
      }
    }
  };

  return (
    <Card variant="bordered">
      <Card.Header>Plans</Card.Header>
      <Card.Divider />
      <Card.Body>
        {isLoading ? (
          <Row css={{ py: '$md', fd: 'column' }} align="center">
            <Loading type="points" size="xl" />
            <Spacer y={1} />
            <Text size={16} color="$gray800">
              Loading your billing information
            </Text>
          </Row>
        ) : (
          <>
            <Row css={{ flexDirection: 'column' }}>
              {isLifetime ? (
                <Text size={14}>
                  Nothing to do here. You have the Lifetime subscription{' '}
                  <span role="img" aria-hidden={true}>
                    🎉{' '}
                  </span>
                  . Thank you!
                </Text>
              ) : (
                <Radio.Group
                  aria-label="Plans"
                  defaultValue={selectedPlan}
                  onChange={(value) => setPlan(value as Plans)}
                >
                  {isTrial && (
                    <Radio
                      value={Plans.TRIAL}
                      description={`${daysLeft} days left.`}
                      isDisabled={isTrial}
                    >
                      Trial {isTrial && '(Current subscription)'}
                    </Radio>
                  )}

                  <Radio
                    value={Plans.MONTH}
                    description="For month-to-month flexibility."
                    isDisabled={isMonthly}
                  >
                    Monthly $3.00 {isMonthly && '(Current subscription)'}
                  </Radio>

                  <Radio
                    value={Plans.YEAR}
                    description="For more long-term savings."
                    isDisabled={isYearly}
                  >
                    Yearly $24.00 {isYearly && '(Current subscription)'}
                  </Radio>
                  <Radio
                    value={Plans.LIFETIME}
                    description="Helps support early Budgetwise growth."
                  >
                    Lifetime $99.00
                  </Radio>
                </Radio.Group>
              )}
            </Row>
          </>
        )}
      </Card.Body>
      <Card.Divider />
      <Card.Footer>
        <Row justify="flex-end">
          <Button
            disabled={isLifetime}
            onClick={updateSubscription}
            isLoading={updateSubscriptionPlan.isLoading}
          >
            {label}
          </Button>
        </Row>
      </Card.Footer>
    </Card>
  );
}

export { SelectPlan };
