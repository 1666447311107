import { api } from '../../api';

async function fetchImports(accountId) {
  const response = await api.get('/imports', {
    params: {
      account_id: accountId,
    },
  });

  return response.data;
}

export { fetchImports };
