import moment from 'moment';
import { MONTH_NEXT, MONTH_PREVIOUS } from '../action-list';

const initialState = {
  currentMonth: moment(),
  prev2Month: moment().subtract(2, 'month'),
  prevMonth: moment().subtract(1, 'month'),
  nextMonth: moment().add(1, 'month'),
  next2Month: moment().add(2, 'month'),
};

export default function(state = initialState, action) {
  switch (action.type) {
    case MONTH_NEXT:
      return {
        prevMonth: state.prevMonth.add(1, 'month'),
        nextMonth: state.nextMonth.add(1, 'month'),
        prev2Month: state.prev2Month.add(1, 'month'),
        next2Month: state.next2Month.add(1, 'month'),
        currentMonth: state.currentMonth.add(1, 'month'),
      };

    case MONTH_PREVIOUS:
      return {
        currentMonth: state.currentMonth.subtract(1, 'month'),
        prevMonth: state.prevMonth.subtract(1, 'month'),
        nextMonth: state.nextMonth.subtract(1, 'month'),
        prev2Month: state.prev2Month.subtract(1, 'month'),
        next2Month: state.next2Month.subtract(1, 'month'),
      };

    default:
      return state;
  }
}
