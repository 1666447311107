import React, { Fragment, memo, PureComponent } from 'react';
import { List } from 'react-virtualized';
import { isMobile } from '../../../utilities/helpers';
import TransactionsHeader from './transactions-header';
import TransactionListItem from './transaction-list-item';
import './transaction-list-item/transaction-list-item.css';

class TransactionsVirtualizedList extends PureComponent {
  state = {
    idOfEditedRow: this.props.transactionBeingEdited || null,
    adjustChildren: {},
    childCollapsed: {},
    startIndex: -1,
    stopIndex: -1,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.transactionBeingEdited &&
      this.props.transactionBeingEdited !== prevProps.transactionBeingEdited
    ) {
      this.list.recomputeRowHeights();
      this.list.forceUpdateGrid();
    }

    if (
      this.props.transactionBeingEdited !== prevProps.transactionBeingEdited
    ) {
      this.list.recomputeRowHeights();
      this.list.forceUpdateGrid();
    }

    if (
      prevProps.transactionBeingEdited &&
      this.props.transactionBeingEdited === ''
    ) {
      this.list.recomputeRowHeights();
      this.list.forceUpdateGrid();
    }

    if (
      this.props.transactions &&
      this.props.transactions.length === prevProps.transactions.length
    ) {
      // this.list.recomputeRowHeights();
      // this.list.forceUpdateGrid();
    } else {
      this.list && this.list.recomputeRowHeights();
      this.list && this.list.forceUpdateGrid();
    }

    if (this.props.isSelectEnabled !== prevProps.isSelectEnabled) {
      this.list.recomputeRowHeights();
      this.list.forceUpdateGrid();
    }

    if (
      this.props.selectedTransactions &&
      this.props.selectedTransactions.length !==
        prevProps.selectedTransactions.length
    ) {
      this.list.recomputeRowHeights();
      this.list.forceUpdateGrid();
    }

    // scroll to the top when a new transaction is made
    if (
      this.props.transactions &&
      this.props.transactions.length - 1 === prevProps.transactions.length
    ) {
      this.list.scrollToRow(0);
    }

    if (
      (this.props.accountDetails && this.props.accountDetails.id) !==
      (prevProps.accountDetails && prevProps.accountDetails.id)
    ) {
      this.list.recomputeRowHeights();
      this.list.forceUpdateGrid();
    }
  }

  getRowHeight = ({ index }) => {
    const children =
      (this.props.transactions[index].child_transactions.length +
        (this.state.adjustChildren[this.props.transactions[index].id] || 0)) *
      !(this.childCollapsed(index) || 0);
    if (
      // If its a newly created transaction
      // we can probably remove this because the newly made transaction will be considered "being edited" from reducer
      // (this.props.transactions && !this.props.transactions[index].created) ||
      // Or if it's currently being edited
      this.props.transactions &&
      this.props.transactions[index].id === this.props.transactionBeingEdited
    ) {
      const childHeight = 41;
      this.list.recomputeRowHeights(index);
      this.list.forceUpdateGrid();
      return 79 + childHeight * (children && children + 1);
    }
    const childHeight = 43;
    return 35 + childHeight * children;
  };

  handleChildChange = (e, id) => {
    this.setState(({ adjustChildren }) => ({
      adjustChildren: {
        ...adjustChildren,
        [id]: e + (adjustChildren[id] || 0),
      },
    }));
  };

  handleChildCollapse = (e, id) => {
    this.setState(({ childCollapsed }) => ({
      childCollapsed: { ...childCollapsed, [id]: e },
    }));
    this.list.recomputeRowHeights();
    this.list.forceUpdateGrid();
  };

  handleToggleEditingRow = (id) => {
    this.setState({ idOfEditedRow: id });
    this.list.recomputeRowHeights();
    this.list.forceUpdateGrid();
  };

  childCollapsed(index) {
    const id = this.props.transactions[index].id;
    if (id === this.state.idOfEditedRow) {
      const childCount =
        this.props.transactions[index].child_transactions.length +
        (this.state.adjustChildren[id] || 0);
      return !childCount;
    } else {
      const collapsed = this.state.childCollapsed[id];
      return collapsed == undefined ? true : collapsed;
    }
  }

  rowRenderer = ({ index, isScrolling, isVisible, key, parent, style }) => {
    return (
      <div style={style} key={this.props.transactions[index].id}>
        <TransactionListItem
          toggleEditingRow={this.handleToggleEditingRow}
          accountDetails={this.props.accountDetails}
          isSelectEnabled={this.props.isSelectEnabled}
          isMobile={this.props.isMobile}
          onSelected={this.props.onSelected}
          selectedTransactions={this.props.selectedTransactions}
          transaction={this.props.transactions[index]}
          startIndex={this.state.startIndex}
          stopIndex={this.state.stopIndex}
          onChildChange={(e) =>
            this.handleChildChange(e, this.props.transactions[index].id)
          }
          onChildCollapse={(e) =>
            this.handleChildCollapse(e, this.props.transactions[index].id)
          }
          childCollapsed={this.childCollapsed(index)}
        />
      </div>
    );
  };

  onRowsRendered = ({ startIndex, stopIndex }) => {
    this.setState({
      startIndex,
      stopIndex,
    });
  };

  renderTransactionHeader = () => {
    return !isMobile() ? (
      <TransactionsHeader
        accountDetails={this.props.accountDetails}
        width={this.props.width}
        inflowFirst={false}
      />
    ) : null;
  };

  render() {
    const {
      accountDetails,
      accountsById,
      height,
      isMobile,
      width,
      transactions,
    } = this.props;

    const scrollStartIndex =
      (accountsById &&
        accountsById[accountDetails] &&
        accountsById[accountDetails.id] &&
        accountsById[accountDetails.id].stopIndex) ||
      -1;

    return (
      <Fragment>
        {this.renderTransactionHeader()}
        <List
          height={height}
          width={width}
          rowHeight={isMobile ? 58 : this.getRowHeight}
          estimatedRowSize={31}
          scrollToIndex={scrollStartIndex}
          style={{ overflow: 'overlay' }}
          ref={(ref) => {
            this.list = ref;
          }}
          rowCount={transactions.length}
          overscanRowCount={10}
          rowRenderer={this.rowRenderer}
          onRowsRendered={this.onRowsRendered}
        />
      </Fragment>
    );
  }
}

export default memo(TransactionsVirtualizedList);
