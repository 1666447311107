import React, { ReactNode } from 'react';
import classNames from 'classnames';

export interface BoxProps {
  children: ReactNode;
  className?: string;
}

function Box(props: BoxProps) {
  const { children, className, ...rest } = props;
  const classes = classNames('box-border', 'm-0', 'min-w-0', className);
  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
}

export { Box };
