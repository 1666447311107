import { createSelector } from 'reselect';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import {
  add,
  sub,
  sum,
  sumTimeframeSubcategories,
} from '../../src/utilities/budgeting-formulas';

const moment = extendMoment(Moment);

const getAllTimeframes = ({ timeframes }) => timeframes;

const getAllTimeframeSubcategories = ({ budget }) =>
  budget.timeframe_subcategories;

const getCurrentTimeframe = (state, { monthToDisplay }) => monthToDisplay;

const getTimeframeSubcategories = ({ budget }, { monthToDisplay }) => {
  if (budget.timeframe_subcategories && budget.timeframe_subcategories.length) {
    return budget.timeframe_subcategories.filter((tf_subcategory) => {
      return tf_subcategory.timeframe === monthToDisplay;
    });
  }

  return [];
};

const getAllTransactionsByMonth = (
  { transactionsByMonth },
  { monthToDisplay }
) => {
  return transactionsByMonth[monthToDisplay];
};

const getTimeframeSubcategoriesWithoutCC = ({ budget }, { monthToDisplay }) => {
  if (budget.timeframe_subcategories && budget.timeframe_subcategories.length) {
    return budget.timeframe_subcategories.filter((tf_subcategory) => {
      return (
        tf_subcategory.timeframe === monthToDisplay &&
        tf_subcategory.category_id !== 'creditcard'
      );
    });
  }

  return [];
};

const getTimeframeSubcategoriesWithCC = ({ budget }, { monthToDisplay }) => {
  if (
    budget.cc_timeframe_subcategories &&
    budget.cc_timeframe_subcategories.length
  ) {
    return budget.cc_timeframe_subcategories.filter((cc_tf_subcategory) => {
      return (
        cc_tf_subcategory.timeframe === monthToDisplay &&
        cc_tf_subcategory.category_id === 'creditcard'
      );
    });
  }

  return [];
};

export const sumBudgetedTotalsFactory = () => {
  return createSelector(
    getTimeframeSubcategories,
    (timeframeSubcategories) => {
      return sum(timeframeSubcategories, 'budgeted');
    }
  );
};

export const sumActivityTotalsFactory = () => {
  return createSelector(
    getTimeframeSubcategoriesWithoutCC,
    (timeframeSubcategories) => {
      return sum(timeframeSubcategories, 'spent');
    }
  );
};

const sumAllUncategorizedForMonth = (transactions) => {
  const transactionsToSum =
    (transactions &&
      transactions.filter((transaction) => {
        return (
          (transaction.category === null &&
            transaction.ltb_next === false &&
            transaction.off_budget === false) ||
          (transaction.category === null &&
            transaction.ltb_next === false &&
            transaction.off_budget === false &&
            transaction.include_now === true)
        );
      })) ||
    [];

  return transactionsToSum.length
    ? transactionsToSum.reduce((prevTransaction, currTransaction) => {
        if (!currTransaction.ltb_next) {
          return currTransaction.type === 'outflow'
            ? sub(prevTransaction, currTransaction.amount)
            : add(prevTransaction, currTransaction.amount);
        }
      }, 0)
    : null;
};

export const sumUncategorizedFactory = () => {
  return createSelector(
    getAllTransactionsByMonth,
    (allTransactionsByMonth) => {
      return sumAllUncategorizedForMonth(allTransactionsByMonth);
    }
  );
};

export const sumRemainingTotalsFactory = () => {
  return createSelector(
    [getCurrentTimeframe, getAllTimeframeSubcategories, getAllTimeframes],
    (currentTimeframe, timeframeSubcategories, timeframes) => {
      return sumTimeframeSubcategories(
        currentTimeframe,
        timeframes,
        timeframeSubcategories,
        (subCat, month) => {
          return (
            subCat.timeframe === month.format('MMYYYY') &&
            subCat.category_id !== ('creditcard' || 'debtother') &&
            moment(subCat.timeframe, 'MMYYYY').isSameOrBefore(
              moment(currentTimeframe, 'MMYYYY')
            ) &&
            subCat.is_cc === false
          );
        }
      );
    }
  );
};
