import React, { Component } from 'react';
import classNames from 'classnames';
import BudgetTableHeader from './budget-table-header';
import BudgetTableBody from './budget-table-body';
import LeftToBudget from '../left-to-budget';

import './budget-table.css';

class BudgetTableContainer extends Component {
  renderBudgetTable = () => {
    const { categoriesLength, settings } = this.props;

    return categoriesLength > 0 ? (
      <section
        className={classNames('budget-table-wrapper', {
          'wrapper-multi-month':
            settings.month_view === 'multi' && window.innerWidth > 1199,
        })}
      >
        <LeftToBudget />
        <BudgetTableHeader />
        <BudgetTableBody />
      </section>
    ) : (
      <span className="empty-state-msg-budget">
        No category groups found.
        <br />
        <br />
        Create a new group to get started!
      </span>
    );
  };

  render() {
    return this.renderBudgetTable();
  }
}

export default BudgetTableContainer;
