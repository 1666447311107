import React from 'react';
import { connect } from 'react-redux';
import {
  updateCCOrderInGroupLocally,
  updateCCOrderInGroup,
} from '../../../../actions/budget';
import BudgetTableCCCategoryGroupContainer from './budget-table-cc-category-group-container';

const BudgetTableCCCategoryGroupConnector = ({
  activeMonths,
  accounts,
  budgetDetails,
  settings,
  updateCCOrderInGroupLocally,
  updateCCOrderInGroup,
}) => {
  return (
    <BudgetTableCCCategoryGroupContainer
      activeMonths={activeMonths}
      budgetDetails={budgetDetails}
      accounts={accounts}
      settings={settings}
      updateCCOrderInGroupLocally={updateCCOrderInGroupLocally}
      updateCCOrderInGroup={updateCCOrderInGroup}
    />
  );
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    return {
      activeMonths: state.activeMonths,
      accounts: state.accounts,
      budgetDetails: state.budgetDetails,
      settings: state.settings,
    };
  };
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  { updateCCOrderInGroupLocally, updateCCOrderInGroup }
)(BudgetTableCCCategoryGroupConnector);
