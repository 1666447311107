import React, { memo } from 'react';
import BudgetTableCCCategoryGroupMonth from './budget-table-cc-category-group-month';
import { isMobile } from '../../../../utilities/helpers';

const BudgetTableCCCategoryGroupView = ({ children, monthsDisplayed }) => {
  return (
    <section className="budget-category-group">
      <div className="budget-table-row category-group-row">
        <div className="budget-column-name">
          <span style={{ paddingLeft: '4px' }}>
            <br />
            Credit Cards & Loans
          </span>
        </div>
        {!isMobile() && <div className="budget-column-date" />}
        {monthsDisplayed.map((monthToDisplay, index) => {
          return (
            <BudgetTableCCCategoryGroupMonth
              key={monthToDisplay}
              isMiddleMonth={index === 1}
              monthToDisplay={monthToDisplay}
            />
          );
        })}
      </div>
      <div className="budget-table-category-body">{children}</div>
    </section>
  );
};

export default memo(BudgetTableCCCategoryGroupView);
