import React, { Component } from 'react';
import ButtonPrimary from '../common/button/button-primary';
import ButtonSecondary from '../common/button/button-secondary';

class RenameAccountForm extends Component {
  state = {
    name: '',
    ...(this.props.accountDetails || {}),
  };

  componentDidMount() {
    this.nameField.focus();
  }

  handleNameChange = ({ target }) => {
    const name = target.value;

    this.setState({
      name,
    });
  };

  handleFocus = ({ target }) => target.select();

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      this.handleSave();
    }
  };

  handleSave = () => {
    this.props.onSave(this.props.accountDetails.id, this.state);
    this.props.onClose();
  };

  render() {
    return (
      <div className="form-body">
        <div className="form-group">
          <label className="input-label" htmlFor="budget-name">
            Account Name
          </label>
          <input
            className="input-field"
            name="budget-name"
            placeholder="Rename your account"
            ref={(input) => (this.nameField = input)}
            value={this.state.name || ''}
            onFocus={this.handleFocus}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleNameChange}
          />
        </div>
        <div className="form-controls-group">
          <ButtonSecondary onClick={this.props.onClose}>Cancel</ButtonSecondary>
          <ButtonPrimary
            onClick={this.handleSave}
            isDisabled={!this.state.name.length}
          >
            Apply Changes
          </ButtonPrimary>
        </div>
      </div>
    );
  }
}

export default RenameAccountForm;
