import { PAYEE_FETCH_SUCCESS } from '../action-list';
import { headers } from '../api';
import { ELIXIR_API } from '../constants';
import axios from 'axios';

export const fetchPayees = (budgetId) => {
  return (dispatch) => {
    axios
      .get(`${ELIXIR_API}/budgets/${budgetId}/payees`, { headers: headers() })
      .then((res) => {
        dispatch(payeeFetchSuccess(res.data.data));
      });
  };
};

export function payeeFetchSuccess(payload) {
  return {
    type: PAYEE_FETCH_SUCCESS,
    payload,
  };
}

export const deleteBulkPayees = (budgetId, payeesSelected) => {
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${ELIXIR_API}/budgets/${budgetId}/payees/bulk_delete`,
      headers: headers(),
      data: {
        payload: payeesSelected,
      },
    }).then((res) => {
      dispatch(fetchPayees(budgetId));
    });
  };
};
