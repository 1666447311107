import React from 'react';
import { PropTypes } from 'prop-types';
import { Route, Redirect, Switch } from 'react-router';

const MatchAuthenticated = ({
  path,
  exact,
  isAuthenticated,
  willAuthenticate,
  component: Component,
}) => (
  <Switch>
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        if (isAuthenticated) {
          return <Component {...props} />;
        }
        if (willAuthenticate) {
          return null;
        }
        if (!willAuthenticate && !isAuthenticated) {
          return <Redirect exact={true} to={{ pathname: '/login' }} />;
        }

        return null;
      }}
    />;
  </Switch>
);

MatchAuthenticated.propTypes = {
  path: PropTypes.string,
  exact: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  willAuthenticate: PropTypes.bool,
};

export default MatchAuthenticated;
