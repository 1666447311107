import React from 'react';
import { connect } from 'react-redux';
import BudgetTableCategoryGroupMonthContainer from './budget-table-category-group-month-container';
import {
  getCategoryGroupTotalActivityByMonthFactory,
  getCategoryGroupTotalBudgetedByMonthFactory,
  getCategoryGroupTotalRemainingByMonthFactory,
} from '../../../../../selectors/category-group-totals';

const BudgetTableCategoryGroupMonthConnector = ({
  categoryGroupTotalActivityByMonth,
  categoryGroupTotalBudgetedByMonth,
  categoryGroupTotalRemainingByMonth,
  isMiddleColumn,
  monthToDisplay,
  settings,
}) => {
  return (
    <BudgetTableCategoryGroupMonthContainer
      categoryGroupTotalActivityByMonth={categoryGroupTotalActivityByMonth}
      categoryGroupTotalBudgetedByMonth={categoryGroupTotalBudgetedByMonth}
      categoryGroupTotalRemainingByMonth={categoryGroupTotalRemainingByMonth}
      isMiddleColumn={isMiddleColumn}
      monthToDisplay={monthToDisplay}
      settings={settings}
    />
  );
};

const makeMapStateToProps = () => {
  const getCategoryGroupTotalBudgetedByMonth = getCategoryGroupTotalBudgetedByMonthFactory();
  const getCategoryGroupTotalActivityByMonth = getCategoryGroupTotalActivityByMonthFactory();
  const getCategoryGroupTotalRemainingByMonth = getCategoryGroupTotalRemainingByMonthFactory();

  const mapStateToProps = (state, props) => {
    return {
      categoryGroupTotalBudgetedByMonth: getCategoryGroupTotalBudgetedByMonth(
        state,
        props
      ),
      categoryGroupTotalActivityByMonth: getCategoryGroupTotalActivityByMonth(
        state,
        props
      ),
      categoryGroupTotalRemainingByMonth: getCategoryGroupTotalRemainingByMonth(
        state,
        props
      ),
      settings: state.settings,
    };
  };
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  null
)(BudgetTableCategoryGroupMonthConnector);
