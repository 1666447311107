import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter, Route } from 'react-router-dom';
import { Switch } from 'react-router';
import { connect } from 'react-redux';
import { authenticate, unauthenticate } from '../../actions/session';
import MatchAuthenticated from './match-authenticated-view';
import RedirectAuthenticated from './redirect-authenticated-view';
import VerifyEmail from '../registration/verify-email';
// import PageResetPassword from '../registration/reset-password';
import {
  PageChangePassword,
  PageResetPassword,
} from '../../v1/pages/reset-password';
import Home from './home-connector';
import Login from '../registration/login-container';
import Signup from '../registration/signup-container';
import RecoverPasswordConnector from '../registration/recover-password-connector';

import '../registration/registration.css';

const queryClient = new QueryClient();

function App({
  authenticate,
  unauthenticate,
  isAuthenticated,
  willAuthenticate,
}) {
  const authProps = { isAuthenticated, willAuthenticate };

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      authenticate();
    } else {
      unauthenticate();
    }

    if (localStorage.getItem('theme') === 'dark') {
      document.querySelector('html').classList.add('dark');
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <BrowserRouter>
          <Switch>
            <Route
              path="/reset-password"
              component={PageResetPassword}
              exact={true}
            />
            <Route
              path="/change-password/:token"
              component={PageChangePassword}
              exact
            />
            <Route
              path="/recover_password"
              component={RecoverPasswordConnector}
              exact={true}
            />
            <Route path="/verify_email" component={VerifyEmail} exact={true} />
            <RedirectAuthenticated
              path="/signup"
              component={Signup}
              {...authProps}
            />
            <RedirectAuthenticated
              path="/login"
              component={Login}
              {...authProps}
            />
            <MatchAuthenticated path="/" component={Home} {...authProps} />
          </Switch>
        </BrowserRouter>
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default connect(
  (state) => ({
    isAuthenticated: state.session.isAuthenticated,
    willAuthenticate: state.session.willAuthenticate,
  }),
  { authenticate, unauthenticate }
)(App);
