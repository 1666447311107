import React from 'react';

import { LoggedOutLayout } from '../../components/layout';
import { ResetPassword } from '../../components/ui/reset-password';

function PageResetPassword() {
  return (
    <LoggedOutLayout>
      <ResetPassword />
    </LoggedOutLayout>
  );
}

export { PageResetPassword };
