import React, { useState } from 'react';
import classNames from 'classnames';

function BudgetTableCategoryDropdown({ deleteCategoryRow }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <div
      className="category-dropdown-toggle mobile-hidden"
      onClick={toggleDropdown}
    >
      {isDropdownOpen && (
        <div className="dropdown-backdrop" onClick={toggleDropdown} />
      )}
      <i className="far fa-ellipsis-v fa-fw fa-lg button-icon" />
      <ul
        className={classNames('category-dropdown-menu category-dropdown-size', {
          [`category-dropdown-open`]: isDropdownOpen,
        })}
      >
        <li onClick={deleteCategoryRow}>
          <i className="fas fa-minus-octagon fa-fw button-icon is-warning" />
          <span className="is-warning">Delete Category</span>
        </li>
      </ul>
    </div>
  );
}

export default BudgetTableCategoryDropdown;
