import Decimal from 'decimal.js';

export const addMissingDecimals = (val) => {
  return new Decimal(val || 0).toFixed(2);
};

export const roundToTwoDecimals = (val, decimal = '.') => {
  const roundedValue = new Decimal(val || 0).toFixed(2);
  return roundedValue.replace(/,|[.]/g, decimal);
};

export const trimTrailingOperators = (stringNumber) => {
  if (
    stringNumber.charAt(stringNumber.length - 1) === '+' ||
    stringNumber.charAt(stringNumber.length - 1) === '-' ||
    stringNumber.charAt(stringNumber.length - 1) === '*' ||
    stringNumber.charAt(stringNumber.length - 1) === '/'
  ) {
    return stringNumber.slice(0, -1);
  }

  return stringNumber;
};
