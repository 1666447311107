import React, { Fragment, Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import ModalBase from '../../../common/modal/modal-base';
import ButtonWarning from '../../../common/button/button-warning';
import ButtonLink from '../../../common/button/button-link';
import 'react-confirm-alert/src/react-confirm-alert.css'; // PageImport css
import 'react-day-picker/lib/style.css';

export default class DayPickerWithRecurrence extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRecurring: props.isRecurring,
      dailyInterval: props.dailyInterval,
      repeatFrequency: props.repeatFrequency,
      selectedWeekdays: props.weekday,
    };
  }

  toggleRecurring = ({ target }) => {
    const isRecurring = target.checked;

    this.props.onRecurringChange({ ...this.state, isRecurring });

    if (target.checked) {
      return this.setState({ isRecurring });
    }

    if (!target.checked) {
      this.props.children.props.restrictDays({ daysOfWeek: [] });
    }

    return this.setState({
      repeatFrequency: '',
      dailyInterval: 1,
      isRecurring,
    });
  };

  changeRepeatFrequency = ({ target }) => {
    this.setState({ repeatFrequency: target.value });
    this.props.onRecurringChange({
      ...this.state,
      repeatFrequency: target.value,
    });
  };

  setWeekdays = ({ target }) => {
    const selectedDays = [...target.selectedOptions].map((day) =>
      parseInt(day.value)
    );
    const weekDays = [0, 1, 2, 3, 4, 5, 6];

    const daysToRestrict = {
      daysOfWeek: weekDays.filter((weekday) => {
        return !selectedDays.includes(weekday);
      }),
    };

    this.setState({
      selectedWeekdays: selectedDays,
    });

    this.props.children.props.restrictDays(daysToRestrict);
    this.props.onRecurringChange({
      ...this.state,
      selectedWeekdays: selectedDays,
    });
  };

  deleteSeriesAlert = (e, data, target) => {
    const { props: childrenProps } = this.props.children;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ModalBase
            onClose={onClose}
            title={'Cancel Recurring Series'}
            className="recurring-cancel"
          >
            <i
              className="alert-icon fas fa-exclamation-circle fa-fw fa-lg"
              style={{ color: '#c26e6b' }}
            />
            <div className="alert-text-wrapper">
              <span className="alert-title">
                Are you sure you want to cancel this series?
              </span>
              <span className="alert-text">
                This recurring transaction and all future instances will be
                removed.
              </span>
              <div className="has-text-right alert-button-container">
                <ButtonLink
                  className="button is-outlined"
                  onClick={() => onClose()}
                >
                  Cancel
                </ButtonLink>
                <ButtonWarning
                  className="button is-danger"
                  onClick={() => {
                    // this.accountDeletedToast();
                    onClose();
                    childrenProps.cancelSeries(childrenProps);
                  }}
                >
                  {`Cancel Recurring Transaction`}
                </ButtonWarning>
              </div>
            </div>
          </ModalBase>
        );
      },
    });
  };

  handleDailyIntervalChange = (e) => {
    this.setState({ dailyInterval: e.target.value });
    this.props.onRecurringChange({
      ...this.state,
      dailyInterval: e.target.value,
    });
  };

  overlayProps() {
    const {
      isRecurring,
      repeatFrequency,
      weekdays,
      dailyInterval,
      onRecurringChange,
      selectedDay,
      classNames,
      ...overlayProps
    } = this.props;
    return overlayProps;
  }

  render() {
    const { classNames: inheritedClasses, children } = this.props;
    const { recurringId } = children.props;
    const {
      isRecurring,
      repeatFrequency,
      selectedWeekdays,
      daysToRestrict,
      dailyInterval,
    } = this.state;

    return (
      <div className={inheritedClasses.overlayWrapper} {...this.overlayProps()}>
        <div className={inheritedClasses.overlay}>
          {children}

          <div className="has-text-left" style={{ padding: '0 16px 16px' }}>
            <Fragment>
              {children.props.newTransaction && (
                <div className="repeat-toggle">
                  <label className="budget-checkbox-container">
                    <input
                      type="checkbox"
                      checked={isRecurring}
                      onChange={this.toggleRecurring}
                    />
                    <span className="budget-checkmark" />
                  </label>
                  {' Repeat Transaction'}
                </div>
              )}
            </Fragment>

            {isRecurring && children.props.newTransaction && (
              <div className="repeat-frequency">
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="">Repeat: </label>
                  </div>
                  <div className="field-body has-text-right">
                    <div
                      className="field"
                      style={{ marginBottom: '0px', marginRight: '8px' }}
                    >
                      <div className="control is-expanded">
                        <div className="select is-small is-fullwidth">
                          <select
                            placeholder="Select"
                            value={repeatFrequency}
                            onChange={this.changeRepeatFrequency}
                          >
                            <option>Select Frequency</option>
                            <option value="daily">Daily</option>
                            <option value="weekly">Weekly</option>
                            <option value="biweekly">2 Weeks</option>
                            <option value="qtrweekly">4 Weeks</option>
                            <option value="monthly">Monthly</option>
                            <option value="quarterly">Quarterly</option>
                            <option value="half_yearly">6 Months</option>
                            <option value="yearly">Yearly</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {repeatFrequency === 'daily' && children.props.newTransaction && (
              <div className="repeat-frequency-detail has-text-left">
                <div className="field is-horizontal has-text-left">
                  <div className="left-field field-label is-normal has-text-left">
                    <label className="">Every </label>
                  </div>
                  <input
                    type="text"
                    className="input-field"
                    placeholder="#"
                    value={dailyInterval}
                    onChange={(e) =>
                      this.setState({ dailyInterval: e.target.value })
                    }
                    onBlur={this.handleDailyIntervalChange}
                  />
                  <div className="right-field field-label is-normal has-text-left">
                    <label className="">day(s) </label>
                  </div>
                </div>
              </div>
            )}

            {(repeatFrequency === 'weekly' ||
              repeatFrequency === 'biweekly' ||
              repeatFrequency === 'qtrweekly') && (
              <div className="repeat-frequency-detail has-text-left">
                <div className="field is-horizontal has-text-left">
                  <div className="left-field field-label is-normal has-text-left">
                    <label className="">
                      {
                        {
                          weekly: 'Every week on',
                          biweekly: 'Every 2nd week on',
                          qtrweekly: 'Every 4th week on',
                        }[repeatFrequency]
                      }
                    </label>
                  </div>
                  <div className="control">
                    <div className="select is-small is-fullwidth">
                      <select
                        placeholder="Select"
                        value={selectedWeekdays}
                        onChange={this.setWeekdays}
                      >
                        <option value="0">Sunday</option>
                        <option value="1">Monday</option>
                        <option value="2">Tuesday</option>
                        <option value="3">Wednesday</option>
                        <option value="4">Thursday</option>
                        <option value="5">Friday</option>
                        <option value="6">Saturday</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {recurringId && (
              <div style={{ marginTop: '20px' }}>
                <button
                  className="button is-outlined is-danger is-small"
                  onClick={this.deleteSeriesAlert}
                >
                  Cancel Series
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
