import { createSelector } from 'reselect';
import moment from 'moment';
import {
  sum,
  sumTimeframeSubcategories,
} from '../utilities/budgeting-formulas';

const getCurrentTimeframe = (state, { monthToDisplay }) => monthToDisplay;

const getAllTimeframes = ({ timeframes }) => timeframes;

const getAllTimeframeSubcategoriesByCategoryGroup = (
  { budget },
  { categoryGroupId }
) => {
  if (
    budget.cc_timeframe_subcategories &&
    budget.cc_timeframe_subcategories.length
  ) {
    // return budget.timeframe_subcategories.filter((timeframe_subcategory) => {
    return budget.cc_timeframe_subcategories;
    // });
  }

  return [];
};

const getTimeframeSubcategoriesByMonth = ({ budget }, { monthToDisplay }) => {
  if (
    budget.cc_timeframe_subcategories &&
    budget.cc_timeframe_subcategories.length > 0
  ) {
    return budget.cc_timeframe_subcategories.filter(
      (cc_timeframe_subcategory) => {
        return cc_timeframe_subcategory.timeframe === monthToDisplay;
      }
    );
  }

  return [];
};

export const getCreditCardGroupTotalBudgetedByMonthFactory = () => {
  return createSelector(
    getTimeframeSubcategoriesByMonth,
    (timeframeSubcategoriesByMonth) => {
      return sum(timeframeSubcategoriesByMonth, 'budgeted');
    }
  );
};

export const getCreditCardGroupTotalActivityByMonthFactory = () => {
  return createSelector(
    getTimeframeSubcategoriesByMonth,
    (timeframeSubcategoriesByMonth) => {
      return sum(timeframeSubcategoriesByMonth, 'spent');
    }
  );
};

export const getCreditCardGroupTotalRemainingByMonthFactory = () => {
  return createSelector(
    [
      getCurrentTimeframe,
      getAllTimeframeSubcategoriesByCategoryGroup,
      getAllTimeframes,
    ],
    (currentTimeframe, timeframeSubcategoriesByCategoryGroup, timeframes) => {
      return sumTimeframeSubcategories(
        currentTimeframe,
        timeframes,
        timeframeSubcategoriesByCategoryGroup,
        (subCat, month) => {
          return (
            subCat.timeframe === month.format('MMYYYY') &&
            moment(subCat.timeframe, 'MMYYYY').isSameOrBefore(
              moment(currentTimeframe, 'MMYYYY')
            )
          );
        }
      );
    }
  );
};
