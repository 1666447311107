import { api } from '../../api';

async function deleteImports(importId) {
  const response = await api.delete('/imports', {
    data: {
      import_id: importId,
    },
  });

  return response.data;
}

export { deleteImports };
