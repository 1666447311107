import { reset } from 'redux-form';
import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import api, { headers } from '../api';
import { ELIXIR_API } from '../constants';
import {
  SERVICE_FETCH_SUCCESS,
  SUBSCRIPTION_INFO_FETCH_SUCCESS,
} from '../action-list';

function setCurrentUser(dispatch, response) {
  localStorage.setItem('token', JSON.stringify(response.meta.token));
  dispatch({ type: 'AUTHENTICATION_SUCCESS', response });
}

function normalizeEmail(email) {
  return email.toLowerCase();
}

export function login(data) {
  const payload = {
    ...data,
    email: normalizeEmail(data.email),
  };

  return (dispatch) =>
    api
      .post('/sessions', payload)
      .then((response) => {
        setCurrentUser(dispatch, response);
        dispatch(reset('login'));
        window.location = '/';
      })
      .catch((err) => {
        return dispatch({ type: 'LOGIN_ERROR', payload: err.error });
      });
}

export function createUser(data, router) {
  return (dispatch) =>
    api
      .post('/users', { ...data, email: normalizeEmail(data.email) })
      .then(({ data }) => {
        dispatch(reset('signup'));
      });
}

export function signup(data, router) {
  return (dispatch) =>
    api
      .post('/users', { ...data, email: normalizeEmail(data.email) })
      .then((response) => {
        mixpanel.identify(response.data.id);
        mixpanel.people.set({
          $email: response.data.email,
          'Billing Plan': 'free trial',
          'Early Access': true,
        });
        mixpanel.track('Sign Up', { 'Is early access': true });

        setCurrentUser(dispatch, response);
        dispatch(reset('signup'));
      });
}

export function logout(router) {
  return (dispatch) =>
    api.delete('/sessions').then(() => {
      mixpanel.track('Log Out');

      localStorage.removeItem('token');
      dispatch({ type: 'LOGOUT' });
      window.location = '/login';
    });
}

export function authenticate() {
  return (dispatch) => {
    dispatch({ type: 'AUTHENTICATION_REQUEST' });
    return api
      .post('/sessions/refresh')
      .then((response) => {
        mixpanel.identify(response.data.id);
        mixpanel.track('Session refresh');
        setCurrentUser(dispatch, response.data);
      })
      .catch((error) => {
        localStorage.removeItem('token');
        window.location = '/login';
      });
  };
}

export const fetchServices = () => {
  return (dispatch) => {
    return axios
      .get(`${ELIXIR_API}/services`, { headers: headers() })
      .then((res) => {
        return dispatch(servicesFetchSuccess(res.data));
      });
  };
};

export function servicesFetchSuccess(payload) {
  return {
    type: SERVICE_FETCH_SUCCESS,
    payload,
  };
}

export const fetchSubscriptionInfo = () => {
  return (dispatch) => {
    return axios
      .get(`${ELIXIR_API}/service/status`, { headers: headers() })
      .then((res) => {
        return dispatch(subscriptionInfoFetchSuccess(res.data));
      });
  };
};

export function subscriptionInfoFetchSuccess(payload) {
  return {
    type: SUBSCRIPTION_INFO_FETCH_SUCCESS,
    payload,
  };
}

export const unauthenticate = () => ({ type: 'AUTHENTICATION_FAILURE' });
