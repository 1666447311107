import React, { useState } from 'react';
import axios from 'axios';
import { ELIXIR_API } from '../../constants';
import { headersNoAuth } from '../../api';

function RecoverPassword({ onSubmit }) {
  const [formData, setFormData] = useState({
    error: null,
    submitting: false,
    email: '',
    password: '',
    passwordConfirm: '',
  });

  const handleEmailChange = ({ target }) => {
    const { value: email } = target;

    setFormData({
      ...formData,
      email,
    });
  };

  const handlePasswordChange = ({ target }) => {
    const { value: password } = target;

    setFormData({
      ...formData,
      password,
    });
  };

  const handlePasswordConfirmChange = ({ target }) => {
    const { value: passwordConfirm } = target;

    formData({
      ...formData,
      passwordConfirm,
    });
  };

  const submitNewPassword = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const { email, password } = formData;
    const token = urlParams.get('token');

    return axios({
      method: 'post',
      url: `${ELIXIR_API}/recover_password`,
      headers: headersNoAuth(),
      data: {
        token,
        password,
      },
    }).then(() => {
      handleLogin({ email, password });
    });
  };

  const handleLogin = (data) => onSubmit(data);

  const { email, password, passwordConfirm, submitting, error } = formData;

  return (
    <>
      <a className="registration-logo" href="http://www.budgetwise.io/">
        <img
          src="/img/budgetwise_logo_light.png"
          alt="Budgetwise - Zero Sum Budgeting and Debt Management"
        />
      </a>
      <div className="registration-wrapper">
        <div className="container is-fluid">
          <div className="registration-area columns is-centered is-mobile">
            <div className="column card-column">
              <h2 className="registration-title has-text-centered">
                Create New Password
              </h2>
              <div className="card registration-form">
                <div className="registration-form-inner">
                  <form>
                    <div className="control">
                      <input
                        name="email"
                        type="email"
                        className="input"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                        style={{ marginBottom: '1rem' }}
                      />
                    </div>
                    <div className="control">
                      <input
                        name="password"
                        type="password"
                        className="input"
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordChange}
                        style={{ marginBottom: '1rem' }}
                      />
                    </div>
                    <div className="control">
                      <input
                        name="confirm_password"
                        type="password"
                        className="input"
                        value={passwordConfirm}
                        onChange={handlePasswordConfirmChange}
                        placeholder="Confirm Password"
                      />
                    </div>
                    <button
                      type="button"
                      disabled={
                        email &&
                        (!password ||
                          password.length < 6 ||
                          password !== passwordConfirm)
                      }
                      style={{ marginTop: '1rem' }}
                      className="button is-primary is-fullwidth"
                      onClick={submitNewPassword}
                    >
                      {submitting ? 'Saving...' : 'Save and Confirm'}
                    </button>
                    {error && (
                      <span
                        style={{
                          marginTop: '6px',
                          fontSize: '14px',
                          color: 'rgb(255, 59, 48)',
                        }}
                      >
                        {error}
                      </span>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecoverPassword;
