import React from 'react';
import moment from 'moment';
import AccountHeaderView from './account-header-view';
import { confirmAlert } from 'react-confirm-alert';
import ModalBase from '../../common/modal/modal-base';
import MobileNewTransactionForm from '../../forms/mobile-new-transaction-form';
import NewTransferForm from '../../forms/new-transfer-form';

function AccountHeaderContainer({
  accounts,
  accountBalanceSheet,
  accountsById,
  accountDetails,
  budget,
  budgetDetails,
  settings,
  payees,
  handleAddTransaction,
  saveTimeframeSubcategory,
  deleteTransactions,
  onToggleSelect,
  savePayee,
  selectedTransactions,
  searchString,
  updateSearchString,
  isSelectEnabled,
  numOfAccounts,
  transactionBeingEdited,
  saveTransaction,
  handleSavePayee,
}) {
  const onAddTransaction = () => {
    const { id: accountId } = accountDetails;

    handleAddTransaction(accountId);
  };

  const formatPayees = (payees) => {
    const formattedPayees = [];

    for (let i = 0; i < payees.length; i++) {
      if (payees[i].name) {
        formattedPayees.push({
          value: payees[i].name,
          label: payees[i].name,
        });
      }
    }

    return formattedPayees;
  };

  const handleAddMobileTransaction = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ModalBase
            onClose={onClose}
            title={'New Transaction'}
            className="new-transaction-modal"
          >
            <MobileNewTransactionForm
              accountDetails={accountDetails}
              budgetDetails={budgetDetails}
              payees={formatPayees(payees)}
              onSavePayee={handleSavePayee}
              settings={settings}
              onSaveMobileTransaction={handleSaveMobileTransaction}
              onClose={onClose}
            />
          </ModalBase>
        );
      },
    });
  };

  const onAddTransfer = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ModalBase
            onClose={onClose}
            title={'New Transfer'}
            className="new-transfer-modal"
          >
            <NewTransferForm
              accounts={accounts}
              accountDetails={accountDetails}
              budgetDetails={budgetDetails}
              onSaveMobileTransaction={handleSaveMobileTransaction}
              payees={formatPayees(payees)}
              settings={settings}
              accountList={budgetDetails.accountList}
              onClose={onClose}
            />
          </ModalBase>
        );
      },
    });
  };

  const handleSaveMobileTransaction = (transaction) => {
    const budgetId = budgetDetails.id;
    const accountId = accountDetails.id;

    // If the account is off budget, don't create a timeframe category, just save it.
    if (accountDetails.off_budget) {
      // Make sure the off_budget field is set to true for all saved transactions as added security
      const updatedTransaction = {
        ...transaction,
        off_budget: true,
      };

      saveTransaction(updatedTransaction, budgetId, accountId);
    }

    if (transaction.category === 'Left to Budget') {
      saveTransaction(transaction, budgetId, accountId);
    } else if (transaction.accountType === 'creditcard') {
      if (transaction.child_transactions.length) {
        const childrenWithDistinctCategories = [];
        const mapOfChildren = new Map();

        for (const child of transaction.child_transactions) {
          if (!mapOfChildren.has(child.category_id)) {
            mapOfChildren.set(child.category_id, true);
            childrenWithDistinctCategories.push({
              ...child,
            });
          }
        }

        for (const child of childrenWithDistinctCategories || []) {
          createTimeframeSubcatIfNoneExists(child, budgetId);
          createCCTimeframeSubcatIfNoneExists(child, budgetId);
        }
      } else {
        createCCTimeframeSubcatIfNoneExists(transaction, budgetId);
        createTimeframeSubcatIfNoneExists(transaction, budgetId);
      }

      saveTransaction(transaction, budgetId, accountId);
    } else if (
      transaction.is_transfer === false &&
      !accountDetails.off_budget
    ) {
      if (
        transaction.child_transactions &&
        transaction.child_transactions.length
      ) {
        const childrenWithDistinctCategories = [];
        const mapOfChildren = new Map();

        for (const child of transaction.child_transactions) {
          if (!mapOfChildren.has(child.category_id)) {
            mapOfChildren.set(child.category_id, true);
            childrenWithDistinctCategories.push({
              ...child,
            });
          }
        }

        for (const child of childrenWithDistinctCategories || []) {
          createTimeframeSubcatIfNoneExists(child, budgetId);
        }
      } else {
        createTimeframeSubcatIfNoneExists(transaction, budgetId);
      }

      saveTransaction(transaction, budgetId, accountId);
    } else if (transaction.is_transfer) {
      saveTransaction(transaction, budgetId, accountId);
    }
  };

  const createTimeframeSubcatIfNoneExists = (transaction, budget_id) => {
    const { category_id, date } = transaction;
    const dateAsTimeframe = moment(date).format('MMYYYY');

    if (!checkIfTimeframeExists(dateAsTimeframe, category_id)) {
      const categoryIdx = budget.subcategories.findIndex(
        (obj) => obj.id === category_id
      );
      const selectedCategory = budget.subcategories[categoryIdx] || {};
      const timeframeSubcategory = {
        timeframe: dateAsTimeframe,
        section_id: selectedCategory.section_id || null,
        category_id,
        budget_id,
      };

      saveTimeframeSubcategory(timeframeSubcategory, budget_id);
    }
  };

  const createCCTimeframeSubcatIfNoneExists = (transaction, budget_id) => {
    const { account_id, date } = transaction;
    const dateAsTimeframe = moment(date).format('MMYYYY');

    if (!checkIfCCTimeframeExists(dateAsTimeframe, account_id)) {
      const timeframeSubcategory = {
        timeframe: dateAsTimeframe,
        cc_account_id: accountDetails.id,
        is_cc: true,
        budget_id,
      };

      saveTimeframeSubcategory(timeframeSubcategory, budget_id);
    }
  };

  const checkIfTimeframeExists = (timeframe, categoryId) => {
    const timeframe_subcategories = budget.timeframe_subcategories || [];
    const timeframe_subcategoriesToRender = timeframe_subcategories.filter(
      (timeframe_subcategory) => {
        return (
          timeframe_subcategory.category_id === categoryId &&
          timeframe_subcategory.timeframe === timeframe
        );
      }
    );

    if (timeframe_subcategoriesToRender.length) {
      return timeframe_subcategoriesToRender[0];
    }
    return null;
  };

  const checkIfCCTimeframeExists = (timeframe, accountId) => {
    const cc_timeframe_subcategories = budget.cc_timeframe_subcategories || [];
    const timeframe_subcategoriesToRender = cc_timeframe_subcategories.filter(
      (cc_timeframe_subcategory) => {
        return (
          cc_timeframe_subcategory.cc_account_id === accountId &&
          cc_timeframe_subcategory.timeframe === timeframe
        );
      }
    );

    if (timeframe_subcategoriesToRender.length) {
      return timeframe_subcategoriesToRender[0];
    }
    return null;
  };

  handleSavePayee = (newPayee) => {
    const { id: budgetId } = budgetDetails;

    savePayee(newPayee, budgetId);
  };

  const handleDeleteTransactions = () => {
    const selectedTransactionList = selectedTransactions.map((e) => e.id);
    const accountId = accountDetails.id;
    const budgetId = budgetDetails.id;

    const transactionsDetailed = accountsById[accountId].transactions.filter(
      (t) => {
        return selectedTransactionList.indexOf(t.id) >= 0;
      }
    );

    deleteTransactions(
      budgetId,
      selectedTransactionList,
      accountId,
      transactionsDetailed,
      budgetDetails.accounts_order
    );
    onToggleSelect();
  };

  return (
    <AccountHeaderView
      accountBalanceSheet={accountBalanceSheet}
      accountDetails={accountDetails}
      deleteSelectedTransactions={handleDeleteTransactions}
      isSelectEnabled={isSelectEnabled}
      addTransaction={onAddTransaction}
      onAddMobileTransaction={handleAddMobileTransaction}
      onAddTransfer={onAddTransfer}
      selectedTransactionsCount={selectedTransactions.length || 0}
      numOfAccounts={numOfAccounts}
      onToggleSelect={onToggleSelect}
      transactionBeingEdited={transactionBeingEdited}
      searchString={searchString}
      settings={settings}
      updateSearchString={updateSearchString}
    />
  );
}

export default AccountHeaderContainer;
