import React from 'react';
import { connect } from 'react-redux';
import { createNewBudget } from '../../../actions/budget';
import NewBudgetContainer from './new-budget-container';

const NewBudgetConnector = ({
  budgetDetails,
  createNewBudget,
  session,
  onClose,
}) => {
  return (
    <NewBudgetContainer
      budgetDetails={budgetDetails}
      createNewBudget={createNewBudget}
      currentUserId={session.currentUser.id}
      onClose={onClose}
    />
  );
};

const mapStateToProps = ({ budgetDetails, session }) => ({
  budgetDetails,
  session,
});

export default connect(
  mapStateToProps,
  { createNewBudget }
)(NewBudgetConnector);
