import React, { useEffect, ChangeEvent, useCallback, useRef } from 'react';
import debounce from 'lodash.debounce';
import { useSearchBarStore } from './SearchBar.store';

interface SearchBarProps {
  callback: (searchTerm: string) => void;
}

function SearchBar({ callback }: SearchBarProps) {
  const inputRef = useRef(null);
  const { searchTerm, setSearchTerm } = useSearchBarStore((state) => state);

  const triggerSearch = useCallback(
    debounce((searchTerm) => {
      callback(searchTerm);

      if (searchTerm !== '') {
        // @ts-ignore
        // inputRef.current && inputRef.current.focus();
      }
    }, 500),
    []
  );

  useEffect(() => {
    triggerSearch(searchTerm);
  }, [searchTerm, triggerSearch]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  return (
    <input
      ref={inputRef}
      className="input-field account-search"
      placeholder="Search..."
      value={searchTerm}
      onChange={handleChange}
    />
  );
}

export { SearchBar };
