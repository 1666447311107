import React, { Component } from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import './left-to-budget-desktop.css';

const printSign = (amount) => {
  const printedSign = Number(amount) >= 0 ? '+' : '-';

  return printedSign;
};

// TODO: extract this out since its used in multiple places
const renderLtb = (ltbBreakdown, globalLtbEnabled) => {
  if (globalLtbEnabled) {
    return (ltbBreakdown && ltbBreakdown.globalLeftToBudget) || 0;
  } else {
    return (ltbBreakdown && ltbBreakdown.leftToBudget) || 0;
  }
};

class LeftToBudgetDesktopView extends Component {
  state = {
    ltbLoading: true,
  };

  componentDidMount() {
    setTimeout(() => {
      //Start the timer
      this.setState({ ltbLoading: false }); //After 1 second, set render to true
    }, 1300);
  }

  renderLtb = () => {
    const {
      lastMonthString,
      ltbBreakdown,
      settings,
      thisMonthString,
    } = this.props;

    const amountLtb = renderLtb(ltbBreakdown, settings.global_ltb);

    return (
      <div className="ltb-wrapper">
        <p className="ltb-line-item">
          <span>Fwd from {lastMonthString}: </span>
          <NumberFormat
            displayType="text"
            className="ltb-amount"
            name="fwdFromLastMonth"
            value={(ltbBreakdown && ltbBreakdown.fwdFromLastMonth) || 0}
            isNumericString={true}
            allowNegative={true}
            placeholder="0.00"
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={settings.thousands}
            decimalSeparator={settings.decimal}
            prefix={
              settings.show_symbol
                ? `${printSign(
                    ltbBreakdown && ltbBreakdown.fwdFromLastMonth
                  )} ${settings.symbol}`
                : `${printSign(ltbBreakdown && ltbBreakdown.fwdFromLastMonth)}`
            }
          />
        </p>
        <p className="ltb-line-item">
          <span>Income for {thisMonthString}: </span>
          <NumberFormat
            displayType="text"
            className="ltb-amount"
            name="fwdFromLastMonth"
            value={(ltbBreakdown && ltbBreakdown.incomeForMonth) || 0}
            isNumericString={true}
            allowNegative={true}
            placeholder="0.00"
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={settings.thousands}
            decimalSeparator={settings.decimal}
            prefix={
              settings.show_symbol
                ? `${printSign(ltbBreakdown && ltbBreakdown.incomeForMonth)} ${
                    settings.symbol
                  }`
                : `${printSign(ltbBreakdown && ltbBreakdown.incomeForMonth)}`
            }
          />
        </p>
        <p className="ltb-line-item">
          <span>Budgeted in {thisMonthString}: </span>
          <NumberFormat
            displayType="text"
            className="ltb-amount"
            name="fwdFromLastMonth"
            value={(ltbBreakdown && ltbBreakdown.budgetedForMonth) || 0}
            isNumericString={true}
            allowNegative={true}
            placeholder="0.00"
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={settings.thousands}
            decimalSeparator={settings.decimal}
            prefix={settings.show_symbol ? `- ${settings.symbol}` : '-'}
          />
        </p>
        {settings.global_ltb && (
          <p className="ltb-line-item">
            <span>
              Budgeted Ahead:{' '}
              <i
                data-balloon-pos="left"
                aria-label="Total Budgeted in the Future"
                className="fas fa-question-circle fa-fw fa-sm"
              />
            </span>
            <NumberFormat
              displayType="text"
              className="ltb-amount"
              name="fwdFromLastMonth"
              value={(ltbBreakdown && ltbBreakdown.budgetedAhead) || 0}
              isNumericString={true}
              allowNegative={true}
              placeholder="0.00"
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={settings.thousands}
              decimalSeparator={settings.decimal}
              prefix={settings.show_symbol ? `- ${settings.symbol}` : '-'}
            />
          </p>
        )}
        <p className="ltb-line-item left-to-budget-line-item">
          <span>
            Left to Budget:{' '}
            {amountLtb < 0 && (
              <i
                data-balloon-pos="left"
                data-balloon-length="large"
                data-balloon-break
                aria-label="It looks like you've budgeted more than the cash you have on hand. To correct, move money from positive categories until Left to Budget is zeroed out."
                className="fas fa-question-circle fa-fw fa-sm"
              />
            )}
          </span>
          <NumberFormat
            displayType="text"
            className={classNames('ltb-amount', {
              'neg-val': amountLtb && amountLtb < 0,
            })}
            name="fwdFromLastMonth"
            value={amountLtb || 0}
            isNumericString={true}
            allowNegative={true}
            placeholder="0.00"
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={settings.thousands}
            decimalSeparator={settings.decimal}
            prefix={settings.show_symbol ? `${settings.symbol}` : ''}
          />
        </p>
      </div>
    );
  };

  render() {
    return (
      <div className="ltb-desktop-month">
        {this.state.ltbLoading ? (
          <i className="far fa-spinner-third fa-spin ltb-loading" />
        ) : (
          this.renderLtb()
        )}
      </div>
    );
  }
}

export default LeftToBudgetDesktopView;
