import React from 'react';
import { connect } from 'react-redux';
import {
  sumActivityTotalsFactory,
  sumBudgetedTotalsFactory,
  sumRemainingTotalsFactory,
  sumUncategorizedFactory,
} from '../../../../selectors/budget-totals';
import BudgetTableHeaderMonthGroupView from './budget-table-header-month-group-view';

const BudgetTableHeaderMonthGroupConnector = ({
  activeMonths,
  isMiddleMonth,
  settings,
  totalActivity,
  totalBudgeted,
  totalRemaining,
  totalUncategorized,
}) => {
  return (
    <BudgetTableHeaderMonthGroupView
      activeMonths={activeMonths}
      isMiddleMonth={isMiddleMonth}
      settings={settings}
      totalActivity={totalActivity}
      totalBudgeted={totalBudgeted}
      totalRemaining={totalRemaining}
      totalUncategorized={totalUncategorized}
    />
  );
};

const makeMapStateToProps = () => {
  const getBudgetedTotalSum = sumBudgetedTotalsFactory();
  const getActivityTotalSum = sumActivityTotalsFactory();
  const getRemainingTotalSum = sumRemainingTotalsFactory();
  const getUncategorizedTotalSum = sumUncategorizedFactory();

  const mapStateToProps = (state, props) => {
    return {
      activeMonths: state.activeMonths,
      settings: state.settings,
      totalActivity: getActivityTotalSum(state, props),
      totalBudgeted: getBudgetedTotalSum(state, props),
      totalRemaining: getRemainingTotalSum(state, props),
      totalUncategorized: getUncategorizedTotalSum(state, props),
    };
  };
  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  null
)(BudgetTableHeaderMonthGroupConnector);
