import React from 'react';
import { connect } from 'react-redux';
import {
  deleteBudget,
  fetchAllBudgets,
  setDefaultBudget,
} from '../../../actions/budget';
import ManageBudgetsContainer from './manage-budgets-container';

const ManageBudgetsConnector = ({
  budgetDetails,
  deleteBudget,
  fetchAllBudgets,
  session,
  setDefaultBudget,
  onClose,
}) => {
  return (
    <ManageBudgetsContainer
      budgetDetails={budgetDetails}
      defaultBudget={session.currentUser.default_budget}
      deleteBudget={deleteBudget}
      fetchAllBudgets={fetchAllBudgets}
      setDefaultBudget={setDefaultBudget}
      userId={session.currentUser.id}
      onClose={onClose}
    />
  );
};

const mapStateToProps = ({ session, budgetDetails }) => ({
  session,
  budgetDetails,
});

export default connect(
  mapStateToProps,
  { deleteBudget, fetchAllBudgets, setDefaultBudget }
)(ManageBudgetsConnector);
