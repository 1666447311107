import { BUDGET_IS_LOADING, FORM_IS_SUBMITTING } from '../action-list';

export function budgetIsLoading(state = false, action) {
  switch (action.type) {
    case BUDGET_IS_LOADING:
      return action.isLoading;

    default:
      return state;
  }
}

export function formIsSubmitting(state = false, action) {
  switch (action.type) {
    case FORM_IS_SUBMITTING:
      return action.isLoading;

    default:
      return state;
  }
}
