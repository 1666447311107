import React, { memo } from 'react';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import BudgetTableCCCategoryMonth from './budget-table-cc-category-month';
import Select from 'react-select';
import { isMobile } from '../../../../utilities/helpers';
import { dueDateOptions } from '../../../../utilities/due-date-options';

const customSelectStyles = {
  option: (base, state) => {
    return {
      ...base,
      backgroundColor: state.isFocused ? '#F4F5F6' : '',
      padding: '4px 8px',
      color: state.isSelected ? '#565a5c' : '#565a5c',
    };
  },
  control: (base, state) => ({
    ...base,
    justifyContent: 'center',
  }),
  placeholder: (base, state) => ({
    ...base,
  }),
  container: (base, state) => ({
    ...base,
    justifyContent: 'center',
    width: '60px',
  }),
  groupHeading: (base, state) => ({
    ...base,
    borderBottom: '1px solid #D8D8D8',
    borderTop: '1px solid #D8D8D8',
    padding: '0px 0px',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '10px',
    color: '#384f66',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  }),
  group: (base, state) => ({
    paddingTop: '0 !important',
    marginTop: '0 !important',
  }),
  input: (base, state) => ({
    ...base,
    justifyContent: 'center',
    textAlign: 'center',
  }),
  menu: (base, state) => ({
    ...base,
    width: 'auto',
    left: '-8px',
    minWidth: '75px',
    wrap: 'none !important',
  }),
  menuList: (base, state) => ({
    ...base,
    width: 'auto',
    minWidth: '70px',
    textAlign: 'center',
    wrap: 'none !important',
    paddingTop: '0 !important',
    marginTop: '0 !important',
  }),
  singleValue: (base, state) => ({
    ...base,
    justifyContent: 'center',
  }),
};

const BudgetTableCCCategoryView = ({
  accountDetails,
  dueDate,
  onEditNotes,
  index,
  monthsDisplayed,
  settings,
}) => {
  return (
    <Draggable draggableId={accountDetails.id} index={index}>
      {(provided, snapshot) => (
        <div
          className={classNames(
            'budget-table-row category-row credit-card-row',
            {
              'category-is-dragging': snapshot.isDragging,
            }
          )}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
        >
          <div className="budget-column-name">
            {accountDetails.name}
            <a
              className={
                accountDetails.budget_notes
                  ? 'add-notes-button always-on-btn'
                  : 'add-notes-button'
              }
              data-balloon-pos="right"
              aria-label={
                accountDetails.budget_notes &&
                `Notes: ${accountDetails.budget_notes}`
              }
              onClick={onEditNotes}
            >
              <i className="fal fa-sticky-note fa-fw fa-lg" />
            </a>
          </div>
          {!isMobile() && !settings.hide_due_date && (
            <div className="budget-column-date">
              {' '}
              <Select
                className="due-date"
                classNamePrefix="select"
                defaultValue={dueDate}
                isClearable={true}
                isSearchable={true}
                backspaceRemovesValue={true}
                styles={customSelectStyles}
                placeholder="---"
                // onChange={handleSaveDueDate}
                components={{ ClearIndicator: null, DropdownIndicator: null }}
                name="due-date"
                options={dueDateOptions}
              />
            </div>
          )}
          {monthsDisplayed.map((monthToDisplay, index) => {
            return (
              <BudgetTableCCCategoryMonth
                key={monthToDisplay}
                accountDetails={accountDetails}
                isMiddleColumn={index === 1}
                monthToDisplay={monthToDisplay}
              />
            );
          })}
        </div>
      )}
    </Draggable>
  );
};

export default memo(BudgetTableCCCategoryView);
