import React, { ReactNode } from 'react';
import cn from 'classnames';
import { Spacer } from '@nextui-org/react';
import { Container } from '../ui/container';
import { PageHeader } from './PageHeader';

interface LoggedOutLayoutProps {
  children: ReactNode;
  title?: string;
  subtitle?: string;
}

function LoggedOutLayout({ title, subtitle, children }: LoggedOutLayoutProps) {
  const classes = cn(
    'flex',
    'flex-col',
    'h-screen',
    'bg-[#262D34]',
    'text-white'
  );

  return (
    <Container className={classes}>
      <img
        className={cn('w-56')}
        src="/img/budgetwise_logo_light.png"
        alt="Budgetwise - Zero Sum Budgeting and Debt Management"
      />
      {children}
    </Container>
  );
}

export { LoggedOutLayout };
