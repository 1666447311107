import { SERVICE_FETCH_SUCCESS } from '../action-list';

const initialState = {
  isAuthenticated: false,
  willAuthenticate: true,
  currentUser: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case 'AUTHENTICATION_REQUEST':
      return {
        ...state,
        willAuthenticate: true,
      };
    case 'AUTHENTICATION_SUCCESS':
      return {
        ...state,
        willAuthenticate: false,
        isAuthenticated: true,
        currentUser: action.response.data,
      };
    case 'AUTHENTICATION_FAILURE':
      return {
        ...state,
        willAuthenticate: false,
      };

    case 'LOGIN_ERROR':
      return {
        ...state,
        errors: action.payload,
      };

    case SERVICE_FETCH_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          services: action.payload,
        },
      };

    case 'LOGOUT':
      return {
        ...state,
        willAuthenticate: false,
        isAuthenticated: false,
        currentUser: {},
      };
    default:
      return state;
  }
}
