import React, { PureComponent } from 'react';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import { Parser } from 'expr-eval';
import {
  roundToTwoDecimals,
  trimTrailingOperators,
} from '../../../../../utilities/number-formatting';
import { isMobile } from '../../../../../utilities/helpers';

const parser = new Parser();

class BudgetTableCCCategoryMonthView extends PureComponent {
  budgetInput = React.createRef();

  state = {
    budgetFieldString:
      (this.props.creditCardDetailsByMonth &&
        this.props.creditCardDetailsByMonth.budgeted) ||
      roundToTwoDecimals(
        (this.props.creditCardDetailsByMonth &&
          this.props.creditCardDetailsByMonth.budgeted) ||
          0
      ),
    isEditingEnabled: false,
  };

  constructor(props) {
    super(props);

    this.budgetInput = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.creditCardDetailsByMonth &&
        this.props.creditCardDetailsByMonth.budgeted) !==
      (prevProps.creditCardDetailsByMonth &&
        prevProps.creditCardDetailsByMonth.budgeted)
    ) {
      this.setState({
        budgetFieldString: roundToTwoDecimals(
          (this.props.creditCardDetailsByMonth &&
            this.props.creditCardDetailsByMonth.budgeted) ||
            0
        ),
      });
    }
  }

  handleBudgetFieldFocus = () => {
    this.setState(
      {
        isEditingEnabled: true,
      },
      () => {
        return (
          this.budgetInput &&
          this.budgetInput.current &&
          this.budgetInput.current.focus()
        );
      }
    );
  };

  handleBudgetEditFieldFocus = (e) => {
    return e.target.select();
  };

  handleBudgetInput = (e) => {
    this.setState({
      budgetFieldString: e.target.value,
    });
  };

  handleSaveBudgetedField = (e) => {
    // TODO: Refactor this horrible mess into a clean function
    if (e.key === 'Enter' || e.type === 'blur') {
      e.preventDefault();
      e.stopPropagation();

      let expressionString;

      // Evaluate, and convert all custom decimals to a . for calculation
      if (this.props.settings.decimal === ',') {
        expressionString = this.state.budgetFieldString
          .toString()
          .replace(/,/g, '.');
        // .replace(/./g, '');
      } else if (this.props.settings.decimal === '-') {
        expressionString = this.state.budgetFieldString
          .toString()
          .replace(/-/g, '.')
          .replace(/./g, '')
          .replace(/,/g, '');
      } else {
        expressionString = this.state.budgetFieldString
          .toString()
          .replace(/,/g, '');
      }

      const trimmedExpression = trimTrailingOperators(expressionString);

      const evaluatedExpression = parser
        .parse(trimmedExpression || '0')
        .evaluate(trimmedExpression);

      this.setState(
        {
          budgetFieldString:
            roundToTwoDecimals(
              evaluatedExpression,
              this.props.settings.decimal
            ) || '0.00',
          savedBudgetAmount: roundToTwoDecimals(evaluatedExpression) || '0.00',
          isEditingEnabled: false,
        },
        () => {
          this.handleBlur();
        }
      );
    }

    if (e.keyCode === 187 && e.shiftKey) {
      // + symbol
      e.preventDefault();
      e.stopPropagation();
      this.setState((prevState) => {
        const formattedCalculation =
          this.state.budgetFieldString === prevState.budgetFieldString
            ? `${this.state.budgetFieldString}+`
            : this.state.budgetFieldString;

        return {
          budgetFieldString: formattedCalculation,
        };
      });
    }

    if (e.keyCode === 189) {
      // - symbol
      e.preventDefault();
      e.stopPropagation();
      this.setState((prevState) => {
        const formattedCalculation =
          this.state.budgetFieldString === prevState.budgetFieldString
            ? `${this.state.budgetFieldString}-`
            : this.state.budgetFieldString;

        return {
          budgetFieldString: formattedCalculation,
        };
      });
    }

    if (e.keyCode === 191) {
      // / symbol
      e.preventDefault();
      e.stopPropagation();
      this.setState((prevState) => {
        const formattedCalculation =
          this.state.budgetFieldString === prevState.budgetFieldString
            ? `${this.state.budgetFieldString}/`
            : this.state.budgetFieldString;

        return {
          budgetFieldString: formattedCalculation,
        };
      });
    }

    if (e.keyCode === 56 && e.shiftKey) {
      // * symbol
      e.preventDefault();
      e.stopPropagation();
      this.setState((prevState) => {
        const formattedCalculation =
          this.state.budgetFieldString === prevState.budgetFieldString
            ? `${this.state.budgetFieldString}*`
            : this.state.budgetFieldString;

        return {
          budgetFieldString: formattedCalculation,
        };
      });
    }
  };

  handleBlur = () => {
    const {
      accountDetails,
      creditCardDetailsByMonth,
      monthToDisplay,
    } = this.props;

    this.setState({ isEditingEnabled: false });

    const updatedCategoryDetails = {
      ...creditCardDetailsByMonth,
      section_id: null,
      is_cc: true,
      cc_account_id: accountDetails.id,
      budget_id: accountDetails.budget_id,
      timeframe: monthToDisplay,
      budgeted: parseFloat(this.state.budgetFieldString.replace(/,/g, '.')),
    };

    this.props.saveTimeframeSubcategory(
      updatedCategoryDetails,
      accountDetails.budget_id
    );
  };

  renderBudgetedField = () => {
    const { settings } = this.props;
    const { isEditingEnabled } = this.state;

    return !isEditingEnabled ? (
      <NumberFormat
        displayType="input"
        className="budget-category-input-editable"
        inputmode="decimal"
        name="budgeted"
        value={this.state.budgetFieldString}
        isNumericString={true}
        placeholder={`0${settings.decimal}00`}
        decimalScale={2}
        allowNegative={true}
        fixedDecimalScale={true}
        thousandSeparator={settings.thousands}
        decimalSeparator={settings.decimal}
        onFocus={this.handleBudgetFieldFocus}
      />
    ) : (
      <input
        className="budget-category-input-editable"
        type="text"
        inputmode="decimal"
        ref={this.budgetInput}
        value={this.state.budgetFieldString}
        onFocus={this.handleBudgetEditFieldFocus}
        onChange={this.handleBudgetInput}
        onKeyDown={this.handleSaveBudgetedField}
        placeholder={`0${settings.decimal}00`}
        onBlur={this.handleSaveBudgetedField}
      />
    );
  };

  render() {
    const {
      creditCardDetailsByMonth,
      creditCardTotalRemainingByMonth,
      isMiddleColumn,
      settings,
    } = this.props;

    const creditCardDetailsByMonthWithDefaults = {
      budgeted: 0,
      spent: 0,
      ...creditCardDetailsByMonth,
    };

    const { budgeted, spent } = creditCardDetailsByMonthWithDefaults;

    return (
      <div
        className={classNames('month-group', {
          'is-middle-column': isMiddleColumn,
        })}
      >
        <div className="budget-column-budgeted">
          <span className="">{this.renderBudgetedField(budgeted)}</span>
        </div>
        {!isMobile() && (
          <div className="budget-column-activity">
            <span className="">
              <NumberFormat
                displayType="text"
                className="budget-category-input-editable"
                name="spent"
                value={spent}
                isNumericString={true}
                placeholder="0.00"
                decimalScale={2}
                allowNegative={true}
                fixedDecimalScale={true}
                thousandSeparator={settings.thousands}
                decimalSeparator={settings.decimal}
              />
            </span>
          </div>
        )}
        <div className="budget-column-remaining">
          <span className="">
            <NumberFormat
              displayType="text"
              className="budget-category-input-editable"
              name="spent"
              value={creditCardTotalRemainingByMonth || '0'}
              isNumericString={true}
              placeholder="0.00"
              decimalScale={2}
              allowNegative={true}
              fixedDecimalScale={true}
              thousandSeparator={settings.thousands}
              decimalSeparator={settings.decimal}
            />
          </span>
        </div>
      </div>
    );
  }
}

export default BudgetTableCCCategoryMonthView;
