import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AccountHeaderContainer from './account-header-container';
import {
  addTransaction,
  deleteBulkTransactions,
  savePayee,
  saveTransaction,
  updateSearchString,
} from '../../../actions/account';
import {
  buildAccountList,
  saveTimeframeSubcategory,
} from '../../../actions/budget';
import { getAccountBalanceSheetFactory } from '../../../selectors/account-balance-sheet';

const AccountHeaderConnector = (props) => {
  const accountId = props.match && props.match.params && props.match.params.id;

  return (
    <AccountHeaderContainer
      accounts={props.accounts}
      accountBalanceSheet={props.accountBalanceSheet}
      accountsById={props.accountsById}
      budget={props.budget}
      budgetDetails={props.budgetDetails}
      buildAccountList={props.buildAccountList}
      currentView={props.currentView}
      deleteTransactions={props.deleteBulkTransactions}
      handleAddTransaction={props.addTransaction}
      handleToggleView={props.handleToggleView}
      accountDetails={props.accountsById && props.accountsById[accountId]}
      budgetId={props.budget}
      selectedTransactions={props.selectedTransactions}
      isSelectEnabled={props.isSelectEnabled}
      numOfAccounts={props.accounts && props.accounts.length}
      onToggleSelect={props.onToggleSelect}
      payees={props.payees}
      saveTransaction={props.saveTransaction}
      saveTimeframeSubcategory={props.saveTimeframeSubcategory}
      savePayee={props.savePayee}
      searchString={props.searchString}
      settings={props.settings}
      transactionBeingEdited={props.transactionBeingEdited}
      updateSearchString={props.updateSearchString}
    />
  );
};

const makeMapStateToProps = () => {
  const getAccountBalanceSheet = getAccountBalanceSheetFactory();

  const mapStateToProps = (state, props) => {
    return {
      accounts: state.accounts,
      accountBalanceSheet: getAccountBalanceSheet(state, props),
      accountsById: state.accountsById,
      budget: state.budget,
      budgetDetails: state.budgetDetails,
      payees: state.payees,
      searchString: state.searchString,
      settings: state.settings,
      transactionBeingEdited: state.transactionBeingEdited,
    };
  };

  return mapStateToProps;
};

export default withRouter(
  connect(
    makeMapStateToProps,
    {
      addTransaction,
      buildAccountList,
      deleteBulkTransactions,
      savePayee,
      saveTransaction,
      saveTimeframeSubcategory,
      updateSearchString,
    }
  )(AccountHeaderConnector)
);
