import { useMutation } from 'react-query';
import { userLogin, userSignup } from './User.req';
import { UserPayload } from './User.types';
import { AxiosError, AxiosResponse } from 'axios';

function useUserSignup() {
  return useMutation<AxiosResponse, AxiosError, UserPayload>(
    (payload: UserPayload) => {
      return userSignup(payload);
    }
  );
}

function useUserLogin() {
  return useMutation((payload: UserPayload) => {
    return userLogin(payload);
  });
}

export { useUserSignup, useUserLogin };
