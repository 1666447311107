import {
  ACCOUNT_FETCH_SUCCESS,
  ACCOUNT_ORDER_CHANGE,
  ACCOUNT_UPDATE_NOTES,
} from '../action-list';

export default function(state = null, action) {
  switch (action.type) {
    case ACCOUNT_FETCH_SUCCESS:
      return action.payload;

    case ACCOUNT_ORDER_CHANGE:
      return action.payload;

    case ACCOUNT_UPDATE_NOTES:
      const newAccountList = [...state];
      const acctIndex = newAccountList.findIndex(
        (obj) => obj.id === action.accountId
      );

      newAccountList[acctIndex].budget_notes = action.budgetNotes;

      return newAccountList;

    default:
      return state;
  }
}
