import React from 'react';
import dashify from 'dashify';
import cn from 'classnames';
import * as Label from '@radix-ui/react-label';
import { useFormContext } from 'react-hook-form';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { Box } from '../box';

interface InputProps {
  label: string;
  name: string;
  type: string;
  className?: string;
  labelClassName?: string;
  defaultValue?: string;
  placeholder?: string;
  error?: string;
}

function Input(props: InputProps) {
  const {
    label,
    type = 'text',
    defaultValue,
    className,
    labelClassName,
    placeholder,
    error,
    ...rest
  } = props;

  const { clearErrors } = useFormContext();

  const htmlId = dashify(label);

  const labelClasses = cn(
    'block',
    // 'text-sm',
    'font-medium',
    'text-gray-700',
    'mb-1',
    labelClassName
  );

  const inputErrorClasses = cn(
    'border-red-300',
    'text-red-900',
    'placeholder-red-300',
    'focus:border-red-500',
    'focus:ring-red-500'
  );

  const inputClasses = cn(
    'block',
    'w-full',
    'rounded-md',
    'border-gray-300',
    'pr-10',
    'shadow-sm',
    'text-gray-700',
    'focus:border-brand-green',
    'focus:ring-brand-green',
    // 'sm:text-sm',
    className,
    !!error && inputErrorClasses
  );

  return (
    <Box>
      <Label.Root className={labelClasses} htmlFor={htmlId}>
        {label}
      </Label.Root>

      <Box className={cn('relative', 'mt-1', 'rounded-md', 'shadow-sm')}>
        <input
          className={inputClasses}
          type={type}
          id={htmlId}
          defaultValue={defaultValue}
          placeholder={placeholder}
          aria-invalid={!!error}
          aria-describedby={!!error ? `${htmlId}-error` : undefined}
          onFocus={() => clearErrors()}
          {...rest}
        />
        {!!error && (
          <div
            className={cn(
              'pointer-events-none',
              'absolute',
              'inset-y-0',
              'right-0',
              'flex',
              'items-center',
              'pr-3'
            )}
          >
            <ExclamationCircleIcon
              className={cn('h-5', 'w-5', 'text-red-500')}
              aria-hidden="true"
            />
          </div>
        )}
      </Box>
      {!!error && (
        <p
          className={cn('mt-2', 'text-sm', 'text-red-600')}
          id={`${htmlId}-error`}
        >
          {error}
        </p>
      )}
    </Box>
  );
}

export { Input };
