import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import SidebarAccountItemConnector from './sidebar-account-item-connector';
import SidebarAccountDropdown from './sidebar-account-dropdown';
import { useTransactionsView } from '../../../../store/transactionsView';

// const DraggablePortal = ({usePortal, children}) => {
//   return usePortal ? <Portal>{children}</Portal> : children;
// };

function SidebarAccountsSectionContainer({
  onDeleteAccount,
  budgetDetails,
  location,
  history,
  clearSearchString,
  accounts,
  accountsById,
  sectionTitle,
  searchString,
  slug,
  settings,
  updateAccount,
  removeCCAccountFromOrder,
}) {
  const { clearTransactionsView } = useTransactionsView((state) => state);
  const deleteAccount = (accountId) => {
    const currentUrl = `/${budgetDetails.id}/accounts/${accountId}`;
    onDeleteAccount(accountId);

    if (location.pathname === currentUrl) {
      history.push('/');
    }
  };

  if (!accounts || !accounts.length) {
    return null;
  }

  const isCreditCard =
    accounts[0].type === 'creditcard' || accounts[0].type === 'debtother';

  return (
    <Droppable droppableId={slug} type={`sidebar-account-${slug}`}>
      {(provided) => (
        <section
          className="sidebar-accounts-section"
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <span>
            <span className="account-section-header">{sectionTitle}</span>
            <hr className="accounts-type-divider" />
            {accounts.map((account, idx) => {
              if (accountsById && accountsById[account.id]) {
                const accountBalById = accountsById[account.id].current_bal;

                return (
                  <Draggable
                    draggableId={`${account.id}-0900`}
                    index={idx}
                    key={idx}
                  >
                    {(provided) => (
                      <li
                        key={account.id}
                        className="account-li"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <NavLink
                          to={`/${budgetDetails.id}/accounts/${account.id}`}
                          className="account-links"
                          onClick={() => {
                            searchString && clearSearchString();
                            localStorage.removeItem('lastDateSelected');
                            // We clear the current view state when switching account.
                            clearTransactionsView();
                          }}
                        >
                          <div className="account-wrapper">
                            <SidebarAccountItemConnector
                              accountId={account.id}
                              accountName={account.name}
                              isOffBudget={account.off_budget}
                              settings={settings}
                              totalUncategorized={
                                account.uncategorized_transactions_total
                              }
                            />
                            <SidebarAccountDropdown
                              accountDetails={account}
                              accountId={account.id}
                              budgetDetails={budgetDetails}
                              deleteAccount={deleteAccount}
                              updateAccount={updateAccount}
                              removeCCAccountFromOrder={
                                removeCCAccountFromOrder
                              }
                            />
                          </div>
                        </NavLink>
                      </li>
                    )}
                  </Draggable>
                );
              }
            })}
          </span>
          {provided.placeholder}
        </section>
      )}
    </Droppable>
  );
}

export default withRouter(SidebarAccountsSectionContainer);
