import React, { PureComponent } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import BudgetTableCategoryView from './budget-table-category-view';
import ModalBase from '../../../common/modal/modal-base';
import ModalFixedHeight from '../../../common/modal/modal-fixed-height';
import EditNotesForm from '../../../forms/edit-notes-form';
import CategoryActivityTable from './category-activity-table/category-activity-table-view';
import { dueDateOptions } from '../../../../utilities/due-date-options';

function BudgetTableCategoryContainer({
  accountsById,
  budgetDetails,
  categoryDetails,
  deleteCategoryRow,
  saveCategoryNotes,
  index,
  monthsDisplayed,
  saveCategory,
  settings,
}) {
  const handleDeleteCategoryRow = () => {
    const { budget_id: budgetId, id: categoryId } = categoryDetails;

    deleteCategoryRow(budgetId, categoryId);
  };

  const handleSaveCategory = (savedValue) => {
    const { budget_id: budgetId } = categoryDetails;

    const updatedCategoryGroup = {
      ...categoryDetails,
      ...savedValue,
    };

    saveCategory(updatedCategoryGroup, budgetId);
  };

  const handleSaveCategoryNotes = (categoryId, notes) => {
    saveCategoryNotes(
      categoryId,
      notes,
      budgetDetails.id,
      budgetDetails.categories_order
    );
  };

  const handleEditNotes = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ModalBase onClose={onClose} title={'Edit Notes'}>
            <EditNotesForm
              categoryDetails={categoryDetails}
              onSave={handleSaveCategoryNotes}
              onClose={onClose}
            />
          </ModalBase>
        );
      },
    });
  };

  const handleViewActivityTransactions = (transactions, month) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ModalFixedHeight
            onClose={onClose}
            title={`Transactions for ${categoryDetails.name} in ${month}`}
          >
            <CategoryActivityTable
              accountsById={accountsById}
              settings={settings}
              transactions={transactions}
            />
          </ModalFixedHeight>
        );
      },
    });
  };

  const handleSaveDueDate = (selectedDate) => {
    const { budget_id: budgetId } = categoryDetails;

    if (categoryDetails.created) {
      if (Array.isArray(selectedDate) && selectedDate.length === 0) {
        const updatedCategoryGroup = {
          ...categoryDetails,
          due_date: null,
        };

        saveCategory(updatedCategoryGroup, budgetId);
      }

      const updatedCategoryGroup = {
        ...categoryDetails,
        due_date: selectedDate.value || null,
      };

      saveCategory(updatedCategoryGroup, budgetId);
    }

    return;
  };

  const renderDueDate = (dueDate) => {
    if (!dueDate) {
      return null;
    }

    // Checks if the due date exists in the available options and grabs that index
    const dueDateIndex = dueDateOptions.findIndex(
      (obj) => obj.value === dueDate
    );

    return dueDateOptions[dueDateIndex];
  };

  const dueDateToRender = renderDueDate(categoryDetails.due_date);

  return (
    <BudgetTableCategoryView
      settings={settings}
      index={index}
      categoryDetails={categoryDetails}
      dueDate={dueDateToRender}
      handleSaveName={handleSaveCategory}
      handleSaveDueDate={handleSaveDueDate}
      onViewActivityTransactions={handleViewActivityTransactions}
      monthsDisplayed={monthsDisplayed}
      onEditNotes={handleEditNotes}
      onDeleteCategoryRow={handleDeleteCategoryRow}
    />
  );
}

export default BudgetTableCategoryContainer;
