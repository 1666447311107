import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  accountSortChange,
  budgetUpdateCategoriesOrder,
  budgetUpdateSubcategoriesOrder,
  fetchBudget,
  fetchCategories,
  fetchInitialLtbBreakdownByMonth,
  fetchSectionsWithCategories,
  fetchSettings,
  fetchSubcategories,
  fetchTimeframeSubcategories,
  renameBudget,
  setBudgetTable,
  updateCCOrderByList,
  updateCCOrderInGroup,
  updateSingleSubcategoryOrderInGroup,
  updateSubcategoryOrder,
  updateSubcategoryParentId,
} from '../../actions/budget';
import {
  deleteAccount,
  fetchAccounts,
  fetchAllAccountsAndTransactions,
  fetchAllTransactions,
  saveAccount,
  updateAccount,
} from '../../actions/account';
import { sumTotalIncomeByMonthsFactory } from '../../selectors/left-to-budget-calc';
import { fetchPayees } from '../../actions/payee';
import { fetchServices, logout } from '../../actions/session';
import routes from '../../routes';
import Sidebar from './sidebar/sidebar';
import ContentAreaView from './content-area/content-area-view';
import { useUserStore } from '../../v1/user';
import { api } from '../../v1/api';
import {
  SelectPlanFullScreen,
  useSubscriptionPlan,
} from '../../v1/components/ui/subscription';
import { Plans } from '../../v1/components/ui/subscription/Subscription.types';

function Home({
  currentUser,
  fetchBudget,
  fetchSectionsWithCategories,
  fetchSettings,
  fetchPayees,
  fetchCategories,
  fetchInitialLtbBreakdownByMonth,
  fetchAllAccountsAndTransactions,
  fetchTimeframeSubcategories,
  fetchServices,
  budget,
  budgetDetails,
  saveAccount,
  logout,
  budgetUpdateCategoriesOrder,
  updateSubcategoryOrder,
  updateSingleSubcategoryOrderInGroup,
  updateSubcategoryParentId,
  isAuthenticated,
  subscriptionDetails,
  isLoading,
  settings,
  updateCCOrderByList,
  updateCCOrderInGroup,
}) {
  const mounted = useRef(null);
  const [isSidebarOpen, openSidebar] = useState(true);
  const {
    data: sub,
    isLoading: subInfoLoading,
    isSuccess,
  } = useSubscriptionPlan();
  const userStore = useUserStore((state) => state);

  useEffect(() => {
    // api.get('/check-trial').then((response) => {
    //   console.log('response', response);
    // });

    if (!mounted.current) {
      // Component did mount
      const defaultBudgetId = currentUser.default_budget;

      userStore.setDefaultBudgetId(defaultBudgetId);

      fetchBudget(defaultBudgetId);
      fetchSectionsWithCategories(defaultBudgetId);
      fetchSettings(defaultBudgetId);
      fetchPayees(defaultBudgetId);
      fetchCategories(defaultBudgetId);
      fetchInitialLtbBreakdownByMonth(defaultBudgetId);
      fetchAllAccountsAndTransactions(defaultBudgetId);
      fetchTimeframeSubcategories(defaultBudgetId);
      fetchServices();

      mounted.current = true;
    } else {
      // Component did update

      if (currentUser.services === null) {
        // fetchServices();
      }
    }
  }, []);

  const handleSaveAccount = (account) => {
    saveAccount(account, budgetDetails);
  };

  const handleLogout = () => logout();

  const toggleSidebar = () => openSidebar(!isSidebarOpen);

  const onDragEnd = ({ destination, source, draggableId, type }) => {
    if (!destination) {
      return;
    }

    // see if location of draggable changed
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === 'category-group') {
      const newCategoryOrder = Array.from(budgetDetails.categories_order);
      newCategoryOrder.splice(source.index, 1);
      newCategoryOrder.splice(destination.index, 0, draggableId);

      budgetUpdateCategoriesOrder(
        budget.categories,
        newCategoryOrder,
        budgetDetails.id
      );

      return;
    }

    if (type === 'category') {
      const prevCatGroup = budget.categories.filter(
        (group) => group.id === source.droppableId
      )[0];

      const newCatGroup = budget.categories.filter(
        (group) => group.id === destination.droppableId
      )[0];

      if (source.droppableId === destination.droppableId) {
        const newSubcatOrder = Array.from(prevCatGroup.subcategories_order);

        newSubcatOrder.splice(source.index, 1);
        newSubcatOrder.splice(destination.index, 0, draggableId);

        const updatedCatGroup = {
          ...prevCatGroup,
          subcategories_order: newSubcatOrder,
        };

        updateSubcategoryOrder(
          budgetDetails.id,
          source.droppableId,
          updatedCatGroup
        );

        updateSingleSubcategoryOrderInGroup(
          budgetDetails.id,
          draggableId,
          destination.droppableId,
          destination.index
        );

        return;
      }

      // moving from one list to another
      const prevCatGroupIds = Array.from(prevCatGroup.subcategories_order);

      prevCatGroupIds.splice(source.index, 1);

      const updatedPrevCatGroup = {
        ...prevCatGroup,
        subcategories_order: prevCatGroupIds,
      };

      const newCatGroupIds = Array.from(newCatGroup.subcategories_order);

      newCatGroupIds.splice(destination.index, 0, draggableId);

      const updatedNewcatGroup = {
        ...newCatGroup,
        subcategories_order: newCatGroupIds,
      };

      updateSubcategoryParentId(
        budgetDetails.id,
        draggableId,
        destination.droppableId
      );

      updateSubcategoryOrder(
        budgetDetails.id,
        source.droppableId,
        updatedPrevCatGroup,
        updatedNewcatGroup
      );

      updateSingleSubcategoryOrderInGroup(
        budgetDetails.id,
        draggableId,
        destination.droppableId,
        destination.index
      );

      return;
    }

    if (type === 'credit-card') {
      const newCCOrder = Array.from(budgetDetails.cc_order);

      newCCOrder.splice(source.index, 1);
      newCCOrder.splice(destination.index, 0, draggableId);

      updateCCOrderByList(newCCOrder);
      updateCCOrderInGroup(budgetDetails.id, null, newCCOrder);

      return;
    }
  };

  const isMobile = window.innerWidth < 1024;

  if (isLoading && subInfoLoading) {
    return <div className="loading-spinner" />;
  }

  // Trial expired
  if (isSuccess && sub.current_plan === Plans.TRIAL && sub.trial_expired) {
    return <SelectPlanFullScreen />;
  }

  // Cancelled
  if (isSuccess && sub.current_plan !== Plans.TRIAL && !sub.is_active) {
    return <SelectPlanFullScreen />;
  }

  return (
    <>
      {isAuthenticated && (
        <Router>
          <Fragment>
            <DragDropContext onDragEnd={onDragEnd}>
              <Sidebar
                routes={routes}
                onSaveAccount={handleSaveAccount}
                isActive={isSidebarOpen}
                settings={settings}
                subscriptionDetails={subscriptionDetails}
                onLogout={handleLogout}
              />
              <>
                <ContentAreaView
                  routes={routes}
                  sidebarActive={isSidebarOpen}
                  onToggleSidebar={toggleSidebar}
                  currentUser={currentUser}
                  subscriptionDetails={subscriptionDetails}
                />
              </>
            </DragDropContext>
          </Fragment>
        </Router>
      )}
      <div
        className={`sidebar-overlay ${
          !isSidebarOpen && isMobile ? 'enabled' : 'disabled'
        }`}
        onClick={toggleSidebar}
      />
    </>
  );
}

const makeMapStateToProps = () => {
  const setTotalIncomeByMonths = sumTotalIncomeByMonthsFactory();

  return (state) => {
    return {
      isAuthenticated: state.session.isAuthenticated,
      currentUser: state.session.currentUser,
      budget: state.budget,
      budgetDetails: state.budgetDetails,
      subscriptionDetails: state.subscriptionDetails,
      isLoading: state.budgetIsLoading,
      settings: state.settings,
      timeframes: state.timeframes,
      totalIncomeByMonth: setTotalIncomeByMonths(state),
    };
  };
};

export default connect(makeMapStateToProps, {
  accountSortChange,
  budgetUpdateCategoriesOrder,
  budgetUpdateSubcategoriesOrder,
  updateSubcategoryOrder,
  updateSingleSubcategoryOrderInGroup,
  updateSubcategoryParentId,
  updateCCOrderByList,
  updateCCOrderInGroup,
  fetchBudget,
  fetchSettings,
  fetchCategories,
  fetchSubcategories,
  fetchSectionsWithCategories,
  fetchInitialLtbBreakdownByMonth,
  fetchTimeframeSubcategories,
  setBudgetTable,
  renameBudget,
  deleteAccount,
  updateAccount,
  fetchAccounts,
  fetchPayees,
  fetchServices,
  fetchAllAccountsAndTransactions,
  fetchAllTransactions,
  saveAccount,
  logout,
})(Home);
