import React from 'react';

import { LoggedOutLayout } from '../../components/layout';
import { ChangePassword } from '../../components/ui/reset-password';

// @ts-ignore
function PageChangePassword(props) {
  return (
    <LoggedOutLayout>
      <ChangePassword token={props.match.params.token} />
    </LoggedOutLayout>
  );
}

export { PageChangePassword };
