import React from 'react';
import { Row, Modal, Spacer, Text } from '@nextui-org/react';
import { Heading } from '../heading';
import { SelectPlan } from './SelectPlan';
import { useSubscriptionPlan } from './SelectPlan.queries';

function SelectPlanFullScreen() {
  const { data: subscription, isSuccess, isLoading } = useSubscriptionPlan();
  return (
    <Modal preventClose blur aria-labelledby="modal-title" open={true}>
      <Modal.Header>
        <Heading h2>Please Subscribe</Heading>
      </Modal.Header>

      <Modal.Body>
        <Row css={{ flexDirection: 'column' }}>
          <Text>
            Your trial has expired. Please choose a plan to continue using
            Budgetwise.
          </Text>

          <Spacer y={1} />

          <SelectPlan
            email={isSuccess && subscription.email}
            daysLeft={isSuccess && subscription.days_left}
            isLoading={isLoading}
          />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div />
      </Modal.Footer>
    </Modal>
  );
}

export { SelectPlanFullScreen };
