import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { PropTypes } from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Link, useHistory } from 'react-router-dom';
import Input from '../common/input';
import ButtonPrimary from '../common/button/button-primary';
import ButtonLink from '../common/button/button-link';
import { useUserLogin } from '../../v1/user';

function LoginForm({ dispatch }) {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { mutate, isLoading } = useUserLogin();

  const handleSubmit = (event) => {
    const loginToast = toast.loading('Logging in...');
    event.preventDefault();
    const payload = {
      email,
      password,
    };

    mutate(payload, {
      onSuccess: (response) => {
        toast.success('Login successful', {
          id: loginToast,
        });
        localStorage.setItem('token', JSON.stringify(response[0].meta.token));
        // https://react-redux.js.org/using-react-redux/connect-mapdispatch#approaches-for-dispatching
        dispatch({ type: 'AUTHENTICATION_SUCCESS', response: response[0] });
        history.push('/');
      },
      onError: (error) => {
        const message = `${error.message}. Please try again.`;
        toast.error(message, {
          id: loginToast,
        });
        dispatch({ type: 'LOGIN_ERROR', payload: error });
      },
    });
  };
  return (
    <Fragment>
      <a className="registration-logo" href="https://www.budgetwise.io/">
        <img src="/img/budgetwise_logo_light.png" alt="Budgetwise logo" />
      </a>
      <div className="registration-wrapper">
        <div className="container is-fluid">
          <div className="registration-area columns is-centered is-mobile">
            <div className="column card-column mx-auto">
              <h2 className="registration-title has-text-centered">Sign In</h2>
              <div className="card registration-form">
                <div className="registration-form-inner">
                  <form onSubmit={handleSubmit}>
                    <label className="input-label">Email</label>
                    <Field
                      name="email"
                      type="text"
                      component={Input}
                      placeholder="Email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    <label className="input-label">Password</label>
                    <Field
                      name="password"
                      type="password"
                      component={Input}
                      placeholder="Password"
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    <ButtonPrimary
                      type="submit"
                      className="registration-form-button"
                      isDisabled={isLoading}
                    >
                      {isLoading ? 'Logging in...' : 'Login'}
                    </ButtonPrimary>
                  </form>
                  <div className="has-text-centered forgot-password-box">
                    <Link className="forgot-password-link" to="/reset-password">
                      <ButtonLink>Forgot password?</ButtonLink>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="no-account">
                <span className="no-account-text">Don't have an account?</span>
                <Link to="/signup">
                  <ButtonLink>Create Account</ButtonLink>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  }
  if (!values.password) {
    errors.password = 'Required';
  }
  return errors;
};

LoginForm.propTypes = {
  errors: PropTypes.node,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

export default connect(
  null,
  null
)(
  reduxForm({
    form: 'login',
    validate,
  })(LoginForm)
);
