import React, { memo } from 'react';
import { getMonthsToDisplay } from '../../../../utilities/helpers';
import { isMobile } from '../../../../utilities/helpers';
import BudgetTableHeaderMonthGroupConnector from './budget-table-header-month-group-connector';

const BudgetTableHeaderView = ({ activeMonths, settings }) => {
  const monthsDisplayed = getMonthsToDisplay(activeMonths, settings.month_view);

  return (
    <div className="budget-table-row header-row">
      <div className="budget-column-name">
        <br />
        Name
      </div>
      {!isMobile() && !settings.hide_due_date && (
        <div className="budget-column-date">
          <br />
          Due Date
        </div>
      )}
      {monthsDisplayed.map((monthToDisplay, index) => {
        return (
          <BudgetTableHeaderMonthGroupConnector
            key={monthToDisplay}
            isMiddleMonth={index === 1}
            monthToDisplay={monthToDisplay}
          />
        );
      })}
    </div>
  );
};

export default memo(BudgetTableHeaderView);
